import { Review, IReviewJSON } from '../models/Review';

import axios, { AxiosInstance } from 'axios';
import { createContext } from 'react';

export class ReviewRepository {

  static readonly $ = new ReviewRepository(axios);
  static readonly Context = createContext(ReviewRepository.$);

  constructor(private readonly a: AxiosInstance) {
  }

  async findAll(scriptId: number) {
    const { data } = await this.a.get<IReviewJSON[]>(`/scripts/${scriptId}/reviews`);

    return data.map(Review.build);
  }

  async save(body: string, scriptId: number, referrerId?: number) {
    const { data } = await this.a.post<IReviewJSON>(
      referrerId ?
        `/scripts/${scriptId}/reviews/${referrerId}/replies` :
        `/scripts/${scriptId}/reviews`,
      { review: { body } }
    );

    return Review.build(data);
  }

}
