import { ChoiceSolution, IChoiceSolutionJSON } from './ChoiceSolution';
import { EssaySolution, IEssaySolutionJSON } from './EssaySolution';
import { MultiAnswerSolution, IMultiAnswerSolutionJSON } from './MultiAnswerSolution';
import { RatingSolution, IRatingSolutionJSON } from './RatingSolution';
import { PredicateSolution, IPredicateSolutionJSON } from './PredicateSolution';

export function fromJSON(data: ISolutionJSON) {
    if (isMultiAnswer(data)) {
        return MultiAnswerSolution.fromJSON(data);
    }

    if (isChoice(data)) {
        return ChoiceSolution.fromJSON(data);
    }
    if (isRating(data)) {
        return RatingSolution.fromJSON(data);
    }
    if (isPredicate(data)) {
        return PredicateSolution.fromJSON(data);
    }

    return EssaySolution.fromJSON(data);
}

export type Solution = ChoiceSolution | EssaySolution | MultiAnswerSolution | RatingSolution | PredicateSolution;

export type ISolutionJSON = IChoiceSolutionJSON | IEssaySolutionJSON | IMultiAnswerSolutionJSON | IRatingSolutionJSON;

function isChoice(s: ISolutionJSON): s is IChoiceSolutionJSON {
    return (s as IChoiceSolutionJSON).option !== undefined;
}

// eslint-disable-next-line
function isEssay(s: ISolutionJSON): s is IEssaySolutionJSON {
    return (s as IEssaySolutionJSON).paragraph !== undefined;
}

function isRating(s: ISolutionJSON): s is IRatingSolutionJSON {
    return (s as IRatingSolutionJSON).rating !== undefined;
}

function isMultiAnswer(s: ISolutionJSON): s is IMultiAnswerSolutionJSON {
    return (s as IMultiAnswerSolutionJSON).options !== undefined;
}

function isPredicate(s: ISolutionJSON): s is IPredicateSolutionJSON {
    return (s as IPredicateSolutionJSON).checked !== undefined;
}
