import { DigestScoop } from './DigestScoop';

export class ScriptHit {
    public static build(data: IScriptHitJSON) {
        return new ScriptHit(
            data.id,
            (data.formula && DigestScoop.build(data.formula)) || undefined,
            data.library_size,
            data.target,
            data.potency,
        );
    }

    constructor(
        readonly id: number,
        readonly formula: DigestScoop | undefined,
        readonly librarySize: string,
        readonly target: string,
        readonly potency: string) {
    }
}

export interface IScriptHitJSON {
    readonly id: number;
    readonly formula?: string;
    readonly library_size: string;
    readonly target: string;
    readonly potency: string;
}
