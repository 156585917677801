import React from 'react';
import styled from 'styled-components';

import { Option } from '../../models/Option';
import { ChoiceScalar } from '../../models/ChoiceScalar';
import { ChoiceSolution } from '../../models/ChoiceSolution';
import { EssaySolution } from '../../models/EssaySolution';
import { MultiAnswerSolution } from '../../models/MultiAnswerSolution';
import { Solution } from '../../models/Solution';

import OptionItem from './OptionItem';

import checked from './checked.svg';
import unchecked from './unchecked.svg';

interface P {
  it: ChoiceScalar;
  solution?: Solution;
  onChange(solution: Solution): void;
}

function ChoiceScalarItem(props: P) {
  const { it, solution, onChange } = props;
  const scratch = solution && (solution as EssaySolution | MultiAnswerSolution).paragraph;

  const handleToggle = (option: Option, on: boolean) => {
    if (!it.multiple) {
      onChange(new ChoiceSolution(it.interest, option));

      return;
    }

    onChange(
      (solution instanceof MultiAnswerSolution ? solution : new MultiAnswerSolution(it.interest, [], undefined)).toggle(option, on)
    );
  };
  const handleScratch = (value?: string) => {
    if (!it.multiple) {
      onChange(new EssaySolution(it.interest, value || ''));
    }

    onChange(
      (solution instanceof MultiAnswerSolution ? solution : new MultiAnswerSolution(it.interest, [], undefined)).amend(value)
    );
  };

  return (
    <React.Fragment>
      {it.options.rollOut().map(opt => (
        <OptionItem
          it={opt}
          multiple={it.multiple}
          checked={opt.isChecked(solution)}
          onChange={handleToggle}
          key={opt.permanentID}
        />
      ))}
      {it.scratch && (
        <Scratch>
          <label
            className={undefined !== scratch ? 'checked': ''}
            onClick={() => handleScratch(undefined !== scratch ? undefined : '')}
          >
            {it.scratch}
          </label>
          <input
            value={scratch || ''}
            onFocus={() => handleScratch(scratch || '')}
            onChange={e => handleScratch(e.currentTarget.value)}
          />
        </Scratch>
      )}
    </React.Fragment>
  );
}

export default ChoiceScalarItem;

const Scratch = styled.div`
  display: block;
  margin-top: 10px;
  position: relative;

  label {
    padding-left: 2rem;

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      margin-top: -8px;
      background: url(${unchecked}) no-repeat center center / contain;
      position: absolute;
      top: 50%;
      left: 0;
    }
  
    &.checked:before {
      background-image: url(${checked});
    }
  }

  input {
    margin-left: .5rem;
    border: none;
    border-bottom: 1px solid #333;

    &:focus {
      outline: none;
    }
  }
`;
