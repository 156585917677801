import { Scalar, IScalarJSON } from './Scalar';
import { Interest } from './Interest';
import { Footnote } from './Footnote';
import { Solution } from './Solution';
import { PredicateSolution } from './PredicateSolution';
import { Localizable } from './Localizable';
import moment from 'moment';

export class Predicate extends Scalar implements Localizable<Predicate> {
    static fromJSON(data: IPredicateJSON) {
        return new Predicate(
            data.permanent_id,
            data.serial_number,
            Interest.fromJSON(data.interest),
            data.required,
            (data.footnotes || []).map(Footnote.fromJSON),
            moment(data.created_at),
        );
    }

    localize(locale: string) {
        return new Predicate(
            this.permanentID,
            this.serialNumber,
            this.interest.localize(locale),
            this.required,
            this.footnotes.map(f => f.localize(locale)),
            this.createdAt,
        );
    }

    solve(solutions?: Record<string, Solution>): boolean {
        return !!(
            solutions
                && solutions[this.interest.permanentID]
                && (solutions[this.interest.permanentID] as PredicateSolution).checked
        );
    }
}

export interface IPredicateJSON extends IScalarJSON {
    dtype: 'predicate',
}
