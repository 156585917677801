import React, { Component, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Fragment } from './models/Fragment';

const Placeholder = styled.p`
  margin-top: 200px !important;
  text-align: center;
  line-height: 20px;
  color: #999;

  b {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    color: #666;
  }
`;

const Item = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  &:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

interface P<T> {
  className?: string;
  fragment: Fragment<T>;
  empty: string;
  start?: string;
  children: (value: T) => ReactElement;
}

class Catalog<T extends { id: number; }> extends Component<P<T>> {

  render() {
    const { className, fragment, empty, start, children } = this.props;

    return (
      <div className={className}>
        {!fragment.total ? (
          <Placeholder>
            <b>
              <FormattedMessage id={empty} />
            </b>
            {start && (
              <FormattedMessage id={start} />
            )}
          </Placeholder>
        ) : fragment.data.map(value => (
          <Item key={value.id}>
            {children(value)}
          </Item>
        ))}
      </div>
    );
  }

}

export default Catalog;
