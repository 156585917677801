import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Form, InputOnChangeData, Message, TextAreaProps } from 'semantic-ui-react';
import styled from 'styled-components';

import { InstitutionForm } from '../../models/InstitutionForm';
import { InstitutionRepository } from '../../repositories/InstitutionRepository';

import ScoopImageInput from '../scoop/ImageInput';

import add from '../scoop/add.png';

import Layout from '../../PrivateLayout';
import Panel from '../../Panel';
import MyHelmet from '../widgets/MyHelmet';

type P = RouteComponentProps & InjectedIntlProps & {
  className?: string;
};

interface IInstitutionEditState {
  form: InstitutionForm;
  loading: boolean;
}

class InstitutionEdit extends React.Component<P & { repo: InstitutionRepository }, IInstitutionEditState> {

  public state = { form: new InstitutionForm(), loading: false };

  private setTitle = this.patchString('title');
  private setImage = this.patch('image');
  private setUrl = this.patchString('url');
  private setSynopsis = this.patchString('synopsis');

  public componentDidMount() {
    this
      .props.repo
      .fetch()
      .then(institution => this.setState({ form: institution || new InstitutionForm(), loading: false }))
    ;
    this.setState({ loading: true });
  }

  public render() {
    const { className, intl: { formatMessage } } = this.props;
    const { form, loading } = this.state;

    return (
      <Layout breadcrumb={[ 'nav.become_provider' ]} className={className}>
        <MyHelmet title="nav.become_provider" />

        <Panel width={16} header={<FormattedMessage id="nav.become_provider" />}>
          <Form loading={loading} onSubmit={this.submit}>
            <Form.Input
              required
              label={formatMessage({ id: 'institution.title' })}
              type="text"
              value={form.title}
              onChange={this.setTitle}
            />
            <Form.Field>
              <label>
                <FormattedMessage id="institution.image" />
              </label>
              <ScoopImageInput
                trigger={<Link to="#" className="add-image"><img src={add} alt="add" /></Link>}
                value={form.image}
                onChange={this.setImage}
              />
              <Message info>
                <Message.Content>
                  <FormattedMessage
                    id="institution.image_prompt"
                    values={{
                      width: '600px',
                      size: '900KB',
                      format: 'JPG/PNG/SVG',
                    }}
                  />
                </Message.Content>
              </Message>
            </Form.Field>
            <Form.Input
              required
              label={formatMessage({ id: 'institution.url' })}
              type="text"
              value={form.url}
              onChange={this.setUrl}
            />
            <Form.TextArea
              required
              label={formatMessage({ id: 'institution.synopsis' })}
              value={form.synopsis}
              onChange={this.setSynopsis}
            />

            <Form.Button primary type="submit">
              <FormattedMessage id="save" />
            </Form.Button>
          </Form>
        </Panel>
      </Layout>
    );
  }

  private patchString<K extends 'title' | 'url' | 'synopsis'>(key: K) {
    return (_: React.SyntheticEvent<{}>, { value }: InputOnChangeData | TextAreaProps) =>
      this.patch(key)(value as string);
  }

  private patch<K extends keyof InstitutionForm>(key: K) {
    return (value: InstitutionForm[K]) =>
      this.setState({ form: this.state.form.patch(key, value) });
  }

  private submit = (e: React.FormEvent<{}>) => {
    e.preventDefault();

    const { form } = this.state;
    const { repo, history } = this.props;
    repo
      .persist(form)
      .then(() => history.push('/institution'))
      .catch(() => this.setState({ loading: false }))
    ;
    this.setState({ loading: true });
  }

}

export default styled(injectIntl((props: P) => (
  <InstitutionRepository.Context.Consumer>
    {repo => (
      <InstitutionEdit repo={repo} {...props} />
    )}
  </InstitutionRepository.Context.Consumer>
)))`
`;
