import * as React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Grid, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import URI from 'urijs';

import { IAppState } from '../../AppState';
import { Kit } from '../../models/Kit';
import { Subscriber } from '../../models/Subscriber';
import { KitRepository } from '../../repositories/KitRepository';
import { Search } from '../../Util';

import Layout from '../../Layout';
import Panel from '../../Panel';
import KitItem from './Item';
import InstitutionPanel from './InstitutionPanel';
import Scoop from './Scoop';

const Wrapper = styled.div`
  padding-bottom: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 40px 0;

  & > p {
    line-height: 22px;
    color: #666;
  }
`;

type P = RouteComponentProps<{ id: string }> & InjectedIntlProps & {
  className?: string;
  subscriber?: Subscriber | null;
};

interface IKitShowState {
  kit?: Kit;
}

class KitShow extends React.Component<P & { repo: KitRepository }, IKitShowState> {

  state: IKitShowState = {};

  componentDidMount() {
    this.find();
  }

  componentDidUpdate(prevProps: P) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.find();
    }
  }

  render() {
    const { className, intl: { formatMessage }, location: { pathname }, subscriber } = this.props;
    const { kit } = this.state;
    const params = URI.parseQuery(this.props.location.search) as Search<'success'>;

    return (
      <Layout breadcrumb={[ ['/_/kits', 'kit.index'], 'kit.show' ]} className={className}>
        <Helmet>
          <title>
            {kit ? kit.title : formatMessage({ id: 'kit.show' })}
            - {formatMessage({ id: 'nav.del' })}
            - DELopen
          </title>
          {kit && (
            <meta name="description" content={kit.synopsis} />
          )}
        </Helmet>

        <Message warning>
          <p><FormattedMessage id="services.notice" values={{ br: <br /> }} /></p>
        </Message>

        <Grid>
          <Panel width={10} header={<FormattedMessage id="kit.show" />}>
            {kit && (
              <React.Fragment>
                {params.success && (
                  <Message positive>
                    <Message.Content>
                      <FormattedMessage id="kit.application_ok" values={{ institution: kit.subscriber.i9n, br: <br /> }} />
                    </Message.Content>
                  </Message>
                )}

                <Wrapper>
                  <KitItem kit={kit} partial />
                  <p>{kit.synopsis}</p>
                  {kit.supplements.map(s => (
                    <Scoop supplement={s} key={s.id}>
                      {!subscriber ? (
                        <Download href={URI.build({ path: '/sign-in', query: URI.buildQuery({ next: pathname }) })}>
                          <FormattedMessage id="download" />
                        </Download>
                      ) : (
                        <Download href={`/api/v1/supplements/${s.id}/file`} target="_blank">
                          <FormattedMessage id="download" />
                        </Download>
                      )}
                    </Scoop>
                  ))}
                </Wrapper>

                <Link to={`/kits/${kit.id}/trials/new`} className="ui primary button">
                  <FormattedMessage id="kit.apply" />
                </Link>
              </React.Fragment>
            )}
          </Panel>

          <InstitutionPanel institution={kit && kit.subscriber.canonicalInstitution} />
        </Grid>
      </Layout>
    );
  }

  private async find() {
    const { repo, match: { params: { id } } } = this.props;
    const kit = await repo.find(id);

    this.setState({ kit });
  }

}

export default injectIntl(connect(
  ({ subscriber }: IAppState) => ({ subscriber }),
)((props: P) => (
  <KitRepository.Context.Consumer>
    {repo => (
      <KitShow repo={repo} {...props} />
    )}
  </KitRepository.Context.Consumer>
)));

const Download = styled.a`
  margin-left: 30px;
`;
