import { LocalLabel, ILocalLabelJSON } from './LocalLabel';
import { Localizable, localize } from './Localizable';

export class Interest implements Localizable<Interest> {
    static fromJSON(data: IInterestJSON) {
        return new Interest(
            data.permanent_id,
            data.label,
            (data.local_labels || []).map(LocalLabel.fromJSON),
        );
    }

    constructor(
        readonly permanentID: string,
        readonly label: string,
        readonly localLabels: LocalLabel[],
    ) {
    }

    localize(locale: string) {
        return new Interest(
            this.permanentID,
            localize<'label'>(locale, this.localLabels, this).label,
            this.localLabels,
        );
    }
}

export interface IInterestJSON {
    readonly permanent_id: string;
    readonly label: string;
    readonly local_labels?: ILocalLabelJSON[];
}
