import { DigestScoop } from './DigestScoop';
import { LanguageTag, ILanguageTagBlueprint } from './LanguageTag';

export class LocalPost {
  public static build(data: ILocalPostJSON) {
    return new LocalPost(
      data.id,
      LanguageTag.build(data.language),
      data.title,
      data.source || '',
      (data.cover && DigestScoop.build(data.cover)) || undefined,
      data.body,
    );
  }

  constructor(
    readonly id: number,
    readonly language: LanguageTag,
    readonly title: string,
    readonly source: string,
    readonly cover: DigestScoop | undefined,
    readonly body: string,
  ) {
  }

  public synopsis(length: number = 200) {
    return this.body.slice(0, length) + (this.body.length > length ? '...' : '');
  }
}

export interface ILocalPostJSON {
  readonly id: number;
  readonly language: ILanguageTagBlueprint;
  readonly title: string;
  readonly source?: string;
  readonly cover?: string;
  readonly body: string;
}
