import { Scalar, IScalarJSON } from './Scalar';
import { Interest } from './Interest';
import { Footnote } from './Footnote';
import { Solution } from './Solution';
import { EssaySolution } from './EssaySolution';
import { Localizable } from './Localizable';
import moment from 'moment';

export class EssayScalar extends Scalar implements Localizable<EssayScalar> {
    static fromJSON(data: IEssayScalarJSON) {
        return new EssayScalar(
            data.permanent_id,
            data.serial_number,
            Interest.fromJSON(data.interest),
            data.required,
            (data.footnotes || []).map(Footnote.fromJSON),
            moment(data.created_at),
        );
    }

    localize(locale: string) {
        return new EssayScalar(
            this.permanentID,
            this.serialNumber,
            this.interest.localize(locale),
            this.required,
            this.footnotes.map(f => f.localize(locale)),
            this.createdAt,
        );
    }

    solve(solutions?: Record<string, Solution>): string {
        return (solutions
            && solutions[this.interest.permanentID]
            && (solutions[this.interest.permanentID] as EssaySolution).paragraph)
            || ''
        ;
    }
}

export interface IEssayScalarJSON extends IScalarJSON {
}
