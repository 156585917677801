import { AbstractSolution } from './AbstractSolution';
import { Interest, IInterestJSON } from './Interest';

export class PredicateSolution extends AbstractSolution {
    static fromJSON(data: IPredicateSolutionJSON) {
        return new PredicateSolution(
            Interest.fromJSON(data.interest),
            data.checked
        );
    }

    constructor(
        interest: Interest,
        readonly checked: boolean,
    ) {
        super(interest);
    }

    get valid() {
        return this.checked;
    }

    dispose() {
        return ({
            checked: this.checked,
        });
    }
}

export interface IPredicateSolutionJSON {
    readonly interest: IInterestJSON;
    readonly checked: boolean;
}
