import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import URI from 'urijs';

import { PageComponentProps, withPage } from '../widgets/Paginator';

type PagerProps = PropsWithChildren<RouteComponentProps & PageComponentProps & {
  className?: string;
  total: number;
}>;

function PurePager({ className, total, page, pageSize, location: { pathname, search }, children }: PagerProps) {
  const [ n, setN ] = useState<number>(page);
  const lastPage = Math.ceil(total / pageSize);
  const { p, ...q } = URI.parseQuery(search) as { p?: string };

  useEffect(() => setN(page), [page]);
  if (lastPage < 2) {
    return null;
  }

  return (
    <form action={pathname} className={className}>
      {children}

      {page > 1 ? (
        <React.Fragment>
          <span>
            <Link to={URI.build({ path: pathname, query: URI.buildQuery(q) })}>
              <FormattedMessage id="pager.first" />
            </Link>
          </span>
          <span>
            <Link to={URI.build({ path: pathname, query: URI.buildQuery(Object.assign(q, page > 2 ? { p: page - 1 } : {})) })}>
              <FormattedMessage id="pager.prev" />
            </Link>
          </span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <FormattedMessage id="pager.first" />
          <FormattedMessage id="pager.prev" />
        </React.Fragment>
      )}
      <span className="field">
        <FormattedMessage id="pager.page" />
        <input
          type="number"
          min="1"
          max={lastPage}
          name="p"
          value={n || ''}
          onChange={e => setN(parseInt(e.target.value))}
        />
        of
        {` ${lastPage}`}
      </span>
      {page < lastPage ? (
        <React.Fragment>
          <span>
            <Link to={URI.build({ path: pathname, query: URI.buildQuery(Object.assign(q, { p: page + 1 })) })}>
              <FormattedMessage id="pager.next" />
            </Link>
          </span>
          <span>
            <Link to={URI.build({ path: pathname, query: URI.buildQuery(Object.assign(q, { p: lastPage })) })}>
              <FormattedMessage id="pager.last" />
            </Link>
          </span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <FormattedMessage id="pager.next" />
          <FormattedMessage id="pager.last" />
        </React.Fragment>
      )}
    </form>
  );
}

export default styled(withRouter(withPage(PurePager)))`
  float: right;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;

  & > span {
    margin-left: 30px;
    color: #999;

    &.field {
      color: #333;
    }

    a {
      color: #4072ee;
    }
  }

  input {
    outline: none;
    margin: 0 5px;
    width: 30px;
    height: 24px;
    font-family: inherit;
    font-size: 16px;
    text-align: center;

    &::-webkit-inner-spin-button {
      display: none;
    }
  }
`;
