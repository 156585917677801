import { DigestScoop } from './DigestScoop';
import { KitForm } from './KitForm';
import { ISubscriberBlueprint, Subscriber } from './Subscriber';

import moment from 'moment';
import { SupplementJSON, Supplement } from './Supplement';

export class Kit extends KitForm {
  public static build(data: IKitBlueprint): Kit {
    return new Kit(
      data.id,
      Subscriber.build(data.subscriber),
      data.title,
      data.article_no,
      data.volume || '',
      data.synopsis,
      (data.image && DigestScoop.build(data.image)) || undefined,
      moment(data.created_at),
      (data.published_at && moment(data.published_at)) || undefined,
      (data.supplements || []).map(Supplement.build),
    );
  }

  constructor(
    public readonly id: number,
    public readonly subscriber: Subscriber,
    title: string,
    articleNo: string,
    volume: string,
    synopsis: string,
    public readonly image: DigestScoop | undefined,
    public readonly createdAt: moment.Moment,
    public readonly publishedAt: moment.Moment | undefined,
    readonly supplements: Supplement[],
  ) {
    super(title, articleNo, volume, synopsis, image, supplements);
  }

  public unpublish() {
    const { id, subscriber, title, articleNo, volume, synopsis, image, createdAt, supplements } = this;

    return new Kit(id, subscriber, title, articleNo, volume, synopsis, image, createdAt, undefined, supplements);
  }
}

export interface IKitBlueprint {
  readonly id: number;
  readonly subscriber: ISubscriberBlueprint;
  readonly title: string;
  readonly article_no: string;
  readonly volume?: string;
  readonly synopsis: string;
  readonly image?: string;
  readonly created_at: string;
  readonly published_at?: string;
  readonly supplements?: SupplementJSON[];
}
