import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { Trial } from '../../models/Trial';

interface P {
  className?: string;
  trial: Trial;
  your?: boolean;
}

class TrialItem extends Component<P> {

  render() {
    const { className, trial, your, children } = this.props;

    return (
      <div className={className}>
        {your ? (
          <Fragment>
            <div>
              <div className="meta">
                <Header as="h3">#{trial.refNo}</Header>
                <p className="timestamp">{trial.createdAt.format('YYYY.MM.DD HH:mm')}</p>
              </div>
              <Header as="h3">{trial.kit.title}</Header>
              <p className="contacts">{trial.kit.subscriber.i9n}</p>
              <p className="contacts">{trial.kit.articleNo}</p>
            </div>
            <div>
              <Header as="h3"><FormattedMessage id="trial.delopen_h3" /></Header>
              <p className="contacts"><FormattedMessage id="trial.supervisor_name" />: {`${trial.firstName} ${trial.lastName}`}</p>
              <p className="contacts"><FormattedMessage id="common.email" />: {trial.email}</p>
              <p className="contacts"><FormattedMessage id="common.phone_number" />: {trial.phoneNumber}</p>
              <p className="contacts"><FormattedMessage id="common.institution" />: {trial.institution}</p>
              <p className="contacts"><FormattedMessage id="security.country" />: {trial.country.name}</p>
              <Header as="h3"><FormattedMessage id="trial.supervisor_h3" /></Header>
              <p className="contacts"><FormattedMessage id="trial.supervisor_name" />: {`${trial.supervisorFirstName} ${trial.supervisorLastName}`}</p>
              <p className="contacts"><FormattedMessage id="trial.supervisor_email" />: {trial.supervisorEmail}</p>
              <p className="contacts"><FormattedMessage id="trial.supervisor_institution" />: {trial.supervisorInstitution}</p>
              <p className="contacts"><FormattedMessage id="trial.supervisor_title" />: {trial.supervisorTitle}</p>
              <p className="contacts"><FormattedMessage id="trial.supervisor_homepage" />: {trial.supervisorHomepage}</p>
            </div>
            <div>
              <Header as="h3"><FormattedMessage id="trial.shippment_h3" /></Header>
              <p className="contacts"><FormattedMessage id="trial.consignee" />: {trial.consignee}</p>
              <p className="contacts"><FormattedMessage id="trial.shipping_email" />: {trial.shippingEmail}</p>
              <p className="contacts"><FormattedMessage id="trial.shipping_phone" />: {trial.shippingPhone}</p>
              <p className="contacts"><FormattedMessage id="trial.address" />: {trial.address}</p>
              <p className="contacts"><FormattedMessage id="trial.postcode" />: {trial.postcode}</p>
              <p className="contacts"><FormattedMessage id="trial.fedex_account" />: {trial.fedexAccount}</p>
              <Header as="h3"><FormattedMessage id="trial.sample_return_h3" /></Header>
              <p className="contacts"><FormattedMessage id="trial.sample_return" />: {trial.sampleReturn}</p>
              <Header as="h3"><FormattedMessage id="trial.optional_service_h3" /></Header>
              <p className="contacts">{trial.optionalService}</p>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div>
              <div className="meta">
                <Header as="h3">#{trial.refNo}</Header>
                <p className="timestamp">{trial.createdAt.format('YYYY.MM.DD HH:mm')}</p>
              </div>
              <Header as="h3">{trial.kit.title}</Header>
            </div>
            <div>
              <Header as="h3"><FormattedMessage id="trial.delopen_h3" /></Header>
              <p className="contacts"><FormattedMessage id="trial.supervisor_name" />: {`${trial.firstName} ${trial.lastName}`}</p>
              <p className="contacts"><FormattedMessage id="common.email" />: {trial.email}</p>
              <p className="contacts"><FormattedMessage id="common.phone_number" />: {trial.phoneNumber}</p>
              <p className="contacts"><FormattedMessage id="common.institution" />: {trial.institution}</p>
              <p className="contacts"><FormattedMessage id="security.country" />: {trial.country.name}</p>
              <Header as="h3"><FormattedMessage id="trial.supervisor_h3" /></Header>
              <p className="contacts"><FormattedMessage id="trial.supervisor_name" />: {`${trial.supervisorFirstName} ${trial.supervisorLastName}`}</p>
              <p className="contacts"><FormattedMessage id="trial.supervisor_email" />: {trial.supervisorEmail}</p>
              <p className="contacts"><FormattedMessage id="trial.supervisor_institution" />: {trial.supervisorInstitution}</p>
              <p className="contacts"><FormattedMessage id="trial.supervisor_title" />: {trial.supervisorTitle}</p>
              <p className="contacts"><FormattedMessage id="trial.supervisor_homepage" />: {trial.supervisorHomepage}</p>
            </div>
            <div>
              <Header as="h3"><FormattedMessage id="trial.shippment_h3" /></Header>
              <p className="contacts"><FormattedMessage id="trial.consignee" />: {trial.consignee}</p>
              <p className="contacts"><FormattedMessage id="trial.shipping_email" />: {trial.shippingEmail}</p>
              <p className="contacts"><FormattedMessage id="trial.shipping_phone" />: {trial.shippingPhone}</p>
              <p className="contacts"><FormattedMessage id="trial.address" />: {trial.address}</p>
              <p className="contacts"><FormattedMessage id="trial.postcode" />: {trial.postcode}</p>
              <p className="contacts"><FormattedMessage id="trial.fedex_account" />: {trial.fedexAccount}</p>
              <Header as="h3"><FormattedMessage id="trial.sample_return_h3" /></Header>
              <p className="contacts"><FormattedMessage id="trial.sample_return" />: {trial.sampleReturn}</p>
              <Header as="h3"><FormattedMessage id="trial.optional_service_h3" /></Header>
              <p className="contacts">{trial.optionalService}</p>
            </div>
          </Fragment>
        )}
        <div className="actions">
          {children}
        </div>
      </div>
    );
  }

}

export default styled(TrialItem)`
  display: flex;
  padding: 40px 0;
  margin: 0 -1rem;

  & > div {
    width: ${100 * 5 / 14}%;
    padding: 0 1rem;

    &.meta {
      width: ${100 * 3 / 14}%;

      & + div {
        width: ${100 * 4 / 14}%;
      }
    }

    &.actions {
      width: ${100 * 2 / 14}%;
      text-align: right;
    }
  }

  h3 {
    margin: 0 0 10px !important;
  }

  .timestamp {
    line-height: 20px;
    color: #666;
  }

  .contacts {
    margin-bottom: 10px;
    line-height: 20px;
    color: #666;

    &:first-child {
	color: #333;
    }
  }

  .kit {
    margin-bottom: 10px;
    line-height: 20px;
    color: #333;
  }

  .address,
  .synopsis {
    font-size: 13px;
    line-height: 20px;
    color: #999;
  }
`;
