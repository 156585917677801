import { ChoiceScalar, IChoiceScalarJSON } from './ChoiceScalar';
import { ChoiceVector, IChoiceVectorJSON } from './ChoiceVector';
import { RatingScalar, IRatingScalarJSON } from './RatingScalar';
import { RatingVector, IRatingVectorJSON } from './RatingVector';
import { EssayScalar, IEssayScalarJSON } from './EssayScalar';
import { Predicate, IPredicateJSON } from './Predicate';
import { Section, ISectionJSON } from './Section';

export function fromJSON(data: IFieldJSON) {
    return isSection(data) ? Section.fromJSON(data) :
        isChoiceScalar(data) ? ChoiceScalar.fromJSON(data) :
        isChoiceVector(data) ? ChoiceVector.fromJSON(data) :
        isRatingScalar(data) ? RatingScalar.fromJSON(data) :
        isRatingVector(data) ? RatingVector.fromJSON(data) :
        isPredicate(data) ? Predicate.fromJSON(data) :
        EssayScalar.fromJSON(data)
    ;
}

export type ScalarField = ChoiceScalar | RatingScalar | EssayScalar | Predicate;
export type VectorField = ChoiceVector | RatingVector;

export type Field = Section | ScalarField | VectorField;

export function isScalar(f: Field): f is ScalarField {
    return !!(f as ScalarField).interest;
}

export function isVector(f: Field): f is VectorField {
    return !!(f as VectorField).interests;
}

export type IFieldJSON =
    ISectionJSON |
    IChoiceScalarJSON |
    IChoiceVectorJSON |
    IRatingScalarJSON |
    IRatingVectorJSON |
    IEssayScalarJSON |
    IPredicateJSON
;

function isSection(f: IFieldJSON): f is ISectionJSON {
    return (f as ISectionJSON).children !== undefined;
}

function isChoiceScalar(f: IFieldJSON): f is IChoiceScalarJSON {
    return (f as IChoiceScalarJSON).interest !== undefined && (f as IChoiceScalarJSON).options !== undefined;
}

function isChoiceVector(f: IFieldJSON): f is IChoiceVectorJSON {
    return (f as IChoiceVectorJSON).interests !== undefined && (f as IChoiceVectorJSON).options !== undefined;
}

function isRatingScalar(f: IFieldJSON): f is IRatingScalarJSON {
    return (f as IRatingScalarJSON).interest !== undefined && (f as IRatingScalarJSON).scale !== undefined;
}

function isRatingVector(f: IFieldJSON): f is IRatingVectorJSON {
    return (f as IRatingVectorJSON).interests !== undefined && (f as IRatingVectorJSON).scale !== undefined;
}

function isPredicate(f: IFieldJSON): f is IPredicateJSON {
    return (f as IPredicateJSON).dtype === 'predicate';
}
