import * as React from 'react';
import { DropdownProps, Form } from 'semantic-ui-react';

import { Country } from '../../models/Country';
import { CountryRepository } from '../../repositories/CountryRepository';

interface ICountrySelectProps {
  value?: Country;
  onChange: (value?: Country) => void;
}

interface ICountrySelectState {
  countries: Country[];
}

class CountrySelect extends React.Component<ICountrySelectProps & { repo: CountryRepository }, ICountrySelectState> {

  public state: ICountrySelectState = { countries: [] };

  public componentDidMount() {
    this
      .props.repo
      .findAll()
      .then(countries => this.setState({ countries }))
    ;
  }

  public render() {
    const { value } = this.props;
    const { countries } = this.state;

    return (
      <Form.Select
        required
        options={countries.map(c => ({ text: c.name, value: c.id }))}
        value={value && value.id}
        onChange={this.change}
      />
    );
  }

  private change = (_: React.SyntheticEvent, { value }: DropdownProps) =>
    this.props.onChange(this.state.countries.find(c => c.id === value));

}

export default ((props: ICountrySelectProps) => (
  <CountryRepository.Context.Consumer>
    {repo => (
      <CountrySelect repo={repo} {...props} />
    )}
  </CountryRepository.Context.Consumer>
));
