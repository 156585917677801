import moment, { Moment } from 'moment';

export class Review {
  static build(data: IReviewJSON) {
    return new Review(
      data.id,
      data.subscriber,
      data.body,
      data.referrer,
      moment(data.created_at),
    );
  }

  constructor(
    readonly id: number,
    readonly subscriber: IReviewer,
    readonly body: string,
    readonly referrer: number | undefined,
    readonly createdAt: Moment,
  ) {
  }
}

export interface IReviewJSON {
  readonly id: number;
  readonly subscriber: IReviewer;
  readonly body: string;
  readonly referrer?: number;
  readonly created_at: string;
}

export interface IReviewer {
  readonly nickname: string;
  readonly portrait?: string;
}
