import { Country, ICountryBlueprint } from './Country';
import { DigestScoop } from './DigestScoop';
import { IInstitutionBlueprint, Institution } from './Institution';
import { ILanguageTagBlueprint, LanguageTag } from './LanguageTag';
import { SubscriberForm } from './SubscriberForm';

import moment from 'moment';

export class Subscriber extends SubscriberForm {
  public static build(data: ISubscriberBlueprint): Subscriber {
    return new Subscriber(
      data.id,
      data.email,
      data.roles,
      data.nickname,
      (data.portrait && DigestScoop.build(data.portrait)) || undefined,
      data.first_name,
      data.last_name,
      Country.build(data.country),
      data.institution,
      data.canonical_institution && Institution.build(data.canonical_institution),
      data.phone_number || '',
      data.biography || '',
      data.principal_investigator,
      moment(data.created_at),
      LanguageTag.build(data.language_tag),
      data.token,
    );
  }

  constructor(
    public readonly id: number,
    email: string,
    public readonly roles: string[],
    public readonly nickname: string,
    portrait: DigestScoop | undefined,
    firstName: string,
    lastName: string,
    public readonly country: Country,
    institution: string,
    public readonly canonicalInstitution: Institution | undefined,
    phoneNumber: string,
    biography: string,
    principalInvestigator: boolean,
    public readonly createdAt: moment.Moment,
    public readonly languageTag: LanguageTag,
    public readonly token: string,
  ) {
    super(email, portrait, firstName, lastName, country, institution, phoneNumber, biography, principalInvestigator);
  }

  public hasRole(role: string) {
    return this.roles.indexOf(`ROLE_${role.toUpperCase()}`) !== -1;
  }

  get i9n(): string {
    if (this.canonicalInstitution && this.canonicalInstitution.approvedAt) {
      return this.canonicalInstitution.title;
    }

    return this.institution;
  }
}

export interface ISubscriberBlueprint {
  readonly id: number;
  readonly email: string;
  readonly roles: string[];
  readonly nickname: string;
  readonly portrait?: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly country: ICountryBlueprint;
  readonly institution: string;
  readonly canonical_institution?: IInstitutionBlueprint;
  readonly phone_number?: string;
  readonly biography?: string;
  readonly principal_investigator: boolean;
  readonly created_at: string;
  readonly language_tag: ILanguageTagBlueprint;
  readonly token: string;
}
