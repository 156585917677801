import { AbstractField, IAbstractFieldJSON } from './AbstractField';
import { fromJSON, Field, IFieldJSON, isScalar, isVector } from './Field';
import { ILocalLabelJSON, LocalLabel } from './LocalLabel';
import { Footnote } from './Footnote';
import { Localizable, localize } from './Localizable';
import { AnswerSheet } from './AnswerSheet';
import moment, { Moment } from 'moment';

export class Section extends AbstractField implements Localizable<Section> {
    static fromJSON(data: ISectionJSON): Section {
        return new Section(
            data.permanent_id,
            data.serial_number,
            data.label,
            (data.local_labels || []).map(LocalLabel.fromJSON),
            data.required,
            data.children.map(fromJSON),
            (data.footnotes || []).map(Footnote.fromJSON),
            moment(data.created_at),
        );
    }

    constructor(
        permanentID: string,
        serialNumber: number,
        readonly label: string,
        readonly localLabels: LocalLabel[],
        required: boolean,
        readonly children: Field[],
        footnotes: Footnote[],
        createdAt: Moment,
    ) {
        super(permanentID, serialNumber, required, footnotes, createdAt);
    }

    localize(locale: string): Section {
        return new Section(
            this.permanentID,
            this.serialNumber,
            localize<'label'>(locale, this.localLabels, this).label,
            this.localLabels,
            this.required,
            this.children.map(child => child.localize(locale)),
            this.footnotes.map(f => f.localize(locale)),
            this.createdAt,
        );
    }

    validata(answerSheet: AnswerSheet): string[] {
        return this.children.reduce<string[]>((violations, child) => {
            if (isScalar(child)) {
                const solution = answerSheet.solutions[child.interest.permanentID];

                if (child.required && !(solution && solution.valid)) {
                    return violations.concat(child.interest.permanentID);
                }
            } else if (isVector(child)) {
                if (child.required) {
                    return child.interests.reduce((vs, i) => {
                        const solution = answerSheet.solutions[i.permanentID];

                        return solution && solution.valid ? vs : vs.concat(i.permanentID);
                    }, violations);
                }                
            } else {
                return violations.concat(child.validata(answerSheet));
            }

            return violations;
        }, []);
    }
}

export interface ISectionJSON extends IAbstractFieldJSON {
    readonly label: string;
    readonly local_labels?: ILocalLabelJSON[];
    readonly children: IFieldJSON[];
}
