import * as React from 'react';
import { Link } from 'react-router-dom';
import { SemanticSIZES } from 'semantic-ui-react';

import { DigestScoop } from '../../models/DigestScoop';
import { FileScoop } from '../../models/FileScoop';

import ScoopImage from './Image';

interface IScoopImageInputProps {
  trigger: JSX.Element;
  size?: SemanticSIZES;
  value?: DigestScoop | FileScoop;
  onChange: (scoop?: FileScoop) => void;
};

class ScoopImageInput extends React.Component<IScoopImageInputProps> {

  private input: HTMLInputElement;

  constructor(props: IScoopImageInputProps) {
    super(props);

    this.input = document.createElement('input');
    this.input.type = 'file';
    this.state = {};
  }

  public componentDidMount() {
    this.input.addEventListener('change', this.change);
  }

  public componentWillUnmount() {
    this.input.removeEventListener('change', this.change);
  }

  public render() {
    const { trigger, size, value } = this.props;
    if (value) {
      return (
        <Link to="#" className="scoop-input" onClick={this.browse}>
          <ScoopImage scoop={value} size={size} />
        </Link>
      );
    }

    return React.cloneElement(trigger, {
      onClick: this.browse,
    });
  }

  private browse = () => this.input.click();

  private change = () => {
    const { input, props } = this;
    if (!input.files) {
      return;
    }

    props.onChange(new FileScoop(input.files[0]));

    this.input.value = '';
    /* CHROME: reset file input to stop empty FileList or so. */
  }

}

export default ScoopImageInput;
