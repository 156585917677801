import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Form, Header, InputOnChangeData, Message, Modal } from 'semantic-ui-react';

import { SecurityRepository } from '../../repositories/SecurityRepository';

type P = InjectedIntlProps & {
  open?: boolean;
  onClose: () => void;
};

interface IChangePasswordState {
  loading: boolean;
  old: string;
  password: string;
  retypedPassword: string;
  oops: boolean;
}

class ChangePassword extends React.Component<P & { repo: SecurityRepository }, IChangePasswordState> {

  public state: IChangePasswordState = { loading: false, old: '', password: '', retypedPassword: '', oops: false };

  private patchOld = this.patch('old');
  private patchPassword = this.patch('password');
  private patchRetypedPassword = this.patch('retypedPassword');

  public render() {
    const { intl: { formatMessage }, open } = this.props;
    const { loading, old, password, retypedPassword, oops } = this.state;

    return (
      <Modal open={open} onClose={this.dismiss} style={{ width: '570px' }}>
        <Modal.Content>
          <Header as="h1">
            <FormattedMessage id="nav.change_password" />
          </Header>

          <Form error loading={loading} onSubmit={this.submit}>
            <Form.Input
              required
              type="password"
              label={formatMessage({ id: 'security.old_password' })}
              value={old}
              onChange={this.patchOld}
            />
            {
              oops && (
                <Message error size="small">
                  <Message.Content>
                    <FormattedMessage id="security.bad_password" />
                  </Message.Content>
                </Message>
              )
            }
            <Form.Input
              required
              type="password"
              label={formatMessage({ id: 'security.new_password' })}
              value={password}
              onChange={this.patchPassword}
            />
            {
              password && !SecurityRepository.isStrongPassword(password) && (
                <Message error size="small">
                  <Message.Content>
                    <FormattedMessage id="security.strong_password" values={{ min: 8, max: 20 }} />
                  </Message.Content>
                </Message>
              )
            }
            <Form.Input
              required
              type="password"
              label={formatMessage({ id: 'security.retype_new_password' })}
              value={retypedPassword}
              onChange={this.patchRetypedPassword}
            />
            {
              retypedPassword && retypedPassword !== password && (
                <Message error size="small">
                  <Message.Content>
                    <FormattedMessage id="security.password_mismatch" />
                  </Message.Content>
                </Message>
              )
            }

            <Form.Button primary>
              <FormattedMessage id="save" />
            </Form.Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }

  private patch(key: 'old' | 'password' | 'retypedPassword') {
    return (_: React.SyntheticEvent, { value }: InputOnChangeData) => {
      this.setState({ [key]: value } as Pick<IChangePasswordState, 'old' | 'password' | 'retypedPassword'>);

      switch (key) {
        case 'old':
          this.setState({ oops: false });
          break;
      }
    };
  }

  private submit = (e: React.FormEvent) => {
    e.preventDefault();

    const { old, password, retypedPassword } = this.state;
    if (!SecurityRepository.isStrongPassword(password) || retypedPassword !== password) {
      return;
    }

    this
      .props.repo
      .changePassword(password, old)
      .then(this.dismiss)
      .catch(() => { this.setState({ oops: true }); })
      .then(() => { this.setState({ loading: false }); })
    ;

    this.setState({ loading: true });
  }

  private dismiss = () => {
    this.setState({ old: '', password: '', retypedPassword: '', oops: false });
    this.props.onClose();
  }

}

export default injectIntl((props: P) => (
  <SecurityRepository.Context.Consumer>
    {repo => (
      <ChangePassword repo={repo} {...props} />
    )}
  </SecurityRepository.Context.Consumer>
));
