import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { SupplementForm } from '../../models/Supplement';

import pdf from './pdf.svg';

function Scoop(props: PropsWithChildren<{
  className?: string;
  supplement: SupplementForm;
}>) {
  return (
    <div className={props.className}>
      <img src={pdf} alt="pdf" />
      {props.supplement.name}
      <Size size={props.supplement.size} />
      {props.children}
    </div>
  );
}

export default styled(Scoop)`
  display: flex;
  align-items: center;
  margin-top: 30px;

  & + & {
    margin-top: 15px;
  }

  img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
`;

const Size = styled(function (props: { className?: string; size: number; }) {
  if (props.size >= 1e6) {
    return (
      <span className={props.className}>{(props.size / 1e6).toFixed(1)}M</span>
    );
  }

  if (props.size >= 1e3) {
    return (
      <span className={props.className}>{(props.size / 1e3).toFixed(1)}K</span>
    );
  }

  return (
    <span className={props.className}>{props.size}</span>
  );
})`
  margin-left: 20px;
  color: #999;
`;
