import { Country } from './Country';
import { DigestScoop } from './DigestScoop';
import { FileScoop } from './FileScoop';

export class SubscriberForm {
  constructor(
    public readonly email: string = '',
    public readonly portrait?: DigestScoop | FileScoop,
    public readonly firstName: string = '',
    public readonly lastName: string = '',
    public readonly country?: Country,
    public readonly institution: string = '',
    public readonly phoneNumber: string = '',
    public readonly biography: string = '',
    public readonly principalInvestigator: boolean = false,
    public readonly inviter: string = '',
  ) {
  }

  public patch<K extends keyof SubscriberForm>(key: K, value: SubscriberForm[K]) {
    const { email, portrait, firstName, lastName, country, institution, phoneNumber, biography, principalInvestigator, inviter }
      = Object.assign({}, this, { [key]: value });

    return new SubscriberForm(email, portrait, firstName, lastName, country, institution, phoneNumber, biography, principalInvestigator, inviter);
  }

  public get valid() {
    return !!(this.email && this.lastName && this.firstName && this.country && this.institution);
  }

  public dispose() {
    return ({
      email: this.email.toLowerCase(),
      portrait: this.portrait && this.portrait.dispose(),
      firstName: this.firstName,
      lastName: this.lastName,
      country: this.country && this.country.id,
      institution: this.institution,
      phoneNumber: this.phoneNumber,
      biography: this.biography,
      principalInvestigator: this.principalInvestigator,
      inviter: this.inviter,
    });
  }
}
