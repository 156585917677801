export class LanguageTag {

  public static build(data: ILanguageTagBlueprint): LanguageTag {
    return new LanguageTag(
      data.id,
      data.name,
      data.code,
    );
  }

  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly code: string,
  ) {
  }

}

export interface ILanguageTagBlueprint {
  readonly id: number;
  readonly name: string;
  readonly code: string;
}
