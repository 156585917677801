import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Header } from 'semantic-ui-react';

import Page from './Page';

class Privacy extends Component {

  render() {
    return (
      <Page loc="nav.privacy">
        <Helmet>
          <title>Privacy Policy - DELOpen</title>
        </Helmet>

        <Header as="h1">Privacy Policy</Header>

        <p>
          We are committed to protecting the privacy of our visitors while they interact with the content, products and services on this site (the "Site”). This Privacy Policy applies to the Site only. It does not apply to other websites to which we may link. Because we gather certain types of information about our users, we want you to understand what information we collect about you, how we collect it, how that information is used, and how you can control our disclosure of it. You agree that your use of the Site signifies your assent to this Privacy Policy. If you do not agree with this Privacy Policy, please do not use the Site. Please also visit our Terms of Use page, which establishes the use, disclaimers, and limitations of liability governing the use of the Site.
        </p>

        <Header as="h2">Information Collected</Header>

        <p>
          We collect two types of information from you: information that you voluntarily provide to us such as your name, email address, phone number, located country/region, your employer, address and postcodeand information that is derived through automated tracking mechanisms.
        </p>

        <p>
          We collect personally identifiable information when you choose to use certain features of the Site, including project tracking and other client support services. When you choose to use these additional features, we may require you to provide your “contact information” in addition to other personal information.
        </p>

        <p>
          The Site may use "cookies" and other tracking technologies in order to store user-provided information. You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser, but please note that not accepting cookies may limit your use of some features of the Site.
        </p>

        <Header as="h2">How We Use Your Information</Header>

        <p>
          We may use your personal information for handling shipping matters.  Your personal information will not be sold or otherwise transferred to unaffiliated third parties without your approval at the time of collection. We will not disclose, use, give or sell any personal information to third parties for any purposes unless required to do so by law. We reserve the right to contact you regarding matters relevant to the underlying service provided or the information collected.
        </p>

        <p>
          Please note that personally identifiable information is used only to provide you with a more convenient online experience and to help us identify and provide information that may be of interest to you. We use your personally identifiable information to support and enhance your use of the Site and its features, including providing customer service and project tracking and other client support services.
        </p>

        <p>
          We may permit certain trusted third parties to track usage and analyze data such as the source address that a page request is coming from, your IP address or domain name, the date and time of the page request, the referring Web site and other parameters in the URL. This is collected in order to better understand our Web site usage, and enhance the performance of services to maintain and operate the Site and certain features on the Site. We may use third parties to host the Site, operate various features available on the Site, send emails, analyze data and provide search results and links. We may share personally identifiable or other information with our affiliatestoprovide the Website, for purposes including preventing illegal use and supporting users.
        </p>

        <p>
          We may transfer personally identifiable information as an asset in connection with a proposed or actual merger or sale (including any transfers made as part of an insolvency or bankruptcy proceeding) involving all or part of our business or as part of a corporate reorganization, stock sale or other change in control.
        </p>

        <p>
          We may disclose contact information in special cases where we have reason to believe that disclosing this information is necessary to identify, contact or bring legal action against someone who may be violating our terms and conditions of use or may be causing injury or interference with our rights, property, our customers or anyone who could be harmed by such activities.
        </p>

        <p>
          You will receive notice when your personally identifiable information might be provided to any third party for any reason other than as set forth in this Privacy Policy, and you will have an opportunity to request that we not share such information.
        </p>

        <p>
          We use non-identifying and aggregate information to better design our website and for business and administrative purposes. We may also use or share with third parties for any purpose aggregated data that contains no personally identifiable information.
        </p>

        <Header as="h2">How We Protect Your Information</Header>

        <p>
          We are committed to protecting the information we receive from you. We take appropriate security measures to protect your information against unauthorized access to or unauthorized alteration, disclosure or destruction of data. To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we maintain appropriate physical, electronic, and managerial procedures to safeguard and secure the information and data stored on our system. While no computer system is completely secure, we believe the measures we have implemented reduce the likelihood of security problems to a level appropriate to the type of data involved.
        </p>

        <Header as="h2">How Long Do We Keep Your Information</Header>

        <p>
          We retain your information for as long as it is needed for us to provide the Website and services and operate our business, or it is necessary to fulfill the purposes for which such information was collected. 
        </p>

        <p>
          We may also retain your information during the period when we have other legitimate purposes; for example, in the event we are subject to legal, contractual or similar obligations to retain the personal information (e.g., mandatory data retention laws, government orders to preserve data relevant to an investigation), or we may need to retain your information for the purposes of pending or impending litigation or dispute.
        </p>

        <Header as="h2">Your Rights</Header>

        <p>
          We respect and attach great importance to your rights in connection with your personal information. We provide mechanisms for updating and correcting your personal information for many of our services. If you are a registered user, you may access and update your registration information and your preferences to receive email or other communications from us by sending an email to <a href="mailto:support@delopen.org">support@delopen.com</a>.
        </p>

        <p>
          In certain circumstances, you can request us to delete all or some of your personal information:
        </p>

        <p>
        .	Our processing of your personal information violates applicable laws and regulations;<br />
        .	We collected or used your personal information without your consent;<br />
        .	Our processing of your personal information violates our agreement with you;<br />
        .	The purpose of processing your personal information has been achieved, cannot be achieved, or is no longer necessary for achieving the processing purpose; or<br />
        .	You have withdrawn your consent for us to process your personal information.
        </p>

        <p>
        You also have the right to withdraw your consent at any time where our processing of your personal information is based on your consent. However, certain features of the Website and our services may not be available if your consent has been withdrawn. 
        </p>

        <Header as="h2">Email Choice/Opt-out</Header>

        <p>
          If any user who receives an email from the Site and would prefer not to receive such communications in the future, he or she can do so by following the instructions in the emails. In addition, you may send an email to <a href="mailto:support@delopen.org">support@delopen.com</a>. We will make commercially reasonable efforts to implement your opt-out requests promptly, but you may still receive communications from us for up to ten business days as we process your request.
        </p>

        <Header as="h2">Disclaimer to Security</Header>

        <p>
          By consenting to the Terms and Conditions of the Site and hence the Privacy Policy, you consent that no data transmission over the Internet is completely secure. We cannot guarantee or warrant the security of any information you provide to us and you transmit such information to us at your own risk.
        </p>

        <Header as="h2">International Transfer</Header>

        <p>
          We provide products and services through resources all over the world, and your personal information collected by us may be transferred to, stored at, processed in or otherwise accessed from a place located outside of the jurisdiction where you access the Website. We will take measures to ensure the security of such transfer, storage and processing of your information in accordance with this Privacy Policy. We will also obtain your separate consent for international transfer of your personal information to the extent required under applicable laws.
        </p>

        <Header as="h2">Notification of Changes</Header>

        <p>
          We reserve the right to change this Privacy Policy from time to time at our sole discretion. Without your explicit consent, we will not diminish the rights you are entitled to according to this policy.If at some point in the future, there is a change to our Privacy Policy, unless we obtain your express consent, such change will only apply to information collected after the revised Privacy Policy took effect. Your continued use of the Site indicates your assent to the Privacy Policy as posted.
        </p>

        <Header as="h2">Children</Header>

        <p>
          The Website is not directed at children under the age of 14 or equivalent minimum age in the relevant jurisdiction. We do not knowingly collect any personally identifiable information from any persons under such age. If we discover that we have actually collected such information, we will promptly proceed with its deletion.
        </p>

        <Header as="h2">Contact Information</Header>

        <p>
          If you have any questions or concerns regarding this Privacy Policy please contact <a href="mailto:support@delopen.org">support@delopen.com</a>. Unless otherwise specified by laws and regulations, we will provide a response within 15 working days.
        </p>

        <Header as="h2">This website uses Google AdWords</Header>

        <p>
          This website uses the Google AdWords remarketing service to advertise on third party websites (including Google) to previous visitors to our site. It could mean that we advertise to previous visitors who haven’t completed a task on our site, for example using the contact form to make an enquiry. This could be in the form of an advertisement on the Google search results page, or a site in the Google Display Network. Third-party vendors, including Google, use cookies to serve ads based on someone’s past visits to the DELopen website. Of course, any data collected will be used in accordance with our own privacy policy and Google’s privacy policy.
        </p>

        <p>
          You can set preferences for how Google advertises to you using the <a href="http://www.google.com/settings/ads/onweb/">Google Ad Preferences page</a>, and if you want to you can opt out of interest-based advertising entirely by cookie settings.
        </p>
      </Page>
    );
  }

}

export default Privacy;
