import { AbstractSolution } from './AbstractSolution';
import { Interest, IInterestJSON } from './Interest';

export class RatingSolution extends AbstractSolution {
    static fromJSON(data: IRatingSolutionJSON) {
        return new RatingSolution(
            Interest.fromJSON(data.interest),
            data.rating,
        );
    }

    constructor(
        interest: Interest,
        readonly rating: number,
    ) {
        super(interest);
    }

    dispose() {
        return ({
            rating: this.rating,
        });
    }
}

export interface IRatingSolutionJSON {
    readonly interest: IInterestJSON;
    readonly rating: number;
}
