export class Fragment<T> {
  public static build<U, V>(data: IFragmentBlueprint<U>, f: (x: U) => V) {
    return new Fragment(
      data.data.map(f),
      data.total,
    );
  }

  constructor(
    public readonly data: T[] = [],
    public readonly total: number = 0
  ) {
  }

  public patch(t0: T, t1: T) {
    const { data, total } = this;

    return new Fragment(
      data.map(t => t !== t0 ? t : t1),
      total,
    );
  }
}

export interface IFragmentBlueprint<T> {
  readonly data: T[];
  readonly total: number;
}

export function arrow<U extends object, K extends keyof U, V>(s: U, k: K, f: (value: U[K]) => V) {
  return Object.assign({}, s, {
    [k]: f(s[k]),
  } as { [P in K]: V });
}
