import { Scalar, IScalarJSON } from './Scalar';
import { Interest } from './Interest';
import { ScratchLabel, IScratchLabelJSON } from './ScratchLabel';
import { OptionGroup, IOptionGroupJSON } from './Option';
import { Footnote } from './Footnote';
import { Localizable, localize } from './Localizable';
import moment, { Moment } from 'moment';

export class ChoiceScalar extends Scalar implements Localizable<ChoiceScalar> {
    static fromJSON(data: IChoiceScalarJSON) {
        return new ChoiceScalar(
            data.permanent_id,
            data.serial_number,
            Interest.fromJSON(data.interest),
            data.required,
            data.multiple,
            data.scratch || '',
            data.prompt || '',
            (data.local_scratches || []).map(ScratchLabel.fromJSON),
            OptionGroup.fromJSON(data.options),
            (data.footnotes || []).map(Footnote.fromJSON),
            moment(data.created_at),
        );
    }

    constructor(
        permanentID: string,
        serialNumber: number,
        interest: Interest,
        required: boolean,
        readonly multiple: boolean,
        readonly scratch: string,
        readonly prompt: string,
        readonly localScratches: ScratchLabel[],
        readonly options: OptionGroup,
        footnotes: Footnote[],
        createdAt: Moment,
    ) {
        super(permanentID, serialNumber, interest, required, footnotes, createdAt);
    }

    localize(locale: string) {
        return new ChoiceScalar(
            this.permanentID,
            this.serialNumber,
            this.interest.localize(locale),
            this.required,
            this.multiple,
            localize<'label'>(locale, this.localScratches, { label: this.scratch }).label,
            localize<'prompt'>(locale, this.localScratches, this).prompt,
            this.localScratches,
            this.options.localize(locale),
            this.footnotes.map(f => f.localize(locale)),
            this.createdAt,
        );
    }
}

export interface IChoiceScalarJSON extends IScalarJSON {
    readonly multiple: boolean;
    readonly scratch?: string;
    readonly prompt?: string;
    readonly local_scratches?: IScratchLabelJSON[];
    readonly options: IOptionGroupJSON;
}
