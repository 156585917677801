import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import URI from 'urijs';

import { Subscriber } from '../../models/Subscriber';

import { IAppState, login } from '../../AppState';

import axios from 'axios';

type P = RouteComponentProps & {
  optional?: boolean;
  subscriber: Subscriber | undefined | null;
  login: (subscriber?: Subscriber) => void;
}

interface S {
  force: boolean;
}

class Secured extends Component<P, S> {

  private iid = -1;

  constructor(props: P) {
    super(props);

    this.state = { force: !props.optional };
  }

  componentDidMount() {
    this.iid = axios.interceptors.response.use(
      undefined,
      error => {
        const { subscriber } = this.props;
        if (error.response && (401 === error.response.status || 403 === error.response.status) && (null !== subscriber)) {
          this.setState({ force: true });
          this.props.login();
        }

        return Promise.reject(error);
      }
    );
  }

  componentWillUnmount() {
    axios.interceptors.response.eject(this.iid);
  }

  render() {
    const { subscriber, location, children } = this.props;
    const { force } = this.state;

    if (null === subscriber) {
      return null;
    } else if (undefined === subscriber && force) {
      return (
        <Redirect
          to={{
            pathname: '/sign-in',
            search: URI.buildQuery({
              next: URI.build({
                path: location.pathname,
                query: location.search.replace(/^\?/, ''),
                fragment: location.hash,
              }),
            }),
          }}
        />
      );
    } else {
      return React.Children.only(children);
    }
  }

}

export default connect(
  ({ subscriber }: IAppState) => ({ subscriber }),
  dispatch => ({
    login: (subscriber?: Subscriber) => dispatch(login(subscriber)),
  }),
)(withRouter(Secured));
