import { DigestScoop } from './DigestScoop';

export class ReactionMeta {
    public static compile(data: IReactionMetaJSON) {
        return new ReactionMeta(
            data.id,
            data.name,
            (data.scheme && DigestScoop.build(data.scheme)) || undefined,
        );
    }

    constructor(
        readonly id: number,
        readonly name: string,
        readonly scheme?: DigestScoop,
    ) {
    }
}

export interface IReactionMetaJSON {
    readonly id: number;
    readonly name: string;
    readonly scheme?: string;
}
