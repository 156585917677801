import { ILocalParagraphJSON, LocalParagraph } from './LocalParagraph'
import { Localizable, localize } from './Localizable';

export class Footnote implements Localizable<Footnote> {
    static fromJSON(data: IFootnotJSON) {
        return new Footnote(
            data.id,
            data.paragraph,
            (data.local_paragraphs || []).map(LocalParagraph.fromJSON),
        );
    }

    constructor(
        readonly id: number,
        readonly paragraph: string,
        readonly localParagraphs: LocalParagraph[],
    ) {
    }

    localize(locale: string) {
        return new Footnote(
            this.id,
            localize<'paragraph'>(locale, this.localParagraphs, this).paragraph,
            this.localParagraphs,
        );
    }
}

export interface IFootnotJSON {
    readonly id: number;
    readonly paragraph: string;
    readonly local_paragraphs: ILocalParagraphJSON[];
}
