import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Form, Header, InputOnChangeData } from 'semantic-ui-react';

import { SecurityRepository } from '../../repositories/SecurityRepository';

import Flyer from '../../Flyer';
import MyHelmet from '../widgets/MyHelmet';

type P = RouteComponentProps<void> & InjectedIntlProps;

interface IResetState {
  email: string;
  loading: boolean;
}

class Reset extends React.Component<P & { repo: SecurityRepository }, IResetState> {

  public state = { email: '', loading: false };

  public render() {
    const { formatMessage } = this.props.intl;
    const { email, loading } = this.state;

    return (
      <Flyer>
        <MyHelmet title="security.reset" />

        <Header as="h1" color="blue">
          <FormattedMessage id="security.reset" />
        </Header>

        <Form loading={loading} onSubmit={this.submit}>
          <Form.Input
            required
            type="email"
            label={formatMessage({ id: 'common.email' })}
            value={email}
            onChange={this.setEmail}
          />

          <Form.Button fluid primary type="submit">
            <FormattedMessage id="security.reset" />
          </Form.Button>
        </Form>
      </Flyer>
    );
  }

  private setEmail = (e: React.SyntheticEvent<{}>, { value }: InputOnChangeData) =>
    this.setState({ email: value });

  private submit = (e: React.FormEvent<{}>) => {
    e.preventDefault();

    const { email } = this.state;
    const { repo, history, intl: { locale } } = this.props;
    repo
      .reset(email, locale)
      .then(() => history.push('/sent?action=reset'))
      .catch(() => this.setState({ loading: false }))
    ;

    this.setState({ loading: true });
  }

}

export default injectIntl((props: P) => (
  <SecurityRepository.Context.Consumer>
    {repo => (
      <Reset repo={repo} {...props} />
    )}
  </SecurityRepository.Context.Consumer>
));
