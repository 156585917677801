import { Day, Frequency, SubscriptionForm } from './SubscriptionForm';

import moment, { Moment } from 'moment';

export class Subscription extends SubscriptionForm {

  static fromJSON(data: ISubscriptionJSON) {
    return new Subscription(
      data.permanent_id,
      data.email,
      data.frequency as Frequency,
      (data.day || Day.FirstDay) as Day,
      data.always,
      data.quantity,
      moment(data.created_at),
      moment(data.updated_at),
    );
  }

  constructor(
    readonly permanentID: string,
    readonly email: string,
    readonly frequency: Frequency,
    readonly day: Day,
    readonly always: boolean,
    readonly quantity: number,
    readonly createdAt: Moment,
    readonly updatedAt: Moment,
  ) {
    super(email, frequency, day, always, quantity);
  }

}

export interface ISubscriptionJSON {
  readonly permanent_id: string;
  readonly email: string;
  readonly frequency: number;
  readonly day?: number;
  readonly always: boolean;
  readonly quantity: number;
  readonly created_at: string;
  readonly updated_at: string;
}
