import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import Layout from '../../Layout';

interface P {
  className?: string;
  loc: string;
}

class Page extends Component<P> {

  render() {
    const { className, loc } = this.props;

    return (
      <Layout breadcrumb={loc} className={className}>
        <div className="text">
          <Grid centered>
            <Grid.Column width={14}>
              {this.props.children}
            </Grid.Column>
          </Grid>
        </div>
      </Layout>
    );
  }

}

export default styled(Page)`
  .text {
    padding: 40px 0 80px;
    background: #fff;

    h1 {
      padding-bottom: 40px;
      margin: 0 0 40px !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      line-height: 30px;
    }

    h2 {
      margin: 20px 0 0;
      line-height: 20px;
      color: #333;
    }

    p {
      margin: 20px 0 0 !important;
      color: #666;

      b {
        color: #333;
      }
    }
  }
`;
