import React, { useContext, useEffect, useState, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Form, Icon, Input, InputOnChangeData } from 'semantic-ui-react';
import styled from 'styled-components';
import URI from 'urijs';

import { DigestScoop } from '../../models/DigestScoop';
import { Review } from '../../models/Review';
import { Subscriber } from '../../models/Subscriber';
import { Thread } from '../../models/Thread';
import { ReviewRepository } from '../../repositories/ReviewRepository';
import { SecurityRepository } from '../../repositories/SecurityRepository';

import { IAppState, login } from '../../AppState';

import portrait from '../../portrait.png';

import ScoopImage from '../scoop/Image';

function Comments(props: { className?: string; scriptId: number; }) {
  const repository = useContext(ReviewRepository.Context);

  const [ threads, setThreads ] = useState<Thread[]>([]);
  const [ replyTo, setReplyTo ] = useState<Review>();
  const handleCancel = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setReplyTo(undefined);
  };
  const handleSubmit = async (body: string) => {
    const review = await repository.save(body, props.scriptId, replyTo && replyTo.id);

    setThreads(Thread.build([ review ], threads));
    setReplyTo(undefined);
  };

  useEffect(() => {
    repository
      .findAll(props.scriptId)
      .then(reviews => setThreads(Thread.build(reviews)))
    ;
  }, [ repository, props.scriptId ]);

  return (
    <div className={props.className}>
      <H2>Comments</H2>
      {!threads.length && (
        <Pl>There are no comments yet. You can be the first.</Pl>
      )}
      {threads.map(({ review, replies }) => (
        <React.Fragment key={review.id}>
          <Comment review={review} onReply={setReplyTo} />
          <Replies>
            {replies.map(r => (
              <Comment review={r} onReply={setReplyTo} key={r.id} />
            ))}
          </Replies>
        </React.Fragment>
      ))}
      {!replyTo ? (
        <H2>Leave a Reply</H2>
      ) : (
        <H2>
          Leave a Reply to {replyTo.subscriber.nickname}
          <Link to="#" onClick={handleCancel}>cancel reply</Link>
        </H2>
      )}
      <ReviewEdit onSubmit={handleSubmit} />
    </div>
  );
}

export default Comments;

const Comment = styled((props: { className?: string; review: Review; onReply(review: Review): void; }) => {
  const handleReply = (e: React.SyntheticEvent) => {
    e.preventDefault();

    props.onReply(props.review);
  };

  return (
    <div className={props.className}>
      <Reviewer>
        {props.review.subscriber.portrait ? (
          <ScoopImage scoop={DigestScoop.build(props.review.subscriber.portrait)} />
        ) : (
          <img src={portrait} alt={props.review.subscriber.nickname} />
        )}
        <div>
          <Nickname>{props.review.subscriber.nickname}</Nickname>
          <Timestamp>{props.review.createdAt.format('YYYY/MM/DD hh:mm A')}</Timestamp>
        </div>
      </Reviewer>

      {props.review.body.split('\n').map((p, i) => (
        <p key={i}>{p}</p>
      ))}

      <Link to="#" onClick={handleReply}>Reply</Link>
    </div>
  );
})`
  margin: 30px 0;
`;

function ReviewEdit(props: { onSubmit(body: string): Promise<void> }) {
  const subscriber = useSelector((s: IAppState) => s.subscriber);

  const [ body, setBody ] = useState('');
  const [ loading, setLoading ] = useState(false);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    props
      .onSubmit(body)
      .then(() => {
        setBody('');
        setLoading(false);
      })
    ;
    setLoading(true);
  };

  return (
    <Form loading={loading} onSubmit={handleSubmit}>
      {!subscriber ? (
        <Placeholder />
      ) : (
        <React.Fragment>
          <ReviewerEdit subscriber={subscriber} />
          <Form.TextArea required value={body} onChange={(_, { value }) => setBody(String(value))} />
        </React.Fragment>
      )}

      <Submit primary type="submit">Submit</Submit>
    </Form>
  );
}

function ReviewerEdit(props: { subscriber: Subscriber }) {
  const repository = useContext(SecurityRepository.Context);
  const dispatch = useDispatch();

  const input = createRef<Input>();
  const [ nickname, setNickname ] = useState<string>('');
  const [ phase, setPhase ] = useState<'on' | 'off'>();
  const handleEdit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setNickname(props.subscriber.nickname);
    setPhase('on');
  };
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setPhase('off');
    if (nickname) {
      repository
        .changeNickname(nickname)
        .then(subscriber => {
          dispatch(login(subscriber))
          setPhase(undefined);
        })
      ;
    }
  };

  useEffect(() => {
    input.current && input.current.focus();
  }, [ input ]);

  return (
    <Reviewer>
      {props.subscriber.portrait ? (
        <ScoopImage scoop={props.subscriber.portrait} />
      ) : (
        <img src={portrait} alt={props.subscriber.nickname} />
      )}
      <div>
        {undefined === phase ? (
          <Nickname>
            {props.subscriber.nickname}
            <Link to="#" onClick={handleEdit}><Icon name="pencil" /></Link>
          </Nickname>
        ) : (
          <Nickname>
            <NicknameInput
              disabled={'on' !== phase}
              value={nickname}
              onChange={(_: React.SyntheticEvent, { value }: InputOnChangeData) => setNickname(value)}
              ref={input}
            >
              <input size={Math.max(nickname.length, 4)} />
            </NicknameInput>
            <Link to="#" onClick={handleSubmit}><Icon name="check" /></Link>
          </Nickname>
        )}
        <Timestamp />
      </div>
    </Reviewer>
  );
}

const H2 = styled.h2`
  margin: 40px 0 30px;
  font-size: ${24 / 16}rem;
  font-weight: bold;
  line-height: ${30 / 24};

  a {
    margin-left: 10px;
    font-size: 1rem;
    font-weight: normal;
    color: #666 !important;
  }
`;

const Pl = styled.p`
  margin-top: -10px !important;
  font-size: ${20 / 16}rem;
  line-height: ${30 / 20};
  color: #666;
`;

const Replies = styled.div`
  margin-left: 65px;
`;

const Placeholder = styled(withRouter((props: RouteComponentProps & { className?: string }) => {
  const to = URI.build({
    path: '/sign-in',
    query: URI.buildQuery({ next: props.location.pathname }),
  });

  return (
    <div className={props.className}>
      Please&nbsp;<Link to={to}>login</Link>&nbsp;to comment
    </div>
  );
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  background: #fdfdfd;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 2px;

  a {
    text-decoration: underline;
  }
`;

const Reviewer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  img {
    margin-right: 20px;
    width: 45px;
    height: 45px;
    border-radius: 24px;
  }
`;

const Nickname = styled.div`
  font-size: 1rem;
  font-weight: bold;
  line-height: ${18 / 16};

  a {
    margin-left: 10px;
  }
`;

const Timestamp = styled.div`
  margin-top: 5px;
  height: 1rem;
  font-size: ${14 / 16}rem;
  color: #999;
`;

const NicknameInput = styled(Input)`
  input {
    padding: 0 !important;
    border: 0 !important;
    font-family: monospace !important;
    font-size: 1rem;
    line-height: ${18 / 16};
  }
`;

const Submit = styled(Form.Button)`
  margin-top: 20px !important;
`;
