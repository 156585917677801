import { IInstitutionBlueprint, Institution } from '../models/Institution';
import { InstitutionForm } from '../models/InstitutionForm';

import axios, { AxiosInstance } from 'axios';
import * as React from 'react';
import { ScoopRepository } from './ScoopRepository';

export class InstitutionRepository {

  public static readonly $ = new InstitutionRepository(axios, ScoopRepository.$);
  public static readonly Context = React.createContext(InstitutionRepository.$);

  constructor(private a: AxiosInstance, private sr: ScoopRepository) {
  }

  public async fetch(): Promise<Institution | null> {
    const { data } = await this.a.get<IInstitutionBlueprint | null>('/institution')

    return data && Institution.build(data);
  }

  public async persist(form: InstitutionForm): Promise<Institution> {
    const image = form.image && await this.sr.persist(form.image);
    const { data } = await this.a.put<IInstitutionBlueprint>('/institution', { institution: form.patch('image', image).dispose() });

    return Institution.build(data);
  }

}
