export class FileScoop {

  public static readonly MEGABYTES = 2;

  constructor(public readonly file: File) {
  }

  public dispose() {
    // noop
  }

}
