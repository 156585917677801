import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

import molecule from './1.del_molecule.png';
import production from './2.del_production.png';
import workflow from './3.del_workflow.png';

import Page from './Page';

class FAQs extends Component<{ className?: string; }> {

  render() {
    return (
      <Page loc="nav.faqs" className={this.props.className}>
        <Helmet>
          <title>About DNA-encoded chemical libraries (DEL) Technology - DELOpen</title>
          <meta name="description" content="DEL (DNA-encoded chemical libraries) accelerates the drug discovery process through the synthesis and conjugation of chemical compounds to DNA barcodes." />
        </Helmet>

        <Header as="h1">
          About DNA-encoded chemical libraries (DEL) Technology
        </Header>

        <Header as="h2">What is DEL?</Header>
        <p>
          DNA-encoded chemical libraries (DEL) is a technology
          that involves the synthesis and conjugation of
          chemical compounds to DNA barcodes (Figure 1). This
          enables affinity screening against targets with
          billions of molecules, followed by deconvolution
          through high-throughput sequencing. Boosted by
          recent development of the next-generation sequencing
          technology, DEL aims to accelerate the drug
          discovery process.
        </p>
        <figure>
          <img src={molecule} style={{ width: '650px' }} alt="Schematic illustration of a DEL molecule" />
          <figcaption>Figure 1. Schematic illustration of a DEL molecule.</figcaption>
        </figure>

        <Header as="h2">How is DEL produced?</Header>
        <p>
          The DNA-encoded library is produced through a
          “split&pool” methodology (Figure 2). Briefly, a set
          of unique DNA tags is chemically conjugated to a
          corresponding set of small organic molecules. These
          DNA-conjugated compounds are then mixed (“pool”) and
          divided (“split”) into a number of groups before a
          second set of DNA tags and their corresponding
          building blocks are added to each of those
          groups. This “split&pool” step can be repeated a
          number of times in a combinatorial manner, thus
          enables rapid production of highly diversified
          libraries.
        </p>
        <figure>
          <img src={production} style={{ width: '910px' }} alt="A typical production route of DNA-encoded libraries" />
          <figcaption>Figure 2. A typical production route of DNA-encoded libraries.</figcaption>
        </figure>

        <Header as="h2">How does DEL selection work?</Header>
        <p>
          Affinity selection is by far the most popular
          selection method of DEL against target proteins
          (Figure 3). Typically, DEL molecules are pooled into
          one test tube and incubated with the target protein,
          which can be immobilized onto an affinity
          matrix. This allows the bound and unbound molecules
          to be separated by the mobile and stationary and
          mobile phases. Isolated binders are then decoded
          through DNA sequencing and re-synthesized without
          DNA for subsequent bioactivity confirmation.
        </p>
        <figure>
          <img src={workflow} style={{ width: '920px' }} alt="The general workflow of DEL selection" />
          <figcaption>Figure 3. The general workflow of DEL selection.</figcaption>
        </figure>

        <Header as="h2">What are the advantages of DEL?</Header>
        <p>
          As a breakthrough in early drug discovery
          technology, DEL offers two advantages that are
          unprecedented. First, the diversity of the libraries
          are in the hundreds of billions. Second, the
          selection is executed in a single tube thus can be
          performed at the benchtop. In contrast to
          conventional screening procedures such as
          high-throughput screening, DEL technology does not
          require biochemical assay development and does not
          require heavy instrumentation. Therefore, DEL
          technology offers the most rapid and cost-effective
          method to discover high quality hits and
          pharmaceutical leads. In addition, since the
          selection is affinity-based, DEL is useful on many
          targets that are traditionally challenge or
          “undrugable”, such as PPI. Recent advancement in the
          bifunctional/bispecific molecules, such as Protacs,
          can also receive tremendous assistance from
          DEL. Beyond drug discovery, DEL is also the
          powerhouse of generating affinity tool compounds for
          biomedical research.
        </p>

        <Header as="h2">What is DELopen?</Header>
        <p>
          DELopen is a platform connecting industry and academic
          research institutions to realize effective sharing of
          scientific research information and protection of
          intellectual property. Through this platform, researchers
          and institutions engaged in the development of new drugs
          with DEL technology can more quickly conduct early new drug
          development and expedite the development of results to
          commercialization as soon as possible.
        </p>

        <Header as="h2">How is DELopen beneficial to academics and non-profit research organizations?</Header>
        <p>
          Institutions interested in using the DELopen compound
          library for target screening can access libraries and
          perform affinity screening against billions of compounds
          with no cost or commitment. The structure information of the
          compound library and target information are confidential to
          both parties. After screening by the researcher, the
          experimental results are returned to the owner of the
          compound library for subsequent PCR / Sequencing. Once
          positive results are found, all parties can communicate with
          each other for further collaboration.
        </p>

        <Header as="h2">What is the initial cost to academics and non-profit research organizations?</Header>
        <p>
          There is no cost to receive the DELopen library or to run
          the affinity screen.
        </p>

        <Header as="h2">What are the different collaboration modes once a meaningful result is achieved?</Header>
        <p>
          After screening and subsequent PCR/sequencing by the library
          provider, the library provider and researcher may choose
          from a number of options. The primary goal of DELopen is to
          create a platform for sharing information, so the default
          mode is for the researcher to swap information. In exchange
          for free access to a limited number of hits and chemotypes,
          the remaining data from the screen will be made available on
          the DELopen platform to qualified
          participants. Alternatively, the two parties may decide to
          move forward with a commercial agreement involving shared
          intellectual property or a fee for service business model.
        </p>

        <Header as="h2">How can you earn credits?</Header>
        <p>
          The platform awards credits for both parties involved in the
          collaboration if information from the affinity screens are
          shared on the DELopen platform. More detail about earning
          and trading credits will be posted soon.
        </p>

        <Header as="h2">
          Since the researcher does not know the identity of the hits,
          how is the decision made to identify the top hits?
        </Header>
        <p>
          The decision to pick the top 5 hits will be driven by WuXi’s
          proprietary informatics analysis system and our scientists’
          experience with DEL technology. The researcher is provided
          with a report that identifies those hits with the greatest
          promise as well as other hits that may be of
          interest. Although the report does not identify the exact
          structure of the results, it does show the basic library
          schematic which identifies the building blocks integral to
          the hits. The report will show: (1) the compound IDs and
          their enrichment signal score, (2) the library scheme of the
          chemotype in the form of the library schematic, (3) a chart
          showing signal binning of various conditions, and (4) a cube
          analysis illustrating the selection range of top
          hits. Please refer to the attached data analysis report
          summary. The researcher is presented with a recommendation
          for the top 5 hits along with other compounds that may
          alternatively be selected by the researcher, backed up by
          the report elements described above and illustrated in the
          attachment.
        </p>

        <Header as="h2">
          What are the terms associated with the sequencing at stage 1
          of the process? What are the terms and conditions that
          regulate the DELopen sequencing?
        </Header>
        <p>
          WuXi will not use the material returned by the researcher
          for any purpose other than the sequencing, and WuXi will
          provide the researcher with an affinity report (as described
          above). On another note, it would be WuXi, rather than
          DELopen, that performs the sequencing.. The sequencing data
          will be stored in the DELopen database. Except for WuXi
          AppTec no else will know what those sequence barcodes
          represent. The sequences will not be shared with anyone.
        </p>

        <Header as="h2">How long will the sequencing take?</Header>
        <p>
          The sequencing will take 3-4 weeks from the receipt of the
          samples by the WuXi China facility
        </p>

        <Header as="h2">
          Before the researcher sends WuXi the potential hits, is
          there a set of terms and conditions required for the
          researcher to receive the kit in the first place?
        </Header>
        <p>
          There is a DELopen Platform Agreement that governs the
          provision of kits of all DELopen providers.
        </p>

        <Header as="h2">
          What are the terms associated with the protection of the
          “top 5 hits”? What are the conditions under which the other
          hits are shared to the DELopen community?
        </Header>
        <p>
          Regarding the protection of the top 5 hits, the
          bioactivities of the hits on the target will be proprietary
          information of the researcher, and WuXi will keep it
          confidential for at least 5 years, more than enough for the
          researcher to file a patent application claiming the hits in
          relation to the target. The data shared with the community
          will only be used by the DELopen users who have created an
          account on the DELopen website. Any data queried by the
          DELopen community for research purposes/publications must
          acknowledge the name of the original author of the data as a
          reference for any type of work.
        </p>

        <Header as="h2">
          Does WuXiapp (or an affiliate) own the IP into all the
          compounds that are shared? What about the compounds that are
          the “top 5 hits”?
        </Header>
        <p>
          WuXi claims no IP ownership of the compounds that are shared
          nor of the top 5
        </p>

        <Header as="h2">
          Can I do two conditions now and then send the other two in a
          separate shipment (after receiving the results based on what
          we will have obtained)? If yes, do you provide that for free
          as well?
        </Header>
        <p>
          It is not recommended to do this. If you do the four
          conditions simultaneously, send them all back together. If
          you do two selections first and do the other two later, you
          will need to set NTC on both operations. In that case the
          total condition number in a kit will be insufficient. NTC
          control is important in affinity selection and can’t be
          removed or overlapped.
        </p>

        <Header as="h2">
          How many controls do I need for each of the following cases:
          <small>
            1. if the proteins are the same but bead-type is different
            (e.g. Protein 1-binds to GST beads and Protein 1-binds to
            Nickel beads)?
            <br />
            2. if the proteins are different (e.g. Protein 1 and
            Protein 2) and bead-type is also different? (e.g. Protein
            1-binds to GST beads, Protein 2-binds to Nickel beads)
            <br />
            3. if the proteins are different but bead-type is the same
            (e.g. Protein 1-binds to GST beads and Protein 2-binds to
            GST beads)?
          </small>
        </Header>
        <p>
          NTC is used to filter out the false positive binding,
          including binding to the beads. So you need to set one NTC
          for each bead type. But if the proteins are different, as
          long as they use the same bead and do the selection at the
          same time, they can share one NTC.
        </p>
        <p>
          So the NTC number of the 3 conditions are: 2, 2 and 1,
          respectively.
        </p>

        <Header as="h2">
          In Section 1 of the user manual, does it make sense to test
          different protein concentrations (e.g. 1-5 μg)?
        </Header>
        <p>
          You may choose to test the capacity of a certain volume of
          beads under different protein concentration, and then choose
          an optimal beads volume and protein amount for affinity
          selection.
        </p>

        <Header as="h2">
          Is centrifuging a viable option for separationinstead of
          collecting the flow through via gravity column? If yes, what
          is the speed that should be used?
        </Header>
        <p>
          Yes, centrifuging is a viable option for separation of beads
          and supernatant. And the centrifuging speed can be
          determined according to the product manual or protocols of
          the beads.
        </p>

        <Header as="h2">
          When eluting the remaining from beads using SDS-page sample
          loading buffer at 95 C-5min, should the beads be
          re-suspended in a sample loading buffer and then boiled, and
          use that to load the gel?
        </Header>
        <p>
          Yes.
        </p>

        <Header as="h2">
          In Step 9, should steps 1-6 be repeated three times?
        </Header>
        <p>
          Yes, three rounds of selection should be performed and FRESH
          immobilized protein should be used in each round of
          selection to incubate with DELopen superpool or elute from
          previous round of selection.
        </p>

        <Header as="h2">
          When transferring supernatant to the post selection tubes,
          what is the storage temperature?
        </Header>
        <p>
          Post selection sample should be stored at ultra-low
          temperatures (-80 ˚C or dry ice) during both storage and
          shipment.
        </p>

        <Header as="h2">
          Do I need to do all three conditions and then use condition
          4 as a control with no protein? Or does each condition
          represents something different?
        </Header>
        <p>
          It is not required to set all four conditions. Condition
          set-up, including number of conditions, proteins in each
          condition, additives be used, etc, should be determined
          based on the screening objectives. And the last condition,
          condition four in most cases, should be No Protein Control,
          which helps to exclude matrix binders.
        </p>

        <Header as="h2">
          If all conditions are the same, do I need a control for
          every protein or for every bead type.
        </Header>
        <p>
          Yes, a protein control is needed for every bead type.
        </p>

        <Header as="h2">
          Where do I find the DELopen Kit ID number? Is it the
          reference number or the compound ID?
        </Header>
        <p>
          Kit ID is printed on the label that seals the DELopen
          Kit. It helps to locate individual kit and also works as a
          client ID that allow us to keep track upon receiving it.
        </p>
      </Page>
    );
  }

}

export default styled(FAQs)`
  h2 {
    margin-top: 40px !important;
    padding-left: 2rem !important;
    line-height: 1.5 !important;
  }

  h2 small {
    display: block;
  }

  h2::before {
    content: "Q:";
    display: inline-block;
    width: 2rem;
    margin-left: -2rem;
  }

  p {
    padding-left: 2rem;
  }

  h2 + p::before {
    content: "A:";
    display: inline-block;
    width: 2rem;
    margin-left: -2rem;
    font-size: 1.28571429rem;
    font-weight: bold;
    color: #333;
  }

  img {
    display: block;
    margin: auto;
  }

  figure {
    margin: 30px 0 40px;
  }

  figcaption {
    margin-top: 20px;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    color: #666;
  }
`;
