import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { InjectedIntlProps, injectIntl } from 'react-intl';

class MyHelmet extends Component<InjectedIntlProps & {
  title: string;
  description?: string;
}> {

  render() {
    const { title, description, children, intl: { formatMessage } } = this.props;
    
    return (
      <Helmet>
        <title>{formatMessage({ id: title })} - DELopen</title>
        {description && (
          <meta name="description" content={formatMessage({ id: description })} />
        )}
        {children}
      </Helmet>
    );
  }

}

export default injectIntl(MyHelmet);
