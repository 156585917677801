import React, { Component, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Header, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import moment from 'moment';

import { Fragment } from '../../models/Fragment';
import { Script } from '../../models/Script';
import { IScriptBadgeClass, ScriptBadge } from '../../models/ScriptBadge';
import { ScriptDatabase } from '../../models/ScriptDatabase';
import { ScriptKind } from '../../models/ScriptKind';
import { FK, ScriptQuery } from '../../models/ScriptQuery';
import { ScriptBadgeRepository } from '../../repositories/ScriptBadgeRepository';
import { ScriptRepository } from '../../repositories/ScriptRepository';

import Layout from '../../Layout';
import MyHelmet from '../widgets/MyHelmet';
import { PageComponentProps, withPage } from '../widgets/Paginator';
import Hotwords from './Hotwords';
import NeoPager from './NeoPager';
import Search from './Search';
import Sort from './Sort';
import delet_ from './delete.svg';
import del from './del_s.svg';

const DefaultSort = 'age';

type P = RouteComponentProps & PageComponentProps & {
  className?: string;
};

interface S {
  total?: number;
  fragment?: Fragment<Script>;
}

class ScriptIndex extends Component<P & { repo: ScriptRepository; }, S> {

  state: S = { };

  componentDidMount() {
    this.props.repo.count().then(cs => this.setState({ total: cs.reduce((n, c) => n + c._all, 0) }));
    this.find();
  }

  componentDidUpdate({ location: { search } }: P) {
    if (this.props.location.search !== search) {
      this.find();
    }
  }

  render() {
    const { className, page, pageSize, location: { search } } = this.props;
    const { total, fragment } = this.state;
    const start = pageSize * (page - 1);
    const query = ScriptQuery.build(search);

    return (
      <Layout wide breadcrumb={[['/scripts/_', 'nav.scripts.dashboard'], 'nav.scripts']} className={className}>
        <MyHelmet title="nav.scripts" />

        <H1 as="h1">
          <FormattedMessage id="script.finder">
            {h1 => (
              <span>
                <img src={del} alt="" />
                {h1}
              </span>
            )}
          </FormattedMessage>
          <Header.Subheader>
            {total || 0} references were collected
          </Header.Subheader>
        </H1>
        <Search placeholder="script.placeholder" action="/scripts">
          <Hotwords />
        </Search>

        <Container>
          <Filters>
            <Filter>
              <FormattedMessage id="script.filters" tagName="h4" />
              {(['t', 'y', 'db', 'r'] as FK[]).map(k =>
                query[k].map(v => (
                  <p key={v}>
                    {k !== 'r' ? v : '★'.repeat(Number(v))}
                    <Del to={`/scripts?${query.toggle(k, v)}`}>
                      <img src={delet_} alt="" />
                    </Del>
                  </p>
                ))
              )}
            </Filter>

            <BadgeFilter title="script.type" badgeClass={ScriptKind} query={query} />
            <YearFilter title="script.publication_date" query={query} />
            <BadgeFilter title="script.database" badgeClass={ScriptDatabase} query={query} />
            <Filter>
              <FormattedMessage id="script.rating" tagName="h4" />
              {Array(5).fill(undefined).map((_, i) => (
                <Link
                  to={`/scripts?${query.toggle('r', String(1 + i))}`}
                  className={query.includes('r', String(1 + i)) ? 'active' : ''}
                  key={i}
                >
                  {'★'.repeat(1 + i)}
                </Link>
              ))}
            </Filter>
          </Filters>

          <Items>
            <Sort query={query.defaultSort(DefaultSort)} />
            <FormattedMessage id="script.search_results" tagName="h1" />
            {!fragment ? (
              <Loader active content="Loading" />
            ) : (
              <React.Fragment>
                <Status>
                  <FormattedMessage id="pager.items" />
                  {': '}
                  {fragment.total > 0 ? `${start + 1}-${start + fragment.data.length} of ${fragment.total}` : 0}

                  <NeoPager total={fragment.total}>
                    <input type="hidden" name="q" value={query.q} />
                    <input type="hidden" name="sort" value={query.sort} />

                    {['db', 't', 'kw', 'y', 'r'].map(k => (
                      <React.Fragment key={k}>
                        {query[k as FK].map(v => (
                          <input type="hidden" name={`${k}[]`} value={v} key={v} />
                        ))}
                      </React.Fragment>
                    ))}
                  </NeoPager>
                </Status>

                {fragment.data.map(script => (
                  <Item key={script.id}>
                    <Title to={`/scripts/${script.id}`} dangerouslySetInnerHTML={script.highlight('title')} />
                    <Authors dangerouslySetInnerHTML={script.highlight('authors')} />
                    <Source dangerouslySetInnerHTML={script.highlight('source')} />
                    <Rating>
                      <FormattedMessage id="script.rating" />: <span className="stars">{'★'.repeat(script.rating)}</span>
                    </Rating>
                    <Synopsis dangerouslySetInnerHTML={script.ellipsis(300, 330, true)} />
                  </Item>
                ))}

                <Status>
                  <NeoPager total={fragment.total}>
                    <input type="hidden" name="q" value={query.q} />
                    <input type="hidden" name="sort" value={query.sort} />

                    {['db', 't', 'kw', 'y', 'r'].map(k => (
                      <React.Fragment key={k}>
                        {query[k as FK].map(v => (
                          <input type="hidden" name={`${k}[]`} value={v} key={v} />
                        ))}
                      </React.Fragment>
                    ))}
                  </NeoPager>
                </Status>
              </React.Fragment>
            )}
          </Items>
        </Container>
      </Layout>
    );
  }

  async find() {
    this.setState({ fragment: undefined });

    const { page, pageSize, location: { search }, repo } = this.props;
    const fragment = await repo.findAll(page, pageSize, ScriptQuery.build(search).defaultSort(DefaultSort));

    this.setState({ fragment });
  }

}

export default withPage((props: P) => (
  <ScriptRepository.Context.Consumer>
    {repo => (
      <ScriptIndex repo={repo} {...props} />
    )}
  </ScriptRepository.Context.Consumer>
));

interface IBadgeFilterProps<T extends ScriptBadge> {
  title: string;
  badgeClass: IScriptBadgeClass<T>;
  query: ScriptQuery;
}

function PureBadgeFilter<T extends ScriptBadge>({ title, badgeClass, query, repo }: IBadgeFilterProps<T> & { repo: ScriptBadgeRepository; }) {
  const [badges, setBadges] = useState<[T, number][]>([]);
  const [window, setWindow] = useState<number>(10);

  useEffect(() => {
    repo
      .findAll(badgeClass, query.q)
      .then(badges => setBadges(badges))
    ;
  }, [badgeClass, query, repo]);

  return (
    <Filter>
      <FormattedMessage id={title} tagName="h4" />
      {badges.slice(0, window).map(([b, count]) => (
        <Link
          to={`/scripts?${query.toggle(badgeClass.K, b.name)}`}
          className={query.includes(badgeClass.K, b.name) ? 'active' : ''}
          key={b.id}
        >
          {b.name} ({count})
        </Link>
      ))}
      {window < badges.length && (
        <h5 onClick={() => setWindow(10 + window)}>
          <FormattedMessage id="script.more" />
        </h5>
      )}
    </Filter>
  );
}

function BadgeFilter<T extends ScriptBadge>(props: IBadgeFilterProps<T>) {
  return (
    <ScriptBadgeRepository.Context.Consumer>
      {repo => (
        <PureBadgeFilter repo={repo} {...props} />
      )}
    </ScriptBadgeRepository.Context.Consumer>
  );
}

interface IYearFilterProps {
  title: string;
  query: ScriptQuery;
}

const Decade = styled((props: { className?: string; decade: number; years: [string, number][]; query: ScriptQuery; }) => {
  const [ active, setActive ] = useState<boolean>(false);
  const c = moment().year();
  const beg = props.decade * 10;
  const end = beg + 9;

  return (
    <div className={`${props.className} ${active ? 'active' : ''}`}>
      <h5 onClick={() => setActive(!active)}>{beg} - {end < c && String(end)} ∨</h5>
      {props.years.map(([y, count]) => (
        <Link
          to={`/scripts?${props.query.toggle('y', y)}`}
          className={props.query.includes('y', y) ? 'active' : ''}
          key={y}
        >
          {y} ({count})
        </Link>
      ))}
    </div>
  );
})`
  a {
    display: none !important;
  }

  &.active {
    margin-bottom: 24px;

    a {
      display: block !important;
    }
  }
`;

function PureYearFilter({ title, query, repo }: IYearFilterProps & { repo: ScriptBadgeRepository; }) {
  const [ years, setYears ] = useState<[string, number][]>([]);

  useEffect(() => {
    repo
      .findYears(query.q)
      .then(years => setYears(years))
    ;
  }, [query, repo]);

  const decades = years.reduce<number []>((ds, [y, _]) => {
    const d = Math.floor(Number(y) / 10);
    if (ds.includes(d)) {
      return ds;
    }

    return ds.concat(d);
  }, []);

  return (
    <Filter>
      <FormattedMessage id={title} tagName="h4" />
      {decades.map(d => {
        const ys = years.filter(([y, _]) => Math.floor(Number(y) / 10) === d);

        return ys.length > 0 && (
          <Decade decade={d} years={ys} query={query} key={d} />
        );
      })}
    </Filter>
  );
}

function YearFilter(props: IYearFilterProps) {
  return (
    <ScriptBadgeRepository.Context.Consumer>
      {repo => (
        <PureYearFilter repo={repo} {...props} />
      )}
    </ScriptBadgeRepository.Context.Consumer>
  );
}

const H1 = styled(Header)`
  margin: 40px 0;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  color: #333;
  text-align: center;

  & > span {
    padding-left: 65px;
    position: relative;

    img {
      width: 60px;
      height: 54px;
      margin-top: -27px;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }

  .sub.header {
    margin-top: 7px;
    font-size: 20px;
    font-weight: normal;
    line-height: 24px;
    color: #999;
  }
`;

const Container = styled.div`
  display: flex;
  margin-top: 30px;
  color: #333;
`;

const Filters = styled.div`
  width: 270px;
  padding: 40px 30px;
  background: #fff;
`;

const Filter = styled.div`
  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }

  h4 {
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: 600px;
    line-height: 18px;
  }

  a, a:hover, h5, & > p {
    display: block;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    color: #333;
  }

  h5 {
    cursor: pointer;
  }

  & > p {
    & > a {
      opacity: 0;
      transition-duration: ${1 / 3}s;
    }

    &:hover > a {
      opacity: 1;
    }
  }

  a.active {
    color: #4072ee;
  }
`;

const Del = styled(Link)`
  display: inline !important;
  margin-left: 5px !important;

  img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
`;

const Items = styled.div`
  flex: 1;
  padding: 40px 40px 60px;
  margin-left: 30px;
  position: relative;
  background: #fff;
  counter-reset: it;

  h1 {
    margin-top: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }
`;

const Status = styled.div`
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;

  &:last-child {
    margin-top: 40px;
  }
`;

const Item = styled.div`
  margin-top: 40px;

  em {
    font-style: normal;
    background: #ff0;
  }
`;

const Title = styled(Link)`
  display: block;
  font-size: 22px;
  line-height: 24px;
  counter-increment: it;

  &::before {
    content: counter(it) ". ";
  }

  &, &:hover {
    color: #4072ee;
    text-decoration: underline;
  }

  &:visited {
    color: rgba(121, 156, 243);
  }
`;

const Authors = styled.p`
  margin: 20px 0 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const Source = styled.p`
  margin: 10px 0 0;
  font-size: 16px;
  line-height: 18px;
`;

const Rating = styled.div`
  margin: 20px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;

  .stars {
    color: #fec400;
  }
`;

const Synopsis = styled.p`
  margin: 20px 0 0;
  font-size: 16px;
  line-height: 24px;
`;
