import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Form, Grid, InputOnChangeData, Message, TextAreaProps } from 'semantic-ui-react';
import styled from 'styled-components';

import { FileScoop } from '../../models/FileScoop';
import { KitForm } from '../../models/KitForm';
import { SupplementForm } from '../../models/Supplement';
import { KitRepository } from '../../repositories/KitRepository';

import Layout from '../../PrivateLayout';
import Panel from '../../Panel';
import MyHelmet from '../widgets/MyHelmet';
import ScoopImageInput from '../scoop/ImageInput';
import ScoopInput from '../scoop/ScoopInput';
import Scoop from './Scoop';

import add from '../scoop/add.png';
import upload from './upload.svg';

type P = RouteComponentProps<{ id?: string }> & InjectedIntlProps;

interface IKitEditState {
  form: KitForm;
  loading: boolean;
}

class KitEdit extends React.Component<P & { repo: KitRepository }, IKitEditState> {

  state = { form: new KitForm(), loading: false };

  private action: 'save' | 'publish' = 'save';

  private setTitle = this.patchString('title');
  private setArticleNo = this.patchString('articleNo');
  private setVolume = this.patchString('volume');
  private setSynopsis = this.patchString('synopsis');
  private setImage = this.patch('image');

  componentDidMount() {
    this.find();
  }

  componentDidUpdate(prevProps: P) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.find();
    }
  }

  render() {
    const { match: { params: { id } }, intl: { formatMessage } } = this.props;
    const { form, loading } = this.state;
    const title = id ? 'kit.edit' : 'kit.new';

    return (
      <Layout breadcrumb={[ ['/kits', 'kit.yours'], title ]}>
        <MyHelmet title={title} />

        <Grid>
          <Panel width={16} header={<FormattedMessage id={title} />}>
            <Form loading={loading} onSubmit={this.submit}>
              <Form.Field>
                <label>
                  <FormattedMessage id="kit.image" />
                </label>
                <ScoopImageInput
                  trigger={<Link to="#" className="add-image"><img src={add} alt="add" /></Link>}
                  value={form.image}
                  onChange={this.setImage}
                />
                <Message info>
                  <Message.Content>
                    <FormattedMessage
                      id="kit.image_prompt"
                      values={{
                        dimension: '600px × 600px',
                        size: '900KB',
                        format: 'JPG',
                      }}
                    />
                  </Message.Content>
                </Message>
              </Form.Field>
              <Form.Input
                required
                label={formatMessage({ id: 'kit.title' })}
                type="text"
                value={form.title}
                onChange={this.setTitle}
              />
              <Form.Input
                required
                label={formatMessage({ id: 'kit.article_no' })}
                type="text"
                value={form.articleNo}
                onChange={this.setArticleNo}
              />
              <Form.Input
                label={formatMessage({ id: 'kit.volume' })}
                type="text"
                value={form.volume}
                onChange={this.setVolume}
              />
              <Form.TextArea
                required
                label={formatMessage({ id: 'kit.synopsis' })}
                value={form.synopsis}
                onChange={this.setSynopsis}
              />
              <Form.Field>
                <label>
                  <FormattedMessage id="kit.files" />
                  <Limit>
                    <FormattedMessage id="kit.files.limit" />
                  </Limit>
                </label>

                <ScoopInput accept=".pdf" onChange={this.handleAddFile}>
                  {handleBrowse => (
                    <Upload basic type="button" onClick={handleBrowse}>
                      <img src={upload} alt="" />
                      <FormattedMessage id="upload" />
                    </Upload>
                  )}
                </ScoopInput>

                {form.supplements.map((supplement, i) => (
                  <Scoop supplement={supplement} key={i}>
                    <RemoveButton supplement={supplement} onRemove={this.handleRemove} />
                  </Scoop>
                ))}
              </Form.Field>

              <Actions>
                <Button basic type="submit" onClick={this.save}>
                  <FormattedMessage id="save" />
                </Button>

                <Button primary type="submit" onClick={this.publish}>
                  <FormattedMessage id="publish" />
                </Button>
              </Actions>
            </Form>
          </Panel>
        </Grid>
      </Layout>
    );
  }

  private find() {
    const { repo, match: { params: { id } } } = this.props;

    if (!id) {
      this.setState({ form: new KitForm() });
    } else {
      repo
        .find(id)
        .then(kit => this.setState({ form: kit, loading: false }))
      ;
      this.setState({ loading: true });
    }
  }

  private patchString<K extends 'title' | 'articleNo' | 'volume' | 'synopsis'>(key: K) {
    return (_: React.SyntheticEvent<{}>, { value }: InputOnChangeData | TextAreaProps) =>
      this.patch(key)(value as string);
  }

  private patch<K extends keyof KitForm>(key: K) {
    return (value: KitForm[K]) =>
      this.setState({ form: this.state.form.patch(key, value) });
  }

  private handleAddFile = (scoop?: FileScoop) => {
    if (scoop) {
      this.setState(({ form }) => ({
        form: form.patch('supplements', form.supplements.concat(new SupplementForm(
          scoop.file.name,
          scoop,
          scoop.file.size,
        ))),
      }));
    }
  }

  private handleRemove = (supplement: SupplementForm) => {
    this.setState(({ form }) => ({
      form: form.patch('supplements', form.supplements.filter(s => supplement !== s)),
    }));
  }

  private save = () => this.action = 'save';
  private publish = () => this.action = 'publish';

  private submit = (e: React.FormEvent<{}>) => {
    e.preventDefault();

    const { form } = this.state;
    const { repo, match, history } = this.props;
    repo
      .persist(form, 'publish' === this.action, match.params.id)
      .then(() => history.push('/kits'))
      .catch(() => this.setState({ loading: false }))
    ;
    this.setState({ loading: true });
  }

}

export default injectIntl((props: P) => (
  <KitRepository.Context.Consumer>
    {repo => (
      <KitEdit repo={repo} {...props} />
    )}
  </KitRepository.Context.Consumer>
));

const Limit = styled.small`
  margin-left: 10px;
  font-size: ${13 / 14}rem;
  font-weight: normal;
  color: #999;
`;

const RemoveButton = styled((props: {
  className?: string;
  supplement: SupplementForm;
  onRemove(supplement: SupplementForm): void;
}) => {
  const handleRemove = () => props.onRemove(props.supplement);

  return (
    <button className={props.className} type="button" onClick={handleRemove}>
      <FormattedMessage id="remove" />
    </button>
  );
})`
  margin-left: 30px;
  background: none;
  border: none;
  font-size: 1rem;
  color: #4072ee;
`;

const Upload = styled(Button)`
  display: flex !important;
  align-items: center;
  justify-contents: center;
  margin-top: 15px !important;

  img {
    margin-right: 12px;
  }
`;

const Actions = styled(Form.Field)`
  margin-top: 40px !important;
`;
