import { Carrier, ICarrierBlueprint } from '../models/Carrier';

import axios, { AxiosInstance } from 'axios';
import * as React from 'react';

export class CarrierRepository {

  public static readonly $ = new CarrierRepository(axios);
  public static readonly Context = React.createContext(CarrierRepository.$);

  constructor(private a: AxiosInstance) {
  }

  public async findAll(): Promise<Carrier[]> {
    const { data } = await this.a.get<ICarrierBlueprint[]>('/carriers');

    return data.map(Carrier.build);
  }

}
