export class DigestScoop {

  public static build(digest: string) {
    return new DigestScoop(digest);
  }
  
  constructor(public readonly digest: string) {
  }

  public dispose() {
    return this.digest;
  }
  
}
