import { Fragment, IFragmentBlueprint } from '../models/Fragment';
import { ITrialBlueprint, Trial } from '../models/Trial';
import { TrialForm } from '../models/TrialForm';

import axios, { AxiosInstance } from 'axios';
import * as React from 'react';

export class TrialRepository {

  public static readonly $ = new TrialRepository(axios);
  public static readonly Context = React.createContext(TrialRepository.$);

  constructor(private a: AxiosInstance) {
  }

  public async findAll(page: number, size: number = 20): Promise<Fragment<Trial>> {
    const { data } = await this.a.get<IFragmentBlueprint<ITrialBlueprint>>('/_/trials', { params: { page, size } });

    return Fragment.build(data, Trial.build);
  }

  public async findYours(page: number, size: number = 20): Promise<Fragment<Trial>> {
    const { data } = await this.a.get<IFragmentBlueprint<ITrialBlueprint>>('/trials', { params: { page, size } });

    return Fragment.build(data, Trial.build);
  }

  public async find(id: string): Promise<Trial> {
    const { data } = await this.a.get(`/trials/${id}`);

    return Trial.build(data);
  }

  public async persist(form: TrialForm, locale: string, kitId: string, id?: string): Promise<Trial> {
    const { data } = await (
      id
        ? this.a.put<ITrialBlueprint>(`/${locale}/trials/${id}`, { trial: Object.assign(form.dispose(), { kit: kitId }) })
        : this.a.post<ITrialBlueprint>(`/${locale}/trials`, { trial: Object.assign(form.dispose(), { kit: kitId }) })
    );

    return Trial.build(data);
  }

  public async persistHits(form: TrialForm, token: string, kitId: string, id: number): Promise<Trial> {
    const { data } = await (
      this.a.post<ITrialBlueprint>('/hits/api/kit_persist', { trial: Object.assign(form.dispose(), { kit: kitId, id: id })}, { baseURL: '', headers: {'Authorization':`delopen.org ${token}`}})
    );

    return Trial.build(data);
  }

  public async persistHitsAuth(subscriber: any, token: string, id: string): Promise<Trial> {
    const { data } = await (
      this.a.put<ITrialBlueprint>('/hits/api/kit_persist_auth', { subscriber: Object.assign(subscriber, { trial_id: id })}, { baseURL: '', headers: {'Authorization':`delopen.org ${token}`}})
    );

    return Trial.build(data);
  }

  public async persistAuth(form: TrialForm, isAuth: number, locale: string, kitId: string, id?: string): Promise<Trial> {
    const { data } = await (
      this.a.put<ITrialBlueprint>(`/${locale}/trials/${id}`, { trial: Object.assign(form.dispose(), {  kit: kitId, isAuth: isAuth }) })
    );

    return Trial.build(data);
  }

  public async reject(remark: string, locale: string, id: string | number): Promise<Trial> {
    const { data } = await this.a.post<ITrialBlueprint>(`/${locale}/trials/${id}/rejection`, { rejection: { remark } });

    return Trial.build(data);
  }

}
