import React, { Component } from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Checkbox, Form, Grid, InputOnChangeData, TextAreaProps, DropdownProps, Segment, Header, Message } from 'semantic-ui-react';
import styled from 'styled-components';

import URI from 'urijs';
import CountrySelect from '../widgets/CountrySelect';

import { IAppState } from '../../AppState';
import { Kit } from '../../models/Kit';
import { Subscriber } from '../../models/Subscriber';
import { TrialForm } from '../../models/TrialForm';
import { KitRepository } from '../../repositories/KitRepository';
import { TrialRepository } from '../../repositories/TrialRepository';

import Layout from '../../PrivateLayout';
import Panel from '../../Panel';
import MyHelmet from '../widgets/MyHelmet';
import InstitutionPanel from '../kit/InstitutionPanel';
import { Cap, HomeUrl, T, TermsFragment, TermsUrl } from '../permanent/Styled';

type P = RouteComponentProps<{ kitId: string } | { id: string; }> & InjectedIntlProps & {
  className?: string;
};

interface ITrialEditState {
  kit?: Kit;
  form?: TrialForm;
  loading: boolean;
  disabled: boolean;
  checked: boolean;
}

class TrialEdit extends Component<P & {
  subscriber?: Subscriber;
  repo: TrialRepository;
  kRepo: KitRepository;
}, ITrialEditState> {

  state: ITrialEditState = { loading: false, disabled :true, checked: false };

  private setEmail = this.patchString('email');
  private setFirstName = this.patchString('firstName');
  private setLastName = this.patchString('lastName');
  private setPhoneNumber = this.patchString('phoneNumber');
  private setInstitution = this.patchString('institution');
  private setCountry = this.patch('country');
  private setSupervisorFirstName = this.patchString('supervisorFirstName');
  private setSupervisorLastName = this.patchString('supervisorLastName');
  private setSupervisorTitle = this.patchString('supervisorTitle');
  private setSupervisorHomepage = this.patchString('supervisorHomepage');
  private setSupervisorEmail = this.patchString('supervisorEmail');
  private setSupervisorInstitution = this.patchString('supervisorInstitution');
  private setConsignee = this.patchString('consignee');
  private setShippingEmail = this.patchString('shippingEmail');
  private setShippingPhone = this.patchString('shippingPhone');
  private setAddress = this.patchString('address');
  private setFedexAccount = this.patchString('fedexAccount');
  private setPostcode = this.patchString('postcode');
  private setSampleReturn = this.patchString('sampleReturn');
  private setOptionalService = this.patchOptionalService('optionalService');
  private setSynopsis = this.patchString('synopsis');
  private preparedOff = this.patchPrepared(false);
  private preparedOn = this.patchPrepared(true);

  componentDidMount() {
    this.find();
  }

  componentDidUpdate(prevProps: P & { subscriber?: Subscriber }) {
    if ((this.props.match.params as { id: string }).id !== (prevProps.match.params as { id: string }).id
        || (this.props.match.params as { kitId: string }).kitId !== (prevProps.match.params as { kitId: string }).kitId
        || this.props.subscriber !== prevProps.subscriber) {
      this.find();
    }
  }

  render() {
    const { className, match: { params }, intl: { formatMessage } } = this.props;
    const { kit } = this.state;
    const loading = this.state.loading || !this.state.form;
    const form = this.state.form || new TrialForm();
    const title = !(params as { id: string }).id ? 'trial.new' : 'trial.edit';
    const optionals: Array<object> = [
      {text: 'Protein', value: 'Protein'},
      {text: 'Other DEL collaboration model', value: 'Other DEL collaboration model'},
      {text: 'Biophysics Biochemistry', value: 'Biophysics Biochemistry'},
      {text: 'Structural Biology', value: 'Structural Biology'},
      {text: 'Fragment-Based Drug Discovery', value: 'Fragment-Based Drug Discovery'},
      {text: 'Other Customized Service', value: 'Other Customized Service'},
    ];

    return (
      <Layout breadcrumb={[ ['/trials', 'trial.yours'], title ]} className={className}>
        <MyHelmet title={title} />

        <Grid>
          <Panel width={10} header={<FormattedMessage id={title} />}>
            <Form loading={loading} onSubmit={this.submit}>
              <Header as="h3">{formatMessage({ id: 'trial.delopen_h3' })}</Header>
              <Segment>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  required
                  label={formatMessage({ id: 'common.first_name' })}
                  type="text"
                  value={form.firstName}
                  onChange={this.setFirstName}
                />
                <Form.Input
                  fluid
                  required
                  label={formatMessage({ id: 'common.last_name' })}
                  type="text"
                  value={form.lastName}
                  onChange={this.setLastName}
                />
              </Form.Group>
              <Form.Input
                required
                label={formatMessage({ id: 'common.email' })}
                type="text"
                value={form.email}
                onChange={this.setEmail}
              />
              <Form.Input
                required
                label={formatMessage({ id: 'common.phone_number' })}
                type="text"
                value={form.phoneNumber}
                onChange={this.setPhoneNumber}
              />
              <Form.Input
                required
                label={formatMessage({ id: 'common.institution' })}
                type="text"
                value={form.institution}
                onChange={this.setInstitution}
              />
              <Form.Field required>
                <label>
                  <FormattedMessage id="security.country" />
                </label>
                <CountrySelect
                  value={form.country}
                  onChange={this.setCountry}
                />
              </Form.Field>
              </Segment>
              <Header as="h3">{formatMessage({ id: 'trial.supervisor_h3' })}</Header>
              <FormattedMessage id="trial.supervisor_information" />
              <p><strong>{formatMessage({ id: 'trial.supervisor_information_notice' })}</strong></p>
              <Segment>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  fluid
                  label={formatMessage({ id: 'common.first_name' })}
                  type="text"
                  value={form.supervisorFirstName}
                  onChange={this.setSupervisorFirstName}
                />
                <Form.Input
                  required
                  fluid
                  label={formatMessage({ id: 'common.last_name' })}
                  type="text"
                  value={form.supervisorLastName}
                  onChange={this.setSupervisorLastName}
                />
              </Form.Group>
              <Form.Input
                required
                label={formatMessage({ id: 'trial.supervisor_institution' })}
                type="text"
                value={form.supervisorInstitution}
                onChange={this.setSupervisorInstitution}
              />
              <Form.Input
                required
                label={formatMessage({ id: 'trial.supervisor_email' })}
                type="text"
                value={form.supervisorEmail}
                onChange={this.setSupervisorEmail}
              />
              <Form.Input
                required
                label={formatMessage({ id: 'trial.supervisor_title' })}
                type="text"
                value={form.supervisorTitle}
                onChange={this.setSupervisorTitle}
              />
              <Form.Input
                required
                label={formatMessage({ id: 'trial.supervisor_homepage' })}
                type="text"
                value={form.supervisorHomepage}
                onChange={this.setSupervisorHomepage}
              />
              <small>{formatMessage({ id: 'trial.supervisor_homepage_notice' })}</small>
              </Segment>
              <Header as="h3">{formatMessage({ id: 'trial.shippment_h3' })}</Header>
              <FormattedMessage 
                id="trial.shippment_information"                   
                values={{
                  br: (
                      <br />
                    ),
                }}
              />
              <Segment>
              {/*<Form.Input
                required
                label={formatMessage({ id: 'trial.consignee' })}
                type="text"
                value={form.consignee}
                onChange={this.setConsignee}
              />
              <Form.Input
                required
                label={formatMessage({ id: 'trial.shipping_email' })}
                type="text"
                value={form.shippingEmail}
                onChange={this.setShippingEmail}
              />
              <Form.Input
                required
                label={formatMessage({ id: 'trial.shipping_phone' })}
                type="text"
                value={form.shippingPhone}
                onChange={this.setShippingPhone}
              />*/}
              <Form.Input
                required
                label={formatMessage({ id: 'trial.address' })}
                type="text"
                value={form.address}
                onChange={this.setAddress}
              />
              <Form.Input
                required
                label={formatMessage({ id: 'trial.postcode' })}
                type="text"
                value={form.postcode}
                onChange={this.setPostcode}
              />
              <Form.Input
                required
                label={formatMessage({ id: 'trial.fedex_account' })}
                type="text"
                value={form.fedexAccount}
                onChange={this.setFedexAccount}
              />
              <small>
                <FormattedMessage
                  id="trial.fedex_account_notice"
                  values={{
                    br: (
                      <br />
                    ),
                  }}
                />
              </small>
              </Segment>
              <Header as="h3">{formatMessage({ id: 'trial.sample_return_h3' })}</Header>
              <Segment>
              <Form.Field>
              <Form.Input
                label={formatMessage({ id: 'trial.sample_return' })}
                type="date"
                value={form.sampleReturn}
                onChange={this.setSampleReturn}
              />
              <Message visible size='mini'>
                  <FormattedMessage 
                    id="trial.sample_return_notice"                   
                    values={{
                      day: (
                        <strong>60</strong>
                      ),
                    }}
                  />
              </Message>
              </Form.Field>
              </Segment>
              <Header as="h3">{formatMessage({ id: 'trial.optional_service_h3' })}</Header>
              <Segment>
              <Form.Field>
                <FormattedMessage id="trial.optional_service" tagName="label" />
                <Form.Select
                options={optionals}
                value={form.optionalService}
                onChange={this.setOptionalService}
                />
              </Form.Field>
              <Message visible size='mini' content={formatMessage({ id: 'trial.optional_service_notice' })} />
              </Segment>
              {/*<Form.TextArea
                required
                label={formatMessage({ id: 'trial.synopsis' })}
                value={form.synopsis}
                onChange={this.setSynopsis}
              />
              <Form.Field>
                <FormattedMessage id="trial.prepared" tagName="label" />
                <Form.Group>
                  <Form.Radio
                    label={formatMessage({ id: 'no' })}
                    checked={!form.prepared}
                    onClick={this.preparedOff}
                  />
                  <Form.Radio
                    label={formatMessage({ id: 'yes' })}
                    checked={form.prepared}
                    onClick={this.preparedOn}
                  />
                </Form.Group>
              </Form.Field>*/}
              <Footnote onScroll={this.handleScroll} >
                <Header as="h3">DELOPEN PLATFORM SCREENING AGREEMENT</Header>
                <p>
                  <b>BY ACCEPTING AN ORDER FORM (DEFINED BELOW) THAT REFERENCES THIS SCREENING AGREEMENT (“AGREEMENT”), YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND YOU AGREE TO BE BOUND BY, THE TERMS SET FORTH BELOW. IF THE INDIVIDUAL ACCEPTING THIS AGREEMENT IS DOING SO ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, SUCH INDIVIDUAL REPRESENTS THAT HE OR SHE HAS THE AUTHORITY TO BIND THAT COMPANY OR OTHER LEGAL ENTITY AND THAT ENTITY WILL BE BOUND TO THESE TERMS.</b>
                </p>
                <p>
                  1. <Cap>Definitions</Cap>. In addition to all terms defined elsewhere in this Agreement, the following terms have the meanings set out below:
                </p>
                <Q>
                  <p>
                    “<T>Affiliate</T>” means, with respect to a Party, any entity that currently or in the future controls, is controlled by or is under common control with such Party for so long as that control exists, where “control” means ownership of more than fifty percent (50%) of the outstanding securities representing the right to vote for the election of directors or other managing authority of such Party.
                  </p>
                  <p>
                “<T>Affinity Results</T>” means the summary report generated from the Decoding Experiment, including a selection profile of the potential hits generated from the experiment without their chemical structures.
                  </p>
                  <p>
                “<T>Controls</T>” means, with respect to Intellectual Property Rights and Technology, the Intellectual Property Rights and Technology that a Party: (i) owns; or (ii) has the right license to the other Party on the terms and conditions set forth in this Agreement without violating the terms of any arrangement with any third party.
                  </p>
                  <p>
                “<T>Decoding Experiments</T>” means next-generation sequencing experiments to perform informatics analysis on and decode the chemical structures of the Research Product.
                  </p>
                  <p>
                “<T>DELopen Platform</T>” means the platform made available at <HomeUrl /> and “<T>DELopen</T>” means DELopen Limited.
                  </p>
                  <p>
                “<T>Hit Screening Activities</T>” means the Research and Decoding Experiment, collectively.
                  </p>
                  <p>
                “<T>Intellectual Property Rights</T>” means all intellectual property or industrial rights arising anywhere throughout the world, including: (i) all patents and patent rights (including utility models, and other rights in inventions); (ii) all rights associated with the protection of trade secrets and confidential information; (iii) all copyrights and mask work rights, and “moral” or similar rights; (iv) all rights in databases and data collections; (v) all rights in registrations and applications to any of the foregoing; and (vi) any similar, corresponding or equivalent rights to any of the foregoing.
                  </p>
                  <p>
                “<T>Kit</T>” means a compound library made available by Provider in connection with this Agreement.
                  </p>
                  <p>
                “<T>Order Form</T>” means the ordering document generated by the available functionality on the DELopen Platform that incorporates the information and requirements from the research application materials and the applicable Kit description posted to the DELopen Platform.
                  </p>
                  <p>
                “<T>Party</T>” means Researcher or Provider, individually, and “<T>Parties</T>” means Researcher and Provider, collectively.
                  </p>
                  <p>
                “<T>Provider</T>” means the party who provides compound libraries for Research under this Agreement.
                  </p>
                  <p>
                “<T>Provider DEL Activities</T>” means the following activities undertaken or authorized by any person or entity (including Provider, any of its Affiliates and their respective successors and assigns): (i) creating, conceiving, reducing to practice, developing, generating, using, selling, offering for sale, making, having made, importing, exporting or otherwise exploiting in any manner any Technology related to DNA-encoded chemical library technologies; (ii) disclosing or otherwise making available to any person or entity any Technology related to DNA-encoded chemical library technologies; (iii) performing any services or activities related to DNA-encoded chemical library technologies; and (iv) performing any services or activities that are required or helpful to enable Provider or any of its Affiliates to perform Provider’s obligations arising from and in connection with this Agreement.
                  </p>
                  <p>
                “<T>Representative</T>” means an employee or consultant of a Party who needs access to material licensed by the other Party under Section 5(d) to exercise any such licensed rights, in each case, who is bound to terms with respect to the licensed material that are at least as restrictive as those set forth in this Agreement.
                  </p>
                  <p>
                “<T>Research</T>” means DNA sequencing and affinity selection experiments conducted on the Kits.
                  </p>
                  <p>
                “<T>Researcher</T>” means the party who conducts Research under this Agreement.
                  </p>
                  <p>
                “<T>Research Product</T>” means the chemical substances that constitute selection output generated or arising from the Research, contained in Eppendorf tubes or otherwise, but, in each case, excluding the Kits (and, for clarity, the Research Results).
                  </p>
                  <p>
                “<T>Research Results</T>” means all technical and scientific data and written records documenting the same, in each case, generated or arising from the Research (and, for clarity, excluding the Kits).
                  </p>
                  <p>
                “<T>Technology</T>” means any data, results, technology, or information, in any tangible or intangible form, including works of authorship, know-how, trade secrets, practices, techniques, methods, processes, inventions (patentable or otherwise), ideas, developments, specifications, formulations, formulae, materials or compositions of matter of any type or kind (patentable or otherwise), algorithms, technical, scientific or other data of any type or kind, research materials, reagents and compositions of matter, including biological material and chemical compounds.
                  </p>
                </Q>
                <p>
                  Any other capitalized terms that are used in this Agreement but not defined herein will have the meanings ascribed to them in the DELopen Platform Terms of Service located at <TermsUrl /> (“<T>TOS</T>”).
                </p>
                <p>
                  2. <Cap>Scope of Agreement</Cap>. This Agreement governs the Hit Screening Activities. The Hit Screening Activities are further described in the Order Form, which is hereby incorporated into this Agreement by reference. If there is any conflict or inconsistency between this Agreement and any Order Form, this Agreement will apply to the extent of the conflict or inconsistency. The Parties will conduct at the following activities under and subject to the terms in this Agreement: (a) the Provider will deliver the Kit to the Researcher; (b) the Researcher will conduct Research, and deliver Research Products to the Provider; and (c) the Provider will conduct Decoding Experiments and deliver Affinity Results to the Researcher.
                </p>
                <p>
                  3. <Cap>Delivery of the Materials</Cap>. The Provider will deliver the Kits and Affinity Results to the Researcher, and the Researcher will deliver the Research Products to the Provider, in each case, in the quantities specified in the Order Form. Provider will deliver the Affinity Results electronically. Provider will physically ship the Kits to the Researcher, and the Researcher will physically ship the Research Products to the Provider. For all such physically shipped items, the following terms apply:
                </p>
                <Q>
                  <p>
                    The shipping-Party will select the method of shipment and the carrier and will pay all costs associated with shipping and delivery except as set forth under Section 3(b) below. The shipping-Party will deliver the items to be shipped to the receiving-Party’s delivery location set forth in the Order Form (“<T>Delivery Location</T>”) using the shipping-Party’s standard methods for packaging and shipping. Any time quoted for delivery is an estimate only.
                  </p>
                  <p>
                    The receiving-Party will be solely responsible for paying all fees and obtaining all authorizations required under applicable law for the importation of the shipped items into the country where the Delivery Location is located.
                  </p>
                  <p>
                    Risk of loss passes to the receiving-Party when the shipping-Party tenders the applicable materials to the carrier at the facility from which those materials will be shipped. Title to the shipped items will pass to the receiving-Party upon physical receipt of the shipped materials at the Delivery Location.
                  </p>
                  <p>
                    A receiving-Party will notify the shipping-Party of any applicable import restrictions in the jurisdiction where the receiving-Party is located before the applicable items and delivered (whether electronically or through physical shipment).
                  </p>
                </Q>
                <p>
                  4. <Cap>Blinding</Cap>. Provider will deliver the Kits and Affinity Results on a blinded basis such that these items will not include information that reveals the chemical structures of any compound within the Kit or Research Products and nothing in this Agreement requires Provider to disclose that information.
                </p>
                <p>5. <Cap>Intellectual Property</Cap></p>
                <Q>
                  <p>
                    <Cap>Developed IP</Cap>. <b>ALL TECHNOLOGY AND THE INTELLECTUAL PROPERTY RIGHTS THEREIN THAT RESEARCHER CONCEIVES, DEVELOPS OR REDUCES TO PRACTICE IN CONNECTION WITH THIS AGREEMENT WILL BE THE SOLE AND EXCLUSIVE PROPERTY OF THE RESEARCHER (“<T>RESEARCHER IP</T>”). PROVIDER WILL NOT, DIRECTLY OR INDIRECTLY, ASSERT ANY RIGHTS IN OR TO THE RESEARCHER IP. FOR THE AVOIDANCE OF DOUBT, THE RESEARCHER IP EXCLUDES ANY INTELLECTUAL PROPERTY RIGHTS OWNED OR CONTROLLED BY PROVIDER AND ITS AFFILIATES, INCLUDING ANY IN OR TO THE KITS AND AFFINITY RESULTS (“<T>PROVIDER IPR</T>”). PROVIDER SOLELY AND EXCLUSIVELY OWNS AND RETAINS ALL OF ITS RIGHTS, TITLE AND INTEREST IN AND TO SUCH PROVIDER IPR, SUBJECT TO THE LICENSES GRANTED BELOW.</b>  During and after the Term, Researcher will not assert, nor will Researcher authorize, assist, or encourage any of its Affiliates or any other person or entity to assert, against Provider or any of its Affiliates, or any of their respective successors or assigns, any claim or cause of action alleging any infringement, misappropriation or violation of any Intellectual Property Rights arising from or in connection with the Provider DEL Activities.
                  </p>
                  <p>
                    <Cap>Provider</Cap>. Subject to the terms and conditions of this Agreement, Provider hereby grants to Researcher during the Term (defined in Section 6(a)) a non-exclusive, irrevocable (subject to Provider’s rights to terminate this Agreement under Section 6), non-transferable (except under Section 5(e)), non-sublicensable (except as permitted in Section 5(d)) royalty-free and worldwide license under all Intellectual Property Rights Controlled by Provider in and to the Kits and Affinity Results, to: (i) use the Kits solely for Researcher’s internal, non-commercial business purposes as necessary to conduct the Research in accordance with this Agreement; and (ii) to use the Affinity Results solely for Researcher’s internal, non-commercial business purposes as necessary to evaluate a possible business relationship with Provider to be covered under a separate written agreement between the Parties.
                  </p>
                  <p>
                    <Cap>Researcher</Cap>. Subject to the terms and conditions of this Agreement, Researcher hereby grants to Provider during the Term a non-exclusive, irrevocable (subject to Researcher’s rights to terminate this Agreement under Section 6), non-transferable (except under Section 5(e)), non-sublicensable (except as permitted in Section 5(d)), royalty-free and worldwide license, under all Intellectual Property Rights Controlled by Researcher in and to the Research Products, to use the Research Products solely for Provider’s internal, non-commercial business purposes and as necessary to conduct the Decoding Experiments in accordance with the Agreement.  The Provider will destroy the unconsumed portion of the Research Products, if any, promptly upon the earlier to occur of (i) the completion of the Decoding Experiment, or (ii) any expiration or termination of this Agreement.
                  </p>
                  <p>
                    <Cap>Sublicenses</Cap>. The rights granted to each Party in Sections 5(b) and 5(c) above are sublicensable by each Party solely to its Representatives and solely as necessary to enable the sublicensing Party to perform its obligations under this Agreement in accordance with this Agreement.
                  </p>
                  <p>
                    <Cap>Restrictions</Cap>. Without limiting any other terms in this Agreement, each Party as a licensee under Section 5(b) or 5(c), as applicable, will not, and will not authorize or permit any third party (including its Representatives) to: (i) lend, license, sell, assign or otherwise transfer or grant rights to or over the materials licensed to such Party to any third party except for any sublicensee expressly authorized above in Section 5(d) (and, for clarity in the case of Researcher, except to any Representative the Researcher engages to store the Kits); (ii) reverse engineer or otherwise attempt to reconstruct, investigate, discover, modify, replicate, make derivatives of, analyze or otherwise use the materials licensed to such Party in any manner except as expressly set forth in Section 5(b) or 5(c), as applicable; or (iii) use any portion of the materials licensed to such Party to file or support any patent application filing.
                  </p>
                  <p>
                    <Cap>Reservation of Rights</Cap>. Each Party hereby reserves all rights that are not expressly granted to the other in Section 5(b) or 5(c), as applicable, and neither Party grants to the other any implied rights or license under any theory.
                  </p>
                  <p>
                    <Cap>Affiliates</Cap>. Each Party may discharge any obligations and exercise any right hereunder through any of its Affiliates. Each Party hereby guarantees the performance by its Affiliates of such Party’s obligations under this Agreement, and shall cause its Affiliates to comply with the provisions of this Agreement in connection with such performance. Any breach by a Party’s Affiliate of any of such Party’s obligations under this Agreement is a breach by such Party, and the other Party may proceed directly against such Party without any obligation to first proceed against such Party’s Affiliate.
                  </p>
                  <p>
                    <Cap>Limitations on Research Product Use</Cap>. Researcher agrees to the following restrictions with respect to the Research Products:
                  </p>
                  <Q>
                    <p>
                      The Researcher may maintain the Research Product for backup purposes for the sole purpose of enabling Provider to perform the Decoding Experiment, but cannot use the Research Product for any other purpose.
                    </p>
                    <p>
                      The Researcher cannot lend, license, sell, assign or otherwise transfer or grant control of or rights to or over the Research Products to any third party.
                    </p>
                    <p>
                      Unless otherwise agreed to by the Parties in writing, during the Term and for a period of ten (10) years after the termination or expiration of this Agreement, the Researcher will not attempt to reverse engineer or otherwise attempt to reconstruct, investigate, discover, modify, replicate, make derivatives of, analyze or otherwise use any chemical substance in the Research Product.
                    </p>
                    <p>
                      The Researcher will destroy the Research Product in its possession, if any, promptly upon the earlier to occur of (x) its receipt of the Affinity Results or (y) any expiration or termination of this Agreement.
                    </p>
                  </Q>
                </Q>
                <p>6. <Cap>Term; Termination</Cap>.</p>
                <Q>
                  <p>
                    <Cap>Term</Cap>. This Agreement commences upon Provider’s acceptance of the Screening Contract Offer as described in <TermsFragment to="4.6">Section 4.6 of the TOS</TermsFragment> and will remain in effect until all Hit Screening Activities have been reported to DELopen as completed by both Parties as further described in Section 4.7 of the TOS, unless earlier terminated in accordance with this Section (“<T>Term</T>”).
                  </p>
                  <p>
                    <Cap>Termination without Cause</Cap>. Either Party may terminate this Agreement upon written notice to the other Party for any or no reason upon at least ten (10) days’ prior written notice.
                  </p>
                  <p>
                    <Cap>Termination for Cause</Cap>. This Agreement may be terminated by either Party for material breach by the other Party, provided that the terminating Party has given the breaching Party written notice of the breach and at least thirty (30) days to cure the breach prior to the effective date of termination.
                  </p>
                  <p>
                    <Cap>Termination for Failure to Deliver Materials</Cap>.
                  </p>
                  <Q>
                    <p>
                      If the Provider fails to deliver the Kit, in at least the required quantity as stated in the Order Form, within two (2) months after this Agreement takes effect, this Agreement will terminate on the date that is two (2) months after this Agreement takes effect.
                    </p>
                    <p>
                      If the Researcher fails to deliver the Research Product, in at least such quantity as stated in the Order Form, within sixty (60) after the delivery of the Kit to the Researcher, Provider may, in its sole discretion, deem this Agreement terminated immediately upon notice to the Researcher.
                    </p>
                    <p>
                      If the Provider fails to deliver the Affinity Results within forty five (45) days after the delivery of the Research Product to the Provider, this Agreement will be deemed terminated by Provider.
                    </p>
                  </Q>
                  <p>
                    <Cap>Effects of Termination</Cap>. Upon any expiration or termination of this Agreement, (i) the Parties will notify DELopen of the completion of termination of this Agreement as further described in Section 4.7 of the TOS; and (ii) the following Sections, and any defined terms and provisions required to interpret or enforce those Sections (but only to the extent required for such interpretation or enforcement), will survive: 1, 2, 3(b), 3(c), 4, 5(a), 5(e), 5(f), 5(g), 5(h), 6(e), 7, 8, 9, and 10.
                  </p>
                </Q>
                <p>7. <Cap>Representations, Warranties and Covenants</Cap>.</p>
                <Q>
                  <p>
                    Each Party represents and warrants that: (i) entering into this Agreement and granting the rights set forth in this Agreement does not and will not breach, violate or otherwise conflict with such Party’s contractual obligations to any third party; (ii) entering into this Agreement has been duly authorized by all necessary corporate action of the Party; (iii) during the Term, this Agreement will constitute the legal, valid and binding obligation of such Party, enforceable against such Party in accordance with its terms; (iv) when performing its obligations or exercising its rights under this Agreement, it will comply with all applicable laws, rules and regulations. Without limiting the foregoing, Researcher further represents and warrants that it will not use, or authorize the use of, any materials licensed by Provider under this Agreement on or in humans under any circumstances whatsoever.
                  </p>
                  <p>
                    Provider further represents and warrants that it has all rights, licenses and consents necessary to deliver the Kits and Affinity Results to the Researcher in the form so delivered. Researcher hereby further represents and warrants that it has all rights, licenses and consents necessary to deliver the Research Products to the Provider in the form so delivered.
                  </p>
                  <p>
                    EXCEPT AS EXPRESSLY STATED ABOVE IN THIS SECTION 7, NEITHER PARTY MAKES ANY REPRESENTATIONS OR WARRANTIES UNDER THIS AGREEMENT, AND EACH PARTY HEREBY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES THAT ARE NOT EXPRESSLY SET FORTH IN THIS AGREEMENT, INCLUDING THE WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE.
                  </p>
                </Q>
                <p>
                  8. <Cap>Limitation of Liability</Cap>. TO THE FULLEST EXTENT PERMITTED BY LAW: (a) IN NO EVENT WILL PROVIDER OR ITS AFFILIATES BE LIABLE TO THE RESEARCHER FOR ANY LOSS OF USE REVENUE, OR PROFIT, OR FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGE WAS FORESEEABLE AND WHETHER OR NOT PROVIDER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; AND (b) IN NO EVENT WILL THE TOTAL LIABILITY OF PROVIDER AND ITS AFFILIATES UNDER OR IN CONNECTION WITH THIS AGREEMENT EXCEED $500 IN THE AGGREGATE.
                </p>
                <p>
                  9. <Cap>Indemnification</Cap>. Researcher will indemnify, defend, and hold harmless Provider and its Affiliates, and their respective officers, directors, employees, contractors and representatives (each, an “<T>Indemnified Party</T>”) from any liabilities, damages, losses, costs or expenses (including reasonable legal expenses) in connection with any third party claims, actions, suits or proceedings arising from or related to the arrangement contemplated under this Agreement, including any that allege any breach of Section 7 by Researcher or any of its employees, consultants or other personnel (each, a “<T>Claim</T>”). The Indemnified Party will give Researcher reasonable notice of each Claim. Researcher will use counsel reasonably satisfactory to the Indemnified Party to defend each Claim. An Indemnified Party may participate in the defense at Researcher’s sole expense. If at any time the Indemnified Party reasonably determines that Researcher is not willing or able to properly represent its interests, then, without limiting Researcher’s indemnification obligations, the Indemnified Party may take control of the defense of the Claim at Researcher’s sole expense, and in such event the Indemnified Party and its counsel will proceed diligently and in good faith with that defense. Researcher will not settle any Claim without the Indemnified Parties’ prior express written consent, which may be withheld in the Indemnified Party’s sole discretion.
                </p>
                <p>10. <Cap>Miscellaneous</Cap>.</p>
                <Q>
                  <p>
                    <Cap>Dispute Resolution</Cap>. This Agreement will be governed by the laws of the State of New York, excluding its conflicts of laws principles. If there is any dispute between the Parties arising out of this Agreement (each, a “<T>Dispute</T>”), then authorized representatives of each Party will negotiate in good faith to resolve the Dispute. If such representatives cannot resolve the Dispute after no less than thirty (30) days of good faith negotiations, then any such Dispute may be settled by binding arbitration under the Rules of the American Arbitration Association as presently in force (“<T>Rules</T>”) and by three (3) arbitrators appointed in accordance with said Rules. Judgment on the award rendered may be entered in any court having jurisdiction thereof. The place of arbitration will be New York, New York. Any monetary award will be in U.S. dollars and the arbitration will be conducted in the English language. The Parties may apply to any court of competent jurisdiction for temporary or permanent injunctive relief, without breach of this Section 10(a) and without any abridgment of the powers of the arbitrator.
                  </p>
                  <p>
                    <Cap>Assignment</Cap>. Neither Party may assign this Agreement or any rights under it, in whole or in part, without the other Party’s prior express written consent; provided that Provider may assign this Agreement or any rights under it in its sole discretion to any of its Affiliates and to any successor in connection with a merger, acquisition, reorganization, consolidation, or sale of all or substantially all of its assets or the business to which this Agreement relates. Any attempt to assign this Agreement other than as permitted above will be void.
                  </p>
                  <p>
                    <Cap>Severability</Cap>. If any provision in this Agreement is held to be unenforceable, then: (i) it will be severed from this Agreement; (ii) the court of competent jurisdiction will replace the severed provision with another provision that most closely reflects the Parties’ original intent to the fullest extent permitted by law; and (iii) this Agreement will remain in full force and effect.
                  </p>
                  <p>
                    <Cap>Entire Agreement</Cap>. This Agreement, together with the TOS, embodies the entire agreement between the Parties with respect to the subject matter set forth in this Agreement and supersedes any previous or contemporaneous communications, whether oral or written, express or implied.
                  </p>
                  <p>
                    <Cap>Waivers; Amendments</Cap>. All waivers of rights arising under this Agreement must be made in writing by the Party waiving rights, and all amendments to this Agreement must be made in writing and signed by authorized representatives of both Parties.
                  </p>
                  <p>
                    <Cap>English Language</Cap>. English is the authoritative text of this Agreement, and all communications and proceedings must be conducted in English. If this Agreement is translated, then the English language version will control.
                  </p>
                  <p>
                    <Cap>Relationship of the Parties; Non-Exclusivity</Cap>. The relationship between the Parties is that of independent contractors. Nothing contained in this Agreement will be construed as creating any agency, partnership, joint venture or other form of joint enterprise, employment or fiduciary relationship between the Parties, and neither Party will have authority to contract for or bind the other Party in any manner whatsoever. Nothing in this Agreement establishes an exclusive relationship of any kind between the Parties.
                  </p>
                  <p>
                    <Cap>Force Majeure</Cap>. No Party will be liable to the other Party, nor be deemed to have breached this Agreement, for any failure or delay in fulfilling or performing any term of this Agreement, when and to the extent the failure or delay is caused by or results from acts beyond the impacted Party’s control (“<T>Force Majeure Event</T>”). A Party will give the other Party prompt written notice of any event or circumstance that is reasonably likely to result in a Force Majeure Event, and the anticipated duration of such Force Majeure Event. An affected Party will use diligent efforts to end the Force Majeure Event, ensure that the effects of any Force Majeure Event are minimized, and resume full performance under this Agreement.
                  </p>
                  <p>
                    <Cap>Interpretation</Cap>. Unless a clear contrary intention appears: (i) any term defined in the singular includes the plural when required by the applicable context and “including” means “including, without limitation”; (ii) all references in this Agreement to “Sections” are intended to refer to Sections in this Agreement; (iii) all references to days means business days (i.e., Monday through Friday excluding any days when banks are not open in the United States); and (iv) the headings in this Agreement are for convenience of reference only, will not be deemed to be a part of this Agreement, and will not be referred to in connection with the construction or interpretation of this Agreement.
                  </p>
                </Q>
              </Footnote>
              <Form.Field>
                <Checkbox 
                  label={formatMessage({ id: 'trial.screening' })}
                  onClick={this.handleClick}
                  checked={this.state.checked}
                  disabled={this.state.disabled}
                />
              </Form.Field>

              <Form.Button primary disabled={!this.state.checked} type="submit">
                <FormattedMessage id="submit" />
              </Form.Button>
            </Form>
          </Panel>

          <InstitutionPanel institution={kit && kit.subscriber.canonicalInstitution} />
        </Grid>
      </Layout>
    );
  }

  private find() {
    const { repo, kRepo, match: { params }, subscriber } = this.props;

    if (!(params as { id: string }).id) {
      if (!this.state.form) {
        this.setState({
            form: subscriber && new TrialForm(
              subscriber.firstName,
              subscriber.lastName,
              subscriber.email,
              subscriber.phoneNumber,
              subscriber.i9n,
              subscriber.country,
              subscriber.principalInvestigator === true ? subscriber.firstName : '',
              subscriber.principalInvestigator === true ? subscriber.lastName: '',
              '',
              '',
              subscriber.principalInvestigator === true ? subscriber.email: '',
              subscriber.principalInvestigator === true ? subscriber.i9n: '',
              subscriber.firstName + ' ' + subscriber.lastName,
              subscriber.email,
              subscriber.phoneNumber,
            ),
        });

      }

      kRepo
        .find((params as { kitId: string }).kitId)
        .then(kit => this.setState({ kit }))
      ;
    } else {
      repo
        .find((params as { id: string }).id)
        .then(trial => this.setState({ kit: trial.kit, form: trial, loading: false }))
      ;
      this.setState({ loading: true });
    }
  }

  private patchString<K extends 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'institution' | 'supervisorFirstName' | 'supervisorLastName' | 'supervisorTitle' | 'supervisorHomepage' | 'supervisorEmail' | 'supervisorInstitution' | 'consignee' | 'shippingEmail' | 'shippingPhone' | 'address' | 'freightCompany' | 'fedexAccount' | 'postcode' | 'sampleReturn' | 'optionalService' | 'synopsis'>(key: K) {
    return (_: React.SyntheticEvent<{}>, { value }: InputOnChangeData | TextAreaProps) =>
      this.patch(key)(value as string);
  }

  private patchPrepared(on: boolean) {
    return (_: React.SyntheticEvent) =>
      this.patch('prepared')(on);
  }
 
  private patchOptionalService<K extends 'optionalService'>(key: K) {
    return (_: React.SyntheticEvent<{}>, { value }: DropdownProps) =>
      this.patch(key)(value as string);
  }

  private patch<K extends Exclude<keyof TrialForm, 'patch' | 'dispose'>>(key: K) {
    return (value: TrialForm[K]) =>
      this.setState({ form: (this.state.form as TrialForm).patch(key, value) });
  }

  private submit = (e: React.FormEvent<{}>) => {
    e.preventDefault();

    const { kit, form } = this.state;
    const { repo, match: { params }, intl: { locale }, history } = this.props;
    const kID = (kit && String(kit.id)) || (params as { kitId: string }).kitId;
    repo
      .persist(form as TrialForm, locale, kID, (params as { id: string }).id)
      .then(function(response) { 
        repo.persistHits(form as TrialForm, response.token, kID, response.id);
        history.push(String(URI(`/trials/${response.id}/authorization`)));
      })
      .catch(() => this.setState({ loading: false }))
    ;
    this.setState({ loading: true });
  }

  private handleClick = ()=>{
    if(this.state.disabled === false){
      this.setState({
        checked:!this.state.checked
      })
    }
  }

  private handleScroll = (e: any) => {
    let clientHeight = e.target.clientHeight+2; //可视区域高度
    let scrollTop  =  Math.floor(e.target.scrollTop);  //滚动条滚动高度
    let scrollHeight =  e.target.scrollHeight; //滚动内容高度
    console.log(scrollHeight,scrollTop,clientHeight)

    if(scrollHeight - scrollTop <= clientHeight){
      this.setState({ disabled: false });
    }	
  }

}

export default styled(connect(
  ({ subscriber }: IAppState) => ({ subscriber }),
)(injectIntl((props: P) => (
  <KitRepository.Context.Consumer>
    {kRepo => (
      <TrialRepository.Context.Consumer>
        {repo => (
          <TrialEdit repo={repo} kRepo={kRepo} {...props} />
        )}
      </TrialRepository.Context.Consumer>
    )}
  </KitRepository.Context.Consumer>
))))`
  small {
      display:block;
      position:relative;
      margin-top:-1em;
  }
`;

const Footnote = styled.div`
  background: #F8F8F9;
  border: 1px solid rgba(34, 36, 38, 0.15);
  margin-top: 10px !important;
  margin-bottom: 5px !important;
  padding: 10px !important;
  font-size: ${13 / 14}rem;
  line-height: ${20 / 13}em;
  color: #999;
  text-align: left;
  height: 300px;
  overflow: auto;
`;

const Q = styled.div`
  margin-left: 4em;
  counter-reset: q;

  & > p {
    counter-increment: q;
    text-indent: -2em;

    &::before {
      content: "(" counter(q, lower-alpha) ")";
      display: inline-block;
      width: 2em;
      text-indent: 0;
    }
  }

  & > & {
    counter-reset: qq;

    & > p {
      counter-increment: qq;

      &::before {
        content: "(" counter(qq, lower-roman) ")";
      }
    }
  }
`;
