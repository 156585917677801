import * as React from 'react';
import { Helmet } from 'react-helmet';
import { addLocaleData, IntlProvider } from 'react-intl';
import zh from 'react-intl/locale-data/zh';
import { Provider, connect } from 'react-redux';
import { Router } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import PiwikReactRouter from 'piwik-react-router';

import { Subscriber } from './models/Subscriber';
import { SecurityRepository } from './repositories/SecurityRepository';

import Blueprint from './Blueprint';

import { IAppState, login, store } from './AppState';

import axios from 'axios';
axios.defaults.baseURL = '/api/v1';

addLocaleData([ ...zh ]);

export const piwik = PiwikReactRouter({
  url: process.env.REACT_APP_PIWIK_URL,
  siteId: process.env.REACT_APP_PIWIK_SITE_ID,
});
const history = createBrowserHistory();

interface IAppProps {
  locale: 'en-us' | 'zh-cn';
  login: (subscriber?: Subscriber) => void;
}

class App extends React.Component<IAppProps & { repo: SecurityRepository; }> {

  public componentDidMount() {
    const { login, repo } = this.props;

    repo
      .whoami()
      .then(login)
      .catch(() => login())
    ;
  }

  public render() {
    const { locale } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <style>
            {`html, body { font-size: ${'en-us' === locale ? 16 : 14}px; }`}
          </style>
        </Helmet>

        <IntlProvider
          key={locale}
          locale={locale}
          messages={'en-us' === locale ? require('./translations/en-us.json') : require('./translations/zh-cn.json')}
        >
          <Router history={piwik.connectToHistory(history)}>
            <Blueprint />
          </Router>
        </IntlProvider>
      </React.Fragment>
    );
  }

}

const WiredApp = connect(
  ({ locale }: IAppState) => ({ locale }),
  dispatch => ({
    login: (subscriber?: Subscriber) => dispatch(login(subscriber)),
  }),
)((props: IAppProps) => (
  <SecurityRepository.Context.Consumer>
    {repo => (
      <App repo={repo} {...props} />
    )}
  </SecurityRepository.Context.Consumer>
));

export default () => (
  <Provider store={store}>
    <WiredApp />
  </Provider>
);
