import { Carrier, ICarrierBlueprint } from './Carrier';
import { DeliveryForm } from './DeliveryForm';

import moment from 'moment';

export class Delivery extends DeliveryForm {
  public static build(data: IDeliveryBlueprint) {
    return new Delivery(
      data.id,
      data.carrier,
      data.tracking_number,
      moment(data.created_at),
    );
  }

  constructor(
    public readonly id: number,
    public readonly carrier: Carrier,
    trackingNumber: string,
    public readonly createdAt: moment.Moment,
  ) {
    super(carrier, trackingNumber);
  }
}

export interface IDeliveryBlueprint {
  readonly id: number;
  readonly carrier: ICarrierBlueprint;
  readonly tracking_number: string;
  readonly created_at: string;
}
