import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { Institution } from '../../models/Institution';

import website from './website.png';

import Panel from '../../Panel';
import ScoopImage from '../scoop/Image';

interface P {
  className?: string;
  institution?: Institution;
}

class InstitutionPanel extends Component<P> {

  render() {
    const { className, institution } = this.props;

    return (
      <Panel width={6} header={<FormattedMessage id="nav.provider" />} className={className}>
        {institution && (
          <Fragment>
            <ScoopImage scoop={institution.image} />
            <Header as="h3">
              {institution.title}
            </Header>
            <p>
              {institution.synopsis}
            </p>

            <a href={institution.url} target="_blank" rel="noopener noreferrer" className="website">
              {institution.url}
            </a>
          </Fragment>
        )}
      </Panel>
    )
  }

}

export default styled(InstitutionPanel)`
  img {
    display: block;
    max-width: 100%;
    margin: auto;
  }

  h3 {
    margin-top: 30px !important;
  }

  p {
    margin-top: 20px !important;
    line-height: 1.5;
    color: #666;
  }

  .website {
    display: block;
    margin-top: 30px;
    font-size: 1.14286rem;
    line-height: 1.25em;
    color: #666;
    word-break: break-all;

    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 15px;
      margin-right: 10px;
      background: url(${website}) no-repeat center center/contain;
      vertical-align: middle;
    }
  }
`;
