import { AbstractSolution } from './AbstractSolution';
import { Interest, IInterestJSON } from './Interest';

export class EssaySolution extends AbstractSolution {
    static fromJSON(data: IEssaySolutionJSON) {
        return new EssaySolution(
            Interest.fromJSON(data.interest),
            data.paragraph || '',
        );
    }

    constructor(
        interest: Interest,
        readonly paragraph: string,
    ) {
        super(interest);
    }

    get valid() {
        return !!this.paragraph;
    }

    dispose() {
        return ({
            paragraph: this.paragraph,
        });
    }
}

export interface IEssaySolutionJSON {
    readonly interest: IInterestJSON;
    readonly paragraph?: string;
}
