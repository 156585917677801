import React, { PropsWithChildren } from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import banner from '../../banner.jpg';
import logoInversed from '../../logo_inversed.png';

class Jumbotron extends React.Component<InjectedIntlProps, { it: number; }> {

  public state = { it: 0 };

  private iid = 0;

  public componentDidMount() {
    this.start();
  }

  public componentWillUnmount() {
    this.stop();
  }

  public render() {
    const { intl: { locale } } = this.props;
    const { it } = this.state;

    return (
      <Carousel>
        <It banner={banner} color="#4072ee" className={`${1 === it ? 'active ' : ''}${locale}`}>
          <Grid container>
            <Grid.Column>
              <Grid columns={2}>
                <Grid.Column>
                  <h1>
                    <img src={logoInversed} alt="DELopen" />
                  </h1>
                  <p>
                    <FormattedMessage id="home.slogan" values={{ br: <br /> }} />
                  </p>
                  <Link to="/_/kits">
                    <FormattedMessage id="nav.apply_free_del" />
                  </Link>
                </Grid.Column>
                <Grid.Column>
                  <Header as='h3' style={{ color: '#ffffff' }}>
                    <FormattedMessage id="services.notice" values={{ br: <br /> }} />
                  </Header>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </It>
      </Carousel>
    );
  }

  private start = () => {
    if (!this.iid) {
      this.iid = setInterval(() => {
        this.setState(({ it }) => ({ it: (1 + it) % 2 }));
      }, 5000);
    }
  };

  private stop = () => {
    if (this.iid) {
      clearInterval(this.iid);
      this.iid = 0;
    }
  };

}

export default injectIntl(Jumbotron);

const Carousel = styled.div`
  height: 600px;
  position: relative;
`;

const It = styled(({ className, children }: PropsWithChildren<{ className?: string; banner: string; color: string; }>) => (
  <div className={className}>
    {children}
  </div>
))`
  width: 100%;
  height: 600px;
  padding: 150px 0;
  background: url(${props => props.banner}) center center / cover no-repeat;
  position: absolute;

  @keyframes in {
    from { left: 100%; opacity: 1; }
    to { left: 0; opacity: 1; }
  }

  @keyframes out {
    from { left: 0; opacity: 1; }
    to { left: -100%; opacity: 1; }
  }

  &.active {
    left: 0;
  }

  h1 {
    margin: 0;
  }

  img {
    display: block;
    width: 428px;
    height: 106px;
  }

  p {
    margin: 10px 0 0;
    font-size: 22px;
    line-height: 40px;
    color: #fff;
  }

  a {
    float: left;
    width: auto;
    min-width: 220px;
    padding: 20px 40px;
    background: #fff;
    border-radius: 30px;
    box-shadow: 0 2px 20px 0 rgba(64, 114, 238, 0.1);
    margin-top: 30px;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: ${props => props.color};

    &:hover {
      color: ${props => props.color};
    }
  }

  &.en-us p {
    font-size: 24px;
  }
`;

const Indicators = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 120px;
`;

const Indicator = styled.div`
  width: 10px;
  height: 5px;
  margin-left: 5px;
  background: #fff;
  border-radius: 2.5px;
  opacity: .5;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &.active {
    width: 20px;
    opacity: 1;
  }
`;
