import React from 'react';
import styled from 'styled-components';

import { Option } from '../../models/Option';

import checked from './checked.svg';
import unchecked from './unchecked.svg';

interface P {
  className?: string;
  it: Option;
  multiple: boolean;
  checked: boolean;
  onChange(option: Option, on: boolean): void;
}

function OptionItem(props: P) {
  const { className, it, checked, onChange } = props;
  const handleClick = () => onChange(it, !checked);

  return (
    <label className={`${checked ? 'checked' : ''} ${className}`} onClick={handleClick}>
      {it.label}
    </label>
  );
}

export default styled(OptionItem)`
  display: block;
  padding-left: 2rem;
  margin-top: 10px;
  position: relative;
  font-size: ${14 / 14}rem;
  line-height: ${22 / 14};

  &:before {
    content: "";
    width: 16px;
    height: 16px;
    margin-top: -8px;
    background: url(${unchecked}) no-repeat center center / contain;
    position: absolute;
    top: 50%;
    left: 0;
  }

  &.checked:before {
    background-image: url(${checked});
  }
`;
