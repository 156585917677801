import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { Section } from '../../models/Section';
import { Survey } from '../../models/Survey';
import { SurveyRepository } from '../../repositories/SurveyRepository';

import Layout from '../../Layout';
import T from './T';

import questionnaire from './questionnaire.svg';

type P = RouteComponentProps<{ id: string; }> & {
  className?: string;
};

function SurveyThanks(props: P & { repo: SurveyRepository; }) {
  const { className, match: { params: { id } }, repo } = props;

  const [ survey, setSurvey ] = useState<Survey>();

  useEffect(() => {
    repo
      .find(+id)
      .then(setSurvey)
    ;
  }, [id, repo]);

  return (survey && survey.subject && (
    <T it={survey.subject}>
      {(section: Section) => (
        <Layout breadcrumb={section.label} className={className}>
          <div className="text">
            <Grid centered>
              <Grid.Column width={14}>
                <Header as="h1">{section.label}</Header>

                <G src={questionnaire} />

                <P>
                  <FormattedMessage id="survey.thanks" />
                </P>

                <Lnk to="/">
                  <FormattedMessage id="nav.back_to_home" />
                </Lnk>
              </Grid.Column>
            </Grid>
          </div>
        </Layout>
      )}
    </T>
  )) || null;
}

export default styled((props: P) => (
  <SurveyRepository.Context.Consumer>
    {repo => (
      <SurveyThanks repo={repo} {...props} />
    )}
  </SurveyRepository.Context.Consumer>
))`
  .text {
    padding: 40px 0 80px;
    background: #fff;
  }

  h1:first-child {
    padding-bottom: 40px;
    margin: 0 0 40px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 30px;
  }
`;

const G = styled.img`
  display: block;
  width: 150px;
  height: 160px;
  margin: 80px auto 0;
`;

const P = styled.p`
  margin: 40px 0 0;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
`;

const Lnk = styled(Link)`
  display: block;
  width: 200px;
  height: 40px;
  padding: 10px 0;
  margin: 60px auto 20px;
  background: #4072ee;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #fff;

  &:hover {
    color: #fff;
  }
`;
