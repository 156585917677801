import { ILanguageTagBlueprint, LanguageTag } from './LanguageTag';

export class LocalSticker {
  public static build(data: ILocalStickerBlueprint) {
    return new LocalSticker(
      data.id,
      data.language,
      data.name,
    );
  }

  constructor(
    readonly id: number,
    readonly language: LanguageTag,
    readonly name: string,
  ) {
  }
}

export interface ILocalStickerBlueprint {
  readonly id: number;
  readonly language: ILanguageTagBlueprint;
  readonly name: string;
}
