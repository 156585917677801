import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { IAppState } from '../../AppState';
import { Subscriber } from '../../models/Subscriber';

import logo from '../../logo.png';

import provider from '../provider.png';
import subscriber from '../subscriber.png';

class Join extends React.Component<{ className?: string; subscriber: Subscriber | undefined | null; } & InjectedIntlProps> {

  public render() {
    const { className, intl: { locale } } = this.props;

    return (
      <div className={`${className} ${locale}`}>
        <Grid container verticalAlign="middle">
          <Grid.Column width={5}>
            <Header as="h2">
              <FormattedMessage id="home.join_us" values={{ logo: <img src={logo} alt="DELopen" /> }} />
            </Header>
            <p>
              <FormattedMessage id="home.join_us.p" />
            </p>
          </Grid.Column>
          <Grid.Column />
          <Grid.Column width={5}>
            <img src={provider} alt="" className="role" />
            <b>
              <FormattedMessage id="home.join_us.provider" />
            </b>
            <p>
              <FormattedMessage id="home.join_us.provider.p" values={{ br: <br /> }} />
            </p>
            <Link to="/institution">
              <FormattedMessage id="home.join_us.provider.a" />
            </Link>
          </Grid.Column>
          <Grid.Column width={5}>
            <img src={subscriber} alt="" className="role" />
            <b>
              <FormattedMessage id="home.join_us.subscirber" />
            </b>
            <p>
              <FormattedMessage id="home.join_us.subscirber.p" values={{ br: <br /> }} />
            </p>
            <Link to={this.props.subscriber ? '/profile' : '/sign-up'}>
              <FormattedMessage id="home.join_us.subscirber.a" />
            </Link>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

}

export default styled(connect(
  ({ subscriber }: IAppState) => ({ subscriber }),
)(injectIntl(Join)))`
  padding: 80px 0 100px;
  background: #fff;

  h2.ui.header {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 30px;

    img {
        width: auto;
        height: 36px;
      margin-top: 3px;
      vertical-align: middle;
    }
  }

  h2 + p {
    font-size: 1rem;
    line-height: 1.5em;
    color: #999;
  }

  img.role {
    display: block;
    width: 90px;
    height: 90px;
    margin: auto;
  }

  b {
    display: block;
    margin: 30px 0 10px;
    font-size: 22px !important;
    font-weight: normal !important;
    line-height: 30px !important;
    text-align: center;
    color: #333;
  }

  b + p {
    margin: 0 !important;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #999;
  }

  a {
    display: block;
    width: 220px;
    height: 60px;
    padding: 20px 0;
    margin: 40px auto 0;
    background: #4072ee;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #fff !important;
  }

  &.en-us {
    h2.ui.header {
      font-size: 34px;
      line-height: 40px;
    }
  }
`;
