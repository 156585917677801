import { ILibraryJSON, Library } from './Library';
import { IReactionJSON, Reaction } from './Reaction';
import { IScriptBadgeJSON, ScriptBadge } from './ScriptBadge';
import { ScriptDatabase } from './ScriptDatabase';
import { ScriptInstitution } from './ScriptInstitution';
import { ScriptKeyword } from './ScriptKeyword';
import { ScriptKind } from './ScriptKind';

import { IScriptHitJSON, ScriptHit } from './ScriptHit';

import { DigestScoop } from './DigestScoop';

import moment, { Moment } from 'moment';

import he from 'he';

export function highlight(s: string, fragments: string[]) {
    return ({
        __html: fragments.reduce(
            (escaped, fragment) => escaped.replace(
                he.encode(he.decode(fragment.replace(/<\/?em>/g, ''))),
                fragment
            ),
            he.encode(s)
        ),
    });
}

export class Script {
    public static build(data: IScriptJSON): Script {
        return new Script(
            data.id,
            data.refno,
            data.title,
            data.authors,
            data.institutions.map(i => ScriptBadge.build(i, ScriptInstitution)),
            data.source,
            ScriptBadge.build(data.database, ScriptDatabase),
            ScriptBadge.build(data.kind, ScriptKind),
            moment(data.publication_date),
            data.fuzzy,
            data.keywords.map(s => ScriptBadge.build(s, ScriptKeyword)),
            data.synopsis,
            (data.figure && DigestScoop.build(data.figure)) || undefined,
            data.rating,
            data.url,
            data.tip,
            (data.stashed_at && moment(data.stashed_at)) || undefined,
            (data.removed_at && moment(data.removed_at)) || undefined,
            data.hit && ScriptHit.build(data.hit),
            (data.reactions || []).map(Reaction.build),
            (data.libraries || []).map(Library.build),
            data.highlights,
        );
    }

    constructor(
        readonly id: number,
        readonly refno: string,
        readonly title: string,
        readonly authors: string[],
        readonly institutions: ScriptInstitution[],
        readonly source: string,
        readonly database: ScriptDatabase,
        readonly kind: ScriptKind,
        readonly publicationDate: moment.Moment,
        readonly fuzzy: number,
        readonly keywords: ScriptKeyword[],
        readonly synopsis: string,
        readonly figure: DigestScoop | undefined,
        readonly rating: number,
        readonly url: string | undefined,
        readonly tip: string | undefined,
        readonly stashedAt: Moment | undefined,
        readonly removedAt: Moment | undefined,
        readonly hit: ScriptHit | undefined,
        readonly reactions: Reaction[],
        readonly libraries: Library[],
        private readonly highlights?: IScriptHighlights,
    ) {
    }

    public highlight(key: 'title' | 'authors' | 'synopsis' | 'source') {
        switch (key) {
            case 'authors':
                return highlight(this.authors.join(', '), (this.highlights && this.highlights.authors) || []);

            default:
                return highlight(this[key], (this.highlights && this.highlights[key]) || []);
        }
    }

    get publicationDateString() {
        return synth({ date: this.publicationDate, fuzzy: this.fuzzy });
    }

    ellipsis(min: number, max: number, hl: true): { __html: string; };
    ellipsis(min: number, max: number): string;
    ellipsis(min: number, max: number, hl: boolean = false) {
        const slice = (n: number) => {
            const tail = n < this.synopsis.length ? ' ……' : '';

            return this.synopsis.slice(0, n) + tail;
        }

        const e = (() => {
            const m = this.synopsis.indexOf(' ', min);
            if (0 < m && m < max) {
                return slice(m);
            }

            const n = this.synopsis.lastIndexOf(' ', max);
            if (0 < n) {
                return slice(n);
            }

            return slice(min);
        })();

        return hl ? highlight(e, (this.highlights && this.highlights['synopsis']) || []) : e;
    }
}

interface IScriptHighlights {
    readonly title?: string[];
    readonly authors?: string[];
    readonly synopsis?: string[];
    readonly source?: string[];
}

export interface IScriptJSON {
    readonly id: number;
    readonly refno: string;
    readonly title: string;
    readonly authors: string[];
    readonly institutions: IScriptBadgeJSON[];
    readonly source: string;
    readonly database: IScriptBadgeJSON;
    readonly kind: IScriptBadgeJSON;
    readonly publication_date: string;
    readonly fuzzy: number;
    readonly keywords: IScriptBadgeJSON[];
    readonly synopsis: string;
    readonly figure?: string;
    readonly rating: number;
    readonly url?: string;
    readonly tip?: string;
    readonly stashed_at?: string;
    readonly removed_at?: string;
    readonly hit?: IScriptHitJSON;
    readonly reactions?: IReactionJSON[];
    readonly libraries?: ILibraryJSON[];
    readonly highlights?: IScriptHighlights;
}

export interface IFuzzyDate {
    date: moment.Moment;
    fuzzy: number;
}

export function synth(value?: IFuzzyDate) {
    return (value && value.date.format(['YYYY-MM-DD', 'YYYY-MM', 'YYYY'][value.fuzzy])) || '';
}

export function parse(text: string): IFuzzyDate | undefined {
    if (!/^\d{4}((-|\/)\d{1,2}){0,2}$/.test(text)) {
        return undefined;
    }

    const ps = ['0000', '00', '00'];
    const xs = text.split(/-|\//).map((s, i) => (ps[i] + s).slice(-ps[i].length));
    const date = moment(xs.concat('01', '01').slice(0, 3).join('-'));
    if (!date.isValid()) {
        return undefined;
    }

    return ({ date, fuzzy: 3 - xs.length });
}
