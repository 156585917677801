import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { Adviser } from '../../models/Adviser';
import { AdviserRepository } from '../../repositories/AdviserRepository';

import AdviserCard from './AdviserCard';

interface IAdvisoryBoardProps {
  className?: string;
}

interface IAdvisoryBoardState {
  advisers: Adviser[];
}

class AdvisoryBoard extends React.Component<IAdvisoryBoardProps & { repo: AdviserRepository; }, IAdvisoryBoardState> {

  public state: IAdvisoryBoardState = { advisers: [] };

  public async componentDidMount() {
    const advisers = await this.props.repo.findAll();

    this.setState({ advisers });
  }

  public render() {
    const { className } = this.props;
    const { advisers } = this.state;

    return (
      <div className={className}>
        <Header as="h2">
          <FormattedMessage id="adviser.index" />
        </Header>
        <p>
          <FormattedMessage id="adviser.p" values={{ br: <br /> }} />
        </p>

        <Grid container columns={4}>
          {new Array(4).fill(undefined).map((_, i) => (
            <Grid.Column key={(advisers[i] && advisers[i].id) || `[${i}]`}>
              <AdviserCard adviser={advisers[i]} />
            </Grid.Column>
          ))}
        </Grid>

        <Link to="/advisers">
          <FormattedMessage id="adviser.more" />
        </Link>
      </div>
    );
  }

}

export default styled((props: IAdvisoryBoardProps) => (
  <AdviserRepository.Context.Consumer>
    {repo => (
      <AdvisoryBoard repo={repo} {...props} />
    )}
  </AdviserRepository.Context.Consumer>
))`
  padding: 70px 0;
  background: #f7f7f7;

  h2.ui.header {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 20px;
    text-align: center;
  }

  h2 + p {
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #999;
  }

  a {
    display: block;
    width: 220px;
    height: 60px;
    padding: 20px 0;
    margin: auto;
    background: #4072ee;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #fff !important;
  }
`;
