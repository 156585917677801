import React, { ReactElement, useState, useEffect } from 'react';

import Cookies from 'js-cookie';

import { Survey } from '../../models/Survey';
import { SurveyRepository } from '../../repositories/SurveyRepository';

interface P {
  threshold: number;
  cooldown?: number;
  open?: boolean;
  children(survey: Survey, skip: (survey: Survey) => void): ReactElement;
}

function SurveyPortal({ threshold, cooldown, open, children, repo }: P & { repo: SurveyRepository; }) {
  const [survey, setSurvey] = useState<Survey>();
  const skip = (survey: Survey) => {
    Cookies.set(survey.permanentID, 'skip', { expires: cooldown });

    setSurvey(undefined);
  };

  useEffect(() => {
    repo.findAll().then(surveys => {
      const survey = sample(surveys.filter(survey => open || !Cookies.get(survey.permanentID)));
      if (survey && (Math.random() < threshold)) {
        repo.find(survey.id).then(setSurvey);
      }
    });
  }, [repo, open, threshold]);

  return (survey && survey.subject && children(survey, skip)) || null;
}

function sample<T>(xs: T[]): T | undefined {
  return [] !== xs ? xs[Math.floor(Math.random() * xs.length)] : undefined;
}

export default (props: P) => (
  <SurveyRepository.Context.Consumer>
    {repo => (
      <SurveyPortal repo={repo} {...props} />
    )}
  </SurveyRepository.Context.Consumer>
);
