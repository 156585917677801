import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ScalarField } from '../../models/Field';
import { ChoiceScalar } from '../../models/ChoiceScalar';
import { RatingScalar } from '../../models/RatingScalar';
import { EssayScalar } from '../../models/EssayScalar';
import { Solution } from '../../models/Solution';
import { AnswerSheet } from '../../models/AnswerSheet';

import ChoiceScalarItem from './ChoiceScalarItem';
import RatingScalarItem from './RatingScalarItem';
import EssayScalarItem from './EssayScalarItem';

function ScalarItem(props: {
  className?: string;
  error: boolean;
  it: ScalarField;
  answerSheet: AnswerSheet;
  onChange(solution: Solution): void;
}) {
  const { className, error, it, answerSheet, onChange } = props;

  return (
    <div className={className}>
      <Header>
        <Sq>{it.serialNumber}</Sq>
        {it.interest.label}
        {it.required && <Star />}
        {error && (
          <Error>
            <FormattedMessage id="survey.violation" />
          </Error>
        )}
      </Header>
      <Content>
        {(it instanceof ChoiceScalar && (
          <ChoiceScalarItem it={it} solution={answerSheet.solutions[it.interest.permanentID]} onChange={onChange} />
        )) || (it instanceof RatingScalar && (
          <RatingScalarItem it={it} solution={answerSheet.solutions[it.interest.permanentID]} onChange={onChange} />
        )) || (it instanceof EssayScalar && (
          <EssayScalarItem it={it} solution={answerSheet.solutions[it.interest.permanentID]} onChange={onChange} />
        ))}
      </Content>
    </div>
  );
}

export default styled(ScalarItem)`
  margin-top: 50px;
`;

const Header = styled.div`
  margin-bottom: 30px;
  font-size: ${18 / 14}rem;
  line-height: ${24 / 18};
`;

const Sq = styled.span`
  display: inline-block;
  width: 2rem;

  &:after {
    content: ".";
  }
`;

const Star = styled.span`
  &:after {
    content: "*";
    color: #9f3a38;
  }
`;

const Error = styled.span`
  display: inline-block;
  height: 24px;
  padding: 0 5px;
  margin-left: 10px;
  background: #fff6f6;
  border: 1px solid #e0b4b4;
  border-radius: 4px;
  vertical-align: top;
  font-size: ${14 / 14}rem;
  line-height: 22px;
  color: #9f3a38;
`;

const Content = styled.div`
  overflow: hidden;
`;
