import { LanguageTag, ILanguageTagBlueprint } from './LanguageTag';

export class LocalLabel {
    static fromJSON(data: ILocalLabelJSON) {
        return new LocalLabel(
            data.id,
            LanguageTag.build(data.locale),
            data.label,
        );
    }

    constructor(
        readonly id: number,
        readonly locale: LanguageTag,
        readonly label: string,
    ) {
    }
}

export interface ILocalLabelJSON {
    readonly id: number;
    readonly locale: ILanguageTagBlueprint;
    readonly label: string;
}
