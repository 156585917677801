import { Interest } from './Interest';

export abstract class AbstractSolution {
    constructor(readonly interest: Interest) {
    }

    get valid(): boolean {
        return true;
    }
}
