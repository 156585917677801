import React, { Component, ReactElement, ReactNode } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import { P as TransProps } from './Trans';

class SwitchLocale extends Component<InjectedIntlProps> {

  render() {
    let match: ReactNode;
    React.Children.forEach(this.props.children, c => {
      const props = (c as ReactElement<TransProps>).props;
      if (!match && props && (props.locale === undefined || props.locale === this.props.intl.locale)) {
        match = c;
      }
    });

    return match;
  }

}

export default injectIntl(SwitchLocale);
