import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import banner from '../banner.jpg';
import logo from '../logo.png';

class Landing extends React.Component<{ className?: string; }> {

  public render() {
    return (
      <div className={this.props.className}>
        <Helmet>
          <title>Request Free Access - DELopen</title>
          <meta name="description" content="Open access DNA encoded library (DEL) platform, more than 2.8 billion compounds." />
        </Helmet>

        <div className="banner">
          Open Access DNA Encoded Library (DEL) Platform<br />
          More than 2.8 Billion Compounds<br />
          Free Access to the Libraries
        </div>

        <img src={logo} alt="DELopen" className="logo" />

        <p className="prelude">
          Request Free Access to the Libraries Today<br />
          Limited Number of Phase One Slots Available
        </p>

        <Link to="/request-access" className="button">
          Request Information
        </Link>

        <p className="alt">
          or email <a href="mailto:John.Shirley@delopen.org">John.Shirley@delopen.org</a> for more info
        </p>
      </div>
    );
  }

}

export default styled(Landing)`
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 240px;
    background: url(${banner}) no-repeat center center / cover;
    font-size: 20px;
    line-height: 1.5em;
    color: white;
    text-align: center;
  }

  .logo {
    display: block;
    width: 484px;
    height: 120px;
    margin: 100px auto 50px;
  }

  .prelude {
    height: 44px;
    font-size: 18px;
    line-height: 1.22;
    text-align: center;
    color: #666666;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 60px;
    margin: 60px auto 30px;
    border-radius: 35px;
    background-color: #4072ee;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: white;
  }

  .alt {
    height: 22px;
    margin-bottom: 120px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    text-align: center;
    color: #333333;

    a {
      color: inherit;
    }
  }
`;
