import React, { useState, useEffect, useContext, SyntheticEvent } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { SubscriptionForm, Frequency, Day } from '../../models/SubscriptionForm';
import { SubscriptionRepository } from '../../repositories/SubscriptionRepository';

import Layout from '../../Layout';
import MyHelmet from '../widgets/MyHelmet';

function SubscriptionEdit(props: RouteComponentProps<{ id?: string }>) {
  const { match: { params: { id } }, history } = props;

  const repository = useContext(SubscriptionRepository.Context);
  const [ form, setForm ] = useState(new SubscriptionForm());
  const [ loading, setLoading ] = useState(false);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (!form.email) {
      id && repository
        .remove(id)
        .then(() => {
          history.replace('/subscriptions');
        })
        .catch(function () {})
        .then(() => setLoading(false))
      ;
    } else {
      repository
        .save(form, id)
        .then(subscription => history.push(`/subscriptions/${subscription.permanentID}/done`))
        .catch(() => setLoading(false))
      ;
    }

    setLoading(true);
  };

  useEffect(() => {
    if (!id) {
      setForm(new SubscriptionForm());
      setLoading(false);
    } else {
      repository
        .find(id)
        .then(subscription => {
          setForm(subscription);
          setLoading(false);
        })
        .catch(() => history.replace('/subscriptions'))
      ;

      setLoading(true);
    }
  }, [repository, history, id]);

  return (
    <Layout breadcrumb={[ [ '/scripts', 'nav.scripts' ], 'nav.subscription' ]}>
      <Core>
        <Grid centered>
          <Grid.Column width={14}>
            <MyHelmet title="nav.subscription" />
            <H1 as="h1">
              <FormattedMessage id="nav.subscription" />
            </H1>
            <Legend>
              <FormattedMessage id="subscription.legend" />
            </Legend>

            <F loading={loading} onSubmit={handleSubmit}>
              <Form.Field>
                <label><FormattedMessage id="subscription.email" /></label>
                <Form.Field className="combo">
                  <Form.Input
                    value={form.email}
                    onChange={(_, { value }) => setForm(form => form.patch('email', value))}
                  />
                  <p><FormattedHTMLMessage id="subscription.email.hint" /></p>
                </Form.Field>
              </Form.Field>
              <FormattedMessage id="subscription.frequency">
                {label => (
                  <Form.Select
                    required
                    label={label}
                    options={[
                      { text: 'After each update', value: Frequency.Instantly },
                      { text: 'Daily', value: Frequency.Daily },
                      { text: 'Weekly', value: Frequency.Weekly },
                      { text: 'Monthly', value: Frequency.Monthly },
                    ]}
                    value={form.frequency}
                    onChange={(_, { value }) => setForm(form => form.patch('frequency', value as Frequency))}
                  />
                )}
              </FormattedMessage>
              {[Frequency.Monthly, Frequency.Weekly].includes(form.frequency) && (
                <Form.Field required>
                  <label><FormattedMessage id="subscription.day" /></label>
                  <Form.Field className="combo">
                    <Form.Select
                      options={Frequency.Monthly === form.frequency ? [
                        { text: 'The First Day', value: Day.FirstDay },
                        { text: 'The First Sunday', value: Day.FirstSunday },
                        { text: 'The First Monday', value: Day.FirstMonday },
                        { text: 'The First Tuesday', value: Day.FirstTuesday },
                        { text: 'The First Wednesday', value: Day.FirstWednesday },
                        { text: 'The First Thursday', value: Day.FirstThursday },
                        { text: 'The First Friday', value: Day.FirstFriday },
                        { text: 'The First Saturday', value: Day.FirstSaturday },
                        { text: 'The First Weekday', value: Day.FirstWeekday },
                      ] : [
                        { text: 'Sunday', value: Day.Sunday },
                        { text: 'Monday', value: Day.Monday },
                        { text: 'Tuesday', value: Day.Tuesday },
                        { text: 'Wednesday', value: Day.Wednesday },
                        { text: 'Thursday', value: Day.Thursday },
                        { text: 'Friday', value: Day.Friday },
                        { text: 'Saturday', value: Day.Saturday },
                      ]}
                      value={form.day}
                      onChange={(_, { value }) => setForm(form => form.patch('day', value as Day))}
                    />
                    <FormattedMessage id="subscription.always">
                      {label => (
                        <Form.Checkbox label={label} checked={form.always} onChange={(_, { checked }) => setForm(form => form.patch('always', !!checked))} />
                      )}
                    </FormattedMessage>
                  </Form.Field>
                </Form.Field>
              )}
              <FormattedMessage id="subscription.quantity">
                {label => (
                  <Form.Select
                    required
                    label={label}
                    options={[
                      { text: '1 item', value: 1 },
                      { text: '5 items', value: 5 },
                      { text: '10 items', value: 10 },
                      { text: '20 items', value: 20 },
                    ]}
                    value={form.quantity}
                    onChange={(_, { value }) => setForm(form => form.patch('quantity', value as number))}
                  />
                )}
              </FormattedMessage>

              <Form.Field className="actions">
                <Button primary type="submit">
                  <FormattedMessage id="subscription.save" />
                </Button>
                <Button type="reset">
                  <FormattedMessage id="subscription.cancel" />
                </Button>
              </Form.Field>
            </F>
          </Grid.Column>
        </Grid>
      </Core>
    </Layout>
  );
}

export default SubscriptionEdit;

const Core = styled.div`
  padding: 40px 0 80px;
  background: #fff;
`;

const H1 = styled(Header)`
  padding-bottom: 40px;
  margin: 0 0 40px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  line-height: 30px;
`;

const Legend = styled.p`
  margin-bottom: 40px;
  font-size: ${20 / 16}rem;
  line-height: ${30 / 20};
`;

const F = styled(Form)`
  label {
    margin-bottom: 5px !important;
    font-size: 1rem !important;
    font-weight: normal !important;
  }

  .ui.input, .ui.selection.dropdown {
    width: 495px !important;
    margin-right: 20px;
  }

  .combo {
    display: flex;
    align-items: center;

    .field {
      margin-bottom: 0 !important;
    }
  }

  p {
    em {
      font-style: normal;
      color: #9f3a38;
    }
  }

  .actions {
    margin: 40px 0 20px !important;
  }
`;
