import { DigestScoop } from './DigestScoop';

import moment from 'moment';

export class Adviser {
    public static build(data: IAdviserBlueprint): Adviser {
        return new Adviser(
            data.id,
            data.name,
            DigestScoop.build(data.portrait),
            data.synopsis,
            data.url,
            data.weight,
            moment(data.created_at),
        );
    }

    constructor(
        public readonly id: number,
        public readonly name: string,
        public readonly portrait: DigestScoop,
        public readonly synopsis: string,
        public readonly url: string,
        public readonly weight: number,
        public readonly createdAt: moment.Moment,
    ) {
    }
}

export interface IAdviserBlueprint {
    readonly id: number;
    readonly name: string;
    readonly portrait: string;
    readonly synopsis: string;
    readonly url: string;
    readonly weight: number;
    readonly created_at: string;
}
