import { Section, ISectionJSON } from './Section';
import moment, { Moment } from 'moment';

export class Survey {
    static fromJSON(data: ISurveyJSON) {
        return new Survey(
            data.id,
            data.permanent_id,
            data.subject && Section.fromJSON(data.subject),
            moment(data.created_at),
        );
    }

    constructor(
        readonly id: number,
        readonly permanentID: string,
        readonly subject: Section | undefined,
        readonly createdAt: Moment,
    ) {
    }
}

export interface ISurveyJSON {
    readonly id: number;
    readonly permanent_id: string;
    readonly subject?: ISectionJSON;
    readonly created_at: string;
}
