import React, { Component, createContext } from 'react';

export const Context = createContext<(yOffset?: number) => void>(function () {});

class ScrollSensor extends Component<{ onScroll(yOffset?: number): void; }> {

  componentDidMount() {
    this.onScroll();

    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);

    this.props.onScroll(undefined);
  }

  render() {
    return null;
  }

  private onScroll = (() => {
    if (undefined !== window.pageYOffset) {
      return () => this.props.onScroll(window.pageYOffset);
    }

    if ('CSS1Compat' !== (document.compatMode || '')) {
      return () => this.props.onScroll(document.body.scrollTop);
    }

    return () => this.props.onScroll((document.documentElement as HTMLElement).scrollTop);
  })();

}

export default () => (
  <Context.Consumer>
    {onScroll => (
      <ScrollSensor onScroll={onScroll} />
    )}
  </Context.Consumer>
);
