import { useEffect } from 'react';

import { FileScoop } from '../../models/FileScoop';

interface P {
  accept?: string;
  onChange(scoop?: FileScoop): void;
  children(handleBrowse: () => void): JSX.Element;
}

function ScoopInput({ accept, onChange, children }: P) {
  const input = document.createElement('input');
  input.type = 'file';
  if (accept) {
    input.accept = accept;
  }

  const handleBrowse = () => input.click();
  const handleChange = () => {
    if (!input.files) {
      return;
    }

    onChange(new FileScoop(input.files[0]));

    input.value = '';
    /* CHROME: reset file input to stop empty FileList or so. */
  };

  useEffect(() => {
    input.addEventListener('change', handleChange);

    return () => input.removeEventListener('change', handleChange)
  });

  return children(handleBrowse);
}

export default ScoopInput;
