import { DigestScoop } from './DigestScoop';
import { highlight, IScriptJSON, Script } from './Script';

import moment, { Moment } from 'moment';

export class Library {
    public static build(data: ILibraryJSON) {
        return new Library(
            data.id,
            data.refno,
            data.script && Script.build(data.script),
            (data.scheme && DigestScoop.build(data.scheme)) || undefined,
            data.kind.name,
            data.hit || '',
            data.blocks,
            data.tags || '',
            data.pages,
            moment(data.created_at),
            data.highlights,
        );
    }

    constructor(
        readonly id: number,
        readonly refno: string,
        readonly script: Script | undefined,
        readonly scheme: DigestScoop | undefined,
        readonly kind: string,
        readonly hit: string,
        readonly blocks: string,
        readonly tags: string,
        readonly pages: string,
        readonly createdAt: Moment,
        private readonly highlights?: IReactionHighlights,
    ) {
    }

    public highlight(key: 'kind' | 'hit' | 'blocks' | 'tags' | 'institutions') {
        switch (key) {
            case 'institutions':
                return highlight(
                    (this.script && this.script.institutions.length > 0 && this.script.institutions[0].name) || '',
                    (this.highlights && this.highlights['script.institutions']) || []
                );

            default:
                return highlight(this[key], (this.highlights && this.highlights[key]) || []);
        }
    }
}

export interface ILibraryJSON {
    readonly id: number;
    readonly refno: string;
    readonly script?: IScriptJSON;
    readonly scheme?: string;
    readonly kind: { name: string };
    readonly hit?: string;
    readonly blocks: string;
    readonly tags?: string;
    readonly pages: string;
    readonly created_at: string;
    readonly highlights?: IReactionHighlights;
}

interface IReactionHighlights {
    readonly kind?: string[];
    readonly hit?: string[];
    readonly blocks?: string[];
    readonly tags?: string[];
    readonly 'script.institutions'?: string[];
}
