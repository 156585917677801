import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import URI from 'urijs';

import MyHelmet from '../widgets/MyHelmet';
import Flyer from '../../Flyer';

interface Search {
  action?: string;
}

class Sent extends React.Component<RouteComponentProps & { className?: string; }> {

  render() {
    const { className } = this.props;
    const params = URI.parseQuery(this.props.location.search) as Search;
    const header = 'register' === params.action ? 'security.thanks'
                 : 'access' === params.action ? 'security.request_access'
                 : 'security.reset'
    ;

    return (
      <Flyer wide className={className}>
        <MyHelmet title={header} />

        <Header as="h1" color="blue">
          <FormattedMessage id={header} />
        </Header>

        <p>
          <FormattedMessage
            id={`${header}.p`}
            values={{
              br: <br />,
              contactUs: (<a href="mailto:support@delopen.org">
                <FormattedMessage id="nav.contact_us" />
              </a>)
            }}
          />
        </p>
      </Flyer>
    );
  }

}

export default Sent;
