import { Survey, ISurveyJSON } from '../models/Survey';
import { AnswerSheet, IAnswerSheetJSON } from '../models/AnswerSheet';

import axios, { AxiosInstance } from 'axios';
import { createContext } from 'react';

export class SurveyRepository {

    static readonly $ = new SurveyRepository(axios);
    static readonly Context = createContext(SurveyRepository.$);

    constructor(private a: AxiosInstance) {
    }

    async findAll(): Promise<Survey[]> {
        const { data } = await this.a.get<ISurveyJSON[]>('/surveys');

        return data.map(Survey.fromJSON);
    }

    async find(id: number): Promise<Survey> {
        const { data } = await this.a.get<ISurveyJSON>(`/surveys/${id}`);

        return Survey.fromJSON(data);
    }

    async save(form: AnswerSheet, trackingID: string, id: number): Promise<AnswerSheet> {
        const { data } = await this.a.post<IAnswerSheetJSON>(`/surveys/${id}/answer-sheets`, {
            answer_sheet: { ...form.dispose(), trackingID }
        });

        return AnswerSheet.fromJSON(data);
    }

}
