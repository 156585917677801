import { DigestScoop } from './DigestScoop';
import { FileScoop } from './FileScoop';
import { SupplementForm } from './Supplement';

export class KitForm {
  constructor(
    public readonly title: string = '',
    public readonly articleNo: string = '',
    public readonly volume: string = '',
    public readonly synopsis: string = '',
    public readonly image?: DigestScoop | FileScoop,
    readonly supplements: SupplementForm[] = [],
  ) {
  }

  public patch<K extends keyof KitForm>(key: K, value: KitForm[K]) {
    const { title, articleNo, volume, synopsis, image, supplements } =
      Object.assign({}, this, { [key]: value });

    return new KitForm(title, articleNo, volume, synopsis, image, supplements);
  }

  public async dispose(save: (scoop: DigestScoop | FileScoop) => Promise<DigestScoop>) {
    return ({
      title: this.title,
      articleNo: this.articleNo,
      volume: this.volume,
      synopsis: this.synopsis,
      image: this.image && (await save(this.image)).dispose(),
      supplements: await Promise.all(this.supplements.map(s => s.dispose(save))),
    });
  }
}
