import { DigestScoop } from './DigestScoop';
import { FileScoop } from './FileScoop';

export class SupplementForm {
  constructor(
    readonly name: string,
    readonly file: DigestScoop | FileScoop,
    readonly size: number,
  ) {
  }

  async dispose(save: (scoop: DigestScoop | FileScoop) => Promise<DigestScoop>) {
    return ({
      name: this.name,
      file: this.file && (await save(this.file)).dispose(),
    });
  }
}

export class Supplement extends SupplementForm {
  static build(data: SupplementJSON) {
    return new Supplement(
      data.id,
      data.name,
      DigestScoop.build(data.file),
      data.size,
    );
  }

  constructor(
    readonly id: number,
    name: string,
    readonly file: DigestScoop,
    size: number,
  ) {
    super(name, file, size);
  }
}

export interface SupplementJSON {
  readonly id: number;
  readonly name: string;
  readonly file: string;
  readonly size: number;
}
