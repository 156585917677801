import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import cooperation from '../cooperation.png';
import del from '../del.png';
import search from '../search.png';

import Layout from '../../Layout';
import MyHelmet from '../widgets/MyHelmet';

export class Service extends React.Component<{ className?: string; }> {

  public render() {
    const { className } = this.props;

    return (
      <Layout breadcrumb="nav.services" className={className}>
        <MyHelmet title="nav.services" description="services.meta" />

        <div className="text">
          <Grid centered>
            <Grid.Column width={14}>
              <Header as="h1">
                <FormattedMessage id="nav.services" />
              </Header>

              <Header as="h1">
                <FormattedMessage id="services" />
              </Header>
              <Header.Subheader>
                <FormattedMessage id="services.p" values={{ br: <br /> }} />
              </Header.Subheader>

              <img src={del} alt="" />
              <Header as="h2">
                <FormattedMessage id="services.free_del" />
              </Header>
              <p>
                <FormattedMessage id="services.free_del.p" values={{ br: ' ' }} />
              </p>
              <p>
                <FormattedMessage id="services.free_del.pp" />
              </p>

              <img src={cooperation} alt="" />
              <Header as="h2">
                <FormattedMessage id="services.cooperation" />
              </Header>
              <p>
                <FormattedMessage id="services.cooperation.p" values={{ br: ' ' }} />
              </p>
              <p>
                <FormattedMessage id="services.cooperation.pp" />
              </p>

              <img src={search} alt="" />
              <Header as="h2">
                <FormattedMessage id="services.delfinder" />
              </Header>
              <p>
                <FormattedMessage id="services.delfinder.p" values={{ br: ' ' }} />
              </p>
              <p>
                <FormattedMessage id="services.delfinder.pp" />
              </p>
            </Grid.Column>
          </Grid>
        </div>
      </Layout>
    );
  }

}

export default styled(Service)`
  .text {
    padding: 40px 0 80px;
    background: #fff;
  }

  h1 {
    padding-bottom: 40px;
    margin: 0 0 40px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 30px;
  }

  h1:first-child {
    padding-bottom: 40px;
    margin: 0 0 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 30px !important;
    text-align: left;
  }

  h1 + h1 {
    margin-bottom: 20px !important;
    font-size: ${32 / 14}rem !important;
    line-height: ${40 / 32}em !important;
  }

  .sub.header {
    font-size: ${18 / 14}rem;
    line-height: ${24 / 18}em;
    color: #999;
  }

  h1, h2, .sub.header, p {
    text-align: center;
  }

  img {
    display: block;
    width: 160px;
    height: 110px;
    margin: 80px auto 30px;
  }

  h2 {
    margin: 30px 0 10px !important;
    font-size: ${22 / 14}rem !important;
    font-weight: normal !important;
    line-height: ${30 / 22}em !important;
  }

  h2 + p {
    font-size: ${16 / 14}rem !important;
    line-height: ${20 / 16}em !important;
    color: #999;
  }

  p + p {
    margin-top: 30px;
    color: #666;
  }
`;
