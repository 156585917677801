import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WeiboShareButton } from 'react-share';
import styled from 'styled-components';

import { Post } from '../../models/Post';
import { PostRepository } from '../../repositories/PostRepository';

import Layout from '../../Layout';
import ScoopImage from '../scoop/Image';
import Stickr from './Stickr';
import weibo from '../script/weibo.png';

type P = RouteComponentProps<{ id: string }> & {
  className?: string;
};

interface S {
  post?: Post;
}

class PostShow extends Component<P & InjectedIntlProps & { repo: PostRepository; }, S> {

  state: S = {};

  componentDidMount() {
    this.find();
  }

  componentDidUpdate(props: P) {
    if (this.props.match.params.id !== props.match.params.id) {
      this.find();
    }
  }

  render() {
    const { className, intl: { locale } } = this.props;
    const { post } = this.state;
    if (!post) {
      return null;
    }

    const po = post.localize(locale);

    return (
      <Layout breadcrumb={[ ['/posts', 'post.index'], [[po.title], '$'] ]} className={className}>
        <FormattedMessage id="post.index">
          {pi => (
            <Helmet title={[po.title, pi, 'DELopen'].join(' - ')}>
              <meta name="description" content={po.synopsis(100)} />
            </Helmet>
          )}
        </FormattedMessage>

        <Page>
          <Title>{po.title}</Title>
          <Meta>
            <Stickr sticker={po.sticker} />
            <span>{po.createdAt.format('YYYY-MM-DD')}</span>
            <span>{po.source}</span>
          </Meta>
          <Cover scoop={po.cover} />
          {po.body.split('\n').map((p, i) => (
            <p key={i}>{p}</p>
          ))}

          <ShareButtons>
            <FacebookShareButton
              url={window.location.href}
              quote={po.title}
              hashtag="DELopen"
            >
              <FacebookIcon size={30} borderRadius={4} />
            </FacebookShareButton>
            <TwitterShareButton
              url={window.location.href}
              title={po.title}
              hashtags={[ 'DELopen' ]}
            >
              <TwitterIcon size={30} borderRadius={4} />
            </TwitterShareButton>
            <LinkedinShareButton
              url={window.location.href}
            >
              <LinkedinIcon size={30} borderRadius={4} />
            </LinkedinShareButton>
            <WeiboShareButton
              url={window.location.href}
              title={po.title}
            >
              <Img src={weibo} />
            </WeiboShareButton>
          </ShareButtons>
        </Page>
      </Layout>
    );
  }

  private async find() {
    const { repo, match: { params: { id } } } = this.props;
    const post = await repo.find(id);

    this.setState({ post });
  }

}

const Page = styled.div`
  padding: 40px 75px 70px;
  background: #fff;

  p {
    margin-bottom: ${24 / 16}em;
    font-size: ${16 / 14}rem;
    line-height: ${24 / 16}em;
    color: #666;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Title = styled.h1`
  margin-bottom: 10px;
  font-size: ${22 / 14}rem;
  line-height: ${30 / 14}rem;
`;

const Meta = styled.div`
  font-size: ${12 / 14}rem;
  line-height: ${14 / 14}rem;
  color: #999;

  span + span {
    margin-left: 15px;
  }
`;

const Cover = styled(ScoopImage)`
  display: block;
  width: 100%;
  margin: 40px 0;
`;

const ShareButtons = styled.div`
  margin-top: 40px;
  overflow: hidden;

  * {
    float: left;
    margin-left: 20px;
    outline: none;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const Img = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 4px;
`;

export default injectIntl((props: P & InjectedIntlProps) => (
  <PostRepository.Context.Consumer>
    {repo => (
      <PostShow repo={repo} {...props} />
    )}
  </PostRepository.Context.Consumer>
));
