import { Hotword, HotwordJSON } from '../models/Hotword';

import axios, { AxiosInstance } from 'axios';
import { createContext } from 'react';

export class HotwordRepository {

  static readonly $ = new HotwordRepository(axios);
  static readonly Context = createContext(HotwordRepository.$);

  constructor(private readonly a: AxiosInstance) {
  }

  async findAll(): Promise<Hotword[]> {
    const { data } = await this.a.get<HotwordJSON[]>('/hotwords');

    return data.map(Hotword.build);
  }

}
