import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SurveyPortal from '../survey/Portal';

import arrowUp from './arrow_up.svg';
import drawer from './drawer.svg';

function BacktoTop(props: { className?: string; yOffset: number; }) {
  const backtoTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <div className={`${props.yOffset < 50 ? '' : 'active '}${props.className}`}>
      <SurveyPortal open threshold={1}>
        {survey => (
          <Link to={`/surveys/${survey.id}`}>
            <img src={drawer} alt="" />
          </Link>
        )}
      </SurveyPortal>
      <img src={arrowUp} alt="" onClick={backtoTop} />
    </div>
  );
}

export default styled(BacktoTop)`
  position: fixed;
  right: 20px;
  z-index: 5;
  animation: deactivation .5s ease;
  display: none;

  img {
    display: block;
    width: 40px;
    height: 40px;
    margin-top: 10px;
    opacity: .5;

    &:hover {
      opacity: 1;
    }
  }

  @keyframes activation {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes deactivation {
    from { bottom: 220px; opacity: 1; }
    to { bottom: 220px; opacity: 0; }
  }

  &.active {
    bottom: 220px;
    opacity: 1;
    animation: activation .5s ease;
    display: block;
  }
`;
