import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import URI from 'urijs';

import MyHelmet from '../widgets/MyHelmet';
import Flyer from '../../Flyer';

interface Search {
  action?: string;
}

class ProofExpired extends React.Component<RouteComponentProps & { className?: string; }> {

  render() {
    const { className } = this.props;
    const params = URI.parseQuery(this.props.location.search) as Search;
    const header = params.action === 'activate' ? 'security.activation_failed' : 'security.reset_failed';

    return (
      <Flyer wide className={className}>
        <MyHelmet title={header} />

        <Header as="h1" color="blue">
          <FormattedMessage id={header} />
        </Header>

        <p>
          <FormattedMessage
            id="security.proof_expired"
            values={{
              reset: (
                <Link to="/reset">
                  <FormattedMessage id="security.reset" />
                </Link>
              ),
            }}
          />
        </p>
      </Flyer>
    );
  }

}

export default ProofExpired;
