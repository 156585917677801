import * as React from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { Adviser } from '../../models/Adviser';

import ScoopImage from '../scoop/Image';

interface IAdviserCardProps {
  className?: string;
  adviser?: Adviser;
}

class AdviserCard extends React.Component<IAdviserCardProps> {

  public render() {
    const { className, adviser } = this.props;
    if (!adviser) {
      return (
        <div className={className} />
      );
    }

    return (
      <div className={className}>
        <div className="portrait">
          <ScoopImage scoop={adviser.portrait} alt={adviser.name} />
        </div>
        <Header as="h3">{adviser.name}</Header>
        <p>{adviser.synopsis}</p>
      </div>
    );
  }

}

export default styled(AdviserCard)`
  height: 310px;
  padding: 40px 30px;
  margin: 60px 0;
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(64, 114, 238, 0.1);
  background-color: #fff;

  .portrait {
    width: 90px;
    height: 90px;
    border: solid 3px rgba(64, 114, 238, 0.3);
    border-radius: 45px;
    padding: 3px;
    margin: auto;

    img {
      display: block;
      width: 78px;
      height: 78px;
      border-radius: 39px;
    }
  }

  h3 {
    font-size: ${24 / 16}rem !important;
    font-weight: normal !important;
    line-height: 1.25 !important;
    text-align: center;
    color: #333 !important;
    white-space: nowrap;
  }

  p {
    max-height: 4.5em;
    line-height: 1.5;
    text-align: center;
    color: #999;
    overflow: hidden;
  }
`;
