import moment, { Moment } from 'moment';
import { DigestScoop } from './DigestScoop';
import { LocalPost, ILocalPostJSON } from './LocalPost';
import { IStickerBlueprint, Sticker } from './Sticker';

export class BasePost {
  constructor(
    readonly id: number,
    readonly title: string,
    readonly source: string,
    readonly cover: DigestScoop,
    readonly body: string,
    readonly sticker: Sticker | undefined,
    readonly createdAt: Moment,
  ) {
  }

  public synopsis(length: number = 200) {
    return this.body.slice(0, length) + (this.body.length > length ? '...' : '');
  }
}

export class Post extends BasePost {
  public static build(data: IPostJSON) {
    return new Post(
      data.id,
      data.title,
      data.source,
      DigestScoop.build(data.cover),
      data.body,
      data.sticker && Sticker.build(data.sticker),
      moment(data.created_at),
      (data.local_posts || []).map(LocalPost.build),
    );
  }

  constructor(
    readonly id: number,
    readonly title: string,
    readonly source: string,
    readonly cover: DigestScoop,
    readonly body: string,
    readonly sticker: Sticker | undefined,
    readonly createdAt: Moment,
    readonly localPosts: LocalPost[],
  ) {
    super(id, title, source, cover, body, sticker, createdAt);
  }

  localize(lang: string): BasePost {
    const p = this.localPosts.find(lp => lang === lp.language.code);

    return new BasePost(
      this.id,
      (p && p.title) || this.title,
      (p && p.source) || this.source,
      (p && p.cover) || this.cover,
      (p && p.body) || this.body,
      this.sticker,
      this.createdAt,
    );
  }
}

export interface IPostJSON {
  readonly id: number;
  readonly title: string;
  readonly source: string;
  readonly cover: string;
  readonly body: string;
  readonly sticker?: IStickerBlueprint;
  readonly created_at: string;
  readonly local_posts?: ILocalPostJSON[];
}
