import { ILanguageTagBlueprint, LanguageTag } from './LanguageTag';

export class LocalParagraph {
    static fromJSON(data: ILocalParagraphJSON) {
        return new LocalParagraph(
            data.id,
            LanguageTag.build(data.locale),
            data.paragraph,
        );
    }

    constructor(
        readonly id: number,
        readonly locale: LanguageTag,
        readonly paragraph: string,
    ) {
    }
}

export interface ILocalParagraphJSON {
    readonly id: number;
    readonly locale: ILanguageTagBlueprint;
    readonly paragraph: string;
}
