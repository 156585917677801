import * as React from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { Adviser } from '../../models/Adviser';

import ScoopImage from '../scoop/Image';

import website from '../kit/website.png';

interface IAdviserItemProps {
  className?: string;
  adviser: Adviser;
}

class AdviserItem extends React.Component<IAdviserItemProps> {

  public render() {
    const { className, adviser } = this.props;

    return (
      <div className={className}>
        <div className="portrait">
          <ScoopImage scoop={adviser.portrait} alt={adviser.name} />
        </div>
        <div className="content">
          <Header as="h2">{adviser.name}</Header>
          <p>
            {adviser.synopsis.split('\n').map((ln, i) =>
              <React.Fragment key={i}>
                {ln}
                <br />
              </React.Fragment>
            )}
          </p>

          <a href={adviser.url} target="_blank" rel="noopener noreferrer">{adviser.url}</a>
        </div>
      </div>
    );
  }

}

export default styled(AdviserItem)`
  height: 280px;
  padding: 60px 150px 0 75px;
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(64, 114, 238, 0.1);
  background: #fff;

  & > .portrait {
    float: left;
    width: 120px;
    height: 120px;
    border: solid 3px rgba(64, 114, 238, 0.3);
    border-radius: 60px;
    position: relative;

    & > img {
      width: 108px;
      height: 108px;
      margin: -54px 0 0 -54px;
      border-radius: 54px;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  & > .content {
    margin-left: 180px;

    h2 {
      margin: 0;
      font-size: ${22 / 16}rem;
      font-weight: 600;
      line-height: ${30 / 22}em;
    }

    p {
      margin: 20px 0 0;
      color: #666;
    }

    & > a {
      float: left;
      margin-top: 40px;
      padding-left: 26px;
      line-height: ${20 / 16}rem;
      color: #999;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 16px;
        height: 15px;
        margin-top: -8px;
        background: url(${website}) no-repeat center center / contain;
        position: absolute;
        top: 50%;
        left: 0;
      }
    }
  }
`;
