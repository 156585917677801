import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import Cookies from 'js-cookie';
import { v4 } from 'uuid';

import { isScalar } from '../../models/Field';
import { Section } from '../../models/Section';
import { Survey } from '../../models/Survey';
import { Solution } from '../../models/Solution';
import { AnswerSheet } from '../../models/AnswerSheet';
import { SurveyRepository } from '../../repositories/SurveyRepository';

import Layout from '../../Layout';
import ScalarItem from './ScalarItem';
import T from './T';

type P = RouteComponentProps<{ id: string; }> & {
  className?: string;
};

function SurveyShow({ className, match: { params: { id } }, repo }: P & { repo: SurveyRepository; }) {
  const LIFETIME = 3650;
  const COOLDOWN = 3650;

  const [ survey, setSurvey ] = useState<Survey>();
  const [ answerSheet, setAnswerSheet ] = useState(new AnswerSheet({}));
  const [ violations, setViolations ] = useState<string[]>([]);
  const [ loading, setLoading ] = useState(false);

  const handleChangeSolution = (solution: Solution) => {
    setAnswerSheet(new AnswerSheet({ ...answerSheet.solutions, [solution.interest.permanentID]: solution }));
    setViolations(violations.filter(i => i !== solution.interest.permanentID));
  };
  const handleSubmit = () => {
    if (survey && survey.subject) {
      const vs = survey.subject.validata(answerSheet);
      if (vs.length > 0) {
        setViolations(vs);

        return;
      }

      repo
        .save(answerSheet, TRACKING_ID, +id)
        .then(() => Cookies.set(survey.permanentID, 'submit', { expires: COOLDOWN }))
        .catch(noop)
        .then(() => setLoading(false))
      ;

      Cookies.set('tracking_id', TRACKING_ID, { expires: LIFETIME });
      setLoading(true);
    }
  };

  useEffect(() => {
    repo
      .find(+id)
      .then(setSurvey)
    ;
  }, [id, repo]);

  if (!survey || !survey.subject) {
    return null;
  }

  if ('submit' === Cookies.get(survey.permanentID)) {
    return (
      <Redirect to={`/surveys/${+id}/thanks`} />
    );
  }

  return (
    <T it={survey.subject}>
      {(section: Section) => (
        <Layout breadcrumb={section.label} className={className}>
          <div className="text">
            <Grid centered>
              <Grid.Column width={14}>
                <Header as="h1">{section.label}</Header>
                {section.footnotes.map(f => (
                  <p key={f.id}>{f.paragraph}</p>
                ))}

                {section.children.map(child => (
                  isScalar(child) && (
                    <ScalarItem
                      error={violations.includes(child.interest.permanentID)}
                      it={child}
                      answerSheet={answerSheet}
                      onChange={handleChangeSolution}
                      key={child.permanentID}
                    />
                  )
                ))}

                <Button disabled={loading} onClick={handleSubmit}>
                  <FormattedMessage id="submit" />
                </Button>
              </Grid.Column>
            </Grid>
          </div>
        </Layout>
      )}
    </T>
  );
}

function noop() {}

export default styled((props: P) => (
  <SurveyRepository.Context.Consumer>
    {repo => (
      <SurveyShow repo={repo} {...props} />
    )}
  </SurveyRepository.Context.Consumer>
))`
  .text {
    padding: 40px 0 80px;
    background: #fff;
  }

  h1:first-child {
    padding-bottom: 40px;
    margin: 0 0 40px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 30px;
  }
`;

const Button = styled.button`
  display: block
  width: 220px;
  height: 40px;
  margin: 70px auto 0;
  background: #4072ee;
  border: none;
  border-radius: 4px;
  font-size: ${14 / 14}rem;
  color: #fff;
  
  &:focus {
    outline: none;
  }
`;

const TRACKING_ID = Cookies.get('tracking_id') || v4();
