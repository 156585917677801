import * as Cookies from 'js-cookie';
import locale2 from 'locale2';
import { createStore } from 'redux';

import { Subscriber } from './models/Subscriber';
import { SecurityRepository } from './repositories/SecurityRepository';

type Action = {
  type: 'TOGGLE_LOCALE';
} | {
  type: 'LOGIN';
  subscriber?: Subscriber;
};

export function login(subscriber?: Subscriber) {
  return ({ type: 'LOGIN', subscriber });
}

export interface IAppState {
  locale: 'en-us' | 'zh-cn';
  subscriber: Subscriber | undefined | null; // undefined: anonymous, null: n/a
}

function getInitialState(): IAppState {
  const locale = locale2.toLowerCase();

  return ({
    locale: (Cookies.get('locale') || (['en-us', 'zh-cn'].indexOf(locale) < 0 ? 'zh-cn' : locale)) as ('en-us' | 'zh-cn'),
    subscriber: null,
  });
}

function changeLocale(locale: string, login: boolean = false) {
  if (!login) {
    SecurityRepository.$.changeLocale(locale);
  }

  Cookies.set('locale', locale, { expires: 14 });
}

function app(state: IAppState | undefined, action: Action) {
  if (!state) {
    state = getInitialState()
  }

  switch (action.type) {
    case 'TOGGLE_LOCALE':
      switch (state.locale) {
        case 'en-us':
          changeLocale('zh-cn');

          return Object.assign({}, state, { locale: 'zh-cn' });

        case 'zh-cn':
          changeLocale('en-us');

          return Object.assign({}, state, { locale: 'en-us' });
      }
      break;

    case 'LOGIN':
      if (action.subscriber) {
        changeLocale(action.subscriber.languageTag.code, true);
      }

      return Object.assign({}, state, {
        subscriber: action.subscriber,
        locale: action.subscriber ? action.subscriber.languageTag.code : state.locale,
      });
  }

  return state;
}

export const store = createStore(app);
