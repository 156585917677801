import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import floppy from './floppy.svg';

import Layout from '../../Layout';
import MyHelmet from '../widgets/MyHelmet';

function SubscriptionDone() {
  return (
    <Layout breadcrumb={[ [ '/scripts', 'nav.scripts' ], 'nav.subscription' ]}>
      <Core>
        <Grid centered>
          <Grid.Column width={14}>
            <MyHelmet title="nav.subscription" />
            <H1 as="h1">
              <FormattedMessage id="nav.subscription" />
            </H1>
            <img src={floppy} alt="" />
            <p>
              <FormattedMessage id="subscription.saved" />
            </p>
          </Grid.Column>
        </Grid>
      </Core>
    </Layout>
  );
}

export default SubscriptionDone;

const Core = styled.div`
  padding: 40px 0 80px;
  background: #fff;
  
  img {
    display: block;
    width: 154px;
    height: 165px;
    margin: 80px auto 40px;
  }
  
  p {
    margin: 40px 0 200px !important;
    font-size: ${20 / 16}rem;
    line-height: ${30 / 20};
    text-align: center;
  }
`;

const H1 = styled(Header)`
  padding-bottom: 40px;
  margin: 0 0 40px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  line-height: 30px;
`;
