import React, { Component } from 'react';

import Layout from './Layout';

interface P {
  className?: string;
  breadcrumb: string | Array<string | [string, string]>;
}

class PrivateLayout extends Component<P> {

  render() {
    return <Layout root="nav.account" {...this.props} />;
  }

}

export default PrivateLayout;
