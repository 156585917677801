import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { BarChart, Bar, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { ScriptKind } from '../../models/ScriptKind';
import { ICount, ScriptRepository } from '../../repositories/ScriptRepository';

import Layout from '../../Layout';
import MyHelmet from '../widgets/MyHelmet';

import del from './del.svg';
import hit from './hit.svg';
import chem from './chem.svg';
import lib from './lib.svg';

interface ICounts {
  year: string;
  [ kind: string ]: string | number;
};

function ScriptDashboard() {
  const repository = useContext(ScriptRepository.Context);

  const [ count, setCount ] = useState<{
    _all: Array<ICounts>;
    hits: Array<ICounts>;
    reactions: Array<ICounts>;
    libraries: Array<ICounts>;
  }>();
  const [ kinds, setKinds ] = useState<ScriptKind[]>([]);

  useEffect(() => {
    function agg(counts: Array<ICount<ScriptKind>>, key: '_all' | 'hits' | 'reactions' | 'libraries') {
      return counts.reduce<Array<ICounts>>((css, c) => {
        const cs = css[css.length - 1];
        const css2 = (cs && cs.year) === c.year ? css : css.concat({ year: c.year });

        if (c[key] > 0) {
          css2[css2.length - 1][c.kind.name] = c[key];
        }

        return css2;
      }, []);
    }

    repository.count().then(counts => {
      setCount({
        _all: agg(counts, '_all'),
        hits: agg(counts, 'hits'),
        reactions: agg(counts, 'reactions'),
        libraries: agg(counts, 'libraries'),
      });
      setKinds(
        counts
          .reduce<ScriptKind[]>((ks, c) => ks.find(k => k.id === c.kind.id) ? ks : ks.concat(c.kind), [])
            .sort((a, b) => a.id - b.id)
      );
    });
  }, [repository]);

  return (
    <Layout wide="very" breadcrumb="nav.scripts.dashboard">
      <MyHelmet title="nav.scripts" />
      <Sheet>
        <H1 as="h1">
          <FormattedMessage id="script.databases" />
          <Header.Subheader>
            <FormattedMessage id="script.databases.p" values={{ br: <br /> }} />
          </Header.Subheader>
        </H1>

        <Wrapper>
          <Finder
            link="/scripts"
            image={del}
            title="script.finder"
            p="script.finder.p"
            data={count && count._all.slice(-10)}
            kinds={kinds}
          />
          <Finder
            link="/hits"
            image={hit}
            title="script.hits"
            p="script.hits.p"
            data={count && count.hits.slice(-10)}
            kinds={kinds}
          />
          <Finder
            link="/reactions"
            image={chem}
            title="script.chem"
            p="script.chem.p"
            data={count && count.reactions.slice(-10)}
            kinds={kinds}
          />
          <Finder
            link="/libraries"
            image={lib}
            title="script.lib"
            p="script.lib.p"
            data={count && count.libraries.slice(-10)}
            kinds={kinds}
          />
        </Wrapper>
      </Sheet>
    </Layout>
  );
}

export default ScriptDashboard;

const Finder = styled(withRouter((props: RouteComponentProps & {
  className?: string;
  link: string;
  image: string;
  title: string;
  p: string;
  data?: Array<ICounts>;
  kinds: ScriptKind[];
}) => {
  return (
    <div className={props.className}>
      <Link to={props.link}>
        <img src={props.image} alt="" />
      </Link>
      <Link to={props.link}>
        <Header as="h2"><FormattedMessage id={props.title} /></Header>
      </Link>
      <p>
        <Link to={props.link}>
          <FormattedMessage id={props.p} />
        </Link>
      </p>
      {props.data && (
        <BarChart className="chart" width={420} height={300} barSize={15} data={props.data}>
          <YAxis stroke="rgba(0, 0, 0, .05)" tick={{ fill: '#666666' }} />
          <XAxis
            height={70}
            stroke="rgba(0, 0, 0, .05)"
            tick={{ fill: '#666666' }}
            tickMargin={20}
            minTickGap={-20}
            angle={-45}
            dataKey="year"
          />
          <CartesianGrid stroke="rgba(0, 0, 0, .05)" vertical={false} />
          <Tooltip />
          {props.kinds.map(k => (
            <Bar
              dataKey={k.name}
              fill={k.color}
              stackId="c"
              onClick={({ year }) => props.history.push(`${props.link}?y[]=${year}&t[]=${k.name}`)}
              key={k.id}
            />
          ))}
        </BarChart>
      )}
    </div>
  );
}))`
  float: left;
  margin: 80px 0 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 570px;
  height: ${props => props.data ? 650 : 320}px;

  img {
    display: block;
    width: 160px;
    height: 110px;
  }

  h2.ui.header {
    margin: 20px 0 40px;
    font-size: 24px;
    line-height: 30px;
    text-decoration: underline;
    color: #4072ee;
  }

  p {
    flex: 1;
    font-size: ${18 / 16}rem;
    line-height: 24px;
    text-align: justify;
    color: #999;

    a, a:hover {
      color: inherit;
    }
  }

  .chart {
    margin-left: -65px;
    font-size: 14px;
    line-height: 20px;
  }
`;

const Sheet = styled.div`
  padding: 60px 135px 80px;
  background: #fff;
`;

const H1 = styled(Header)`
  margin: 40px 0;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  color: #333;
  text-align: center;

  .sub.header {
    margin-top: 20px;
    font-size: ${20 / 16}rem;
    font-weight: normal;
    line-height: 24px;
    color: #999;
  }
`;

const Wrapper = styled.div`
  margin-left: -30px;
  overflow: hidden;
`;
