import React, { Component, MouseEvent } from 'react';
import { Link, LinkProps } from 'react-router-dom';

type P<T> = Omit<LinkProps, 'onClick'> & {
  data: T;
  onClick: { bivarianceHack(data: T, event: MouseEvent<HTMLAnchorElement>): void }["bivarianceHack"]
}

class DataLink<T> extends Component<P<T>> {

  render() {
    const { data, onClick, ...props } = this.props;

    return (
      <Link onClick={this.handleClick} {...props} />
    );
  }

  private handleClick = (e: MouseEvent<HTMLAnchorElement>) => this.props.onClick(this.props.data, e);

}

export default DataLink;
