import React, { Component, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import URI from 'urijs';

import { Fragment } from '../../models/Fragment';
import { Search } from '../../Util';

import Pagination from './Pagination';

const consumer: React.FC<RouteComponentProps & { children(page: number): ReactElement; }> = props => {
  const { location: { search }, children } = props;
  const { p } = URI.parseQuery(search) as Search<'p'>;

  return children(+(p || 1));
}

const Consumer = withRouter(consumer);

export interface PageComponentProps {
  page: number;
  pageSize: number;
}

export function withPage<P extends PageComponentProps>(C: React.ComponentType<P>, pageSize: number = 20) {
  return (props: Omit<P, keyof PageComponentProps>) => (
    <Consumer>
      {page => (
        <C {...{ page, pageSize, ...props } as P} />
      )}
    </Consumer>
  )
}

interface PaginatorProps<T> extends PageComponentProps {
  fragment: Fragment<T>;
  margin?: number;
}

class Paginator<T> extends Component<RouteComponentProps & PaginatorProps<T>> {

  render() {
    const { fragment, pageSize, page, margin, location: { pathname } } = this.props;

    return (
      <Pagination
        route={this.paginate}
        route0={pathname}
        page={page}
        count={Math.ceil(fragment.total / pageSize)}
        margin={margin || 2}
      />
    );
  }

  private paginate = (i: number) => {
    const { location: { pathname } } = this.props;

    return URI.build({ path: pathname, query: URI.buildQuery({ p: i }) });
  }

}

export default withRouter(withPage(Paginator));
