import React, { Component } from 'react';
import { Grid, GridColumnProps, Header } from 'semantic-ui-react';
import styled from 'styled-components';

const MyHeader = styled(Header)`
  margin: 0 0 40px !important;
`;

type P = GridColumnProps & {
  header?: string;
};

class Panel extends Component<P> {

  render() {
    const { header, children, ...props } = this.props;

    return (
      <Grid.Column {...props}>
        <div>
          <div>
            <div>
              <div>
                {header && (
                  <MyHeader as="h1">{header}</MyHeader>
                )}

                {children}
              </div>
            </div>
          </div>
        </div>
      </Grid.Column>
    );
  }

}

export default styled(Panel)`
  & > div {
    min-height: ${props => props.width < 8 ? 'auto' : '720px'};
    padding: 40px 0 80px;
    background: #fff;

    & > div {
      margin: 0 -1rem;

      & > div {
        width: ${props => 100 * (props.width - 2) / props.width}%;
        padding: 0 1rem;
        margin: auto;
      }
    }
  }
`;
