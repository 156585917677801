import { Country, ICountryBlueprint } from '../models/Country';

import axios, { AxiosInstance } from 'axios';
import * as React from 'react';

export class CountryRepository {

  public static readonly $ = new CountryRepository(axios);
  public static readonly Context = React.createContext(CountryRepository.$);

  constructor(private a: AxiosInstance) {
  }

  public async findAll(): Promise<Country[]> {
    const { data } = await this.a.get<ICountryBlueprint[]>('/countries');

    return data.map(Country.build);
  }

}
