import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Header } from 'semantic-ui-react';

import { Fragment } from '../../models/Fragment';
import { Post } from '../../models/Post';
import { PostRepository } from '../../repositories/PostRepository';

import PostItem from './Item';
import Page from '../permanent/Page';
import MyHelmet from '../widgets/MyHelmet';
import styled from 'styled-components';

type P = RouteComponentProps & {
  className?: string;
};

interface S {
  fragment: Fragment<Post>;
}

class PostIndex extends Component<P & { pageSize: number; repo: PostRepository; }, S> {

  state: S = { fragment: new Fragment() };

  componentDidMount() {
    this
      .props
      .repo
      .findAll(1, this.props.pageSize)
      .then(fragment => this.setState({ fragment }))
    ;
  }

  render() {
    const { className } = this.props;
    const { fragment: { data } } = this.state;

    return (
      <Page loc="post.index" className={className}>
        <MyHelmet title="post.index" />

        <Header as="h1">
          <FormattedMessage id="post.index" />
        </Header>

        {data.map(p => (
          <Item post={p} key={p.id} />
        ))}
      </Page>
    );
  }

}

const Item = styled(PostItem)`
  padding: 40px 0;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  h1 + & {
    padding-top: 0;
  }
`;

export default (props: P) => (
  <PostRepository.Context.Consumer>
    {repo => (
      <PostIndex pageSize={20} repo={repo} {...props} />
    )}
  </PostRepository.Context.Consumer>
);
