import React from 'react';
import styled from 'styled-components'

import { EssayScalar } from '../../models/EssayScalar';
import { Solution } from '../../models/Solution';
import { EssaySolution } from '../../models/EssaySolution';

interface P {
  className?: string;
  it: EssayScalar;
  solution?: Solution;
  onChange(solution: Solution): void;
}

function EssayScalarItem(props: P) {
  const { className, it, solution, onChange } = props;

  return (
    <textarea
      className={className}
      value={solution instanceof EssaySolution ? solution.paragraph : ''}
      onChange={e => onChange(new EssaySolution(it.interest, e.currentTarget.value))}
    />
  );
}

export default styled(EssayScalarItem)`
  display: block;
  width: 100%;
  height: 130px;
  padding: .5rem;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 2px;
  resize: none;

  &:focus {
    outline: none;
  }
`;
