import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Header, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Trial } from '../../models/Trial';

const StyledModal = styled(Modal)`
  width: 570px !important;

  textarea {
    height: 125px;
  }
`;

interface IReviewButtonProps {
  trial: Trial;
}

interface IReviewButtonState {
  open: boolean;
}

class ReviewButton extends React.Component<IReviewButtonProps, IReviewButtonState> {

  public state: IReviewButtonState = { open: false };

  public render() {
    const { trial } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        <Button basic onClick={this.open}>
          <FormattedMessage id="review" />
        </Button>

        <StyledModal open={open} onClose={this.close}>
          <Modal.Content>
            <Header as="h1">
              <FormattedMessage id="review" />
            </Header>

            <p>{trial.remark}</p>
          </Modal.Content>
        </StyledModal>
      </React.Fragment>
    );
  }

  private open = () => this.setState({ open: true });

  private close = () => this.setState({ open: false });

}

export default ReviewButton;
