import React from 'react';
import styled from 'styled-components';

import { RatingScalar } from '../../models/RatingScalar';
import { Solution } from '../../models/Solution';
import { RatingSolution } from '../../models/RatingSolution';

interface P {
  className?: string;
  it: RatingScalar;
  solution?: Solution;
  onChange(solution: Solution): void;
}

function RatingScalarItem(props: P) {
  const { className, it, solution, onChange } = props;

  return (
    <div className={className}>
      <Captions>
        {Array(it.scale).fill(undefined).map((_, i) => (
          <span key={i}>
            {it.captions[1 + i]}
          </span>
        ))}
      </Captions>
      <Ratings>
        {Array(it.scale).fill(undefined).map((_, i) => 1 + i).map(rating => (
          <label
            className={solution instanceof RatingSolution && rating === solution.rating ? 'active' : ''}
            onClick={() => onChange(new RatingSolution(it.interest, rating))}
            key={rating}
          >
            {rating}
          </label>
        ))}
      </Ratings>
    </div>
  );
}

export default styled(RatingScalarItem)`
  float: left;
`;

const Captions = styled.div`
  display: flex;
  margin-bottom: 15px;

  span:not(:empty) {
    height: 20px;
    padding: 0 6px;
    background: #f5f5f5;
    border-radius: 2px;
    font-size: ${12 / 14}rem;
    line-height: 20px;;
  }

  span:empty {
    flex: 1;
  }
`;

const Ratings = styled.div`
  label {
    float: left;
    width: 40px;
    height: 50px;
    background: #e1e1e1;
    border-radius: 2px;
    font-size: ${14 / 14}rem;
    line-height: 50px;
    text-align: center;
  
    &:hover {
      background: #f6f9ff;
      color: #4072ee;
    }
  
    &.active {
      background: #4072ee;
      color: #fff;
    }

    & + label {
      margin-left: 5px;
    }
  }
`;
