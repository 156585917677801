import { Review } from './Review';

export class Thread {
  static build(reviews: Review[], threads: Thread[] = []) {
    return reviews.reduce(
      (ts, r) => r.referrer ?
        ts.map(t => t.includes(r.referrer as number) ? t.reply(r) : t) :
        ts.concat(new Thread(r)),
      threads
    );
  }

  constructor(
    readonly review: Review,
    readonly replies: Review[] = [],
  ) {
  }

  includes(id: number) {
    return id === this.review.id || this.replies.some(r => id === r.id);
  }

  reply(review: Review) {
    return new Thread(this.review, this.replies.concat(review));
  }
}
