import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WeiboShareButton } from 'react-share';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Script } from '../../models/Script';
import { ScriptRepository } from '../../repositories/ScriptRepository';

import Layout from '../../Layout';
import ScoopImage from '../scoop/Image';
import Search from './Search';
import weibo from './weibo.png';

import Comments from './Comments';

type P = RouteComponentProps<{ id: string; }>;

interface S {
  script?: Script;
}

class ScriptShow extends Component<P & { repo: ScriptRepository; }, S> {

  state: S = {};

  componentDidMount() {
    this.find();
  }

  componentDidUpdate({ match: { params: { id } } }: P) {
    if (this.props.match.params.id !== id) {
      this.find();
    }
  }

  render() {
    const { match: { params: { id } } } = this.props;
    const { script } = this.state;

    return (
      <Layout breadcrumb={[ ['/scripts', 'nav.scripts'], id ]}>
        <Search placeholder="script.placeholder" action="/scripts" />

        {script && (
          <React.Fragment>
            <Sheet>
              <Helmet title={`${script.title} - DELopen`}>
                <meta name="description" content={script.ellipsis(50, 160)} />
              </Helmet>

              <Rating>
                <FormattedMessage id="script.rating" />: <span className="stars">{'★'.repeat(script.rating)}</span>
              </Rating>
              <Source>{script.source}</Source>
              <Title>{script.title}</Title>
              <Authors>
                {script.authors.map((author, i) => (
                  <React.Fragment key={i}>
                    {i > 0 && '; '}
                    <Link to="#">{author}</Link>
                  </React.Fragment>
                ))}
              </Authors>
              <Org>
                {script.institutions.map((org, i) => (
                  <React.Fragment key={org.id}>
                    {i > 0 && '; '}
                    <Link to="#">{org.name}</Link>
                  </React.Fragment>
                ))}
              </Org>
              <H2><FormattedMessage id="script.abstract" /></H2>
              <Synopsis>
                {script.synopsis.split('\n').map((p, i) => (
                  <p key={i}>{p}</p>
                ))}
              </Synopsis>
              {script.figure && (
                <Image scoop={script.figure} />
              )}

              {script.libraries.length > 0 && (
                <React.Fragment>
                  <H2><FormattedMessage id="script.libraries" /></H2>
                  <Table className="library">
                    <tr>
                      <th className="no"><FormattedMessage id="library.refno" /></th>
                      <th className="scheme"><FormattedMessage id="library.scheme" /></th>
                      <th className="kind"><FormattedMessage id="library.kind" /></th>
                      <th className="hit"><FormattedMessage id="library.hit" /></th>
                      <th className="blocks"><FormattedMessage id="library.blocks" /></th>
                      <th className="tags"><FormattedMessage id="library.tags" /></th>
                      <th className="pages"><FormattedMessage id="library.pages" /></th>
                    </tr>

                    {script.libraries.map((lib, i) => (
                      <tr key={lib.id}>
                        <td className="no">{1 + i}</td>
                        <td className="scheme">
                          <Zoom trigger={<FlexImage scoop={lib.scheme} />}>
                            <FlexImage scoop={lib.scheme} />
                          </Zoom>
                        </td>
                        <td className="kind">{lib.kind}</td>
                        <td className="hit">{lib.hit}</td>
                        <td className="blocks">
                          {lib.blocks.split('\n').map((b, i) => (
                            <React.Fragment key={i}>
                              {i > 0 && <br />}
                              {b}
                            </React.Fragment>
                          ))}
                        </td>
                        <td className="tags">
                          {lib.tags.split('\n').map((t, i) => (
                            <React.Fragment key={i}>
                              {i > 0 && <br />}
                              {t}
                            </React.Fragment>
                          ))}
                        </td>
                        <td className="pages">{lib.pages}</td>
                      </tr>
                    ))}
                  </Table>
                </React.Fragment>
              )}

              {script.hit && (
                <React.Fragment>
                  <H2><FormattedMessage id="script.hit" /></H2>
                  <Table className="hit">
                    <tr>
                      <th className="size"><FormattedMessage id="script.library_size" /></th>
                      <th className="formula"><FormattedMessage id="script.formula" /></th>
                      <th className="target"><FormattedMessage id="script.target" /></th>
                      <th className="potency"><FormattedMessage id="script.potency" /></th>
                    </tr>

                    <tr>
                      <td className="size">{script.hit.librarySize}</td>
                      <td className="formula">
                        {script.hit.formula && (
                          <Zoom trigger={<FlexImage scoop={script.hit.formula} />}>
                            <FlexImage scoop={script.hit.formula} />
                          </Zoom>
                        )}
                      </td>
                      <td className="target">{script.hit.target}</td>
                      <td className="potency">{script.hit.potency}</td>
                    </tr>
                  </Table>
                </React.Fragment>
              )}

              {script.reactions.length > 0 && (
                <React.Fragment>
                  <H2><FormattedMessage id="script.reactions" /></H2>
                  <Table className="reaction">
                    <th className="no"><FormattedMessage id="reaction.refno" /></th>
                    <th className="name"><FormattedMessage id="reaction.name" /></th>
                    <th className="scheme"><FormattedMessage id="reaction.scheme" /></th>
                    <th className="kinds"><FormattedMessage id="reaction.kinds" /></th>
                    <th className="conditions"><FormattedMessage id="reaction.conditions" /></th>
                    <th className="usage"><FormattedMessage id="reaction.usage" /></th>
                    <th className="pages"><FormattedMessage id="reaction.pages" /></th>

                    {script.reactions.map((reaction, i) => (
                      <tr key={reaction.id}>
                        <td className="no">{1 + i}</td>
                        <td className="name">{reaction.meta.name}</td>
                        <td className="scheme">
                          {reaction.meta.scheme && (
                            <Zoom trigger={<FlexImage scoop={reaction.meta.scheme} />}>
                              <FlexImage scoop={reaction.meta.scheme} />
                            </Zoom>
                          )}
                        </td>
                        <td className="kinds">
                          {reaction.kinds.map((k, i) => (
                            <React.Fragment key={i}>
                              {i > 0 && <br />}
                              {k}
                            </React.Fragment>
                          ))}
                        </td>
                        <td className="conditions">
                          {reaction.conditions.split('\n').map((c, i) => (
                            <React.Fragment key={i}>
                              {i > 0 && <br />}
                              {c}
                            </React.Fragment>
                          ))}
                        </td>
                        <td className="usage">{reaction.usage}</td>
                        <td className="pages">{reaction.pages}</td>
                      </tr>
                    ))}
                  </Table>
                </React.Fragment>
              )}

              {script.tip && (
                <Meta>
                  <FormattedMessage id="script.tip" tagName="b" />
                  {script.tip}
                </Meta>
              )}
              <Meta>
                <FormattedMessage id="script.database" tagName="b" />
                {script.database.name}
              </Meta>
              {script.url && (
                <Meta>
                  <FormattedMessage id="script.url" tagName="b" />
                  <a href={script.url} target="_blank" rel="noopener noreferrer">{script.url}</a>
                </Meta>
              )}
              <Meta>
                <FormattedMessage id="script.keywords" tagName="b" />
                {script.keywords.map(kw => kw.name).join('; ')}
              </Meta>

              <ShareButtons>
                <FacebookShareButton
                  url={window.location.href}
                  quote={script.title}
                  hashtag="DELopen"
                >
                  <FacebookIcon size={30} borderRadius={4} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={window.location.href}
                  title={script.title}
                  hashtags={[ 'DELopen' ]}
                >
                  <TwitterIcon size={30} borderRadius={4} />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={window.location.href}
                >
                  <LinkedinIcon size={30} borderRadius={4} />
                </LinkedinShareButton>
                <WeiboShareButton
                  url={window.location.href}
                  title={script.title}
                >
                  <Img src={weibo} />
                </WeiboShareButton>
              </ShareButtons>
            </Sheet>

            <Sheet>
              <Comments scriptId={+id} />
            </Sheet>
          </React.Fragment>
        )}
      </Layout>
    );
  }

  private async find() {
    const script = await this.props.repo.find(this.props.match.params.id);

    this.setState({ script });
  }

}

export default (props: P) => (
  <ScriptRepository.Context.Consumer>
    {repo => (
      <ScriptShow repo={repo} {...props} />
    )}
  </ScriptRepository.Context.Consumer>
);

const Sheet = styled.div`
  margin-top: 30px;
  padding: 60px 75px 70px;
  background: #fff;
  font-size: 16px;
  line-height: 18px;
  color: #333;
`;

const Title = styled.h1`
  margin: 10px 0;
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
`;

const Authors = styled.div`
  line-height: 24px;
  color: #666;

  a, a:hover {
    text-decoration: underline;
    color: #666;
  }
`;

const Org = styled.div`
  margin-top: 5px;
  color: #666;

  a, a:hover {
    text-decoration: underline;
    color: #666;
  }
`;

const Source = styled.div`
  color: #666;
`;

const Rating = styled.div`
  float: right;

  .stars {
    color: #fec400;
  }
`;

const H2 = styled.h2`
  margin-top: 40px;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;

  table + & {
    margin-top: 0;
  }
`;

const Synopsis = styled.div`
  margin-top: 20px;

  p {
    line-height: 36px;
  }
`;

const Image = styled(ScoopImage)`
  margin-top: 40px;
`;

const Meta = styled.div`
  margin-top: 60px;

  & + & {
    margin-top: 15px;
  }

  b {
    margin-right: 10px;
    font-weight: 600;

    &:after {
      content: ":";
    }
  }

  a, a:hover {
    text-decoration: underline;
    color: #4072ee;
  }
`;

const ShareButtons = styled.div`
  margin-top: 40px;
  overflow: hidden;

  * {
    float: left;
    margin-left: 20px;
    outline: none;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const Img = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 4px;
`;

const FlexImage = styled(ScoopImage)`
  max-width: 100%;
`;

const Zoom = styled(Modal)`
  width: auto !important;
  max-width: 1000px;
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-top: 30px;
  font-size: 14px;

  th, td {
    padding: 0 0 40px 20px;
    vertical-align: top;

    &:first-child {
      padding-left: 0;
    }
  }

  th {
    padding-bottom: 60px;
    text-align: left;
  }

  .no {
    width: 40px;
  }

  .page {
    width: 90px;
  }

  &.library {
    .scheme {
      width: 210px;
    }

    .kind {
      width: 120px;
      font-size: 13px;
    }

    .hit {
      width: 110px;
      font-size: 13px;
    }

    .blocks {
      width: 260px;
      font-size: 13px;
    }

    .tags {
      width: 190px;
      font-size: 13px;
    }
  }

  &.hit {
    .size {
      width: 120px;
    }

    .formula {
      width: 260px;
    }

    .target {
      width: 160px;
    }

    .potency {
      width: 200px;
    }
  }

  &.reaction {
    .name {
      width: 140px;
    }

    .scheme {
      width: 230px;
    }

    .kinds {
      width: 140px;
      font-size: 13px;
    }

    .conditions {
      width: 260px;
      font-size: 13px;
    }

    .usage {
      width: 120px;
      font-size: 13px;
    }

    .pages {
      width: 90px;
    }
  }
`
