import { Carrier } from './Carrier';

export class DeliveryForm {
  constructor(
    public readonly carrier?: Carrier,
    public readonly trackingNumber: string = '',
  ) {
  }

  public patch<K extends keyof DeliveryForm>(key: K, value: DeliveryForm[K]) {
    const { carrier, trackingNumber } = Object.assign({}, this, { [key]: value });

    return new DeliveryForm(carrier, trackingNumber);
  }

  public dispose() {
    return ({
      carrier: this.carrier && this.carrier.id,
      trackingNumber: this.trackingNumber,
    });
  }
}
