import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Form, Header, InputOnChangeData, Modal } from 'semantic-ui-react';

import { Carrier } from '../../models/Carrier';
import { Delivery } from '../../models/Delivery';
import { DeliveryForm } from '../../models/DeliveryForm';
import { Trial } from '../../models/Trial';
import { DeliveryRepository } from '../../repositories/DeliveryRepository';

import CarrierSelect from '../widgets/CarrierSelect';

type P = InjectedIntlProps & {
  trial: Trial;
  onClose: () => void;
  onSubmitted: (delivery: Delivery) => void;
}

interface IDeliveryModalState {
  form: DeliveryForm;
  loading: boolean;
}

class DeliveryModal extends React.Component<P & { repo: DeliveryRepository }, IDeliveryModalState> {

  public state = { form: new DeliveryForm(), loading: false };

  public render() {
    const { onClose, intl: { formatMessage } } = this.props;
    const { form, loading } = this.state;

    return (
      <Modal open onClose={onClose} style={{ width: '570px' }}>
        <Modal.Content>
          <Header as="h1">
            <FormattedMessage id="delivery" />
          </Header>

          <Form loading={loading} onSubmit={this.submit}>
            <Form.Input
              required
              label={formatMessage({ id: 'delivery.tracking_number' })}
              value={form.trackingNumber}
              onChange={this.setTrackingNumber}
            />
            <Form.Field required>
              <label>
                <FormattedMessage id="delivery.carrier" />
              </label>
              <CarrierSelect
                value={form.carrier}
                onChange={this.setCarrier}
              />
            </Form.Field>

            <Form.Button primary type="submit">
              <FormattedMessage id="save" />
            </Form.Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }

  private setCarrier = (carrier?: Carrier) =>
    this.setState({ form: this.state.form.patch('carrier', carrier) });

  private setTrackingNumber = (_: React.SyntheticEvent, { value }: InputOnChangeData) =>
    this.setState({ form: this.state.form.patch('trackingNumber', value) });

  private submit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const { repo, trial, onSubmitted } = this.props;
    repo
      .persist(this.state.form, trial)
      .then(onSubmitted)
      .catch(() => this.setState({ loading: false }))
    ;
    this.setState({ loading: true });
  }

}

export default injectIntl((props: P) => (
  <DeliveryRepository.Context.Consumer>
    {repo => (
      <DeliveryModal repo={repo} {...props} />
    )}
  </DeliveryRepository.Context.Consumer>
));
