import { IScriptBadgeClass, IScriptBadgeJSON, ScriptBadge } from '../models/ScriptBadge';

import axios, { AxiosInstance } from 'axios';
import { createContext } from 'react';

export class ScriptBadgeRepository {

    public static readonly $ = new ScriptBadgeRepository(axios);
    public static readonly Context = createContext(ScriptBadgeRepository.$);

    constructor(private readonly a: AxiosInstance) {
    }

    public async findAll<T extends ScriptBadge>(c: IScriptBadgeClass<T>, q: string): Promise<[T, number][]> {
        const { data } = await this.a.get<[IScriptBadgeJSON, number][]>(`/script-${c.T}s`, { params: { q } });

        return data.map(([b, count]) => [ScriptBadge.build(b, c), count]);
    }

    public async findYears(q: string): Promise<[string, number][]> {
        const { data } = await this.a.get<[string, number][]>('/script-years', { params: { q } });

        return data;
    }

}
