import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Form, Header, InputOnChangeData, Message } from 'semantic-ui-react';
import URI from 'urijs';

import { Subscriber } from '../../models/Subscriber';
import { SecurityRepository } from '../../repositories/SecurityRepository';

import MyHelmet from '../widgets/MyHelmet';
import Flyer from '../../Flyer';

type P = RouteComponentProps & {
  login: (subscriber: Subscriber) => void;
};

interface ILoginState {
  email: string;
  password: string;
  loading: boolean;
  error: boolean;
}

interface Search {
  action?: string;
  next?: string;
  ref?: string;
}

class Login extends React.Component<P & { repo: SecurityRepository }, ILoginState> {

  public state = { email: '', password: '', loading: false, error: false };

  public render() {
    const { email, password, loading } = this.state;

    return (
      <Flyer message={this.flash()}>
        <MyHelmet title="security.login" />

        <Header as="h1" color="blue">
          <FormattedMessage id="security.login" />
        </Header>

        <Form loading={loading} onSubmit={this.submit}>
          <Form.Input
            required
            type="email"
            label={(
              <label>
                <FormattedMessage id="common.email" />
              </label>
            )}
            value={email}
            onChange={this.setEmail}
          />
          <Form.Field required>
            <Link to="/reset" style={{ float: 'right' }} tabIndex={-1}>
              <FormattedMessage id="security.forgot_password" />
            </Link>
            <label>
              <FormattedMessage id="security.password" />
            </label>
            <Form.Input
              required
              type="password"
              value={password}
              onChange={this.setPassword}
            />
          </Form.Field>

          <Form.Button fluid primary type="submit">
            <FormattedMessage id="security.login" />
          </Form.Button>

          <p className="backup">
            <FormattedMessage
              id="security.not_registered"
              values={{
                register: (
                  <Link to="/sign-up">
                    <FormattedMessage id="security.register" />
                  </Link>
                ),
              }}
            />
          </p>
        </Form>
      </Flyer>
    );
  }

  private flash() {
    const { error } = this.state;
    const params = URI.parseQuery(this.props.location.search) as Search;
    const message =
      (error && 'security.bad_credential') ||
      (params.action && (('activate' === params.action || 'apply' === params.action) ? 'security.activation_success' : 'security.reset_success'))
    ;

    return message && (
      <Message {...{ [error ? 'negative' : 'positive']: true }}>
        <p>
          <FormattedMessage id={message} />
        </p>
      </Message>
    );
  }

  private setEmail = (_: React.SyntheticEvent<{}>, { value }: InputOnChangeData) =>
    this.setState({ email: value, error: false });

  private setPassword = (_: React.SyntheticEvent<{}>, { value }: InputOnChangeData) =>
    this.setState({ password: value, error: false });

  private submit = (e: React.FormEvent<{}>) => {
    e.preventDefault();

    const { email, password } = this.state;
    const { repo, history, location, login } = this.props;
    const search = URI.parseQuery(location.search) as Search;
    
    repo
      .login(email, password)
      .then(subscriber => {
        login(subscriber);
        if(search.ref) {
          window.location.href = `${process.env.REACT_APP_HITS_URL}${search.ref}?token=${subscriber.token}`;
        } else if(search.action && search.action === 'apply') {
          history.push('/kits/1/trials/new');        
        } else {
          history.push(search.next || '/');
        }
      })
      .catch(() => this.setState({ loading: false, error: true }))
    ;

    this.setState({ loading: true });
  }

}

export default connect(
  undefined,
  dispatch => ({
    login: (subscriber: Subscriber) => dispatch({ type: 'LOGIN', subscriber }),
  })
)((props: P) => (
  <SecurityRepository.Context.Consumer>
    {repo => (
      <Login repo={repo} {...props} />
    )}
  </SecurityRepository.Context.Consumer>
));
