import { AbstractField, IAbstractFieldJSON } from './AbstractField';
import { LocalLabel, ILocalLabelJSON } from './LocalLabel';
import { IInterestJSON, Interest } from './Interest';
import { Footnote } from './Footnote';
import { Moment } from 'moment';

export class Vector extends AbstractField {
    constructor(
        permanentID: string,
        serialNumber: number,
        readonly label: string,
        readonly localLabels: LocalLabel[],
        required: boolean,
        readonly interests: Interest[],
        footnotes: Footnote[],
        createdAt: Moment,
    ) {
        super(permanentID, serialNumber, required, footnotes, createdAt);
    }
}

export interface IVectorJSON extends IAbstractFieldJSON {
    readonly label: string;
    readonly local_labels?: ILocalLabelJSON[];
    readonly interests: IInterestJSON[];
}
