import React, { Component } from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Post } from '../../models/Post';

import Stickr from './Stickr';

interface P {
  className?: string;
  post: Post;
}

class PostItem extends Component<P & InjectedIntlProps> {
  render() {
    const { post, className, intl: { locale } } = this.props;
    const po = post.localize(locale);

    return (
      <Item className={className}>
        <Content>
          <Title>
            <Stickrr sticker={po.sticker} />
            {po.title}
          </Title>
          <Meta>
            <span>{po.createdAt.format('YYYY-MM-DD')}</span>
            <span>{po.source}</span>
          </Meta>
          <Description>
            {po.synopsis(100).split('\n').map((p, i) => (
              <p key={i}>{p}</p>
            ))}
          </Description>
          <More to={`/posts/${po.id}`}>
            <FormattedMessage id="post.more" />
          </More>
        </Content>
      </Item>
    );
  }
}

const Stickrr = styled(Stickr)`
  display: inline-block;
  padding: 4px 10px;
  margin-right: 10px;
  background: ${props => (props.sticker && props.sticker.color) || 'none'};
  border-radius: 2px;
  font-size: ${10 / 16}rem;
  font-weight: normal;
  line-height: ${12 / 10};
  vertical-align: bottom;
  color: white;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  padding-right: 75px;
`;

const Title = styled.h2`
  margin: 0 0 10px !important;
  font-size: ${18 / 14}rem;
  line-height: ${20 / 14}rem !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Meta = styled.div`
  font-size: ${12 / 14}rem;
  line-height: ${14 / 14}rem;
  color: #999;

  span + span {
    margin-left: 15px;
  }
`;

const Description = styled.div`
  margin: 40px 0 20px;
  font-size: ${14 / 14}rem;
  line-height: ${20 / 14}rem;
  color: #666;
`;

const More = styled(Link)`
  font-size: ${14 / 14}rem;
  line-height: ${16 / 14}rem;
  color: #4072ee;
`;

export default injectIntl(PostItem);
