import { fromJSON, Solution, ISolutionJSON } from './Solution';

export class AnswerSheet {
    static fromJSON(data: IAnswerSheetJSON) {
        return new AnswerSheet(
            Object
                .keys(data.solutions)
                .reduce((carry, k) => ({...carry, [k]: fromJSON(data.solutions[k])}), {})
        );
    }

    constructor(public readonly solutions: Record<string, Solution>) {
    }

    dispose() {
        return ({
            solutions: Object.keys(this.solutions).reduce(
                (r, k) => ({ ...r, [k]: this.solutions[k].dispose() }),
                {}
            ),
        });
    }
}

export interface IAnswerSheetJSON {
    readonly solutions: Record<string, ISolutionJSON>;
}
