import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Button, Form, Header, Modal, TextAreaProps } from 'semantic-ui-react';
import styled from 'styled-components';

import { Trial } from '../../models/Trial';
import { TrialRepository } from '../../repositories/TrialRepository';

const StyledModal = styled(Modal)`
  width: 570px !important;

  textarea {
    height: 125px;
  }
`;

type P = InjectedIntlProps & {
  trial: Trial;
  onRejected(old: Trial, val: Trial): void;
}

interface IRejectionButtonState {
  open: boolean;
  loading: boolean;
  remark: string;
}

class RejectionButton extends React.Component<P & { repo: TrialRepository; }, IRejectionButtonState> {

  public state: IRejectionButtonState = { open: false, loading: false, remark: '' };

  public render() {
    const { intl: { formatMessage } } = this.props;
    const { open, loading, remark } = this.state;

    return (
      <React.Fragment>
        <Button basic onClick={this.open} style={{ display: 'none' }}>
          <FormattedMessage id="reject" />
        </Button>

        <StyledModal open={open} onClose={this.close}>
          <Modal.Content>
            <Header as="h1">
              <FormattedMessage id="rejection" />
            </Header>

            <Form loading={loading} onSubmit={this.submit}>
              <Form.TextArea
                required
                label={formatMessage({ id: 'remark' })}
                value={remark}
                placeholder={formatMessage({ id: 'remark_placeholder' })}
                onChange={this.patchRemark}
              />

              <Form.Button primary type="submit">
                <FormattedMessage id="submit" />
              </Form.Button>
            </Form>
          </Modal.Content>
        </StyledModal>
      </React.Fragment>
    );
  }

  private open = () => this.setState({ open: true });

  private close = () => this.setState({ open: false });

  private patchRemark = (_: React.SyntheticEvent, { value }: TextAreaProps) =>
    this.setState({ remark: String(value || '') })
  ;

  private submit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    this.setState({ loading: true });
    const { repo, trial, onRejected, intl: { locale } } = this.props;
    const newTrial = await repo.reject(this.state.remark, locale, trial.id);

    onRejected(trial, newTrial);
  }

}

export default injectIntl((props: P) =>
  <TrialRepository.Context.Consumer>
    {repo => (
      <RejectionButton repo={repo} {...props} />
    )}
  </TrialRepository.Context.Consumer>
);
