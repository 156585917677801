export enum Frequency {
  Instantly = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
}

export enum Day {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,

  FirstDay = -1,
  FirstSunday = Sunday,
  FirstMonday = Monday,
  FirstTuesday = Tuesday,
  FirstWednesday = Wednesday,
  FirstThursday = Thursday,
  FirstFriday = Friday,
  FirstSaturday = Saturday,
  FirstWeekday = 7,
}

export class SubscriptionForm {

  constructor(
    readonly email: string = '',
    readonly frequency: Frequency = Frequency.Instantly,
    readonly day: Day = Day.FirstDay,
    readonly always: boolean = false,
    readonly quantity: number = 1,
  ) {
  }

  patch<K extends Exclude<keyof SubscriptionForm, 'patch' | 'dispose'>>(key: K, value: SubscriptionForm[K]) {
    const { email, frequency, day, always, quantity } = Object.assign({}, this, { [key]: value });

    return new SubscriptionForm(
      email,
      frequency,
      'frequency' !== key ? day : (value === Frequency.Weekly ? Day.Sunday : Day.FirstDay),
      always,
      quantity,
    );
  }

  dispose() {
    return Object.assign({
      email: this.email,
      frequency: this.frequency,
      always: this.always,
      quantity: this.quantity,
    }, [Frequency.Weekly, Frequency.Monthly].includes(this.frequency) ? {
      day: this.day,
    } : {
    });
  }

}
