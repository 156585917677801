import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CheckboxProps, Form, Header, InputOnChangeData } from 'semantic-ui-react';
import styled from 'styled-components';
import URI from 'urijs';

import { SubscriberForm } from '../models/SubscriberForm';
import { SecurityRepository } from '../repositories/SecurityRepository';

import Flyer from '../Flyer';
import CountrySelect from './widgets/CountrySelect';

type P = RouteComponentProps<void> & InjectedIntlProps;

interface IRequestAccessState {
  form: SubscriberForm;
  loading: boolean;
}

interface Search {
  email?: string;
  inviter?: string;
}

class RequestAccess extends React.Component<P & { repo: SecurityRepository; className?: string; }, IRequestAccessState> {

  private setEmail = this.patchString('email');
  private setFirstName = this.patchString('firstName');
  private setLastName = this.patchString('lastName');
  private setCountry = this.patch('country');
  private setInstitution = this.patchString('institution');

  public constructor(props: P & { repo: SecurityRepository }) {
    super(props);

    const params = URI.parseQuery(this.props.location.search) as Search;
    this.state = {
      form: new SubscriberForm().patch('email', params.email || '').patch('inviter', params.inviter || ''),
      loading: false,
    };
  }

  public render() {
    const { intl: { formatMessage }, className } = this.props;
    const { form, loading } = this.state;

    return (
      <Flyer className={className}>
        <Helmet>
          <title>Request Information - DELopen</title>
          <meta name="description" content="Request free access to the DNA encoded library (DEL) libraries." />
        </Helmet>

        <Header as="h1">
          Request Information<br />
          to Access the Libraries
        </Header>

        <Form loading={loading} onSubmit={this.submit}>
          <Form.Input
            required
            type="email"
            label={formatMessage({ id: 'common.email' })}
            value={form.email}
            onChange={this.setEmail}
          />
          <Form.Group widths="equal">
            <Form.Input
              fluid
              required
              label={formatMessage({ id: 'common.first_name' })}
              value={form.firstName}
              onChange={this.setFirstName}
            />
            <Form.Input
              fluid
              required
              label={formatMessage({ id: 'common.last_name' })}
              value={form.lastName}
              onChange={this.setLastName}
            />
          </Form.Group>
          <Form.Field required>
            <label>
              <FormattedMessage id="security.country" />
            </label>
            <CountrySelect
              value={form.country}
              onChange={this.setCountry}
            />
          </Form.Field>
          <Form.Input
            required
            label={formatMessage({ id: 'common.institution' })}
            value={form.institution}
            onChange={this.setInstitution}
          />
          <Form.Checkbox
            className="tail"
            label={formatMessage({ id: 'common.principle_investigator' })}
            checked={form.principalInvestigator}
            onChange={this.togglePrincipalInvestigator}
          />

          <Form.Button fluid primary type="submit">
            Submit Request
          </Form.Button>
        </Form>

        <p className="backup">
          <FormattedMessage
            id="security.already_registered"
            values={{
              login: (
                <Link to="/sign-in">
                  <FormattedMessage id="security.login" />
                </Link>
              ),
            }}
          />
        </p>
      </Flyer>
    );
  }

  private patchString<K extends 'email' | 'firstName' | 'lastName' | 'institution'>(key: K) {
    return (_: React.SyntheticEvent, { value }: InputOnChangeData) =>
      this.patch(key)(value as string);
  }

  private togglePrincipalInvestigator = (_: React.SyntheticEvent, { checked }: CheckboxProps) =>
    this.patch('principalInvestigator')(!!checked);

  private patch<K extends keyof SubscriberForm>(key: K) {
    return (value: SubscriberForm[K]) =>
      this.setState({ form: this.state.form.patch(key, value) });
  }

  private submit = (e: React.FormEvent<{}>) => {
    e.preventDefault();

    const { form } = this.state;
    if (!form.valid) {
      return;
    }

    const { repo, history, intl: { locale } } = this.props;
    repo
      .requestAccess(form, locale)
      .then(() => history.push('/sent?action=access'))
      .catch(() => this.setState({ loading: false }))
    ;

    this.setState({ loading: true });
  }

}

export default injectIntl(styled((props: P) => (
  <SecurityRepository.Context.Consumer>
    {repo => (
      <RequestAccess repo={repo} {...props} />
    )}
  </SecurityRepository.Context.Consumer>
))`
  h1.ui.header {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
`);
