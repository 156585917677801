import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import { Fragment } from '../../models/Fragment';
import { Kit } from '../../models/Kit';
import { KitRepository } from '../../repositories/KitRepository';

import KitItem from './Item';

import Layout from '../../PrivateLayout';
import Panel from '../../Panel';
import Catalog from '../../Catalog';
import MyHelmet from '../widgets/MyHelmet';
import DataLink from '../widgets/DataLink';
import Paginator, { PageComponentProps, withPage } from '../widgets/Paginator';

const Wrapper = styled.div`
    margin-top: 40px;
`;

interface P extends PageComponentProps {
  className?: string;
}

interface IKitYoursState {
  fragment: Fragment<Kit>;
  loading: boolean;
}

class KitYours extends Component<P & { repo: KitRepository; }, IKitYoursState> {

  state = { fragment: new Fragment<Kit>(), loading: false };

  componentDidMount() {
    this.find();
  }

  componentDidUpdate(prevProps: P) {
    if (this.props.page !== prevProps.page) {
      this.find();
    }
  }

  public render() {
    const { className } = this.props;
    const { fragment } = this.state;

    return (
      <Layout breadcrumb={[ 'kit.yours' ]} className={className}>
        <MyHelmet title="kit.yours" />

        <Grid>
          <Panel width={16} header={<FormattedMessage id="kit.yours" />}>
            <Link to="/kits/new" className="ui primary button">
              <FormattedMessage id="kit.new" />
            </Link>

            <Wrapper>
              <Catalog fragment={fragment} empty="kit.empty" start="kit.start">
                {kit => (
                  <KitItem your kit={kit}>
                    {kit.publishedAt ? (
                      <DataLink to="#" className="ui button" data={kit} onClick={this.unpublish}>
                        <FormattedMessage id="unpublish" />
                      </DataLink>
                    ) : (
                      <Link to={`/kits/${kit.id}/edit`} className="ui basic button">
                        <FormattedMessage id="edit" />
                      </Link>
                    )}
                  </KitItem>
                )}
              </Catalog>
            </Wrapper>

            <br />
            <Paginator fragment={fragment} />
          </Panel>
        </Grid>
      </Layout>
    );
  }

  private async find() {
    const { repo, page, pageSize } = this.props;
    const fragment = await repo.findYours(page, pageSize);

    this.setState({ fragment });
  }

  private unpublish = (kit: Kit) => {
    this
      .props.repo.unpublish(String(kit.id))
      .then(() => {
        const { data, total } = this.state.fragment;

        this.setState({
          fragment: new Fragment(data.map(k => k !== kit ? k : kit.unpublish()), total),
        });
      })
    ;
  }

}

export default withPage((props: P) => (
  <KitRepository.Context.Consumer>
    {repo => (
      <KitYours repo={repo} {...props} />
    )}
  </KitRepository.Context.Consumer>
));
