import { Fragment } from '../models/Fragment';
import { IPostJSON, Post } from '../models/Post';

import axios, { AxiosInstance } from 'axios';
import { createContext } from 'react';

export class PostRepository {

  public static readonly $ = new PostRepository(axios);
  public static readonly Context = createContext(PostRepository.$);

  constructor(private a: AxiosInstance) {
  }

  public async findAll(page: number, size: number): Promise<Fragment<Post>> {
    const { data } = await this.a.get<Fragment<IPostJSON>>('/posts', { params: { page, size } });

    return Fragment.build(data, Post.build);
  }

  public async find(id: number | string): Promise<Post> {
    const { data } = await this.a.get<IPostJSON>(`/posts/${id}`);

    return Post.build(data);
  }

}
