import React, { useContext, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import URI from 'urijs';

import { Hotword } from '../../models/Hotword';
import { ScriptQuery } from '../../models/ScriptQuery';
import { HotwordRepository } from '../../repositories/HotwordRepository';

function Hotwords(props: RouteComponentProps) {
  const query = ScriptQuery.build(props.location.search).defaultSort('rel');
  const repository = useContext(HotwordRepository.Context);

  const [ hotwords, setHotwords ] = useState<Hotword[]>([]);

  useEffect(() => {
    repository
      .findAll()
      .then(setHotwords)
    ;
  }, [ repository ]);
  if (!hotwords.length) {
    return null;
  }

  return (
    <Words>
      <Link to="/scripts?sort=hot">Popular</Link>
      <Link to="/scripts?sort=na">Recently updated</Link>
      {hotwords.map(hotword => (
        <Link
          to={URI.build({ path: '/scripts', query: URI.buildQuery({ q: hotword.name, sort: query.sort }) })}
          key={hotword.id}
        >
          { hotword.name }
        </Link>
      ))}
    </Words>
  );
}

export default withRouter(Hotwords);

const Words = styled.div`
  flex-wrap: wrap;
  margin: 15px 0 0 10px;
  font-size: 14px;
  line-height: 20px;

  a {
    margin-left: 10px;
    text-decoration: underline;
    color: #fff !important;

    &:hover {
      text-decoration: underline;
    }
  }
`;
