import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import { Localizable } from '../../models/Localizable';

type P<T extends Localizable<T>> = InjectedIntlProps & {
  it: T;
  children(t: T): React.ReactElement;
}

export default injectIntl(<T extends Localizable<T>>({ it, children, intl: { locale } }: P<T>) =>
  children(it.localize(locale))
);
