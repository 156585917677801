import { AbstractSolution } from './AbstractSolution';
import { Interest, IInterestJSON } from './Interest';
import { Option, IOptionJSON } from './Option';

export class ChoiceSolution extends AbstractSolution {
    static fromJSON(data: IChoiceSolutionJSON) {
        return new ChoiceSolution(
            Interest.fromJSON(data.interest),
            Option.fromJSON(data.option),
        );
    }

    constructor(
        interest: Interest,
        readonly option: Option,
    ) {
        super(interest);
    }

    dispose() {
        return ({
            option: this.option.permanentID,
        });
    }
}

export interface IChoiceSolutionJSON {
    readonly interest: IInterestJSON;
    readonly option: IOptionJSON;
}
