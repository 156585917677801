import { Adviser, IAdviserBlueprint } from '../models/Adviser';

import axios, { AxiosInstance } from 'axios';
import * as React from 'react';

export class AdviserRepository {

    public static readonly $ = new AdviserRepository(axios);
    public static readonly Context = React.createContext(AdviserRepository.$);

    constructor(private a: AxiosInstance) {
    }

    public async findAll(): Promise<Adviser[]> {
        const { data } = await this.a.get<IAdviserBlueprint[]>('/advisers');

        return data.map(Adviser.build);
    }

}
