import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { IAppState } from '../../AppState';
import { Subscriber } from '../../models/Subscriber';
import { SecurityRepository } from '../../repositories/SecurityRepository';

import ScoopImage from '../scoop/Image';

import Invitation from './Invitation';

import ChangePassword from './ChangePassword';

import portrait from '../../portrait.png';

import Layout from '../../Layout';
import Panel from '../../Panel';
import MyHelmet from '../widgets/MyHelmet';
import ProfileEdit from './ProfileEdit';

const ProfilePanel = styled(Panel)`
  line-height: 20px;

  img {
    display: block;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    margin: auto;
  }

  h3 {
    margin: 20px 0 10px !important;
    text-align: center;
  }

  p {
    text-align: center;
    color: #666;
  }

  dl {
    margin-top: 60px;
  }

  dt {
    float: left;
    color: #666;
  }

  dd {
    display: block;
    margin: 0 0 20px 90px;
  }

  button {
    margin-top: 70px !important;
  }
`;

interface P {
  className?: string;
  subscriber: Subscriber;
}

interface IProfileState {
  edit: boolean;
  password: boolean;
}

class Profile extends Component<P & { repo: SecurityRepository }, IProfileState> {

  state: IProfileState = { edit: false, password: false };

  render() {
    const { className, subscriber } = this.props;
    const { edit, password } = this.state;

    return (
      <Layout breadcrumb={[ 'nav.profile' ]} root="nav.account" className={className}>
        <MyHelmet title="nav.profile" />

        <ProfileEdit open={edit} onClose={this.dismiss} />
        <ChangePassword open={password} onClose={this.dimissPassword} />

        <Grid>
          <ProfilePanel width={10} header={<FormattedMessage id="nav.profile" />}>
            {subscriber.portrait ? (
              <ScoopImage scoop={subscriber.portrait} />
            ) : (
              <img src={portrait} alt="portrait" />
            )}
            <Header as="h3">{`${subscriber.firstName} ${subscriber.lastName}`}</Header>
            <p>{subscriber.i9n}</p>

            <dl>
              <dt>
                <FormattedMessage id="security.country" />
              </dt>
              <dd>
                {subscriber.country.name}
              </dd>
              <dt>
                <FormattedMessage id="common.email" />
              </dt>
              <dd>
                {subscriber.email}
              </dd>
              <dt>
                <FormattedMessage id="common.phone_number" />
              </dt>
              <dd>
                {subscriber.phoneNumber || (
                  <FormattedMessage id="na" />
                )}
              </dd>
              <dt>
                <FormattedMessage id="security.password" />
              </dt>
              <dd>
                <Link to="#" onClick={this.changePassword}>
                  <FormattedMessage id="nav.change_password" />
                </Link>
              </dd>
              <dt>
                <FormattedMessage id="security.biography" />
              </dt>
              <dd>
                {subscriber.biography || (
                  <FormattedMessage id="na" />
                )}
              </dd>
            </dl>

            <Button primary type="button" onClick={this.edit}>
              <FormattedMessage id="edit" />
            </Button>
          </ProfilePanel>

          <Panel width={6} header={<FormattedMessage id="security.invitation" />}>
            <Invitation />
          </Panel>
        </Grid>
      </Layout>
    );
  }

  private edit = () => this.setState({ edit: true });

  private dismiss = () => this.setState({ edit: false });

  private changePassword = () => this.setState({ password: true });

  private dimissPassword = () => this.setState({ password: false });

}

export default connect(
  ({ subscriber }: IAppState) => ({ subscriber: subscriber as Subscriber }),
)((props: P) => (
  <SecurityRepository.Context.Consumer>
    {repo => (
      <Profile repo={repo} {...props} />
    )}
  </SecurityRepository.Context.Consumer>
));
