import { LocalLabel, ILocalLabelJSON } from './LocalLabel';
import { Solution } from './Solution';
import { ChoiceSolution } from './ChoiceSolution';
import { MultiAnswerSolution } from './MultiAnswerSolution';
import { Localizable, localize } from './Localizable';

export class Option implements Localizable<Option> {
    static fromJSON(data: IOptionJSON) {
        return new Option(
            data.permanent_id,
            data.serial_number,
            data.label,
            (data.local_labels || []).map(LocalLabel.fromJSON),
        );
    }

    constructor(
        readonly permanentID: string,
        readonly serialNumber: number,
        readonly label: string,
        readonly localLabels: LocalLabel[],
    ) {
    }

    localize(locale: string) {
        return new Option(
            this.permanentID,
            this.serialNumber,
            localize<'label'>(locale, this.localLabels, this).label,
            this.localLabels,
        );
    }

    get width() {
        return 1;
    }

    get height() {
        return 1;
    }

    isChecked(solution?: Solution) {
        return (solution instanceof ChoiceSolution
            && solution.option.permanentID === this.permanentID)
            || (solution instanceof MultiAnswerSolution
                && solution.options.some(opt => opt.permanentID === this.permanentID));
    }
}

export interface IOptionJSON {
    readonly permanent_id: string;
    readonly serial_number: number;
    readonly label: string;
    readonly local_labels?: ILocalLabelJSON[];
}

export class OptionGroup extends Option implements Localizable<OptionGroup> {
    static fromJSON(data: IOptionGroupJSON): OptionGroup {
        return new OptionGroup(
            data.permanent_id,
            data.serial_number,
            data.label,
            (data.local_labels || []).map(LocalLabel.fromJSON),
            data.children.map(child => isOptionGroup(child) ? OptionGroup.fromJSON(child) : Option.fromJSON(child)),
        );
    }

    constructor(
        permanentID: string,
        serialNumber: number,
        label: string,
        localLabels: LocalLabel[],
        readonly children: (Option | OptionGroup)[],
    ) {
        super(permanentID, serialNumber, label, localLabels);
    }

    localize(locale: string): OptionGroup {
        return new OptionGroup(
            this.permanentID,
            this.serialNumber,
            localize<'label'>(locale, this.localLabels, this).label,
            this.localLabels,
            this.children.map(child => child.localize(locale)),
        );
    }

    get width(): number {
        return this.children.reduce((w, opt) => w + opt.width, 0);
    }

    get height(): number {
        return 1 + this.children.reduce((h, opt) => Math.max(h, opt.height), 0);
    }

    rollOut(): Option[] {
        return this.children.reduce<Option[]>(
            (opts, opt) => opts.concat(opt instanceof OptionGroup ? opt.rollOut() : opt),
            []
        );
    }
}

export interface IOptionGroupJSON extends IOptionJSON {
    readonly children: (IOptionJSON | IOptionGroupJSON)[];
}

function isOptionGroup(opt: IOptionJSON | IOptionGroupJSON): opt is IOptionGroupJSON {
    return (opt as IOptionGroupJSON).children !== undefined;
}
