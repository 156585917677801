import { LocalLabel, ILocalLabelJSON } from './LocalLabel';
import { LanguageTag } from './LanguageTag';

export class ScratchLabel extends LocalLabel {
    static fromJSON(data: IScratchLabelJSON) {
        return new ScratchLabel(
            data.id,
            LanguageTag.build(data.locale),
            data.label,
            data.prompt || '',
        );
    }

    constructor(
        readonly id: number,
        readonly locale: LanguageTag,
        readonly label: string,
        readonly prompt: string,
    ) {
        super(id, locale, label);
    }
}

export interface IScratchLabelJSON extends ILocalLabelJSON {
    prompt?: string;
}
