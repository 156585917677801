import { IReactionMetaJSON, ReactionMeta } from './ReactionMeta';
import { highlight, IScriptJSON, Script } from './Script';

import moment, { Moment } from 'moment';

export class Reaction {
    public static build(data: IReactionJSON) {
        return new Reaction(
            data.id,
            data.refno,
            data.script && Script.build(data.script),
            ReactionMeta.compile(data.meta),
            data.kinds,
            data.conditions,
            data.usage,
            data.pages,
            moment(data.created_at),
            data.highlights,
        );
    }

    constructor(
        readonly id: number,
        readonly refno: string,
        readonly script: Script | undefined,
        readonly meta: ReactionMeta,
        readonly kinds: string[],
        readonly conditions: string,
        readonly usage: string,
        readonly pages: string,
        readonly createdAt: Moment,
        private readonly highlights?: IReactionHighlights,
    ) {
    }

    public highlight(key: 'name' | 'kinds' | 'conditions' | 'usage' | 'institutions') {
        switch (key) {
            case 'name':
                return highlight(this.meta.name, (this.highlights && this.highlights['name']) || []);

            case 'kinds':
                return highlight(this.kinds.join('\n'), (this.highlights && this.highlights.kinds) || []);

            case 'institutions':
                return highlight(
                    (this.script && this.script.institutions.length > 0 && this.script.institutions[0].name) || '',
                    (this.highlights && this.highlights['script.institutions']) || []
                );

            default:
                return highlight(this[key], (this.highlights && this.highlights[key]) || []);
        }
    }
}

export interface IReactionJSON {
    readonly id: number;
    readonly refno: string;
    readonly script?: IScriptJSON;
    readonly meta: IReactionMetaJSON;
    readonly kinds: string[];
    readonly conditions: string;
    readonly usage: string;
    readonly pages: string;
    readonly created_at: string;
    readonly highlights?: IReactionHighlights;
}

interface IReactionHighlights {
    readonly name?: string[];
    readonly kinds?: string[];
    readonly conditions?: string[];
    readonly usage?: string[];
    readonly 'script.institutions'?: string[];
}
