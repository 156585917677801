import { AbstractSolution } from './AbstractSolution';
import { Interest, IInterestJSON } from './Interest';
import { Option, IOptionJSON } from './Option';

export class MultiAnswerSolution extends AbstractSolution {
    static fromJSON(data: IMultiAnswerSolutionJSON) {
        return new MultiAnswerSolution(
            Interest.fromJSON(data.interest),
            data.options.map(Option.fromJSON),
            data.paragraph,
        );
    }

    constructor(
        interest: Interest,
        readonly options: Option[],
        readonly paragraph?: string,
    ) {
        super(interest);
    }

    toggle(option: Option, on: boolean) {
        if (on) {
            if (this.options.find(opt => opt.permanentID === option.permanentID)) {
                return this;
            }

            return new MultiAnswerSolution(this.interest, this.options.concat(option), this.paragraph);
        }

        return new MultiAnswerSolution(this.interest, this.options.filter(opt => opt.permanentID !== option.permanentID), this.paragraph);
    }

    amend(scratch?: string) {
        return new MultiAnswerSolution(this.interest, this.options, scratch);
    }

    get valid() {
        return this.options.length > 0 || !!this.paragraph;
    }

    dispose() {
        return ({
            options: this.options.map(opt => opt.permanentID),
            ...this.paragraph !== undefined && { paragraph: this.paragraph },
        });
    }
}

export interface IMultiAnswerSolutionJSON {
    readonly interest: IInterestJSON;
    readonly options: IOptionJSON[];
    readonly paragraph?: string;
}
