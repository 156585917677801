import { ILocalStickerBlueprint, LocalSticker } from './LocalSticker';

export class Sticker {
  public static build(data: IStickerBlueprint) {
    return new Sticker(
      data.id,
      data.name,
      data.color || '',
      data.local_stickers.map(LocalSticker.build),
    );
  }

  constructor(
    readonly id: number,
    readonly name: string,
    readonly color: string,
    readonly localStickers: LocalSticker[],
  ) {
  }
}

export interface IStickerBlueprint {
  readonly id: number;
  readonly name: string;
  readonly color?: string;
  readonly local_stickers: ILocalStickerBlueprint[];
}
