import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import URI from 'urijs';

import { ScriptQuery } from '../../models/ScriptQuery';

type P = RouteComponentProps & {
  className?: string;
  alphabet?: boolean;
  query: ScriptQuery;
};

class Sort extends Component<P> {

  render() {
    const { className, alphabet, query, location: { pathname } } = this.props;

    return (
      <div className={className}>
        <FormattedMessage id="script.sort" />
        {': '}
        <Dropdown
          direction="left"
          icon={null}
          trigger={
            <Trigger>
              {(query.sort === 'abc' && (
                <FormattedMessage id="script.alphabet" />
              )) || (query.sort === 'age' && (
                <FormattedMessage id="script.recent" />
              )) || (query.sort === 'na' && (
                <FormattedMessage id="script.na" />
              )) || (query.sort === 'hot' && (
                <FormattedMessage id="script.hot" />
              )) || (
                <FormattedMessage id="script.relevant" />
              )}
            </Trigger>
          }
        >
          <Dropdown.Menu>
            <Link to={URI.build({ path: pathname, query: String(query.amend('sort', 'rel')) })} className="item">
              <FormattedMessage id="script.relevant" />
            </Link>
            {alphabet ? (
              <Link to={URI.build({ path: pathname, query: String(query.amend('sort', 'abc')) })} className="item">
                <FormattedMessage id="script.alphabet" />
              </Link>
            ) : (
              <Link to={URI.build({ path: pathname, query: String(query.amend('sort', 'hot')) })} className="item">
                <FormattedMessage id="script.hot" />
              </Link>
            )}
            <Link to={URI.build({ path: pathname, query: String(query.amend('sort', 'na')) })} className="item">
              <FormattedMessage id="script.na" />
            </Link>
            <Link to={URI.build({ path: pathname, query: String(query.amend('sort', 'age')) })} className="item">
              <FormattedMessage id="script.recent" />
            </Link>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

}

export default styled(withRouter(Sort))`
  float: right;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
`;

const Trigger = styled.span`
  &::after {
    content: " ∨";
  }
`;
