import React, { Component } from 'react';
import { Image, ImageProps } from 'semantic-ui-react';

import { DigestScoop } from '../../models/DigestScoop';
import { FileScoop } from '../../models/FileScoop';
import { ScoopRepository, ImageFilter } from '../../repositories/ScoopRepository';

type P = ImageProps & {
  scoop: DigestScoop | FileScoop;
  filter?: ImageFilter;
  background?: boolean;
}

interface IScoopImageState {
  url: string;
  rev: (url: string) => void;
}

class ScoopImage extends Component<P & { repo: ScoopRepository }, IScoopImageState> {

  constructor(props: P & { repo: ScoopRepository }) {
    super(props);

    const [url, rev] = props.repo.find(props.scoop, props.filter);
    this.state = { url, rev };
  }

  public componentDidUpdate(prevProps: P & { repo: ScoopRepository }) {
    if (this.props.scoop !== prevProps.scoop || this.props.filter !== prevProps.filter) {
      this.state.rev(this.state.url);

      const [url, rev] = this.props.repo.find(this.props.scoop, this.props.filter);
      this.setState({ url, rev });
    }
  }

  public componentWillUnmount() {
    this.state.rev(this.state.url);
  }

  public render() {
    const { repo, scoop, filter, background, className, ...props } = this.props;
    const { url } = this.state;

    if (!background) {
      return (
        <Image src={url} size={this.props.size} className={className} {...props} />
      );
    }

    return (
      <div style={{ backgroundImage: `url("${url}")` }} className={className} />
    );
  }

}

export default function (props: P) {
  return (
    <ScoopRepository.Context.Consumer>
      {repo => (
        <ScoopImage repo={repo} {...props} />
      )}
    </ScoopRepository.Context.Consumer>
  );
}
