import { Country } from './Country';

export class TrialForm {
  constructor(
    public readonly firstName: string = '',
    public readonly lastName: string = '',
    public readonly email: string = '',
    public readonly phoneNumber: string = '',
    public readonly institution: string = '',
    public readonly country?: Country,
    public readonly supervisorFirstName: string = '',
    public readonly supervisorLastName: string = '',
    public readonly supervisorTitle: string = '',
    public readonly supervisorHomepage: string = '',
    public readonly supervisorEmail: string = '',
    public readonly supervisorInstitution: string = '',
    public readonly consignee: string = '',
    public readonly shippingEmail: string = '',
    public readonly shippingPhone: string = '',
    public readonly address: string = '',
    public readonly freightCompany: string = 'removed',
    public readonly fedexAccount: string = '',
    public readonly postcode: string = '',
    public readonly sampleReturn: string = '',
    public readonly optionalService: string = '',
    public readonly synopsis: string = 'removed',
    public readonly prepared: boolean = false,
    public readonly isAuth: number = 2,
  ) {
  }

  public patch<K extends Exclude<keyof TrialForm, 'patch' | 'dispose'>>(key: K, value: TrialForm[K]) {
    const { firstName, lastName, email, phoneNumber, institution, country, supervisorFirstName, supervisorLastName, supervisorTitle, supervisorHomepage, supervisorEmail, supervisorInstitution, consignee, shippingEmail, shippingPhone, address, freightCompany, fedexAccount, postcode, sampleReturn, optionalService, synopsis, prepared, isAuth }
      = Object.assign({}, this, { [key]: value });

    return new TrialForm(firstName, lastName, email, phoneNumber, institution, country, supervisorFirstName, supervisorLastName, supervisorTitle, supervisorHomepage, supervisorEmail, supervisorInstitution, consignee, shippingEmail, shippingPhone, address, freightCompany, fedexAccount, postcode, sampleReturn, optionalService, synopsis, prepared, isAuth);
  }

  public dispose() {
    return ({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      institution: this.institution,
      synopsis: this.synopsis,
      country: this.country && this.country.id,
      supervisorFirstName: this.supervisorFirstName,
      supervisorLastName: this.supervisorLastName,
      supervisorTitle: this.supervisorTitle,
      supervisorHomepage: this.supervisorHomepage,
      supervisorEmail: this.supervisorEmail,
      supervisorInstitution: this.supervisorInstitution,
      consignee: this.consignee,
      shippingEmail: this.shippingEmail,
      shippingPhone: this.shippingPhone,
      address: this.address,
      freightCompany: this.freightCompany,
      fedexAccount: this.fedexAccount,
      postcode: this.postcode,
      sampleReturn: this.sampleReturn,
      optionalService: this.optionalService,
      prepared: this.prepared,
      isAuth: this.isAuth,
    });
  }
}
