import { Vector, IVectorJSON } from './Vector';
import { Interest } from './Interest';
import { OptionGroup, IOptionGroupJSON } from './Option';
import moment, { Moment } from 'moment';
import { LocalLabel } from './LocalLabel';
import { Footnote } from './Footnote';
import { Localizable, localize } from './Localizable';
import { ScratchLabel, IScratchLabelJSON } from './ScratchLabel';

export class ChoiceVector extends Vector implements Localizable<ChoiceVector> {
    static fromJSON(data: IChoiceVectorJSON) {
        return new ChoiceVector(
            data.permanent_id,
            data.serial_number,
            data.label,
            (data.local_labels || []).map(LocalLabel.fromJSON),
            data.required,
            data.interests.map(Interest.fromJSON),
            data.multiple,
            data.scratch || '',
            data.prompt || '',
            (data.local_scratches || []).map(ScratchLabel.fromJSON),
            OptionGroup.fromJSON(data.options),
            (data.footnotes || []).map(Footnote.fromJSON),
            moment(data.created_at),
        );
    }

    constructor(
        permanentID: string,
        serialNumber: number,
        label: string,
        localLabels: LocalLabel[],
        required: boolean,
        interests: Interest[],
        readonly multiple: boolean,
        readonly scratch: string,
        readonly prompt: string,
        readonly localScratches: ScratchLabel[],
        readonly options: OptionGroup,
        footnotes: Footnote[],
        createdAt: Moment,
    ) {
        super(permanentID, serialNumber, label, localLabels, required, interests, footnotes, createdAt);
    }

    localize(locale: string) {
        return new ChoiceVector(
            this.permanentID,
            this.serialNumber,
            localize<'label'>(locale, this.localLabels, this).label,
            this.localLabels,
            this.required,
            this.interests.map(i => i.localize(locale)),
            this.multiple,
            localize<'label'>(locale, this.localScratches, { label: this.scratch }).label,
            localize<'prompt'>(locale, this.localScratches, this).prompt,
            this.localScratches,
            this.options.localize(locale),
            this.footnotes.map(f => f.localize(locale)),
            this.createdAt,
        );
    }
}

export interface IChoiceVectorJSON extends IVectorJSON {
    readonly multiple: boolean;
    readonly scratch?: string;
    readonly prompt?: string;
    readonly local_scratches?: IScratchLabelJSON[];
    readonly options: IOptionGroupJSON;
}
