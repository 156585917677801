import React, { Component } from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form, Header, InputOnChangeData, Modal, ModalProps, TextAreaProps } from 'semantic-ui-react';
import styled from 'styled-components';

import { Subscriber } from '../../models/Subscriber';
import { SubscriberForm } from '../../models/SubscriberForm';
import { SecurityRepository } from '../../repositories/SecurityRepository';
import { noop } from '../../Util';

import portrait from '../../portrait.png';

import ScoopImageInput from '../scoop/ImageInput';
import CountrySelect from '../widgets/CountrySelect';

import { IAppState, login } from '../../AppState';

type P = InjectedIntlProps & ModalProps & {
  subscriber: Subscriber;
  login(subscriber: Subscriber): void;
  onClose(): void;
};

interface S {
  form: SubscriberForm;
  loading: boolean;
}

class ProfileEdit extends Component<P & { repo: SecurityRepository; }, S> {

  private setPortrait = this.patch('portrait');
  private setFirstName = this.patchString('firstName');
  private setLastName = this.patchString('lastName');
  private setCountry = this.patch('country');
  private setInstitution = this.patchString('institution');
  private setPhoneNumber = this.patchString('phoneNumber');
  private setBiography = this.patchString('biography');

  constructor(props: P & { repo: SecurityRepository; }) {
    super(props);

    this.state = { form: props.subscriber.patch('institution', props.subscriber.i9n), loading: false };
  }

  render() {
    const { subscriber, login, intl: { formatMessage }, ...props } = this.props;
    const { form, loading } = this.state;

    return (
      <Modal {...props}>
        <Modal.Content>
          <Header as="h1">
            <FormattedMessage id="nav.edit_profile" />
          </Header>

          <Form loading={loading} onSubmit={this.submit}>
            <Form.Field className="portrait">
              <ScoopImageInput
                trigger={(
                  <img src={portrait} alt="portrait" />
                )}
                value={form.portrait}
                onChange={this.setPortrait}
              />
              <label>
                <FormattedMessage id="security.upload_portrait" />
              </label>
            </Form.Field>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                required
                label={formatMessage({ id: 'common.first_name' })}
                value={form.firstName}
                onChange={this.setFirstName}
              />
              <Form.Input
                fluid
                required
                label={formatMessage({ id: 'common.last_name' })}
                value={form.lastName}
                onChange={this.setLastName}
              />
            </Form.Group>
            <Form.Field required>
              <label>
                <FormattedMessage id="security.country" />
              </label>
              <CountrySelect
                value={form.country}
                onChange={this.setCountry}
              />
            </Form.Field>
            <Form.Input
              required
              label={formatMessage({ id: 'common.institution' })}
              value={form.institution}
              onChange={this.setInstitution}
            />
            <Form.Input
              label={formatMessage({ id: 'common.phone_number' })}
              value={form.phoneNumber}
              onChange={this.setPhoneNumber}
            />
            <Form.TextArea
              label={formatMessage({ id: 'security.biography' })}
              value={form.biography}
              onChange={this.setBiography}
            />

            <Form.Button primary type="submit">
              <FormattedMessage id="save" />
            </Form.Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }

  private patchString<K extends 'firstName' | 'lastName' | 'institution' | 'phoneNumber' | 'biography'>(key: K) {
    return (_: React.SyntheticEvent<{}>, { value }: InputOnChangeData | TextAreaProps) =>
      this.patch(key)(value as string);
  }

  private patch<K extends keyof SubscriberForm>(key: K) {
    return (value: SubscriberForm[K]) => {
      const { form } = this.state;

      this.setState({ form: form && form.patch(key, value) });
    }
  }

  private submit = (e: React.FormEvent<{}>) => {
    e.preventDefault();

    const { form } = this.state;
    if (!form.valid) {
      return;
    }

    const { login, onClose, repo } = this.props;
    repo
      .persist(form)
      .then(subscriber => {
        login(subscriber);

        onClose && onClose();
      })
      .catch(noop)
      .then(() => this.setState({ loading: false }))
    ;

    this.setState({ loading: true });
  }

}

export default styled(connect(
  ({ subscriber }: IAppState) => ({ subscriber: subscriber as Subscriber }),
  dispatch => ({
    login: (subscriber: Subscriber) => dispatch(login(subscriber)),
  }),
)(injectIntl((props: P) => (
  <SecurityRepository.Context.Consumer>
    {repo => (
      <ProfileEdit repo={repo} {...props} />
    )}
  </SecurityRepository.Context.Consumer>
))))`
  width: 720px;

  .portrait.field {
    margin: 30px 0;
    text-align: center;

    img {
      display: block;
      width: 120px;
      height: 120px;
      border-radius: 60px;
      margin: 10px auto;
    }
  }
`;
