import 'core-js';
import 'raf/polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import './semantic/dist/semantic.css';
import './index.css';

import 'typeface-lato';

ReactDOM.render(
  <App />,
  document.getElementById('root') as HTMLElement
);
unregister();
