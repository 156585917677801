import * as React from 'react';
import { DropdownProps, Form } from 'semantic-ui-react';

import { Carrier } from '../../models/Carrier';
import { CarrierRepository } from '../../repositories/CarrierRepository';

interface ICarrierSelectProps {
  value?: Carrier;
  onChange: (value?: Carrier) => void;
}

interface ICarrierSelectState {
  carriers: Carrier[];
}

class CarrierSelect extends React.Component<ICarrierSelectProps & { repo: CarrierRepository }, ICarrierSelectState> {

  public state: ICarrierSelectState = { carriers: [] };

  public componentDidMount() {
    this
      .props.repo
      .findAll()
      .then(carriers => this.setState({ carriers }))
    ;
  }

  public render() {
    const { value } = this.props;
    const { carriers } = this.state;

    return (
      <Form.Select
        required
        options={carriers.map(c => ({ text: c.name, value: c.id }))}
        value={value && value.id}
        onChange={this.change}
      />
    );
  }

  private change = (_: React.SyntheticEvent, { value }: DropdownProps) =>
    this.props.onChange(this.state.carriers.find(c => c.id === value));

}

export default ((props: ICarrierSelectProps) => (
  <CarrierRepository.Context.Consumer>
    {repo => (
      <CarrierSelect repo={repo} {...props} />
    )}
  </CarrierRepository.Context.Consumer>
));
