import { DigestScoop } from './DigestScoop';
import { FileScoop } from './FileScoop';

export class InstitutionForm {
  constructor(
    public readonly title: string = '',
    public readonly image?: DigestScoop | FileScoop,
    public readonly url: string = '',
    public readonly synopsis: string = '',
  ) {
  }

  public patch<K extends keyof InstitutionForm>(key: K, value: InstitutionForm[K]) {
    const { title, image, url, synopsis } = Object.assign({}, this, { [key]: value });

    return new InstitutionForm(title, image, url, synopsis);
  }

  public dispose() {
    return ({
      title: this.title,
      image: this.image && this.image.dispose(),
      url: this.url,
      synopsis: this.synopsis,
    });
  }
}
