import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import cooperation from '../cooperation.png';
import del from '../del.png';
import search from '../search.png';

class Services extends React.Component<{ className?: string } & InjectedIntlProps> {

  public render() {
    const { className, intl: { locale } } = this.props;

    return (
      <div className={`${className} ${locale}`}>
        <Grid container>
          <Grid.Column>
            <div className="services">
              <Header as="h2">
                <FormattedMessage id="services" />
              </Header>
              <p>
                <FormattedMessage id="services.p" values={{ br: <br /> }} />
              </p>

              <Grid columns={3}>
                <Grid.Column>
                  <img src={del} alt="" />
                  <b>
                    <FormattedMessage id="services.free_del" />
                  </b>
                  <p>
                    <FormattedMessage id="services.free_del.p" values={{ br: <br /> }} />
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <img src={cooperation} alt="" />
                  <b>
                    <FormattedMessage id="services.cooperation" />
                  </b>
                  <p>
                    <FormattedMessage id="services.cooperation.p" values={{ br: <br /> }} />
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <img src={search} alt="" />
                  <b>
                    <FormattedMessage id="services.delfinder" />
                  </b>
                  <p>
                    <FormattedMessage id="services.delfinder.p" values={{ br: <br /> }} />
                  </p>
                </Grid.Column>
              </Grid>

              <Link to="/service">
                <FormattedMessage id="home.services.more" />
              </Link>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

}

export default styled(injectIntl(Services))`
  padding: 70px 0 80px;
  background: #fff;

  h2.ui.header {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 20px;
    text-align: center;
  }

  p {
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #999;
  }

  .services .ui.grid {
    margin: 20px -1rem !important;

   .column {
      height: 320px;
      padding: 30px 1rem !important;
      text-align: center;

      img {
        display: block;
        width: 160px;
        height: 110px;
        margin: auto;
        border-radius: 20px;
      }

      b {
        display: block;
        margin: 20px 0 22px !important;
        font-size: 22px;
        font-weight: normal;
        line-height: 30px;
        color: #333;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        color: #999;
      }
    }
  }

  a {
    display: block;
    width: 220px;
    height: 60px;
    padding: 20px 0;
    margin: auto;
    background: #4072ee;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #fff !important;
  }

  &.en-us {
    h2.ui.header {
      font-size: 34px;
      line-height: 40px;
    }

    p {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;
