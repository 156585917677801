import * as React from 'react';

import Abstract from './Abstract';
import AdvisoryBoard from './AdvisoryBoard';
import Join from './Join';
import Jumbotron from './Jumbotron';
import Services from './Services';
import Sponsors from './Sponsors';

import MyHelmet from '../widgets/MyHelmet';

class Home extends React.Component {

  public render() {
    return (
      <div>
        <MyHelmet title="nav.home" description="home.meta">
          <style>
            {'#root { padding-top: 0 !important }'}
          </style>
        </MyHelmet>

        <Jumbotron />
        <Abstract />
        <Services />
        <Sponsors />
        <AdvisoryBoard />
        <Join />
      </div>
    );
  }

}

export default Home;
