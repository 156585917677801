import { Fragment, IFragmentBlueprint } from '../models/Fragment';
import { IKitBlueprint, Kit } from '../models/Kit';
import { KitForm } from '../models/KitForm';

import axios, { AxiosInstance } from 'axios';
import * as React from 'react';
import { ScoopRepository } from './ScoopRepository';

export class KitRepository {

  public static readonly $ = new KitRepository(axios, ScoopRepository.$);
  public static readonly Context = React.createContext(KitRepository.$);

  constructor(private a: AxiosInstance, private sr: ScoopRepository) {
  }

  public async findAll(page: number, size: number = 20): Promise<Fragment<Kit>> {
    const { data } = await this.a.get<IFragmentBlueprint<IKitBlueprint>>('/_/kits', { params: { page, size } });

    return Fragment.build(data, Kit.build);
  }

  public async findYours(page: number, size: number = 20): Promise<Fragment<Kit>> {
    const { data } = await this.a.get<IFragmentBlueprint<IKitBlueprint>>('/kits', { params: { page, size } });

    return Fragment.build(data, Kit.build);
  }

  public async find(id: string): Promise<Kit> {
    const { data } = await this.a.get<IKitBlueprint>(`/kits/${id}`);

      return Kit.build(data);
  }

  public async persist(form: KitForm, publish: boolean = false, id?: string): Promise<Kit> {
    const kit = ({
      ...await form.dispose(this.sr.persist.bind(this.sr)),
      ...publish && { publish: true }
    });
    const { data } = await (
      !id
        ? this.a.post<IKitBlueprint>('/kits', { kit })
        : this.a.put<IKitBlueprint>(`/kits/${id}`, { kit })
    );

    return Kit.build(data);
  }

  public async publish(id: string): Promise<Kit> {
    const { data } = await this.a.post<IKitBlueprint>(`/kits/${id}/publish`);

    return Kit.build(data);
  }

  public async unpublish(id: string): Promise<void> {
    await this.a.delete(`/kits/${id}/published-at`);
  }

}
