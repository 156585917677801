import React, { Component, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

import Page from './Page';
import { Addr, Address, Cap, HomeUrl, PrivacyPolicy, PrivacyUrl, SupportMail, T, Tag } from './Styled';

export class Terms extends Component<{ className?: string; }> {

  render() {
    return (
      <Page loc="nav.terms" className={this.props.className}>
        <Helmet>
          <title>Terms of Use - DELOpen</title>
        </Helmet>

        <Header as="h1">DELOPEN PLATFORM TERMS OF SERVICE</Header>

        {/* LAST UPDATED: ‎[date] */}

        <p>
          These DELopen Platform Terms of Service (“<T>Terms</T>”) are between DELopen Limited (“<T>DELopen</T>”, “<T>we</T>”, or “<T>us</T>”) and you, an individual user (“<T>you</T>”), and governs your use of DELopen’s platform available at <HomeUrl /> and all related subdomains (collectively, the “<T>Service</T>”). <b>BY ACCESSING THE SERVICE OR ACCEPTING THESE TERMS THROUGH THE REQUIRED PROCESS, YOU AGREE THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS, THEN YOU MAY NOT USE ANY PORTION OF THE SERVICE.</b>
        </p>

        <p>
          <b>THE SERVICE ENABLES CERTAIN USERS TO COLLABORATE ON RESEARCH ACTIVITIES AS FURTHER DESCRIBED BELOW. NOTHING IN THESE TERMS WILL BE CONSTRUED TO MAKE DELOPEN A PARTY TO ANY CONTRACT BETWEEN THESE USERS AS FURTHER DESCRIBED BELOW AND DELOPEN WILL NOT BE A PARTY TO ANY DISPUTE BETWEEN THEM.</b>
        </p>

        <p>
          <b>AS FURTHER DESCRIBED BELOW IN SECTION 11, AND WITHOUT LIMITING ANY TERMS IN THAT SECTION, DISPUTES BETWEEN YOU AND DELOPEN WILL BE RESOLVED BY BINDING INDIVIDUAL ARBITRATION, AND BY ACCEPTING THESE TERMS THROUGH THE REQUIRED PROCESS, YOU AND DELOPEN EACH WAIVE YOUR RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.</b>
        </p>

        <Header as="h2">1. GENERAL TERMS AND CONDITIONS</Header>

        <P seq="1.1">
          <Cap>Service Overview</Cap>. The Service provides a method for the exchange of information and collaboration between parties conducting research (“<T>Researchers</T>”) and compound library providers (“<T>Providers</T>”) to promote the application of DNA-encoded chemical library (DEL) technology in the field of new drug development. Through the Service, DELopen connects Providers and Researchers and facilitates the formation and execution of a contract between those parties that governs the exchange of compound library information as further described in Section 4. As used in these Terms, “<T>User</T>” means Providers, Researchers and all other users of the Service.
        </P>

        <P seq="1.2">
          <Cap>Eligibility</Cap>. IN THE UNITED STATES, THE SERVICE IS NOT FOR PERSONS UNDER THE AGE OF 13. FOR USERS OUTSIDE OF THE UNITED STATES, THE SERVICE IS NOT FOR PERSONS UNDER THE AGE FOR WHICH RULES APPLY ON AN AGE-BASED BASIS FOR THE COLLECTION OF DATA (“<T>RESTRICTED AGE</T>”). IN ALL CASES, THE SERVICE IS NOT FOR ANY USERS PREVIOUSLY SUSPENDED OR REMOVED FROM THE SERVICE BY DELOPEN. IF YOU ARE UNDER 13 YEARS OF AGE IN THE UNITED STATES OR THE RESTRICTED AGE IN THE TERRITORY WHERE YOU RESIDE, THEN YOU MUST NOT USE OR ACCESS THE SERVICE AT ANY TIME OR IN ANY MANNER. Furthermore, by using the Service, you affirm that either you are at least the age of majority in the territory in which you reside or have been authorized to use the Service by your parent or legal guardian who is at least the age of majority and who entered into these Terms on your behalf. If you are using the Service on behalf of an entity or organization, then you represent and warrant that you have the authority to bind that entity or organization to these Terms and you agree to be bound by these Terms on behalf of that entity or organization.
        </P>

        <P seq="1.3">
          <Cap>Changes</Cap>. We reserve the right to change these Terms on a going-forward basis at any time. Please check these Terms periodically for changes. If a change to these Terms materially modifies your rights or obligations, we may require that you accept the modified Terms in order to continue to use the Service. Material modifications are effective upon your acceptance of the modified Terms. Immaterial modifications are effective upon publication. Except as expressly permitted in this Section 1.3, these Terms may be amended only by a written agreement signed by authorized representatives of the parties to these Terms. Disputes arising under these Terms will be resolved in accordance with the version of these Terms that was in effect at the time the dispute arose.
        </P>

        <P seq="1.4">
          <Cap>Consideration</Cap>. DELopen currently provides you with access to the Service for free. In return for enjoying this free access, you acknowledge and agree that DELopen may generate revenues, increase goodwill or otherwise increase the value of DELopen from your use of the Service, and you will have no right to share in any such revenues, goodwill or value whatsoever. Although currently free to users, DELopen in its sole discretion may eventually charge you fees to access certain features on the Service. DELopen will provide advance notice of any changes to any fees before they take effect. Even if we charge you a fee for the use of any portion of the Service, you will have no right to share in the revenues we charge you or other Users or to share in any goodwill or value created.
        </P>

        <P seq="1.5">
          <Cap>Privacy Policy</Cap>. Your access to and use of the Service is also subject to DELopen’s Privacy Policy located at <PrivacyUrl />.
        </P>

        <Header as="h2">2. COMMUNITY GUIDELINES</Header>

        <p>
          In addition to any other restrictions set forth in these Terms, and without limiting those restrictions, when using the Service, you agree not to (and not attempt to):
        </p>

        <P seq="2.1">
          delete, alter or make unauthorized copies of any content (whether DELopen Content (defined in Section 5.3), User Content (as defined in Section 6.1), or otherwise) made available on or through any part of the Service;
        </P>

        <P seq="2.2">
          attempt to decipher, decompile, disassemble or reverse engineer any of the software or source code comprising or making up any part of the Service, except to the extent any such restrictions are expressly prohibited by applicable law;
        </P>

        <P seq="2.3">
          rent, lease, loan, resell, sublicense, distribute or otherwise transfer any part of the Service or content thereon to any third party;
        </P>

        <P seq="2.4">
          remove, circumvent, disable, damage or otherwise interfere with security-related features of any part of the Service, features that prevent or restrict use or copying of any content accessible on any part of the Service, or features that enforce limitations on use of any part of the Service or any content thereon;
        </P>

        <P seq="2.5">
          delete any copyright or other proprietary rights notices on any part of the Service;
        </P>

        <P seq="2.6">
          impersonate any person or entity, falsely claim an affiliation with any person or entity, or access accounts of others without permission, forge another person’s digital signature, misrepresent the source, identify or content of information transmitted via the Service, or perform any other fraudulent activity;
        </P>

        <P seq="2.7">
          create a new Account (as defined in Section 3.1) with DELopen, without DELopen’s express written consent, if DELopen has previously disabled an Account of yours;
        </P>

        <P seq="2.8">
          restrict, discourage or inhibit any person from using the Service or disclose personal information about a third person on the Service or obtained from the Service without the consent of that person;
        </P>

        <P seq="2.9">
          use the Service, without DELopen’s express written consent, for any commercial purpose, including communicating or facilitating any commercial advertisement or solicitation or spamming;
        </P>

        <P seq="2.10">
          gain unauthorized access to the Service, to other Users’ Accounts, names or personally identifiable information, or to other computers or websites connected or linked to the Service;
        </P>

        <P seq="2.11">
          Post (as defined in Section 6.1) any virus, worm, spyware or any other computer code, file or program that may or is intended to disable, overburden, impair, damage or hijack the operation of any hardware, software or telecommunications equipment or any other aspect of the Service or communications equipment and computers connected to the Service;
        </P>

        <P seq="2.12">
          use manual or automated software or other means to access, “scrape,” “crawl” or “spider” any pages contained in the on the Service;
        </P>

        <P seq="2.13">
          access, tamper or interfere with or disrupt the Service, networks or servers connected to the Service or violate the regulations, policies or procedures of those networks or servers;
        </P>

        <P seq="2.14">
          violate any applicable federal, state or local laws or regulations or the terms of these Terms; or
        </P>

        <P seq="2.15">
          assist or encourage any person to engage in any of the foregoing.
        </P>

        <Header as="h2">3. ACCOUNTS</Header>

        <P seq="3.1">
          <Cap>Accounts</Cap>. While you may browse any public-facing portions of the Service without registering with DELopen, to access most features of the Service, you must register an account with DELopen (“<T>Account</T>”). When you register for an Account, you must provide us with some information about yourself such as your name, email address, or other contact information. When you register, you will be asked to provide a password and an account name. You may not select or use a name as an account name that is: (a) a name of another person with the intent to impersonate that person; or (b) a name subject to any rights of any person, entity, or organization other than yourself without appropriate authorization. As noted above, we have created different categories of Users of the Service and depending upon which category of User you are, your rights and the functionalities made available to you through your Account may differ. If you create an Account as a Provider on behalf of an entity or organization, you must provide your entity or organization’s name, address, and jurisdiction of formation, and all contact information provided in connection with your account will be made public on the Service. DELopen will generate a profile page (“<T>User Profile</T>”) for each User containing aggregated records of such User’s screening projects, including the number of accepted, completed and disputed projects.
        </P>

        <P seq="3.2">
          <Cap>Account Security</Cap>. You are responsible for the security of your Account, and are fully responsible for all activities that occur through the use of your credentials. You agree to notify DELopen immediately at <SupportMail /> if you suspect or know of any unauthorized use of your log-in credentials or any other breach of security with respect to your Account. DELopen will not be liable for any loss or damage (whether direct or indirect) arising from unauthorized use of your credentials prior to you notifying DELopen of such unauthorized use or loss of your credentials. Separate log-in credentials may be required to access External Services (as defined in Section 7).
        </P>

        <P seq="3.3">
          <Cap>Accuracy of Information</Cap>. When creating an Account, you will provide true, accurate, current, and complete information as DELopen requests. You will update such information promptly, and as necessary to keep it current and accurate. DELopen reserves the right to disallow, cancel, remove or reassign certain usernames and permalinks in appropriate circumstances, as determined by DELopen in its sole discretion, and may, with or without prior notice and without liability to you, suspend or terminate your Account if activities occur on your Account that, in DELopen’s sole discretion, would or might constitute a violation of these Terms, cause damage to or impair the Service, infringe or violate any third party rights, damage or bring into disrepute the reputation of DELopen, or violate any applicable laws or regulations. If messages sent to the e-mail address you provide are returned as undeliverable, then DELopen may terminate your Account immediately without notice to you and without any liability to you or any third party.
        </P>

        <Header as="h2">4. HIT SCREENING PROJECTS</Header>

        <P seq="4.1">
          <Cap>Definitions</Cap>. In addition to all terms defined elsewhere in these Terms, the following terms have the meanings set out below:
        </P>

        <Q>
          <p>
            “<T>Affinity Results</T>” means the summary report generated from the Decoding Experiment, including a selection profile of the potential hits generated from the experiment without their chemical structures.
          </p>

          <p>
            “<T>Decoding Experiments</T>” means next-generation sequencing experiments to perform informatics analysis on and decode the chemical structures of the Research Product.
          </p>

          <p>
            “<T>Kit</T>” means a compound library made available by Provider.
          </p>

          <p>
            “<T>Research</T>” means DNA sequencing and affinity selection experiments conducted on the Kits.
          </p>

          <p>
            “<T>Research Product</T>” means the chemical substances that constitute selection output generated or arising from the Research, contained in Eppendorf tubes or otherwise, but, in each case, excluding the Kits.
          </p>
        </Q>

        <P seq="4.2">
          <Cap>Posting Kit Descriptions</Cap>. Providers may Post descriptions of Kits to the Service (each such description a “<T>Kit Description</T>”). Each Kit Description must include at least the following information: (a) a description of the Kit, including name and identification information assigned by the Provider for internal identification purposes; (b) a description of the Research Product requested, including the minimum quantity of substances required for the Provider to conduct a Decoding Experiment; and (c) the Provider’s contact information. The Provider may, in its sole discretion, provide additional information regarding the chemical structures contained in the Kits to other Users beyond Researchers. Each Kit Description will be made available to all other Users, and by Posting a Kit Description, you authorize DELopen to make your Kit Description available on the Service. Nothing in these Terms will prevent Provider from providing the Kits (or compounds contained in any such Kit) to other parties (including publishing the Kit to a third party service) or from pursuing any business opportunities independent of any Users or the Service. THE KITS AND KIT DESCRIPTIONS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND. ACCORDINGLY, EACH PROVIDER DISCLAIMS ALL WARRANTIES THERETO, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
        </P>

        <P seq="4.3">
          <Cap>User Engagement</Cap>; Research Applications. Users may browse Kit Descriptions and Post comments or questions on each Kit Description and the Providers may Post responses to such comments or questions. Any comments, questions, or responses Posted will be made available to all other Users, and by Posting any comments, questions or responses, you authorize DELopen to make the same available on the Service. Researchers may submit an application to conduct Research on one or more Kits (“<T>Research Application</T>”). To submit a Research Application, you must follow any instructions listed in the applicable Kit Description and provide all required information through the available functionality of the Service, including, but not limited to: (a) your name; (b) your contact information; (c) quantity of Kits required to conduct Research; and (d) if you are applying on behalf of an entity or organization, (i) name of such entity or organization, (ii) jurisdiction of formation of such organization; and (iii) address of organization.
        </P>

        <P seq="4.4">
          <Cap>Screening Contract Offer Generation</Cap>. If a Researcher submits a Research Application, then the Service will generate a screening contract through the available functionality of the Service that incorporates the information and requirements from the applicable Kit Description and Research Application (“<T>Screening Contract Offer</T>”). Pursuant to the terms of the Screening Contract Offer, (a) the Provider will deliver the Kit to the Researcher; (b) the Researcher will conduct Research, and deliver Research Products to the Provider; and (c) the Provider will conduct Decoding Experiments and deliver Affinity Results to the Researcher. The Researcher may review the Screening Contract Offer for accuracy before submitting the Screening Contract Offer to the Provider through the Service. By submitting the Screening Contract Offer through the Service, you authorize DELopen to share the contents of your Research Application, as incorporated into the Screening Contract Offer with the Provider.
        </P>

        <P seq="4.5">
          <Cap>Consent to Electronic Communications</Cap>. By Posting a Kit Description or submitting a Screening Contract Offer or Research Application and providing your contact information, you agree that we or other Users of the Service may communicate with you electronically. For example, we or other Users may contact you to confirm receipt of your Kit Description or Research Application, to communicate the status of your Research Application, or to request additional information.
        </P>

        <P seq="4.6">
          <Cap>Acceptance of the Screening Contract Offer</Cap>. The Provider may accept or reject the Research Application within 30 days of receipt and the Provider and Researcher will each have access to view the Research Application through the Service during that 30-day period. If the Provider does not accept the Research Application within the 30-day period, then the Research Application will be deemed rejected and the Provider and Researcher will no longer have access to the Research Application. The Researcher may revoke the Screening Contract Offer through the available functionality of the Service within the Researcher’s Account settings at any time prior to the Provider’s acceptance. A Provider reserves the right to accept or reject a Screening Contract Offer for any reason. If the Screening Contract Offer must be revised before the Provider accepts the same, then the Researcher must revoke the Screening Contract Offer and submit a new Research Application. Upon the Provider’s acceptance of the Screening Contract Offer through the Service, (a) a binding contractual agreement between the Provider and the Researcher who has submitted the said Screening Contract Offer, and only between those parties, will come into force (“<T>Screening Contract</T>”), and (b) DELopen will deliver a copy of the Screening Contract to the Provider and the Researcher. DELopen may store the Screening Contract in connection with the policies further described in DELopen’s Privacy Policy located at <PrivacyUrl />. DELopen recommends that you save copies of any Screening Contracts that you enter into on your personal device(s) or other storage media to ensure that you have permanent access to copies of such Screening Contracts.
        </P>

        <P seq="4.7">
          <Cap>Completion of Screening Contract</Cap>. Both the Researcher and the Provider are responsible for reporting the completion of all activities contemplated by the Screening Contract or any termination of a Screening Contract to DELopen. If one party to the Screening Contract reports the completion of a Screening Contract, but the other party does not report the completion of such Screening Contract within 30 days, then DELopen will consider the Screening Contract as disputed by both Users. DELopen will publicly disclose the number of disputed collaborations each User has engaged in on each User’s User Profile, without disclosing the details of such Screening Contract and without judgment on the merits of such disputes.
        </P>

        <P seq="4.8">
          <Cap>Private Content</Cap>. By default, the content of any Screening Contract will be private and only made available to the parties to such Screening Contract, unless such information has already been provided by such parties publicly through the Service or through an External Service. DELopen will not disclose the content of any Screening Contract to third parties, unless you designate such content for public access through the Service. DELopen may only provide its employees or representatives with access to any private content contained in the Screening Contract if: (a) any employees or representatives require access to provide you support in response to your support requests; (b) access is required to maintain the security or integrity of the Service; or (c) we have reason to believe that any of your content violates any laws, regulations or these Terms and DELopen reserves the right to access, review and remove any such content.
        </P>

        <P seq="4.9">
          <Cap>Permitted Data</Cap>. A Researcher may enter into a separate written agreement with a Provider that grants to that Provider the right to Post certain Researcher data and/or materials (“<T>Permitted Data</T>”) on the Service (any such Researcher, a “<T>Researcher-Licensor</T>”). In this case, effective upon such Provider so Posting the Permitted Data to the Service through the available functionality, Researcher-Licensor hereby grants to all other Researchers a non-exclusive, non-transferable, sublicensable (through multiple tiers), perpetual, irrevocable, royalty-free and worldwide right to use and exploit such Permitted Data without limitation or restriction or any fees or credit due to the Researcher-Licensor.
        </P>

        <Header as="h2">5. GENERAL USE OF THE SERVICE</Header>

        <P seq="5.1">
          <Cap>License</Cap>. Subject to your complete and ongoing compliance with these Terms, DELopen grants you, solely for your personal, non-commercial use, a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to access and use the Service.
        </P>

        <P seq="5.2">
          <Cap>License Restrictions</Cap>. Except and solely to the extent such a restriction is impermissible under applicable law, you may not: (a) reproduce, distribute, publicly display, or publicly perform the Service; (b) make modifications to the Service; or (c) interfere with or circumvent any feature of the Service, including any security or access control mechanism. If you are prohibited under applicable law from using the Service, you may not use it.
        </P>

        <P seq="5.3">
          <Cap>Content</Cap>. Except for any third party content linked to from the Service, the content that DELopen provides to Users on or through the Service (including, without limitation, text, graphics, photos, software and interactive features) are protected by copyright or other intellectual property rights and owned by DELopen or its third party licensors (collectively, the “<T>DELopen Content</T>”). Moreover, DELopen solely owns all design rights, databases and compilation and other intellectual property rights in and to the Service, in each case whether registered or unregistered, and any related goodwill.
        </P>

        <P seq="5.4">
          <Cap>Marks</Cap>. The DELopen trademarks, service marks, and logos (collectively, the “<T>DELopen Trademarks</T>”) used and displayed on the Service are DELopen’s registered and/or unregistered trademarks or service marks. Any other product and service names located on any part the Service may be trademarks or service marks owned by third parties (collectively with the DELopen Trademarks, the “<T>Trademarks</T>”). Except as otherwise permitted by law, you may not use the Trademarks to disparage DELopen or the applicable third party, DELopen’s or a third party’s products or services, or in any manner (using commercially reasonable judgment) that may damage any goodwill in the Trademarks. You may not use any Trademarks as part of a link to or from any website without DELopen’s prior express written consent. All goodwill generated from the use of any DELopen Trademark will inure solely to DELopen’s benefit.
        </P>

        <P seq="5.5">
          <Cap>Reservation of Rights</Cap>. DELopen hereby reserves all rights not expressly granted to you in this Section 5. Accordingly, nothing in these Terms or on the Service will be construed as granting to you, by implication, estoppel or otherwise, any additional license rights in and to the Service or any DELopen Content or Trademarks located or displayed therein.
        </P>

        <Header as="h2">6. USER CONTENT; LICENSE TO DELOPEN; FEEDBACK</Header>

        <P seq="6.1">
          <Cap>Definition</Cap>. This Section 6 governs any content that Users upload, post, or transmit to or through the Service (collectively, “<T>Post</T>”) including, without limitation, Kit Descriptions, Research Applications, comments, questions, concepts, ideas, inventions, messages, text, and any other works subject to protection under the laws of the United States or any other jurisdiction, including, without limitation, patent, trademark, trade secret and copyright laws (“<T>User Content</T>”).
        </P>

        <P seq="6.2">
          <Cap>Screening Content</Cap>. DELopen does not pre-screen any User Content, but reserves the right to remove, disallow, block or delete any User Content in its sole discretion. In addition, DELopen has the right – but not the obligation – in its sole discretion to remove, disallow, block or delete any User Content: (a) that DELopen considers to violate these Terms, applicable law or otherwise constitute Objectionable Content (as defined in Section 6.8); or (b) in response to complaints from other Users, licensors or copyright owners or their agents, with or without notice and without any liability to you. Without limiting the preceding sentences of this Section, DELopen also has the right – but not the obligation – to take remedial action in connection with any Objectionable Content Posted to the Service as described more fully in Section 6.8 below. DELopen recommends that you save copies of any User Content that you Post on your personal device(s) or other storage media to ensure that you have permanent access to copies of such User Content.
        </P>

        <P seq="6.3">
          <Cap>Ownership</Cap>. SUBJECT TO ANY THIRD PARTY RIGHTS IN ANY PREEXISTING CONTENT THAT IS A PART OF YOUR USER CONTENT, YOU RETAIN OWNERSHIP OF ANY RIGHTS YOU MAY HAVE IN YOUR USER CONTENT AND SUBMITTING YOUR USER CONTENT DOES NOT TRANSFER OWNERSHIP OF YOUR RIGHTS TO DELOPEN OR ANY THIRD PARTY.
        </P>

        <P seq="6.4">
          <Cap>License to DELopen</Cap>. You hereby grant DELopen a non-exclusive, assignable, sublicensable, revocable (except as set forth below), royalty-free, fully paid-up license throughout the universe to reproduce, distribute, publicly display, communicate to the public, publicly perform, make available, create derivative works from, retransmit from External Services, and otherwise exploit and use (collectively, “<T>Use</T>”) all or any part of all User Content you Post by any means and through any media and formats, whether now known or hereafter developed, for the purposes of: (a) advertising, marketing, and promoting DELopen, the Service, and the availability of your User Content on the Service; (b) displaying and sharing your User Content to other Users of the Service; and (c) providing the Service as authorized by these Terms. You further grant DELopen a royalty-free license to use your name, image, voice and likeness to identify you as the source of any of your User Content. Following any termination of the licenses set forth in this Section, DELopen may make and retain archival copies of your User Content in a manner consistent with DELopen’s practices regarding data retention and backups. Notwithstanding any other provision in these Terms, the licenses granted in this Section 6.4 are perpetual and irrevocable with respect to any of your User Content that DELopen sublicenses to any third party prior to the effective date of termination of your license grant with respect to any item of your User Content. This means that, content you Post to the Service may live on the Internet in perpetuity, and you should think carefully before Posting any User Content to the Service.
        </P>

        <P seq="6.5">
          <Cap>You Must Have Rights to the User Content You Submit; Certain of Your Representations and Warranties</Cap>. You must not Post any User Content if you are not the owner of or are not fully authorized to grant rights in all of the elements of the User Content you intend to Post. You represent and warrant that: (a) you own the User Content Posted by you or otherwise have all rights necessary to grant the license set forth in these Terms; (b) the Posting and Use of your User Content does not violate the privacy rights, publicity rights, copyrights, trademark rights, patent rights, trade secret rights, contract rights, or any other rights of any party, including, but not limited to, the rights of any person visible in any of your User Content; (c) the Posting of your User Content will not require us to obtain any further licenses from or pay any royalties, fees, compensation or other amounts or provide any attribution to any third parties; and (d) the Posting of your User Content does not result in a breach of contract between you and a third party. You agree to pay all monies owing to any person resulting from submitting your User Content, including from DELopen’s exercise of the license set forth in Section 6.4.
        </P>

        <P seq="6.6">
          <Cap>Waiver of Rights to User Content</Cap>. By Posting User Content, you waive any and all rights of privacy, publicity or any other rights of a similar nature in connection with your User Content, or any portion thereof. To the extent any moral rights are not transferable or assignable, you hereby waive and agree never to assert any and all moral rights, or to support, maintain or permit any action based on any moral rights that you may have in or with respect to any User Content you Post.
        </P>

        <P seq="6.7">
          <Cap>Non-Confidentiality</Cap>. You must not Post any User Content on or through the Service or transmit to DELopen any User Content that you consider to be confidential or proprietary. Subject to Section 4.8, User Content Posted by you or otherwise transmitted to DELopen will be considered non-confidential and non-proprietary, and treated as such by DELopen, and may be used by DELopen in accordance with these Terms without notice to you and without any liability to DELopen.
        </P>

        <P seq="6.8">
          <Cap>Objectionable Content</Cap>. You are not permitted to and agree not to Post any User Content that is or could be interpreted to be, as DELopen may determine in its sole discretion, (a) abusive, bullying, defamatory, harassing, harmful, hateful, inaccurate, infringing, libelous, objectionable, obscene, offensive, pornographic, shocking, threatening, unlawful, violent, vulgar or in violation of any applicable laws; or (b) promoting any product, good or service, or bigotry, discrimination, hatred, intolerance, racism or inciting violence ((a) and (b) collectively, “<T>Objectionable Content</T>”). The Posting of any Objectionable Content may subject you to third party claims and none of the rights granted to you in these Terms may be raised as a defense against any third party claims arising from your Posting of Objectionable Content. You also agree not to use the Service for illegal or unlawful purposes. DELopen reserves the right to remove, disallow, block or delete any User Content in its sole discretion. DELopen in its sole discretion may take any actions it deems necessary and/or appropriate against any User who submits Objectionable Content, including, without limitation, warning the User, suspending or terminating the User’s Account, removing some or all of the User’s User Content and/or reporting the User to law enforcement authorities, either directly or indirectly.
        </P>

        <P seq="6.9">
          <Cap>No Liability</Cap>. For the avoidance of doubt, DELopen will not be liable for any use or misuse of User Content by any User.
        </P>

        <P seq="6.10">
          <Cap>Feedback</Cap>. If you choose to provide DELopen with input or suggestions regarding problems with or proposed modifications or improvements to the Service (“<T>Feedback</T>”), then you hereby grant to DELopen a perpetual, irrevocable, non-exclusive, fully-paid and royalty-free right to use and exploit the Feedback in any manner and for any purpose without any restriction, credit, attribution or fees due to you.
        </P>

        <Header as="h2">7. EXTERNAL SERVICES</Header>

        <p>
          The Service may contain links to or the ability to share information with third party websites and services (“<T>External Services</T>”). DELopen does not endorse any External Services or the content made available on such External Services. All External Services and any content thereon is developed and provided by others. You should contact the site administrator or webmaster for those External Services if you have any concerns regarding such content located on such External Services. DELopen is not responsible for the content of any External Services and does not make any representations regarding the content or accuracy of any materials on such External Services. You should take precautions when downloading files from all websites to protect your computer and mobile devices from viruses and other destructive programs. If you decide to access any External Services, purchase any content from External Services or subscribe to services offered by such External Service, then you do so at your own risk. You agree that DELopen will have no liability to you arising from your use, engagement, exposure to or interaction with any External Services.
        </p>

        <Header as="h2">8. TERM AND TERMINATION</Header>

        <P seq="8.1">
          <Cap>Term</Cap>. The term of these Terms commences as of your first use of the Service and continues until the termination of these Terms by either you or DELopen. If you are a party to any Screening Contracts upon termination of these Terms, such Screening Contracts will remain in effect until terminated in accordance with the terms of the applicable Screening Contract.
        </P>

        <P seq="8.2">
          <Cap>Termination</Cap>. You may terminate these Terms by deleting your Account through your Account settings and/or ceasing your use of the Service. DELopen reserves the right, in its sole discretion, to restrict, suspend your access to all or any part of the Service at any time without prior notice or liability if you breach any provision of these Terms. DELopen may further terminate these Terms immediately for any other reason with or without notice to you, using the email address associated with your account credentials. DELopen reserves the right to change, suspend or discontinue all or any part of the Service at any time without prior notice or liability.
        </P>

        <P seq="8.3">
          <Cap>Survival</Cap>. Sections 1.2, 1.3, 1.4, 2, 3.2, 3.3, 4.1, 4.2, 4.5, 4.6, 4.7, 4.8, 4.9, 5.2, 5.3, 5.4, 5.5, 6, 7, 8, 10, 11, 12, 13, 14, and 15 and all defined terms used therein will survive the termination of these Terms indefinitely.
        </P>

        <Header as="h2">9. INTELLECTUAL PROPERTY POLICY</Header>

        <P seq="9.1">
          <Cap>General</Cap>. If you have an intellectual property rights-related complaint about material posted on the Service, you may contact our Designated Agent at the following address:
        </P>

        <Address>
          <Tag>Via Email</Tag>
          <Addr><SupportMail /></Addr>

          <Tag>Via U.S. Mail</Tag>
          <Addr>
            DELopen Limited<br />
            70 Sir John Rogerson’s Quay<br />
            Dublin 2<br />
            D02 R296<br />
            Attn: Legal Department
          </Addr>
        </Address>

        <p>
          Any notice alleging that materials hosted by or distributed through the Service infringe intellectual property rights must include substantially the following:
        </p>

        <Q>
          <p>an electronic or physical signature of the person authorized to act on behalf of the owner of the right being infringed;</p>
          <p>a description of the intellectual property that you claim has been infringed;</p>
          <p>a description of the material that you claim is infringing and where it is located on the Service;</p>
          <p>your address, telephone number, and email address;</p>
          <p>a statement by you that you have a good faith belief that the use of the materials on the Service of which you are complaining is not authorized by the intellectual property owner, its agent, or the law; and</p>
          <p>a statement by you that the above information in your notice is accurate and that, under penalty of perjury, you are the intellectual property owner or authorized to act on the intellectual property owner’s behalf.</p>
        </Q>

        <P seq="9.2">
          <Cap>Repeat Infringers</Cap>. DELopen’s intellectual property policy is to: (a) remove or disable access to material that DELopen believes in good faith, upon notice from an intellectual property rights owner or his or her agent, is infringing the intellectual property rights of a third party by being made available through the Service; and (b) in appropriate circumstances, to terminate the Accounts of and block access to the Service by User who repeatedly or egregiously infringe other people’s intellectual property rights.
        </P>

        <Header as="h2">10. LIMITATIONS ON LIABILITY AND WARRANTY DISCLAIMER</Header>

        <p>
          THE FOLLOWING TERMS IN THIS SECTION 10 APPLY TO THE FULLEST EXTENT PERMITTED BY LAW:
        </p>

        <P seq="10.1">
          NEITHER DELOPEN NOR ITS CURRENT OR FUTURE AFFILIATES (COLLECTIVELY, THE “DELOPEN PARTIES”) MAKE ANY WARRANTIES OR REPRESENTATIONS ABOUT THE SERVICE AND ANY CONTENT AVAILABLE ON THE SERVICE, INCLUDING, BUT NOT LIMITED TO, THE ACCURACY, COMPLETENESS, APPROPRIATENESS, TIMELINESS OR RELIABILITY THEREOF. THE DELOPEN PARTIES WILL NOT BE SUBJECT TO LIABILITY FOR THE TRUTHFULNESS, ACCURACY OR COMPLETENESS OF ANY CONTENT ON THE SERVICE, OR FOR ERRORS, MISTAKES OR OMISSIONS THEREIN, OR FOR ANY DELAYS OR INTERRUPTIONS OF THE DATA, OR INFORMATION STREAMED ON THE SERVICE FROM WHATEVER CAUSE. AS A USER, YOU AGREE THAT YOU USE THE SERVICE AND ANY CONTENT THEREON AT YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR ALL CONTENT YOU UPLOAD TO THE SERVICE.
        </P>

        <P seq="10.2">
          THE DELOPEN PARTIES DO NOT WARRANT THAT THE SERVICE WILL OPERATE ERROR FREE, OR THAT THE SERVICE AND ANY CONTENT THEREON ARE FREE OF COMPUTER VIRUSES OR SIMILAR CONTAMINATION OR DESTRUCTIVE FEATURES. IF YOUR USE OF THE SERVICE OR ANY CONTENT THEREON RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, NO DELOPEN PARTY WILL BE RESPONSIBLE FOR THOSE COSTS.
        </P>

        <P seq="10.3">
          THE SERVICE AND ALL CONTENT THEREON ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND. ACCORDINGLY, THE DELOPEN PARTIES DISCLAIM ALL WARRANTIES THERETO, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES’ RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE.
        </P>

        <P seq="10.4">
          DELOPEN WILL NOT ASSESS THE SUITABILITY, LEGALITY OR ABILITY OF ANY PROVIDERS OR RESEARCHERS AND YOU EXPRESSLY WAIVE AND RELEASE DELOPEN FROM ANY AND ALL LIABILITY, CLAIMS OR DAMAGES ARISING FROM OR IN ANY WAY RELATED TO THE RESEARCHERS AND PROVIDERS THAT YOU CONTRACT WITH VIA THE SERVICE. DELOPEN WILL NOT BE A PARTY TO DISPUTES OR NEGOTIATIONS OF DISPUTES, BETWEEN YOU AND ANY RESEARCHERS OR PROVIDERS. RESPONSIBILITY FOR THE DECISIONS YOU MAKE REGARDING RESEARCH SERVICES OFFERED VIA THE SERVICE (WITH ALL THEIR IMPLICATIONS) RESTS SOLELY WITH YOU. YOU EXPRESSLY WAIVE AND RELEASE DELOPEN FROM ANY AND ALL LIABILITY, CLAIMS, CAUSES OF ACTION, OR DAMAGES ARISING FROM YOUR USE OF THE SERVICE, OR IN ANY WAY RELATED TO THE THIRD PARTIES INTRODUCED TO YOU BY THE SERVICE.
        </P>

        <P seq="10.5">
          THE QUALITY OF THE RESEARCH SERVICES CONTRACTED FOR THROUGH THE USE OF THE SERVICE IS ENTIRELY THE RESPONSIBILITY OF THE THIRD PARTY WHO ULTIMATELY PROVIDES RESEARCH SERVICES TO YOU. YOU UNDERSTAND THAT BY USING THE SERVICE, YOU USE THE SERVICE AT YOUR OWN RISK.
        </P>

        <P seq="10.6">
          YOU ACKNOWLEDGE THAT (A) THE GENERATION AND PROVISION OF SCREENING CONTRACTS ON THE SERVICE DOES NOT CONSTITUTE LEGAL ADVICE, (B) IS NOT A SUBSTITUTE FOR ADVICE FROM QUALIFIED LEGAL COUNSEL, (C) DOES NOT CONSTITUTE THE PRACTICE OF LAW, AND (D) YOU SOLELY ASSUME ALL RISK OF USING SCREENING CONTRACTS AND WE WILL HAVE NO LIABILITY TO YOU WHATSOEVER ARISING THEREFROM.
        </P>

        <P seq="10.7">
          IN NO EVENT WILL ANY DELOPEN PARTY BE LIABLE FOR ANY SPECIAL, INDIRECT, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, LOST PROFITS OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION RESULTING FROM, OR IN CONNECTION WITH, THE USE OR INABILITY TO USE THE SERVICE AND ANY CONTENT THEREON, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF SUCH DELOPEN PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. DELOPEN’S LIABILITY, AND THE LIABILITY OF ANY OTHER DELOPEN PARTIES, TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS LIMITED TO U.S. $100.
        </P>

        <Header as="h2">11. DISPUTE RESOLUTION</Header>

        <P seq="11.1">
          <Cap>Generally</Cap>. In the interest of resolving disputes between you and DELopen in the most expedient and cost effective manner, and except as described in Section 11.2 and 11.3, you and DELopen agree that every dispute arising in connection with these Terms will be resolved by binding arbitration. Arbitration is less formal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, may allow for more limited discovery than in court, and can be subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award. This agreement to arbitrate disputes includes all claims arising out of or relating to any aspect of these Terms, whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and regardless of whether a claim arises during or after the termination of these Terms. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND DELOPEN ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
        </P>

        <P seq="11.2">
          <Cap>Exceptions</Cap>. Despite the provisions of Section 11.1, nothing in these Terms will be deemed to waive, preclude, or otherwise limit the right of either party to: (a) bring an individual action in small claims court; (b) pursue an enforcement action through the applicable federal, state, or local agency if that action is available; (c) seek injunctive relief in a court of law in aid of arbitration; or (d) to file suit in a court of law to address an intellectual property infringement claim.
        </P>

        <P seq="11.3">
          <Cap>Opt-Out</Cap>. If you do not wish to resolve disputes by binding arbitration, you may opt out of the provisions of this Section 11 within 30 days after the date that you agree to these Terms by sending a letter to DELopen Limited, Attention: Legal Department – Arbitration Opt-Out, 70 Sir John Rogerson’s Quay, Dublin 2, D02 R296 that specifies: your full legal name, the email address associated with your account on the Service, and a statement that you wish to opt out of arbitration (“<T>Opt-Out Notice</T>”). Once DELopen receives your Opt-Out Notice, this Section 11 will be void and any action arising out of these Terms will be resolved as set forth in Section 12. The remaining provisions of these Terms will not be affected by your Opt-Out Notice.
        </P>

        <P seq="11.4">
          <Cap>Arbitrator</Cap>. Any arbitration between you and DELopen will be settled under the Federal Arbitration Act and administered by the American Arbitration Association (“<T>AAA</T>”) under its Consumer Arbitration Rules (collectively, “<T>AAA Rules</T>”) as modified by these Terms. The AAA Rules and filing forms are available online at www.adr.org, by calling the AAA at 1-800-778-7879, or by contacting DELopen. The arbitrator has exclusive authority to resolve any dispute relating to the interpretation, applicability, or enforceability of this binding arbitration agreement.
        </P>

        <P seq="11.5">
          <Cap>Notice of Arbitration; Process</Cap>. A party who intends to seek arbitration must first send a written notice of the dispute to the other party by certified U.S. Mail or by Federal Express (signature required) or, only if that other party has not provided a current physical address, then by electronic mail (“<T>Notice of Arbitration</T>”). DELopen’s address for Notice of Arbitration is: DELopen Limited, 70 Sir John Rogerson’s Quay, Dublin 2, D02 R296. The Notice of Arbitration must: (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought (“<T>Demand</T>”). The parties will make good faith efforts to resolve the claim directly, but if the parties do not reach an agreement to do so within 30 days after the Notice of Arbitration is received, you or DELopen may commence an arbitration proceeding. All arbitration proceedings between the parties will be confidential unless otherwise agreed by the parties in writing. During the arbitration, the amount of any settlement offer made by you or DELopen must not be disclosed to the arbitrator until after the arbitrator makes a final decision and award, if any. If the arbitrator awards you an amount higher than the last written settlement amount offered by DELopen in settlement of the dispute prior to the award, DELopen will pay to you the higher of: (x) the amount awarded by the arbitrator; or (y) $10,000.
        </P>

        <P seq="11.6">
          <Cap>Fees</Cap>. If you commence arbitration in accordance with these Terms, DELopen will reimburse you for your payment of the filing fee, unless your claim is for more than $10,000, in which case the payment of any fees will be decided by the AAA Rules. Any arbitration hearing will take place at a location to be agreed upon in New York County, New York, but if the claim is for $10,000 or less, you may choose whether the arbitration will be conducted: (a) solely on the basis of documents submitted to the arbitrator; (b) through a non-appearance based telephone hearing; or (c) by an in-person hearing as established by the AAA Rules in the county (or parish) of your billing address. If the arbitrator finds that either the substance of your claim or the relief sought in the Demand is frivolous or brought for an improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment of all fees will be governed by the AAA Rules. In that case, you agree to reimburse DELopen for all monies previously disbursed by it that are otherwise your obligation to pay under the AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator must issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the decision and award, if any, are based. The arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees or expenses at any time during the proceeding and upon request from either party made within 14 days of the arbitrator’s ruling on the merits.
        </P>

        <P seq="11.7">
          <Cap>No Class Actions</Cap>. YOU AND DELOPEN AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and DELopen agree otherwise, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of a representative or class proceeding.
        </P>

        <P seq="11.8">
          <Cap>Modifications to this Arbitration Provision</Cap>. If DELopen makes any future change to this arbitration provision, other than a change to DELopen’s address for Notice of Arbitration, you may reject the change by sending us written notice within 30 days of the change to DELopen’s address for Notice of Arbitration, in which case your account with DELopen will be immediately terminated and this arbitration provision, as in effect immediately prior to the changes you rejected will survive.
        </P>

        <P seq="11.9">
          <Cap>Enforceability</Cap>. If Section 11.7 is found to be unenforceable or if the entirety of this Section 11 is found to be unenforceable, or if DELopen receives an Opt-Out Notice from you, then the entirety of this Section 11 will be null and void and, in that case, the parties agree that the exclusive jurisdiction and venue described in Section 12 will govern any action arising out of or related to these Terms.
        </P>

        <Header as="h2">12. GOVERNING LAW; CHOICE OF FORUM</Header>

        <p>
          The laws of the State of New York, excluding its conflicts of law rules, govern these Terms and your use of the Service. Your use of the Service may also be subject to other local, state, national, or international laws; provided, however, that the United Nations Convention on Contracts for the International Sale of Goods will not apply to any provision of these Terms. To the extent that any action relating to any dispute hereunder is permitted to be brought in a court of law, such action will be subject to the exclusive jurisdiction of the state and federal courts located in or having jurisdiction over New York County, New York and you hereby irrevocably submit to personal jurisdiction in such courts, and waive any defense of inconvenient forum.
        </p>

        <Header as="h2">13. INDEMNITY</Header>

        <p>
          To the fullest extent permitted by law, you agree to defend, indemnify and hold harmless the DELopen Parties from and against any claims, actions or demands, including, without limitation, reasonable legal and accounting fees, arising or resulting from: (1) your breach of these Terms; (2) your access to, use or misuse of DELopen Content or the Service; or (3) your User Content. DELopen will provide notice to you of any such claim, suit or proceeding. DELopen reserves the right to assume the exclusive defense and control of any matter which is subject to indemnification under this Section if DELopen believes that you are unwilling or incapable of defending DELopen’s interests. In such case, you agree to cooperate with any reasonable requests assisting DELopen’s defense of such matter at your expense.
        </p>

        <Header as="h2">14. CONSENT TO ELECTRONIC COMMUNICATIONS</Header>

        <p>
          By using the Service, you consent to receiving certain electronic communications from DELopen as further described in the <PrivacyPolicy />. Please read the <PrivacyPolicy /> to learn more about your choices regarding DELopen’s electronic communications practices. You agree that any notices, agreements, disclosures or other communications that DELopen sends to you electronically will satisfy any legal communication requirements, including that such communications be in writing.
        </p>

        <Header as="h2">15. GENERAL</Header>

        <P seq="15.1">
          <Cap>Miscellaneous</Cap>. You agree that no joint venture, partnership, employment or agency relationship exists between you and DELopen as a result of these Terms or use of the Service. If any provision of these Terms is found to be invalid by any court having competent jurisdiction, the invalidity of such provision will not affect the validity of the remaining provisions of these Terms, which will remain in full force and effect. Failure of DELopen to act on or enforce any provision of these Terms will not be construed as a waiver of that provision or any other provision in these Terms. No waiver will be effective against DELopen unless made in writing, and no such waiver will be construed as a waiver in any other or subsequent instance. Except as expressly agreed by DELopen and you, these Terms constitute the entire agreement between you and DELopen with respect to the subject matter hereof, and supersedes all previous or contemporaneous agreements, whether written or oral, between the parties with respect to the subject matter herein. The Section headings are provided merely for convenience and will not be given any legal import. These Terms will inure to the benefit of DELopen’s successors and assigns. You may not assign these Terms or any of the rights or licenses granted hereunder, directly or indirectly, without the prior express written consent of DELopen. DELopen may assign these Terms, including all its rights hereunder, ¯without restriction.
        </P>

        <P seq="15.2">
          <Cap>Contact Us</Cap>. If you would like to contact DELopen in connection with your use of the Service, then please refer to the contact information below: by mail at 70 Sir John Rogerson’s Quay, Dublin 2, D02 R296, and by email at <SupportMail />.
        </P>
      </Page>
    );
  }

}

export default styled(Terms)`
  p {
    text-indent: 2em;
  }
`;

const P = (props: PropsWithChildren<{ seq: string; }>) => (
  <p id={props.seq}>
    <Seq>{props.seq}</Seq>
    {props.children}
  </p>
);

const Seq = styled.span`
  margin-right: .5em;
`;

const Q = styled.div`
  margin-left: 5em;
  counter-reset: q;

  p {
    counter-increment: q;
    text-indent: 0;

    &::before {
      content: counter(q, lower-alpha) ".";
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
`;
