import { ISubscriberBlueprint, Subscriber } from '../models/Subscriber';
import { SubscriberForm } from '../models/SubscriberForm';

import axios, { AxiosInstance } from 'axios';
import * as React from 'react';
import { ScoopRepository } from './ScoopRepository';

export class SecurityRepository {

  public static readonly $ = new SecurityRepository(axios, ScoopRepository.$);
  public static readonly Context = React.createContext(SecurityRepository.$);

  public static isStrongPassword(password: string) {
    // eslint-disable-next-line
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&*(){}\[\]:;"'<,.>?\/\\|_+=-]).{8,20}$/.test(password);
  }

  constructor(private a: AxiosInstance, private sr: ScoopRepository) {
  }

  public async login(email: string, password: string): Promise<Subscriber> {
    const { data } = await this.a.post<ISubscriberBlueprint>('/login', { _username: email.toLowerCase(), _password: password });

    return Subscriber.build(data);
  }

  public async logout(): Promise<void> {
    await this.a.get('/logout');
  }

  public async password(password: string, proof: string): Promise<Subscriber> {
    const { data } = await this.a.post<ISubscriberBlueprint>('/password', { password: { password, proof } });

    return Subscriber.build(data);
  }

  public async changePassword(password: string, old: string): Promise<Subscriber> {
    const { data } = await this.a.post<ISubscriberBlueprint>('/change-password', { password_change: { password, old } });

    return Subscriber.build(data);
  }

  public async register(subscriber: SubscriberForm, locale: string, source?: string): Promise<void> {
    await this.a.post(`/${locale}/register`, { subscriber: Object.assign(subscriber.dispose(), {source: source}) });
  }

  public async reset(email: string, locale: string): Promise<void> {
    await this.a.post(`/${locale}/reset`, { reset: { subscriber: email.toLowerCase() } });
  }

  public async invite(email: string, locale: string): Promise<void> {
    await this.a.post(`/${locale}/invitations`, { form: { emails: [email.toLowerCase()] } });
  }

  public async whoami(): Promise<Subscriber> {
    const { data } = await this.a.get<ISubscriberBlueprint>('/login');

    return Subscriber.build(data);
  }

  public async persist(subscriber: SubscriberForm): Promise<Subscriber> {
    const portrait = subscriber.portrait && await this.sr.persist(subscriber.portrait);
    const { data } = await this.a.put<ISubscriberBlueprint>('/profile', { subscriber: subscriber.patch('portrait', portrait).dispose() });

    return Subscriber.build(data);
  }

  async changeNickname(nickname: string): Promise<Subscriber> {
    const { data } = await this.a.put<ISubscriberBlueprint>('/nickname', { form: { nickname } });

    return Subscriber.build(data);
  }

  public changeLocale(locale: string) {
    this.a.put('/locale', { locale: { languageTag: locale } });
  }

  public async requestAccess(subscriber: SubscriberForm, locale: string): Promise<void> {
    const { email, firstName, lastName, country, institution, principalInvestigator } = subscriber.dispose();
    await this.a.post(`/${locale}/requisitions`, { requisition: { email, firstName, lastName, country, institution, principalInvestigator } });
  }

}
