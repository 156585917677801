import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Form, InputOnChangeData, Message } from 'semantic-ui-react';
import styled from 'styled-components';

import { SecurityRepository } from '../../repositories/SecurityRepository';

import invitation from './invitation.png';

type P = InjectedIntlProps & {
  className?: string;
};

interface IInvitationState {
  email: string;
  loading: boolean;
  sent: boolean;
}

class Invitation extends React.Component<P & { repo: SecurityRepository }, IInvitationState> {

  public state = { email: '', loading: false, sent: false };

  public render() {
    const { className, intl: { formatMessage } } = this.props;
    const { email, loading, sent } = this.state;

    return (
      <Form onSubmit={this.submit} loading={loading} className={className}>
        <img src={invitation} alt="" />

        <p>
          <FormattedMessage id="security.invitation.p" values={{ br: <br /> }} />
        </p>

        <Form.Input
          required
          type="email"
          placeholder={formatMessage({ id: 'security.invitation.placeholder' })}
          value={email}
          onChange={this.setEmail}
        />

        <Form.Button primary type="submit" className="actions">
          <FormattedMessage id="security.invitation.submit" />
        </Form.Button>

        {
          sent && (
            <Message positive>
              <Message.Content>
                <FormattedMessage id="security.invitation.sent" values={{ br: <br /> }} />
              </Message.Content>
            </Message>
          )
        }
      </Form>
    );
  }

  private setEmail = (_: React.SyntheticEvent, { value }: InputOnChangeData) =>
    this.setState({ email: value, sent: false });

  private submit = (e: React.FormEvent) => {
    e.preventDefault();

    this
      .props.repo
      .invite(this.state.email, this.props.intl.locale)
      .then(() => this.setState({ sent: true, loading: false, email: '' }))
    ;
    this.setState({ loading: true });
  }

}

export default styled(injectIntl((props: InjectedIntlProps) => (
  <SecurityRepository.Context.Consumer>
    {repo => (
      <Invitation repo={repo} {...props} />
    )}
  </SecurityRepository.Context.Consumer>
)))`
  img {
    display: block;
    width: 100%;
    height: auto;
    margin: 40px 0 20px;
  }

  p {
    margin-bottom: 30px !important;
    line-height: 1.5;
    color: #666;
  }

  .field.actions {
    margin-top: 15px !important;

    button {
      width: 100%;
      margin-top: 30px;
    }
  }

  .ui.message {
    margin-top: 30px;
  }
`;
