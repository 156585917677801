import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Subscriber } from '../../models/Subscriber';
import { SecurityRepository } from '../../repositories/SecurityRepository';

import { IAppState, login } from '../../AppState';

interface P {
  subscriber?: Subscriber | null;
  logout(): void;
}

class Logout extends React.Component<P & { repo: SecurityRepository; }> {

  componentDidMount() {
    const { repo, logout } = this.props;

    repo
      .logout()
      .then(logout)
    ;
  }

  render() {
    return !this.props.subscriber && (
      <Redirect to="/" />
    );
  }

}

export default connect(
  ({ subscriber }: IAppState) => ({ subscriber }),
  dispatch => ({
    logout: () => dispatch(login()),
  })
)((props: P) => (
  <SecurityRepository.Context.Consumer>
    {repo => (
      <Logout repo={repo} {...props} />
    )}
  </SecurityRepository.Context.Consumer>
));
