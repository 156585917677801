import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Breadcrumb, Grid } from 'semantic-ui-react';
import styled from 'styled-components';

const MyBreadcrumb = styled(Breadcrumb)`
  color: #999;
`;

interface P {
  className?: string;
  wide?: boolean | 'very';
  breadcrumb: string | Array<string | [string | [string], string]>;
  root?: string | [string, string];
}

class Layout extends Component<P> {

  render() {
    const { className, breadcrumb } = this.props;
    const root = this.props.root || ['/', 'nav.home'];

    return (
      <Grid container className={className}>
        <Grid.Row>
          <Grid.Column>
            <MyBreadcrumb>
              <Breadcrumb.Section>
                {typeof root === 'string' ? (
                  <FormattedMessage id={root} />
                ) : (
                  <Link to={root[0]}>
                    <FormattedMessage id={root[1]} />
                  </Link>
                )}
              </Breadcrumb.Section>
              {(typeof breadcrumb === 'string' ? [breadcrumb] : breadcrumb).map(s => (
                <Fragment key={typeof s === 'string' ? s : s[1]}>
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section>
                    {typeof s === 'string' ? (
                      <FormattedMessage id={s} />
                    ) : typeof s[0] === 'string' ? (
                      <Link to={s[0]}>
                        <FormattedMessage id={s[1]} />
                      </Link>
                    ) : (
                      s[0]
                    )}
                  </Breadcrumb.Section>
                </Fragment>
              ))}
            </MyBreadcrumb>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            {this.props.children}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

}

export default styled(Layout)`
  &.ui.grid.container {
    width: calc( ${({ wide }) => 'very' === wide ? 1440 : (wide ? 1340 : 1170)}px + 2rem ) !important;
  }

  margin: 30px auto 90px !important;
`;
