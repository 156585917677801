import * as React from 'react';
import { Link } from 'react-router-dom';

interface IPageProps {
  n: number;
  to: string;
  active: boolean;
  onPage?: (p: number) => void;
}

class Page extends React.Component<IPageProps> {
  public render() {
    const { n, to, active } = this.props;

    return (
      <Link to={to} className={active ? 'active item' : 'item'} onClick={this.onPage}>
        {n}
      </Link>
    );
  }

  private onPage = () => this.props.onPage && this.props.onPage(this.props.n);
}

export default Page;
