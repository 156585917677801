import { AbstractField, IAbstractFieldJSON } from './AbstractField';
import { IInterestJSON, Interest } from './Interest';
import { Footnote } from './Footnote';
import { Moment } from 'moment';

export class Scalar extends AbstractField {
    constructor(
        permanentID: string,
        serialNumber: number,
        readonly interest: Interest,
        required: boolean,
        footnotes: Footnote[],
        createdAt: Moment,
    ) {
        super(permanentID, serialNumber, required, footnotes, createdAt);
    }
}

export interface IScalarJSON extends IAbstractFieldJSON {
    readonly interest: IInterestJSON;
}
