import { FK } from './ScriptQuery';

export abstract class ScriptBadge {
    public static build<T>(data: IScriptBadgeJSON, c: { new(id: number, name: string): T }): T {
        return new c(data.id, data.name);
    }

    constructor(
        readonly id: number,
        readonly name: string) {
    }
}

export interface IScriptBadgeJSON {
    readonly id: number;
    readonly name: string;
}

export interface IScriptBadgeClass<T> {
    T: string;
    K: FK;
    new(id: number, name: string): T;
}
