export class Country {
  public static build(data: ICountryBlueprint) {
    return new Country(data.id, data.name);
  }

  constructor(
    public readonly id: number,
    public readonly name: string,
  ) {
  }
}

export interface ICountryBlueprint {
  readonly id: number;
  readonly name: string;
}
