import { Subscription, ISubscriptionJSON } from '../models/Subscription';
import { SubscriptionForm } from '../models/SubscriptionForm';

import axios, { AxiosInstance } from 'axios';
import { createContext } from 'react';

export class SubscriptionRepository {

  static readonly $ = new SubscriptionRepository(axios);
  static readonly Context = createContext(SubscriptionRepository.$);

  constructor(private a: AxiosInstance) {
  }

  async find(permanentID: string): Promise<Subscription> {
    const { data } = await this.a.get<ISubscriptionJSON>(`/subscriptions/${permanentID}`);

    return Subscription.fromJSON(data);
  }

  async save(subscription: SubscriptionForm, permanentID?: string): Promise<Subscription> {
    const { data } = await (
      permanentID ?
        this.a.put<ISubscriptionJSON>(`/subscriptions/${permanentID}`, { subscription: subscription.dispose() }) :
        this.a.post<ISubscriptionJSON>('/subscriptions', { subscription: subscription.dispose() })
    );

    return Subscription.fromJSON(data);
  }

  async remove(permanentID: string): Promise<void> {
    this.a.delete(`/subscriptions/${permanentID}`);
  }

}
