import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HomeUrl = () => (
  <Link to="/" target="_blank">https://delopen.org/</Link>
);

export const PrivacyUrl = () => (
  <Link to="/privacy" target="_blank">https://delopen.org/privacy</Link>
);

export const TermsUrl = () => (
  <Link to="/terms" target="_blank">https://delopen.org/terms</Link>
);

export const TermsFragment = (props: PropsWithChildren<{ to: string; }>) => (
  <Link to={`/terms#${props.to}`} target="_blank">
    {props.children}
  </Link>
);

export const PrivacyPolicy = () => (
  <Link to="/privacy" target="_blank">Privacy Policy</Link>
);

export const SupportMail = () => (
  <a href="mailto:support@delopen.org">support@delopen.org</a>
);

export const Cap = styled.strong`
  font-weight: normal;
  text-decoration: underline;
`;

export const T = styled.b`
  text-decoration: underline;
`;

export const Address = styled.div`
  margin: 1em 0 0 4em;
  color: #666;
`;

export const Tag = styled.span`
  float: left;

  &:after {
    content: ":";
  }
`;

export const Addr = styled.span`
  display: block;
  margin: 0 0 .5em 10em;
  text-indent: 0;
`;
