import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import sponsorWuxi from '../sponsor_wuxi.png';

class Sponsors extends React.Component<{ className?: string; } & InjectedIntlProps> {

  public render() {
    const { className, intl: { locale } } = this.props;

    return (
      <div className={`${className} ${locale}`}>
        <Header as="h2">
          <FormattedMessage id="home.sponsors" />
        </Header>
        <p>
          <FormattedMessage id="home.sponsors.p" values={{ br: <br /> }} />
        </p>

        <Grid container>
          <Grid.Column>
            <div className="institution card">
              <img src={sponsorWuxi} alt="Wuxi App Tec" />
              <p>
                <FormattedMessage id="home.sponsors.m" />
              </p>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

}

export default styled(injectIntl(Sponsors))`
  padding: 70px 0 100px;
  background: #f6f9ff;

  h2.ui.header {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 20px;
    text-align: center;
  }

  h2 + p {
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #999;
  }

  .ui.grid {
    margin-top: calc(60px - 1rem);
  }

  .card {
    height: 270px;
    padding: 50px 45px;
    background: #fff;

    &.institution {
      display: flex;
      align-items: center;
      height: 320px;
      padding: 75px;
      border-radius: 4px;
      box-shadow: 0 2px 20px 0 rgba(64, 114, 238, 0.1);

	.ui.placeholder {
	  width: 180px;
	  height: 50px;
	}

      img {
        display: block;
        width: 225px;
        margin-right: 75px;
        flex-shrink: 0;
      }

	p {
        max-height: 168px;
        overflow: auto;
	  font-size: ${18 / 16}rem;
	  line-height: ${28 / 18}em;
	  color: #666;
	}

	&.sponsor-g img {
	  height: 76px;
	  margin: -18px 0 -8px;
	}
    }
  }

  &.en-us {
    h2.ui.header {
      font-size: 34px;
      line-height: 40px;
    }

    h2 + p {
      line-height: 24px;
    }
  }
`;
