import { LanguageTag } from "./LanguageTag";

export interface Localizable<T> {
    localize(locale: string): T;
}

export function localize<K extends keyof any, V = string>(locale: string, locals: Array<Record<K, V> & { locale: LanguageTag; }>, fallback: Record<K, V>): Record<K, V> {
    for (const local of locals) {
        if (local.locale.code === locale) {
            return local;
        }
    }

    return fallback;
}
