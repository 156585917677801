import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';

class Abstract extends React.Component<{ className?: string } & InjectedIntlProps> {

  public render() {
    const { className, intl: { locale } } = this.props;
    
    return (
      <div className={`${className} ${locale}`}>
        <Grid container>
          <Grid.Column>
            <div className="abstract">
              <Header as="h2">
                <FormattedMessage id="home.abstract" />
              </Header>
              <p>
                <FormattedMessage id="home.abstract.p" values={{ br: <br /> }} />
              </p>
              <Header as="h2">
                <FormattedMessage id="home.abstract1" />
              </Header>
              <p>
                <FormattedMessage id="home.abstract1.p" values={{ br: <br /> }} />
              </p>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

}

export default styled(injectIntl(Abstract))`
  background: #fff;

  .ui.grid {
    margin-top: 0;

    & > .column:not(.row) {
      padding-top: 0;
    }
  }

  .abstract {
    min-height: 240px;
    padding: 60px 0;
    margin-top: -90px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 30px 0 rgba(64, 114, 238, 0.21);
    position: relative;
    z-index: 2;
  }

  h2.ui.header {
    font-size: 30px;
    line-height: 40px;
    margin: 0 0 20px;
    text-align: center;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #999;
  }

  &.en-us p {
    font-size: 16px;
    line-height: 24px;
  }
`;
