import React from 'react';
import { Route, RouteProps } from 'react-router';

import Secured from './Secured';

const PrivateRoute: React.FC<RouteProps & { optional?: boolean; }> = ({ optional, component: Component, render, ...rest }) => (
  <Route
    render={props => (
      <Secured optional={optional}>
        {(Component && (<Component {...props} />)) || (render && render(props))}
      </Secured>
    )}
    {...rest}
  />
);

export default PrivateRoute;
