import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { ScriptQuery } from '../../models/ScriptQuery';

import SurveyPortal from '../survey/Portal';

import bell from '../subscription/bell.svg';
import search from './search.svg';
import survey from './survey.png';

function Search(props: PropsWithChildren<RouteComponentProps & { placeholder: string; action: string; }>) {
  const query = ScriptQuery.build(props.location.search).defaultSort('rel');
  const [ q, setQ ] = useState<string>('');

  useEffect(() => setQ(query.q), [ query.q ]);

  return (
    <F action={props.action}>
      <Alert to="/subscriptions" target="_blank">
        <img src={bell} alt="" />
        <FormattedMessage id="subscription.new" />
      </Alert>
      <input type="hidden" name="sort" value={query.sort} />

      <div>
        <FormattedMessage id={props.placeholder}>
          {placeholder => (
            <Input
              type="search"
              name="q"
              placeholder={placeholder as string}
              value={q}
              onChange={e => setQ(e.target.value)}
            />
          )}
        </FormattedMessage>
        <Submit />
      </div>

      {props.children}

      <SurveyPortal open threshold={1}>
        {svy => (
          <Link to={`/surveys/${svy.id}`}>
            <img src={survey} alt="" />
          </Link>
        )}
      </SurveyPortal>
    </F>
  );
}

export default withRouter(Search);

const F = styled.form`
  padding: 50px 75px;
  background: #4072ee;
  background: linear-gradient(9deg, #008ff2 21%, #4075ee 34%);
  position: relative;

  & > div {
    display: flex;
  }

  img {
    display: block;
    width: 570px;
    height: 90px;
    margin: 30px 0 -10px;
  }
`;

const Alert = styled(Link)`
  font-size: ${16 / 16}rem;
  line-height: ${20 / 16};
  color: #4072ee;
  position: absolute;
  top: -35px;
  right: 55px;

  img {
    float: left;
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 20px;
  border: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 14px;
  line-height: 20px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #999;
  }
`;

const Submit = styled((props: { className?: string; }) => (
  <button type="submit" className={props.className} />
))`
  box-sizing: border-box;
  width: 72px;
  height: 60px;
  padding: 14px 20px;
  background: url('${search}') no-repeat center center / 32px 32px #fff;
  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  outline: none;
`;
