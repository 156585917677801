import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CheckboxProps, Form, Header, InputOnChangeData } from 'semantic-ui-react';
import URI from 'urijs';
import styled from 'styled-components';

import { SubscriberForm } from '../../models/SubscriberForm';
import { SecurityRepository } from '../../repositories/SecurityRepository';

import CountrySelect from '../widgets/CountrySelect';
import MyHelmet from '../widgets/MyHelmet';
import Flyer from '../../Flyer';

type P = RouteComponentProps<void> & InjectedIntlProps & {
  className?: string;
};

interface IRegisterState {
  form: SubscriberForm;
  loading: boolean;
  checked: boolean;
}

interface Search {
  email?: string;
  inviter?: string;
  source?: string;
}

class Register extends React.Component<P & { repo: SecurityRepository }, IRegisterState> {

  private setEmail = this.patchString('email');
  private setFirstName = this.patchString('firstName');
  private setLastName = this.patchString('lastName');
  private setCountry = this.patch('country');
  private setInstitution = this.patchString('institution');

  public constructor(props: P & { repo: SecurityRepository }) {
    super(props);

    const params = URI.parseQuery(this.props.location.search) as Search;
    this.state = {
      form: new SubscriberForm().patch('email', params.email || '').patch('inviter', params.inviter || ''),
      loading: false,
      checked:false,
    };
  }

  public render() {
    const { className, intl: { formatMessage } } = this.props;
    const { form, loading } = this.state;

    return (
      <Flyer className={className}>
        <MyHelmet title="security.register" />

        <Header as="h1" color="blue">
          <FormattedMessage id="security.register" />
        </Header>

        <Form loading={loading} onSubmit={this.submit}>
          <Form.Input
            required
            type="email"
            label={formatMessage({ id: 'common.email' })}
            value={form.email}
            onChange={this.setEmail}
          />
          <Form.Group widths="equal">
            <Form.Input
              fluid
              required
              label={formatMessage({ id: 'common.first_name' })}
              value={form.firstName}
              onChange={this.setFirstName}
            />
            <Form.Input
              fluid
              required
              label={formatMessage({ id: 'common.last_name' })}
              value={form.lastName}
              onChange={this.setLastName}
            />
          </Form.Group>
          <Form.Field required>
            <label>
              <FormattedMessage id="security.country" />
            </label>
            <CountrySelect
              value={form.country}
              onChange={this.setCountry}
            />
          </Form.Field>
          <Form.Input
            required
            label={formatMessage({ id: 'common.institution' })}
            value={form.institution}
            onChange={this.setInstitution}
          />
          <Form.Checkbox
            className="pi"
            label={formatMessage({ id: 'common.principle_investigator' })}
            checked={form.principalInvestigator}
            onChange={this.togglePrincipalInvestigator}
          />
          <p className="footnote">
            <Form.Checkbox
              required
              label={ 
                <label>
                  <FormattedMessage
                  id="security.reg_accept"
                  values={{
                    terms: (
                      <Link to="/terms" target="_blank">
                        <FormattedMessage id="nav.terms_of_use" />
                      </Link>
                    ),
                    privacy: (
                      <Link to="/privacy" target="_blank">
                        <FormattedMessage id="nav.privacy_policy" />
                      </Link>
                    )
                  }}
                  />
                </label> 
              }
              onClick={this.handleClick}
              checked={this.state.checked}
            />
          </p>

          <Form.Button fluid primary disabled={!this.state.checked} type="submit">
            <FormattedMessage id="security.register" />
          </Form.Button>
        </Form>

        <p className="backup">
          <FormattedMessage
            id="security.already_registered"
            values={{
              login: (
                <Link to="/sign-in">
                  <FormattedMessage id="security.login" />
                </Link>
              ),
            }}
          />
        </p>
      </Flyer>
    );
  }

  private patchString<K extends 'email' | 'firstName' | 'lastName' | 'institution'>(key: K) {
    return (_: React.SyntheticEvent, { value }: InputOnChangeData) =>
      this.patch(key)(value as string);
  }

  private togglePrincipalInvestigator = (_: React.SyntheticEvent, { checked }: CheckboxProps) =>
    this.patch('principalInvestigator')(!!checked);

  private patch<K extends keyof SubscriberForm>(key: K) {
    return (value: SubscriberForm[K]) =>
      this.setState({ form: this.state.form.patch(key, value) });
  }

  private submit = (e: React.FormEvent<{}>) => {
    e.preventDefault();

    const { form } = this.state;
    if (!form.valid) {
      return;
    }

    const { repo, history, location, intl: { locale } } = this.props;
    const search = URI.parseQuery(location.search) as Search;

    repo
      .register(form, locale, search.source || 'activate')
      .then(() => history.push('/sent?action=register'))
      .catch(() => this.setState({ loading: false }))
    ;

    this.setState({ loading: true });
  }

  private handleClick = ()=>{
    this.setState({
      checked:!this.state.checked
    })
  }

}

export default styled(injectIntl((props: P) => (
  <SecurityRepository.Context.Consumer>
    {repo => (
      <Register repo={repo} {...props} />
    )}
  </SecurityRepository.Context.Consumer>
)))`
  .pi {
    margin-bottom: 0 !important;
  }

  .footnote {
    margin-top: 10px !important;
    font-size: ${13 / 14}rem;
    line-height: ${20 / 13}em;
    color: #999;
    text-align: left;
  }
`;
