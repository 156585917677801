import { Delivery, IDeliveryBlueprint } from '../models/Delivery';
import { DeliveryForm } from '../models/DeliveryForm';
import { Trial } from '../models/Trial';

import axios, { AxiosInstance } from 'axios';
import * as React from 'react';

export class DeliveryRepository {

  public static readonly $ = new DeliveryRepository(axios);
  public static readonly Context = React.createContext(DeliveryRepository.$);

  constructor(private a: AxiosInstance) {
  }

  public async persist(form: DeliveryForm, trial: Trial): Promise<Delivery> {
    const { data } = await this.a.post<IDeliveryBlueprint>(`/trials/${trial.id}/deliveries`, { delivery: form.dispose() });

    return Delivery.build(data);
  }

}
