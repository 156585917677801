import { ScriptBadge, IScriptBadgeJSON } from './ScriptBadge';

export class ScriptKind extends ScriptBadge {
    public static readonly T = 'kind';
    public static readonly K = 't';

    public static bless(data: IScriptKindJSON) {
        return new ScriptKind(data.id, data.name, data.color);
    }

    constructor(
        id: number,
        name: string,
        readonly color?: string,
    ) {
        super(id, name);
    }
}

export interface IScriptKindJSON extends IScriptBadgeJSON {
    readonly color?: string;
}
