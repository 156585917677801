import React, { Component, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import logo from './logo.png';

const Wrapper = styled.div`
  padding: 60px 0;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  background-color: #fff;
`;

const Img = styled.img`
  display: block;
  width: 242px;
  height: 60px;
  margin: auto;
`;

class Flyer extends Component<{ className?: string; message?: ReactNode; wide?: boolean; }> {

  render() {
    const { className, message, wide, children } = this.props;

    return (
      <Grid centered container className={className}>
        <Helmet>
          <style>{'body { background: #f6f9ff; }'}</style>
        </Helmet>

        <Column width={wide ? 10 : 8}>
          {message}

          <Wrapper>
            <Grid centered>
              <Grid.Column width={wide ? 16 : 12}>
                <Img src={logo} alt="DELopen" className="logo" />

                {children}
              </Grid.Column>
            </Grid>
          </Wrapper>
        </Column>
      </Grid>
    );
  }

}

export default styled(Flyer)`
  margin: 100px auto 160px !important;

  h1 {
    margin: 10px 0 20px !important;
    font-weight: normal !important;
    text-align: center;
  }

  h1 + p {
    margin: 50px 0 30px !important;
    text-align: center;
  }

  button {
    margin-top: 30px !important;
  }

  p.backup {
    margin-top: 20px;
    text-align: center;
  }
`;

const Column = styled(Grid.Column)`
  & > .ui.message {
    margin: -70px 0 30px !important;
  }
`;
