import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import styled from 'styled-components';

import { Institution } from '../../models/Institution';
import { InstitutionRepository } from '../../repositories/InstitutionRepository';

import Layout from '../../PrivateLayout';
import Panel from '../../Panel';
import MyHelmet from '../widgets/MyHelmet';

interface P {
  className?: string;
}

interface S {
  institution?: Institution | null;
}

class InstitutionShow extends Component<P & { repo: InstitutionRepository; }, S> {

  state: S = {};

  componentDidMount() {
    this
      .props.repo
      .fetch()
      .then(institution => this.setState({ institution }))
    ;
  }

  render() {
    const { className } = this.props;
    const { institution } = this.state;

    switch (institution) {
      case undefined:
        return null;

      case null:
        return (
          <Redirect to="/institution/edit" />
        );

      default:
        if (institution.approvedAt) {
          return (
            <Redirect to="/kits" />
          );
        }
    }

    return (
      <Layout breadcrumb={[ 'nav.become_provider' ]} className={className}>
        <MyHelmet title="nav.become_provider" />

        <Panel width={16} header={<FormattedMessage id="nav.become_provider" />}>
          <Message success>
            <Message.Header>
              <FormattedMessage id="institution.success" />
            </Message.Header>
            <Message.Content>
              <FormattedMessage id="institution.instruction" />
            </Message.Content>
          </Message>

          <Link to="/" className="ui primary button">
            <FormattedMessage id="nav.back_to_home" />
          </Link>
        </Panel>
      </Layout>
    );
  }

}

export default styled((props: P) => (
  <InstitutionRepository.Context.Consumer>
    {repo => (
      <InstitutionShow repo={repo} {...props} />
    )}
  </InstitutionRepository.Context.Consumer>
))`
  .ui.message {
    margin: 40px 0;
  }
`;
