import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import { Fragment } from '../../models/Fragment';
import { Trial } from '../../models/Trial';
import { TrialRepository } from '../../repositories/TrialRepository';

import Layout from '../../PrivateLayout';
import Panel from '../../Panel';
import Catalog from '../../Catalog';
import MyHelmet from '../widgets/MyHelmet';
import Paginator, { PageComponentProps, withPage } from '../widgets/Paginator';
import ReviewButton from './ReviewButton';
import TrackingModal from './TrackingModal';
import TrialItem from './Item';

type P = PageComponentProps & {
  className?: string;
};

interface ITrialYoursState {
  fragment: Fragment<Trial>;
  loading: boolean;
  active?: Trial;
}

class TrialYours extends Component<P & { repo: TrialRepository; }, ITrialYoursState> {

  state: ITrialYoursState = { fragment: new Fragment<Trial>(), loading: false };

  componentDidMount() {
    this.find();
  }

  componentDidUpdate(prevProps: P) {
    if (this.props.page !== prevProps.page) {
      this.find();
    }
  }

  public render() {
    const { className } = this.props;
    const { fragment, active } = this.state;

    return (
      <Layout breadcrumb={[ 'trial.yours' ]} className={className}>
        <MyHelmet title="trial.yours" />

        <Grid>
          <Panel width={16} header={<FormattedMessage id="trial.yours" />}>
            <Catalog fragment={fragment} empty="trial.yours.empty">
              {t => (
                <TrialItem your trial={t}>
                  {(t.rejectedAt && (
                    <ReviewButton trial={t} />
                  )) || (t.deliveries.length > 0 && (
                    <Link to="#" className="ui primary button" data-id={t.id} onClick={this.track}>
                      <FormattedMessage id="track" />
                    </Link>
                  )) || (t.isAuth === 3 && (
                    <Link to="#" className="ui secondary button" data-id={t.id} onClick={this.hits_tracking}>
                      <FormattedMessage id="hits_tracking" />
                    </Link>
                  ))}
                </TrialItem>
              )}
            </Catalog>

            <br />
            <Paginator fragment={fragment} />

            {active && active.deliveries.length > 0 && (
              <TrackingModal
                delivery={active.deliveries[0]}
                onClose={this.close}
              />
            )}
          </Panel>
        </Grid>
      </Layout>
    );
  }

  private async find() {
    const { repo, page, pageSize } = this.props;
    const fragment = await repo.findYours(page, pageSize);

    this.setState({ fragment });
  }

  private track = (e: React.SyntheticEvent) => {
    const id = +(e.currentTarget.getAttribute('data-id') as string);

    this.setState({ active: this.state.fragment.data.find(t => t.id === id) });
  }

  private hits_tracking = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const id = e.currentTarget.getAttribute('data-id') as string;

    const { repo } = this.props;

    repo.
      find(id)   
      .then(function(response) { 
        window.location.href = `${process.env.REACT_APP_HITS_URL}auth_login?trial_id=${id}&token=${response.token}`;
      })
      .catch(() => this.setState({ loading: false }))
    ;

  }

  private close = () => this.setState({ active: undefined });

}

export default styled(withPage((props: P) => (
  <TrialRepository.Context.Consumer>
    {repo => (
      <TrialYours repo={repo} {...props} />
    )}
  </TrialRepository.Context.Consumer>
)))`
  .ui.button {
    width: ${90 / 12}em;
    padding: ${8 / 12}em;
    font-size: ${12 / 14}rem;
    line-height: ${20 / 12}em;
  }
`;
