import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import { Delivery } from '../../models/Delivery';
import { Fragment } from '../../models/Fragment';
import { Trial } from '../../models/Trial';
import { TrialRepository } from '../../repositories/TrialRepository';

import Layout from '../../PrivateLayout';
import Panel from '../../Panel';
import Catalog from '../../Catalog';
import MyHelmet from '../widgets/MyHelmet';
import Paginator, { PageComponentProps, withPage } from '../widgets/Paginator';
import DeliveryModal from './DeliveryModal';
import RejectionButton from './RejectionButton';
import ReviewButton from './ReviewButton';
import TrackingModal from './TrackingModal';
import TrialItem from './Item';

interface P extends PageComponentProps {
  className?: string;
}

interface ITrialIndexState {
  fragment: Fragment<Trial>;
  loading: boolean;
  active?: Trial;
}

class TrialIndex extends Component<P & { repo: TrialRepository; }, ITrialIndexState> {

  state: ITrialIndexState = { fragment: new Fragment<Trial>(), loading: false };

  componentDidMount() {
    this.find();
  }

  componentDidUpdate(prevProps: P) {
    if (this.props.page !== prevProps.page) {
      this.find();
    }
  }

  render() {
    const { className } = this.props;
    const { fragment, active } = this.state;

    return (
      <Layout breadcrumb={[ 'trial.index' ]} className={className}>
        <MyHelmet title="trial.index" />

        <Grid>
          <Panel width={16} header={<FormattedMessage id="trial.index" />}>
            <Catalog fragment={fragment} empty="trial.index.empty">
              {t => (
                <TrialItem trial={t} key={t.id}>
                  {(t.rejectedAt && (
                    <ReviewButton trial={t} />
                  )) || (t.deliveries.length > 0 && (
                    <Link to="#" className="ui primary button" data-id={t.id} onClick={this.modal}>
                      <FormattedMessage id="track" />
                    </Link>
                  )) || (
                    <React.Fragment>
                      <Link to="#" className="ui primary button" data-id={t.id} onClick={this.modal}>
                        <FormattedMessage id="deliver" />
                      </Link>
                      <RejectionButton trial={t} onRejected={this.reject} />
                    </React.Fragment>
                  )}
                </TrialItem>
              )}
            </Catalog>

            <br />
            <Paginator fragment={fragment} />

            {active && (
              active.deliveries.length > 0 ? (
                <TrackingModal
                  delivery={active.deliveries[0]}
                  onClose={this.close}
                />
              ) : (
                <DeliveryModal
                  trial={active}
                  onClose={this.close}
                  onSubmitted={this.deliver}
                />
              )
            )}
          </Panel>
        </Grid>
      </Layout>
    );
  }

  private async find() {
    const { repo, page, pageSize } = this.props;
    const fragment = await repo.findAll(page, pageSize);

    this.setState({ fragment });
  }

  private modal = (e: React.SyntheticEvent) => {
    const id = +(e.currentTarget.getAttribute('data-id') as string);

    this.setState({ active: this.state.fragment.data.find(t => t.id === id) });
  }

  private close = () => this.setState({ active: undefined });

  private deliver = (delivery: Delivery) => {
    const trial = this.state.active as Trial;

    this.setState({
      fragment: this.state.fragment.patch(trial, trial.patch('deliveries', [delivery])),
      active: undefined,
    });
  }

  private reject = (old: Trial, val: Trial) => this.setState({
    fragment: this.state.fragment.patch(old, val),
  });

}

export default styled(withPage((props: P) => (
  <TrialRepository.Context.Consumer>
    {repo => (
      <TrialIndex repo={repo} {...props} />
    )}
  </TrialRepository.Context.Consumer>
)))`
  .ui.button {
    width: ${90 / 12}em;
    padding: ${8 / 12}em;
    font-size: ${12 / 14}rem;
    line-height: ${20 / 12}em;
  }

  .ui.button {
    margin: 0;

    & + .ui.button {
      margin-top: 10px;
    }
  }
`;
