import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Header, Loader, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Fragment } from '../../models/Fragment';
import { Library } from '../../models/Library';
import { ScriptQuery } from '../../models/ScriptQuery';
import { ScriptRepository } from '../../repositories/ScriptRepository';

import Layout from '../../Layout';
import ScoopImage from '../scoop/Image';
import MyHelmet from '../widgets/MyHelmet';
import { PageComponentProps, withPage } from '../widgets/Paginator';
import NeoPager from './NeoPager';
import Search from './Search';
import Sort from './Sort';
import lib from './lib_s.svg';

const DefaultSort = 'age';

type P = RouteComponentProps & PageComponentProps & {
};

interface S {
  fragment?: Fragment<Library>;
  total: number;
}

class LibraryIndex extends Component<P & { repo: ScriptRepository; }, S> {

  public state: S = { total: 0 };

  componentDidMount() {
    this.props.repo.count().then(cs => this.setState({ total: cs.reduce((n, c) => n + c.libraries, 0) }));
    this.find();
  }

  componentDidUpdate({ location: { search } }: P) {
    if (this.props.location.search !== search) {
      this.find();
    }
  }

  render() {
    const { fragment, total } = this.state;
    const query = ScriptQuery.build(this.props.location.search)

    return (
      <Layout wide breadcrumb={[['/scripts/_', 'nav.scripts.dashboard'], 'nav.scripts.lib']}>
        <MyHelmet title="nav.scripts" />

        <H1 as="h1">
          <FormattedMessage id="script.lib">
            {h1 => (
              <span>
                <img src={lib} alt="" />
                {h1}
              </span>
            )}
          </FormattedMessage>
          <Header.Subheader>
            {total} DNA-encoded Libraries were collected
          </Header.Subheader>
        </H1>

        <Search placeholder="script.reaction_placeholder" action="/libraries" />

        <Sheet>
          <Status fragment={fragment} />

          {!fragment ? (
            <Loader active content="Loading" />
          ) : (
            <Table>
              <thead>
                <tr>
                  <th className="refno"><FormattedMessage id="library.refno" /></th>
                  <th className="scheme"><FormattedMessage id="library.scheme" /></th>
                  <th className="kind"><FormattedMessage id="library.kind" /></th>
                  <th className="hit"><FormattedMessage id="library.hit" /></th>
                  <th className="blocks"><FormattedMessage id="library.blocks" /></th>
                  <th className="tags"><FormattedMessage id="library.tags" /></th>
                  <th className="pages"><FormattedMessage id="library.pages" /></th>
                  <th className="source"><FormattedMessage id="script.source" /></th>
                  <th className="institution"><FormattedMessage id="script.institution" /></th>
                </tr>
              </thead>

              <tbody>
                {fragment.data.map((l, i) => (
                  <tr key={l.id}>
                    <td className="refno">{1 + i}</td>
                    <td className="scheme">
                      {l.scheme && (
                        <Zoom trigger={<Image scoop={l.scheme} />}>
                          <Image scoop={l.scheme} />
                        </Zoom>
                      )}
                    </td>
                    <td className="kind" dangerouslySetInnerHTML={l.highlight('kind')} />
                    <td className="hit" dangerouslySetInnerHTML={l.highlight('hit')} />
                    <td className="blocks">
                      {l.highlight('blocks').__html.split('\n').map((__html, i) => (
                        <React.Fragment key={i}>
                          {i > 0 && <br />}
                          <span dangerouslySetInnerHTML={{ __html }} />
                        </React.Fragment>
                      ))}
                    </td>
                    <td className="tags">
                      {l.highlight('tags').__html.split('\n').map((__html, i) => (
                        <React.Fragment key={i}>
                          {i > 0 && <br />}
                          <span dangerouslySetInnerHTML={{ __html }} />
                        </React.Fragment>
                      ))}
                    </td>
                    <td className="pages">{l.pages}</td>
                    <td className="source">
                      {l.script && (
                        <Link to={`/scripts/${l.script.id}`}>
                          {l.script.source}
                        </Link>
                      )}
                    </td>
                    <td className="institution" dangerouslySetInnerHTML={l.highlight('institutions')} />
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

      {fragment && (
        <NP total={fragment.total}>
          <input type="hidden" name="q" value={query.q} />
          <input type="hidden" name="sort" value={query.sort} />
        </NP>
      )}
        </Sheet>
      </Layout>
    );
  }

  async find() {
    this.setState({ fragment: undefined });

    const { page, pageSize, location: { search }, repo } = this.props;
    const fragment = await repo.findLibraries(page, pageSize, ScriptQuery.build(search).defaultSort(DefaultSort));

    this.setState({ fragment, total: fragment.total });
  }

}

export default withPage((props: P) => (
  <ScriptRepository.Context.Consumer>
    {repo => (
      <LibraryIndex repo={repo} {...props} />
    )}
  </ScriptRepository.Context.Consumer>
));

const H1 = styled(Header)`
  margin: 40px 0;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  color: #333;
  text-align: center;

  & > span {
    padding-left: 65px;
    position: relative;

    img {
      width: 60px;
      height: 54px;
      margin-top: -27px;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }

  .sub.header {
    margin-top: 7px;
    font-size: 20px;
    font-weight: normal;
    line-height: 24px;
    color: #999;
  }
`;

const Sheet = styled.div`
  min-height: 400px;
  padding: 40px;
  background: #fff;
  margin-top: 30px;
  position: relative;

  &::after {
    content: "";
    display: block;
    clear: both;
  }
`;

const Status = styled(withRouter(withPage((props: RouteComponentProps & PageComponentProps & {
  className?: string;
  fragment?: Fragment<Library>;
}) => {
  const { fragment, page, pageSize, location: { search } } = props;
  const query = ScriptQuery.build(search);
  const start = pageSize * (page - 1);

  return (
    <div className={props.className}>
      <Sort query={query.defaultSort(DefaultSort)} />
      <FormattedMessage id="script.search_results" tagName="h1" />

      {fragment && (
        <div>
          <FormattedMessage id="pager.items" />
          {': '}
          {fragment.total > 0 ? `${start + 1}-${start + fragment.data.length} of ${fragment.total}` : 0}

          <NeoPager total={fragment.total}>
            <input type="hidden" name="q" value={query.q} />
            <input type="hidden" name="sort" value={query.sort} />
          </NeoPager>
        </div>
      )}
    </div>
  );
})))`
  margin-bottom: 60px;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;

  h1 {
    margin-top: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-top: 40px;
  
  em {
    font-style: normal;
    background: #ff0;
  }

  th, td {
    padding: 0 0 20px 20px;
    font-size: 14px;
    text-align: left;
    vertical-align: top;

    &:first-child {
      padding-left: 0;
    }
  }

  th {
    padding-bottom: 60px;
    font-size: 14px !important;
    font-weight: 600;
    line-height: 20px;
  }

  td {
    line-height: 24px;
  }

  .refno {
    width: 40px;
  }

  .scheme {
    width: 210px;
  }

  .kind {
    width: 120px;
    font-size: 13px;
  }

  .hit {
    width: 110px;
    font-size: 13px;
  }

  .blocks {
    width: 250px;
    font-size: 13px;
  }

  .tags {
    width: 170px;
    font-size: 13px;
  }

  .pages {
    width: 90px;
  }

  .source {
    width: 140px;

    a {
      text-decoration: underline;
    }
  }

  .institution {
    width: 130px;
  }
`;

const Image = styled(ScoopImage)`
  max-width: 100%;
`;

const Zoom = styled(Modal)`
  width: auto !important;
  max-width: 1000px;
`;

const NP = styled(NeoPager)`
  margin-top: 20px;
`;
