import { Vector, IVectorJSON } from './Vector';
import { Interest } from './Interest';
import { Solution } from './Solution';
import { RatingSolution } from './RatingSolution';
import moment, { Moment } from 'moment';
import { LocalLabel } from './LocalLabel';
import { Footnote } from './Footnote';
import { Localizable, localize } from './Localizable';
import { LocalCaption, ILocalCaptionJSON } from './LocalCaption';

export class RatingVector extends Vector implements Localizable<RatingVector> {
    static fromJSON(data: IRatingVectorJSON) {
        return new RatingVector(
            data.permanent_id,
            data.serial_number,
            data.label,
            (data.local_labels || []).map(LocalLabel.fromJSON),
            data.required,
            data.interests.map(Interest.fromJSON),
            data.scale,
            data.captions,
            (data.local_captions || []).map(LocalCaption.fromJSON),
            (data.footnotes || []).map(Footnote.fromJSON),
            moment(data.created_at),
        );
    }

    constructor(
        permanentID: string,
        serialNumber: number,
        label: string,
        localLabels: LocalLabel[],
        required: boolean,
        interests: Interest[],
        readonly scale: number,
        readonly captions: Record<number, string>,
        readonly localCaptions: LocalCaption[],
        footnotes: Footnote[],
        createdAt: Moment,
    ) {
        super(permanentID, serialNumber, label, localLabels, required, interests, footnotes, createdAt);
    }

    localize(locale: string) {
        return new RatingVector(
            this.permanentID,
            this.serialNumber,
            localize<'label'>(locale, this.localLabels, this).label,
            this.localLabels,
            this.required,
            this.interests.map(i => i.localize(locale)),
            this.scale,
            localize<'captions', Record<number, string>>(locale, this.localCaptions, this).captions,
            this.localCaptions,
            this.footnotes.map(f => f.localize(locale)),
            this.createdAt,
        );
    }

    solve(interest: Interest, solutions?: Record<string, Solution>) {
        return solutions
            && solutions[interest.permanentID]
            && (solutions[interest.permanentID] as RatingSolution).rating
        ;
    }
}

export interface IRatingVectorJSON extends IVectorJSON {
    readonly scale: number;
    readonly captions: Record<number, string>;
    readonly local_captions?: ILocalCaptionJSON[];
}
