export type FK = 'db' | 't' | 'kw' | 'y' | 'r';
export type Sort = 'abc' | 'age' | 'hot' | 'na' | 'rel';

export class ScriptQuery {
    static build(search: string) {
        const params = new URLSearchParams(search);
        const sort = params.get('sort');

        return new ScriptQuery(
            params.get('q') || '',
            params.getAll('db[]'),
            params.getAll('t[]'),
            params.getAll('kw[]'),
            params.getAll('y[]'),
            params.getAll('r[]'),
            !!params.get('hit') || undefined,
            (sort && [ 'abc', 'age', 'hot', 'na', 'rel' ].includes(sort) && sort as Sort) || undefined,
        );
    }

    constructor(
        readonly q: string,
        readonly db: string[],
        readonly t: string[],
        readonly kw: string[],
        readonly y: string[],
        readonly r: string[],
        readonly hit: true | undefined,
        readonly sort?: Sort) {
    }

    includes(key: FK, value: string) {
        return this[key].includes(value);
    }

    toggle(key: FK, value: string) {
        const values = this.includes(key, value) ? this[key].filter(v => v !== value) : this[key].concat(value);
        const { q, db, t, kw, y, r, hit, sort } = Object.assign({}, this, { [key]: values });

        return new ScriptQuery(q, db, t, kw, y, r, hit, sort);
    }

    amend<K extends 'q' | 'hit' | 'sort'>(key: K, val: ScriptQuery[K]) {
        const { q, db, t, kw, y, r, hit, sort } = Object.assign({}, this, { [key]: val });

        return new ScriptQuery(q, db, t, kw, y, r, hit, sort);
    }

    defaultSort(sort: Sort) {
        return this.sort ? this : this.amend('sort', sort);
    }

    toString() {
        const params = new URLSearchParams();

        params.set('q', this.q);
        for (const k of ['db', 't', 'kw', 'y', 'r']) {
            for (const v of this[k as FK]) {
                params.append(`${k}[]`, v);
            }
        }
        if (this.hit) {
            params.set('hit', '1');
        }
        if (this.sort) {
            params.set('sort', this.sort);
        }

        return params.toString();
    }
}
