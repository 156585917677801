import { Scalar, IScalarJSON } from './Scalar';
import { Interest } from './Interest';
import { LocalCaption, ILocalCaptionJSON } from './LocalCaption';
import { Footnote } from './Footnote';
import { Solution } from './Solution';
import { RatingSolution } from './RatingSolution';
import { Localizable, localize } from './Localizable';
import moment, { Moment } from 'moment';

export class RatingScalar extends Scalar implements Localizable<RatingScalar> {
    static fromJSON(data: IRatingScalarJSON) {
        return new RatingScalar(
            data.permanent_id,
            data.serial_number,
            Interest.fromJSON(data.interest),
            data.required,
            data.scale,
            data.captions,
            (data.local_captions || []).map(LocalCaption.fromJSON),
            (data.footnotes || []).map(Footnote.fromJSON),
            moment(data.created_at),
        );
    }

    constructor(
        permanentID: string,
        serialNumber: number,
        interest: Interest,
        required: boolean,
        readonly scale: number,
        readonly captions: Record<number, string>,
        readonly localCaptions: LocalCaption[],
        footnotes: Footnote[],
        createdAt: Moment,
    ) {
        super(permanentID, serialNumber, interest, required, footnotes, createdAt);
    }

    localize(locale: string) {
        return new RatingScalar(
            this.permanentID,
            this.serialNumber,
            this.interest.localize(locale),
            this.required,
            this.scale,
            localize<'captions', Record<number, string>>(locale, this.localCaptions, this).captions,
            this.localCaptions,
            this.footnotes.map(f => f.localize(locale)),
            this.createdAt,
        );
    }

    solve(solutions?: Record<string, Solution>) {
        return solutions
            && solutions[this.interest.permanentID]
            && (solutions[this.interest.permanentID] as RatingSolution).rating
        ;
    }
}

export interface IRatingScalarJSON extends IScalarJSON {
    readonly scale: number;
    readonly captions: Record<number, string>;
    readonly local_captions: ILocalCaptionJSON[];
}
