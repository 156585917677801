import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import { Sticker } from '../../models/Sticker';

function Stickr(props: InjectedIntlProps & { className?: string; sticker?: Sticker; }) {
  if (!props.sticker) {
    return null;
  }

  return (
    <span className={props.className}>
      {(props.sticker.localStickers.find(ls => props.intl.locale === ls.language.code) || props.sticker).name}
    </span>
  );
}

export default injectIntl(Stickr);
