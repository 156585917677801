import React, { Component } from 'react';
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import Home from './components/home';

import About from './components/permanent/About';
import FAQs from './components/permanent/FAQs';
import Privacy from './components/permanent/Privacy';
import Screening from './components/permanent/Screening';
import Service from './components/permanent/Service';
import Terms from './components/permanent/Terms';

import AdviserIndex from './components/adviser/Index';

import Login from './components/security/Login';
import Logout from './components/security/Logout';
import Password from './components/security/Password';
import Profile from './components/security/Profile';
import ProofExpired from './components/security/ProofExpired';
import Register from './components/security/Register';
import Reset from './components/security/Reset';
import Sent from './components/security/Sent';

import InstitutionEdit from './components/institution/Edit';
import InstitutionShow from './components/institution/Show';

import KitIndex from './components/kit/Index';
import KitYours from './components/kit/Yours';
import KitEdit from './components/kit/Edit';
import KitShow from './components/kit/Show';

import TrialIndex from './components/trial/Index';
import TrialYours from './components/trial/Yours';
import TrialEdit from './components/trial/Edit';
import TrialAuthorization from './components/trial/Authorization';

import PostIndex from './components/post/Index';
import PostShow from './components/post/Show';

import ScriptDashboard from './components/script/Dashboard';
import HitIndex from './components/script/HitIndex';
import ScriptIndex from './components/script/Index';
import ScriptShow from './components/script/Show';

import LibraryIndex from './components/script/LibraryIndex';
import ReactionIndex from './components/script/ReactionIndex';

import Landing from './components/Landing';
import RequestAccess from './components/RequestAccess';
import SurveyShow from './components/survey/Show';
import SurveyThanks from './components/survey/Thanks';

import SubscriptionDone from './components/subscription/Done';
import SubscriptionEdit from './components/subscription/Edit';

import BacktoTop from './components/widgets/BacktoTop';
import Footer from './components/widgets/Footer';
import Header from './components/widgets/Header';
import ScrollSensor from './components/widgets/ScrollSensor';

import PrivateRoute from './components/security/PrivateRoute';

import { Context as ScrollContext } from './components/widgets/ScrollSensor';

const Wrapper = styled.div`
    min-height: 100%;
    position: relative;
    overflow: hidden;
`;

interface S {
  yOffset?: number;
}

class Blueprint extends Component<{ className?: string; }, S> {

  state: S = {};

  render() {
    const { yOffset } = this.state;

    return (
      <Wrapper className={this.props.className}>
        <ScrollContext.Provider value={this.onScroll}>
          <ScrollSensor />

          <H phase={yOffset !== undefined ? Math.min(yOffset, 50) / 50 : 1} />
          <BacktoTop yOffset={yOffset || 0} />

          <Switch>
            <Route path="/landing" component={Landing} />
            <Route path="/request-access" component={RequestAccess} />

            <Route path="/surveys/:id/thanks" component={SurveyThanks} />
            <Route path="/surveys/:id" component={SurveyShow} />

            <Route path="/subscriptions/:id/done" component={SubscriptionDone} />
            <Route path="/subscriptions/:id" component={SubscriptionEdit} />
            <Route path="/subscriptions" component={SubscriptionEdit} />

            <Route path="/about" component={About} />
            <Route path="/faqs" component={FAQs} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/screening" component={Screening} />
            <Route path="/service" component={Service} />
            <Route path="/terms" component={Terms} />

            <Route path="/advisers" component={AdviserIndex} />

            <Route path="/sign-in" component={Login} />
            <Route path="/sign-out" component={Logout} />
            <Route path="/password" component={Password} />
            <Route path="/proof-expired" component={ProofExpired} />
            <Route path="/sign-up" component={Register} />
            <Route path="/reset" component={Reset} />
            <Route path="/sent" component={Sent} />

            <Route path="/posts/:id" component={PostShow} />
            <Route path="/posts" component={PostIndex} />

            <Route path="/libraries" component={LibraryIndex} />
            <Route path="/reactions" component={ReactionIndex} />
            <Route path="/hits" component={HitIndex} />
            <Route path="/scripts/_" component={ScriptDashboard} />
            <Route path="/scripts/:id" component={ScriptShow} />
            <Route path="/scripts" component={ScriptIndex} />

            <Route path="/_/kits" component={KitIndex} />
            <PrivateRoute path="/_/trials" component={TrialIndex} />

            <PrivateRoute path="/trials/:id/authorization" component={TrialAuthorization} />
            <PrivateRoute path={[ '/trials/:id/edit', '/kits/:kitId/trials/new' ]} component={TrialEdit} />
            <PrivateRoute path="/trials" component={TrialYours} />

            <PrivateRoute path={[ '/kits/:id/edit', '/kits/new' ]} component={KitEdit} />
            <Route path="/kits/:id(\d+)" component={KitShow} />
            <PrivateRoute path="/kits" component={KitYours} />

            <PrivateRoute path="/institution/edit" component={InstitutionEdit} />
            <PrivateRoute path="/institution" component={InstitutionShow} />

            <PrivateRoute path="/profile" component={Profile} />

            <Route path="/" component={Home} />
          </Switch>

          <Footer />
        </ScrollContext.Provider>
      </Wrapper>
    );
  }

  private onScroll = (yOffset?: number) => this.setState({ yOffset });

}

export default Blueprint;

const H = withRouter((props: RouteComponentProps & { phase?: number }) => (
  <Header phase={'/' !== props.location.pathname ? 1 : props.phase} />
));
