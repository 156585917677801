import { LanguageTag, ILanguageTagBlueprint } from './LanguageTag';

export class LocalCaption {
    static fromJSON(data: ILocalCaptionJSON) {
        return new LocalCaption(
            data.id,
            LanguageTag.build(data.locale),
            data.captions,
        );
    }

    constructor(
        readonly id: number,
        readonly locale: LanguageTag,
        readonly captions: Record<number, string>,
    ) {
    }
}

export interface ILocalCaptionJSON {
    readonly id: number;
    readonly locale: ILanguageTagBlueprint;
    readonly captions: Record<number, string>;
}
