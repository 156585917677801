import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Grid, Message } from 'semantic-ui-react';
import styled from 'styled-components';

import { Fragment } from '../../models/Fragment';
import { Kit } from '../../models/Kit';
import { KitRepository } from '../../repositories/KitRepository';

import provider from './provider.png';

import Layout from '../../Layout';
import Panel from '../../Panel';
import MyHelmet from '../widgets/MyHelmet';
import Item from './Item';
import Paginator, { PageComponentProps, withPage } from '../widgets/Paginator';

const KitItem = styled(Item)`
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  &:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const MarginPanel = styled(Panel)`
  img {
    display: block;
    max-width: 100%;
    margin: auto;
  }

  p {
    margin-top: 20px;
    line-height: 1.5;
    color: #666;
  }

  .ui.button {
    margin-top: 30px;
  }
`;

type P = PageComponentProps & {
  className?: string;
};

interface IKitIndexState {
  fragment: Fragment<Kit>;
  loading: boolean;
}

class KitIndex extends React.Component<P & { repo: KitRepository; }, IKitIndexState> {

  state = { fragment: new Fragment<Kit>(), loading: false };

  componentDidMount() {
    this.find();
  }

  componentDidUpdate(prevProps: P) {
    if (this.props.page !== prevProps.page) {
      this.find();
    }
  }

  render() {
    const { className } = this.props;
    const { fragment } = this.state;

    return (
      <Layout breadcrumb={[ 'kit.index' ]} className={className}>
        <MyHelmet title="nav.del" description="kit.meta" />

        <Message warning>
          <p><FormattedMessage id="services.notice" values={{ br: <br /> }} /></p>
        </Message>

        <Grid>
          <Panel width={10} header={<FormattedMessage id="kit.index" />}>
            <div>
              {fragment.data.map(k => (
                <KitItem kit={k} key={k.id}>
                  <Link to={`/kits/${k.id}/m/${k.title}`} className="ui primary button">
                    <FormattedMessage id="apply_now" />
                  </Link>
                </KitItem>
              ))}
            </div>

            <br />
            <Paginator fragment={fragment} />
          </Panel>

          <MarginPanel width={6} header={<FormattedMessage id="nav.become_provider" />}>
            <img src={provider} alt="" />

            <p>
              <FormattedMessage id="nav.become_provider.p" />
            </p>

            <Link to="/institution" className="ui fluid basic button">
              <FormattedMessage id="view" />
            </Link>
          </MarginPanel>
        </Grid>
      </Layout>
    );
  }

  private async find() {
    const { repo, page, pageSize } = this.props;
    const fragment = await repo.findAll(page, pageSize);

    this.setState({ fragment });
  }

}

export default withPage((props: P) => (
  <KitRepository.Context.Consumer>
    {repo => (
      <KitIndex repo={repo} {...props} />
    )}
  </KitRepository.Context.Consumer>
));
