import { Country, ICountryBlueprint } from './Country';
import { Delivery, IDeliveryBlueprint } from './Delivery';
import { IKitBlueprint, Kit } from './Kit';
import { TrialForm } from './TrialForm';

import moment from 'moment';

export class Trial extends TrialForm {
  public static build(data: ITrialBlueprint): Trial {
    return new Trial(
      data.id,
      Kit.build(data.kit),
      data.first_name,
      data.last_name,
      data.email,
      data.phone_number,
      data.institution,
      Country.build(data.country || ''),
      data.supervisor_first_name || '',
      data.supervisor_last_name || '',
      data.supervisor_title || '',
      data.supervisor_homepage || '',
      data.supervisor_email || '',
      data.supervisor_institution || '',
      data.consignee || '',
      data.shipping_email || '',
      data.shipping_phone || '',
      data.address || '',
       data.freight_company || '',
      data.fedex_account || '',
      data.postcode || '',
      data.sample_return || '',
      data.optional_service || '',
      data.synopsis,
      !!data.prepared,
      data.is_auth || 0,
      data.token,
      data.remark,
      moment(data.created_at),
      (data.rejected_at && moment(data.rejected_at)) || undefined,
      data.ref_no,
      (data.deliveries || []).map(Delivery.build),
    );
  }

  constructor(
    public readonly id: number,
    public readonly kit: Kit,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    institution: string,
    public readonly country: Country,
    supervisorFirstName: string,
    supervisorLastName: string,
    supervisorTitle: string,
    supervisorHomepage: string,
    supervisorEmail: string,
    supervisorInstitution: string,
    consignee: string,
    shippingEmail: string,
    shippingPhone: string,
    address: string,
    freightCompany: string,
    fedexAccount: string,
    postcode: string,
    sampleReturn: string,
    optionalService: string,
    synopsis: string,
    prepared: boolean,
    isAuth: number,
    public readonly token: string,
    public readonly remark: string | undefined,
    public readonly createdAt: moment.Moment,
    public readonly rejectedAt: moment.Moment | undefined,
    public readonly refNo: string,
    public readonly deliveries: Delivery[],
  ) {
    super(firstName, lastName, email, phoneNumber, institution, country, supervisorFirstName, supervisorLastName, supervisorTitle, supervisorHomepage, supervisorEmail, supervisorInstitution, consignee, shippingEmail, shippingPhone, address, freightCompany, fedexAccount, postcode, sampleReturn, optionalService, synopsis, prepared, isAuth);
  }

  public patch<K extends keyof TrialForm | 'deliveries'>(key: K, value: Trial[K]) {
    const {
      id,
      kit,
      firstName,
      lastName,
      email,
      phoneNumber,
      institution,
      country,
      supervisorFirstName,
      supervisorLastName,
      supervisorTitle,
      supervisorHomepage,
      supervisorEmail,
      supervisorInstitution,
      consignee,
      shippingEmail,
      shippingPhone,
      address,
      freightCompany,
      fedexAccount,
      postcode,
      sampleReturn,
      optionalService,
      synopsis,
      prepared,
      isAuth,
      token,
      remark,
      createdAt,
      rejectedAt,
      refNo,
      deliveries,
    } = Object.assign({}, this, { [key]: value });

    return new Trial(
      id,
      kit,
      firstName,
      lastName,
      email,
      phoneNumber,
      institution,
      country,
      supervisorFirstName,
      supervisorLastName,
      supervisorTitle,
      supervisorHomepage,
      supervisorEmail,
      supervisorInstitution,
      consignee,
      shippingEmail,
      shippingPhone,
      address,
      freightCompany,
      fedexAccount,
      postcode,
      sampleReturn,
      optionalService,
      synopsis,
      prepared,
      isAuth,
      token,
      remark,
      createdAt,
      rejectedAt,
      refNo,
      deliveries,
    );
  }
}

export interface ITrialBlueprint {
  readonly id: number;
  readonly kit: IKitBlueprint;
  readonly first_name: string;
  readonly last_name: string;
  readonly email: string;
  readonly phone_number: string;
  readonly institution: string;
  readonly country: ICountryBlueprint;
  readonly supervisor_first_name?: string;
  readonly supervisor_last_name?: string;
  readonly supervisor_title?: string;
  readonly supervisor_homepage?: string;
  readonly supervisor_email?: string;
  readonly supervisor_institution?: string;
  readonly consignee?: string;
  readonly shipping_email?: string;
  readonly shipping_phone?: string;
  readonly address?: string;
  readonly freight_company?: string;
  readonly fedex_account?: string;
  readonly postcode?: string;
  readonly sample_return?: string;
  readonly optional_service?: string;
  readonly synopsis: string;
  readonly prepared?: boolean;
  readonly is_auth?: number;
  readonly token: string;
  readonly remark?: string;
  readonly created_at: string;
  readonly rejected_at?: string;
  readonly ref_no: string;
  readonly deliveries?: IDeliveryBlueprint[]
}
