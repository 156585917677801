import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import logo from '../../logo_inversed.png';

class Footer extends React.Component<{ className?: string }> {

  render() {
    return (
      <footer className={this.props.className}>
        <div>
          <Link to="/"><img src={logo} alt="DELopen" /></Link>

          <nav>
            <Link to="/_/kits">
              <FormattedMessage id="nav.apply_del" />
            </Link>
            <Link to="/about">
              <FormattedMessage id="nav.about" />
            </Link>
            <a href="mailto:support@del.org">
              <FormattedMessage id="nav.contact" />
            </a>
            <Link to="/terms">
              <FormattedMessage id="nav.terms" />
            </Link>
            <Link to="/privacy">
              <FormattedMessage id="nav.privacy" />
            </Link>
          </nav>

          <div className="copyright">&copy; DELopen 2018-2019 All Rights Reserved</div>
          </div>
      </footer>
    );
  }

}

export default styled(Footer)`
  height: 200px;

  & > div {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;
    background-color: #4a5164;
    overflow: hidden;
  }

  img {
    display: block;
    width: 145px;
    height: 36px;
    margin: 40px auto 14px;
  }

  nav {
    display: flex;
    justify-content: center;

    a {
      margin: 0 20px;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.5);

      &:hover {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .copyright {
    height: 50px;
    padding: 15px 0;
    margin-top: 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 12px;
    text-align: center;
    color: rgba(255, 255, 255, 0.31);
  }
`;
