import React, { Component } from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Form, Grid, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

import URI from 'urijs';

import { IAppState } from '../../AppState';
import { Kit } from '../../models/Kit';
import { Subscriber } from '../../models/Subscriber';
import { TrialForm } from '../../models/TrialForm';
import { KitRepository } from '../../repositories/KitRepository';
import { TrialRepository } from '../../repositories/TrialRepository';

import Layout from '../../PrivateLayout';
import Panel from '../../Panel';
import MyHelmet from '../widgets/MyHelmet';
import InstitutionPanel from '../kit/InstitutionPanel';

type P = RouteComponentProps<{ kitId: string } | { id: string; }> & InjectedIntlProps & {
  className?: string;
};

interface ITrialEditState {
  kit?: Kit;
  form?: TrialForm;
  loading: boolean;
}

class TrialEdit extends Component<P & {
  subscriber?: Subscriber;
  repo: TrialRepository;
  kRepo: KitRepository;
}, ITrialEditState> {

  state: ITrialEditState = { loading: false };


  componentDidMount() {
    this.find();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps: P & { subscriber?: Subscriber }) {
    if ((this.props.match.params as { id: string }).id !== (prevProps.match.params as { id: string }).id
        || (this.props.match.params as { kitId: string }).kitId !== (prevProps.match.params as { kitId: string }).kitId
        || this.props.subscriber !== prevProps.subscriber) {
      this.find();
    }
  }

  render() {
    const { className, match: { params }, intl: { formatMessage } } = this.props;
    const { kit } = this.state;
    const loading = this.state.loading || !this.state.form;
    const form = this.state.form || new TrialForm();
    const title = 'authorization';

    return (
      <Layout breadcrumb={[ ['/trials', 'trial.yours'], title ]} className={className}>
        <MyHelmet title={title} />

        <Grid>
          <Panel width={10} header={<FormattedMessage id={title} />}>
            <Form loading={loading} onSubmit={this.submit}>
              <Segment>
                <p>{formatMessage({ id: 'trial.authorization_message1' })}</p>
                <p>{formatMessage({ id: 'trial.authorization_message2' })}</p>
              </Segment>
              <Form.Group widths='equal'>
                <Form.Button fluid primary type="submit">
                  <FormattedMessage id="trial.authorization_button1" />
                </Form.Button>
                <Link to="/trials" className="ui fluid basic button">
                  <FormattedMessage id="trial.authorization_button2" />
                </Link>
              </Form.Group>
            </Form>
          </Panel>

          <InstitutionPanel institution={kit && kit.subscriber.canonicalInstitution} />
        </Grid>
      </Layout>
    );
  }

  private find() {
    const { repo, match: { params } } = this.props;
    repo
    .find((params as { id: string }).id)
    .then(trial => this.setState({ kit: trial.kit, form: trial, loading: false }))
    ;
    this.setState({ loading: true });
  }

  private submit = (e: React.FormEvent<{}>) => {
    e.preventDefault();

    const { kit, form } = this.state;
    const { repo, match: { params }, subscriber, intl: { locale }, history } = this.props;
    const kID = (kit && String(kit.id)) || (params as { kitId: string }).kitId;
    const isAuth = 3;

    repo.
      persistAuth(form as TrialForm, isAuth, locale, kID, (params as { id: string }).id)   
      .then(function(response) { 
        repo.persistHitsAuth(subscriber, response.token, (params as { id: string }).id);
        history.push(String(URI(`/kits/${kID}/m/${kit ? kit.title : 'na'}`).query({ 'success': 1 })));
      })
      .catch(() => this.setState({ loading: false }))
    ;
    this.setState({ loading: true });
  }

}

export default styled(connect(
  ({ subscriber }: IAppState) => ({ subscriber }),
)(injectIntl((props: P) => (
  <KitRepository.Context.Consumer>
    {kRepo => (
      <TrialRepository.Context.Consumer>
        {repo => (
          <TrialEdit repo={repo} kRepo={kRepo} {...props} />
        )}
      </TrialRepository.Context.Consumer>
    )}
  </KitRepository.Context.Consumer>
))))`
  small {
      display:block;
      position:relative;
      margin-top:-1em;
  }
`;

