import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { Adviser } from '../../models/Adviser';
import { AdviserRepository } from '../../repositories/AdviserRepository';

import AdviserItem from './Item';
import Layout from '../../Layout';
import MyHelmet from '../widgets/MyHelmet';

const MyHeader = styled(Header)`
  margin: calc(60px - 2rem) 0 0 !important;
  font-size: ${34 / 16}rem;
  line-height: ${40 / 34}em;
  text-align: center;
`;

const Prelude = styled.p`
  margin: 20px auto 60px !important;
  font-size: ${18 / 16}rem;
  line-height: 1.5;
  text-align: center;
  color: #999;
`;

const Item = styled(AdviserItem)`
  margin-top: 30px;
`;

interface P {
  className?: string;
}

interface IAdviserState {
  advisers: Adviser[];
}

class AdviserIndex extends Component<P & { repo: AdviserRepository; }, IAdviserState> {

  public state: IAdviserState = { advisers: [] };

  public componentDidMount() {
    this
      .props
      .repo
      .findAll()
      .then(advisers => this.setState({ advisers }))
    ;
  }

  public render() {
    const { className } = this.props;
    const { advisers } = this.state;

    return (
      <Layout breadcrumb={[ 'adviser.index' ]} className={className}>
        <MyHelmet title="adviser.index" description="adviser.meta" />

        <Grid>
          <Grid.Column className="adviser-index">
            <MyHeader as="h1">
              <FormattedMessage id="adviser.index" />
            </MyHeader>

            <Prelude>
              <FormattedMessage id="adviser.prelude" values={{ br: <br /> }} />
            </Prelude>

            {advisers.map(a =>
              <Item adviser={a} key={a.id} />
            )}
          </Grid.Column>
        </Grid>
      </Layout>
    );
  }

}

export default (props: P) => (
  <AdviserRepository.Context.Consumer>
    {repo => (
      <AdviserIndex repo={repo} {...props} />
    )}
  </AdviserRepository.Context.Consumer>
);
