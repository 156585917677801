import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';

import { Kit } from '../../models/Kit';

import ScoopImage from '../scoop/Image';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0;
  font-size: 1rem;

  img, .ui.placeholder {
    display: block;
    width: 120px;
    height: 120px;
  }
`;

const Content = styled.div`
  flex: 1;
  margin-left: 30px;
  overflow: hidden;

  h4 + small {
    display: block;
    margin-top: 5px;
    font-size: 1rem;
    color: #999;
  }
`;

const Extra = styled.div`
  margin-left: 30px;

  .ui.button {
    margin-right: 0;
  }

  &:empty {
    display: none;
  }
`;

const Header = styled.h4`
  margin: 0;
  font-size: ${18 / 16}rem;
  line-height: ${24 / 18}em;
  color: #333;

  small {
    margin-left: 10px;
    font-size: 100%;
    font-weight: normal;
    color: #999;
  }
`;

const Legend = styled.p`
  margin: 1rem 0 0;
  font-size: 1rem;
  line-height: 1.5em;
  color: #666;

  b {
    color: #333;
  }
`;

const Synopsis = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #999;
`;

class KitItem extends Component<{ className?: string; kit: Kit; partial?: boolean; your?: boolean; }> {

  render() {
    const { className, kit, partial, your } = this.props;

    return (
      <Wrapper className={className}>
        {(kit.image && (
          <ScoopImage scoop={kit.image} />
        )) || (
          <Placeholder>
            <Placeholder.Image />
          </Placeholder>
        )}
        {(your && (
          <Content>
            <Header>
              {kit.title}
              <small>[{kit.articleNo}]</small>
            </Header>
            <Legend>
              <b>
                <FormattedMessage id="kit.volume_" />
                {kit.volume || (
                  <FormattedMessage id="na" />
                )}
              </b>
            </Legend>
            <Synopsis>{kit.synopsis}</Synopsis>
          </Content>
        )) || (partial && (
          <Content>
            <Header>
              {kit.title}
            </Header>
            <small>[{kit.articleNo}]</small>
            <Legend>
              <FormattedMessage id="kit.volume_" />
              {kit.volume || (
                <FormattedMessage id="na" />
              )}
            </Legend>
          </Content>
        )) || (
          <Content>
            <Header>
              {kit.title}
            </Header>
            <Legend>
              {kit.subscriber.i9n}
            </Legend>
            <Synopsis>{kit.synopsis}</Synopsis>
          </Content>
        )}
        <Extra>
          {this.props.children}
        </Extra>
      </Wrapper>
    );
  }

}

export default KitItem;
