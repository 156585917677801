import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Header, Modal } from 'semantic-ui-react';

import { Delivery } from '../../models/Delivery';

class TrackingModal extends React.Component<{
  delivery: Delivery;
  onClose: () => void;
}> {

  public render() {
    const { delivery, onClose } = this.props;

    return (
      <Modal open onClose={onClose} style={{ width: '570px' }}>
        <Modal.Content>
          <Header as="h1">
            <FormattedMessage id="tracking" />
          </Header>

          <p style={{ fontWeight: 700, marginBottom: `${10 / 14}em` }}>{delivery.carrier.name}</p>
          <p>
            <FormattedMessage id="delivery.tracking_number_" />
            {delivery.trackingNumber}
          </p>
        </Modal.Content>
      </Modal>
    );
  }

}

export default TrackingModal;
