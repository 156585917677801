import { Fragment, arrow } from '../models/Fragment';
import { ILibraryJSON, Library } from '../models/Library';
import { IReactionJSON, Reaction } from '../models/Reaction';
import { IScriptJSON, Script } from '../models/Script';
import { IScriptKindJSON, ScriptKind } from '../models/ScriptKind';
import { ScriptQuery } from '../models/ScriptQuery';

import axios, { AxiosInstance } from 'axios';
import { createContext } from 'react';

export interface Summary<T> {
    hot: T[];
    na: T[];
    total: number;
}

export interface ICount<T> {
    year: string;
    kind: T;
    _all: number;
    hits: number;
    reactions: number;
    libraries: number;
};

export class ScriptRepository {

    public static readonly $ = new ScriptRepository(axios);
    public static readonly Context = createContext(ScriptRepository.$);

    constructor(private readonly a: AxiosInstance) {
    }

    public async findAll(page: number, size: number, query?: ScriptQuery): Promise<Fragment<Script>> {
        const { data } = await this.a.get<Fragment<IScriptJSON>>('/scripts', { params: { page, size, ...query } });

        return Fragment.build(data, Script.build);
    }

    public async findReactions(page: number, size: number, query?: ScriptQuery): Promise<Fragment<Reaction>> {
        const { data } = await this.a.get<Fragment<IReactionJSON>>('/reactions', { params: { page, size, ...query } });

        return Fragment.build(data, Reaction.build);
    }

    public async findLibraries(page: number, size: number, query?: ScriptQuery): Promise<Fragment<Library>> {
        const { data } = await this.a.get<Fragment<ILibraryJSON>>('/libraries', { params: { page, size, ...query } });

        return Fragment.build(data, Library.build);
    }

    public async count(): Promise<Array<ICount<ScriptKind>>> {
        const { data } = await this.a.get<Array<ICount<IScriptKindJSON>>>('/scripts/_count');

        return data.map(it => arrow(it, 'kind', ScriptKind.bless));
    }

    public async summary(): Promise<Summary<Script>> {
        function f(data: IScriptJSON[]) { return data.map(Script.build) };
        const { data } = await this.a.get<Summary<IScriptJSON>>('/scripts/summary');

        return arrow(arrow(data, 'hot', f), 'na', f);
    }

    public async find(id: number | string): Promise<Script> {
        const { data } = await this.a.get<IScriptJSON>(`/scripts/${id}`);

        return Script.build(data);
    }

}
