import { Footnote, IFootnotJSON } from './Footnote';
import { Moment } from 'moment';

export abstract class AbstractField {
    constructor(
        readonly permanentID: string,
        readonly serialNumber: number,
        readonly required: boolean,
        readonly footnotes: Footnote[],
        readonly createdAt: Moment,
    ) {
    }
}

export interface IAbstractFieldJSON {
    readonly permanent_id: string;
    readonly serial_number: number;
    readonly required: boolean;
    readonly footnotes?: IFootnotJSON[];
    readonly created_at: string;
}
