import React, { Component, Fragment } from 'react';

export interface P {
  locale?: string;
}

class Trans extends Component<P> {

  render() {
    return (
      <Fragment>
        {this.props.children}
      </Fragment>
    );
  }

}

export default Trans;
