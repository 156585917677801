import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Header, Loader, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { last } from 'lodash';

import { Fragment } from '../../models/Fragment';
import { Reaction } from '../../models/Reaction';
import { ScriptQuery } from '../../models/ScriptQuery';
import { ScriptRepository } from '../../repositories/ScriptRepository';

import Layout from '../../Layout';
import ScoopImage from '../scoop/Image';
import MyHelmet from '../widgets/MyHelmet';
import { PageComponentProps, withPage } from '../widgets/Paginator';
import NeoPager from './NeoPager';
import Search from './Search';
import Sort from './Sort';
import chem from './chem_s.svg';
import { ReactionMeta } from '../../models/ReactionMeta';

const DefaultSort = 'abc';

type P = RouteComponentProps & PageComponentProps & {
};

interface GroupedReactions {
  meta: ReactionMeta;
  reactions: Array<[number, Reaction]>;
}

interface S {
  fragment?: Fragment<Reaction>;
  groups: GroupedReactions[];
  total: number;
}

class ReactionIndex extends Component<P & { repo: ScriptRepository; }, S> {

  public state: S = { groups: [], total: 0 };

  componentDidMount() {
    this.props.repo.count().then(cs => this.setState({ total: cs.reduce((n, c) => n + c.reactions, 0) }));
    this.find();
  }

  componentDidUpdate({ location: { search } }: P) {
    if (this.props.location.search !== search) {
      this.find();
    }
  }

  render() {
    const { fragment, groups, total } = this.state;
    const query = ScriptQuery.build(this.props.location.search);

    return (
      <Layout wide breadcrumb={[['/scripts/_', 'nav.scripts.dashboard'], 'nav.scripts.chem']}>
        <MyHelmet title="nav.scripts" />

        <H1 as="h1">
          <FormattedMessage id="script.chem">
            {h1 => (
              <span>
                <img src={chem} alt="" />
                {h1}
              </span>
            )}
          </FormattedMessage>
          <Header.Subheader>
            {total} DNA-compatible Reactions were collected
          </Header.Subheader>
        </H1>

        <Search placeholder="script.reaction_placeholder" action="/reactions" />

        <Sheet>
          <Status fragment={fragment} />

          {!fragment ? (
            <Loader active content="Loading" />
          ) : (
            <Table>
              <thead>
                <tr>
                  <th className="refno"><FormattedMessage id="reaction.refno" /></th>
                  <th className="name"><FormattedMessage id="reaction.name" /></th>
                  <th className="scheme"><FormattedMessage id="reaction.scheme" /></th>
                  <th className="kinds"><FormattedMessage id="reaction.kinds" /></th>
                  <th className="conditions"><FormattedMessage id="reaction.conditions" /></th>
                  <th className="usage"><FormattedMessage id="reaction.usage" /></th>
                  <th className="pages"><FormattedMessage id="reaction.pages" /></th>
                  <th className="source"><FormattedMessage id="script.source" /></th>
                  <th className="institution"><FormattedMessage id="script.institution" /></th>
                </tr>
              </thead>

              <tbody>
                {groups.map(g => (
                  <React.Fragment key={g.meta.id}>
                    {g.reactions.map(([ j, r ], i) => (
                      <tr className={i < g.reactions.length - 1 ? '' : 'last'} key={r.id}>
                        <td className="refno">{j}</td>
                        <td className="name" dangerouslySetInnerHTML={r.highlight('name')} />
                        <td className="scheme">
                          {r.meta.scheme && (
                            <Zoom trigger={<Image scoop={r.meta.scheme} />}>
                              <Image scoop={r.meta.scheme} />
                            </Zoom>
                          )}
                        </td>
                        <td className="kinds">
                          {r.highlight('kinds').__html.split('\n').map((__html, i) => (
                            <React.Fragment key={i}>
                              {i > 0 && <br />}
                              <span dangerouslySetInnerHTML={{ __html }} />
                            </React.Fragment>
                          ))}
                        </td>
                        <td className="conditions">
                          {r.highlight('conditions').__html.split('\n').map((__html, i) => (
                            <React.Fragment key={i}>
                              {i > 0 && <br />}
                              <span dangerouslySetInnerHTML={{ __html }} />
                            </React.Fragment>
                          ))}
                        </td>
                        <td className="usage" dangerouslySetInnerHTML={r.highlight('usage')} />
                        <td className="pages">{r.pages}</td>
                        <td className="source">
                          {r.script && (
                            <Link to={`/scripts/${r.script.id}`}>
                              {r.script.source}
                            </Link>
                          )}
                        </td>
                        <td className="institution" dangerouslySetInnerHTML={r.highlight('institutions')} />
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          )}

          {fragment && (
            <NP total={fragment.total}>
              <input type="hidden" name="q" value={query.q} />
              <input type="hidden" name="sort" value={query.sort} />
            </NP>
          )}
        </Sheet>
      </Layout>
    );
  }

  async find() {
    this.setState({ fragment: undefined });

    const { page, pageSize, location: { search }, repo } = this.props;
    const fragment = await repo.findReactions(page, pageSize, ScriptQuery.build(search).defaultSort(DefaultSort));
    const groups = fragment.data.reduce<GroupedReactions[]>(
      (gs, r) => {
        const g = last(gs);
        const n = (g && just(last(g.reactions))[0]) || 0;
        const gs2 = r.meta.id === (g && g.meta.id) ? gs : gs.concat({ meta: r.meta, reactions: [] });
        gs2[gs2.length - 1].reactions.push([1 + n, r]);

        return gs2;
      },
      []
    );

    this.setState({ fragment, groups });
  }

}

export default withPage((props: P) => (
  <ScriptRepository.Context.Consumer>
    {repo => (
      <ReactionIndex repo={repo} {...props} />
    )}
  </ScriptRepository.Context.Consumer>
));

function just<T>(x: T | undefined) {
  return x as T;
}

const H1 = styled(Header)`
  margin: 40px 0;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  color: #333;
  text-align: center;

  & > span {
    padding-left: 65px;
    position: relative;

    img {
      width: 60px;
      height: 54px;
      margin-top: -27px;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }

  .sub.header {
    margin-top: 7px;
    font-size: 20px;
    font-weight: normal;
    line-height: 24px;
    color: #999;
  }
`;

const Sheet = styled.div`
  min-height: 400px;
  padding: 40px;
  background: #fff;
  margin-top: 30px;
  position: relative;

  &::after {
    content: "";
    display: block;
    clear: both;
  }
`;

const Status = styled(withRouter(withPage((props: RouteComponentProps & PageComponentProps & {
  className?: string;
  fragment?: Fragment<Reaction>;
}) => {
  const { fragment, page, pageSize, location: { search } } = props;
  const query = ScriptQuery.build(search).defaultSort(DefaultSort);
  const start = pageSize * (page - 1);

  return (
    <div className={props.className}>
      <Sort alphabet query={query} />
      <FormattedMessage id="script.search_results" tagName="h1" />

      {fragment && (
        <div>
          <FormattedMessage id="pager.items" />
          {': '}
          {fragment.total > 0 ? `${start + 1}-${start + fragment.data.length} of ${fragment.total}` : 0}

          <NeoPager total={fragment.total}>
            <input type="hidden" name="q" value={query.q} />
          </NeoPager>
        </div>
      )}
    </div>
  );
})))`
  margin-bottom: 60px;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;

  h1 {
    margin-top: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-top: 40px;
  
  em {
    font-style: normal;
    background: #ff0;
  }

  th, td {
    padding: 0 0 20px 20px;
    font-size: 14px;
    text-align: left;
    vertical-align: top;

    &:first-child {
      padding-left: 0;
    }
  }

  th {
    padding-bottom: 60px;
    font-size: 14px !important;
    font-weight: 600;
    line-height: 20px;
  }

  td {
    line-height: 24px;
  }

  .last td {
    padding-bottom: 40px;
  }

  .refno {
    width: 40px;
  }

  .name {
    width: 140px;
  }

  .scheme {
    width: 230px;
  }

  .kinds {
    width: 130px;
    font-size: 13px;
  }

  .conditions {
    width: 250px;
    font-size: 13px;
  }

  .usage {
    width: 120px;
    font-size: 13px;
  }

  .pages {
    width: 90px;
  }

  .source {
    width: 140px;
    font-size: 13px;

    a {
      word-break: break-all;
      text-decoration: underline;
    }
  }

  .institution {
    width: 120px;
  }
`;

const Image = styled(ScoopImage)`
  max-width: 100%;
`;

const Zoom = styled(Modal)`
  width: auto !important;
  max-width: 1000px;
`;

const NP = styled(NeoPager)`
  margin-top: 20px;
`;
