export class Hotword {
  static build(data: HotwordJSON) {
    return new Hotword(data.id, data.name);
  }

  constructor(readonly id: number, readonly name: string) {
  }
}

export interface HotwordJSON {
  readonly id: number;
  readonly name: string;
}
