export class Carrier {
  public static build(data: ICarrierBlueprint) {
    return new Carrier(data.id, data.name, data.code);
  }

  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly code: string,
  ) {
  }
}

export interface ICarrierBlueprint {
  readonly id: number;
  readonly name: string;
  readonly code: string;
}
