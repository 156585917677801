import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

import about from './about.jpg';

import Page from './Page';
import MyHelmet from '../widgets/MyHelmet';
import SwitchLocale from '../../SwitchLocale';
import Trans from '../../Trans';

class About extends Component<{ className?: string; }> {

  render() {
    return (
      <Page loc="nav.about" className={this.props.className}>
        <MyHelmet title="nav.about" description="about.meta" />
        <Header as="h1">
          <FormattedMessage id="nav.about" />
        </Header>

        <img src={about} alt="" />

        <SwitchLocale>
          <Trans locale="zh-cn">
            <p>
              DEL技术出现之后，学术界与产业界都希望能够有一个公开的平台来分享、推广这一技术在新药研发领域的应用，但是由于涉及到知识产权等信息，产业界与学术界很难达成共识去进行深入的合作。通过一个中立的第三方平台，为知识产权的信息共享与保护提供一个有效的方法，无疑会对整个产业带来变革，改变这种困境。
            </p>

            <p>
              <b>
                DELopen 是由众多致力于使用DEL技术研发新药的学术界学者/机构，药明康德共同发起的促进DEL技术发展与应用的一个平台。
              </b>
            </p>

            <p>
              药明康德以及其它拥有DEL化合物库的企业或机构，通过平台向用户提供免费的DEL化合物库用于靶点的筛选，有意向使用DEL库进行靶点筛选的学术机构，通过平台进行申请。在平台上双方登记、注册基本的库信息、实验信息。库的结构信息与靶点的信息对于双方都是保密的。学术机构进行筛选后，将实验结果返还给库的所有方，库的所有方进行后续的 PCR / Sequencing，一旦发现有 positive 的结果，则化合物库的所有方与提供靶点的组织/机构进行进一步的沟通，就该试验结果的权益进行分配。
            </p>

            <p>
              DELopen 数据库平台，连接产业与学术研究机构，基于区块链技术，实现科研信息的有效分享以及知识产权的保护。使得致力于使用 DEL 技术研发新药的科研人员与机构能够通过这个平台，更加快捷的进行早期新药研发，并尽快的将早期研发的结果推进到商业化。
            </p>
          </Trans>

          <Trans>
            <p>
              With the emergence of DEL technology, researchers in both
              academia and industry are seeking an open platform to promote
              the application of this technology in the field of new drug
              development. However, it is difficult for industry and
              academia to reach a consensus to conduct in-depth cooperation
              primarily due to issues relating to intellectual property
              rights. Providing an effective method for information sharing
              and protection of intellectual property through a neutral
              third-party platform will undoubtedly bring changes to the
              whole industry.
            </p>

            <p>
              <b>
                The free DELopen library consists of compounds provided by
                suppliers for distribution through the platform.
              </b>
            </p>

            <p>
              Institutions interested in using the DELopen compound library
              for target screening will submit an application through the
              platform. Both suppliers and researchers register in DELopen
              and provide compound library information and experimental
              information on the platform. The structure information of the
              compound library and target information are confidential to
              both parties. After screening by the researcher, the
              experimental results should be returned to the owner of the
              compound library for subsequent PCR / Sequencing. Once positive
              results are found, all parties can communicate with each other
              for further collaboration.
            </p>

            <p>
              DELopen is a platform connecting industry and academic
              research institutions to realize effective sharing of
              scientific research information and protection of intellectual
              property. Through this platform, researchers and institutions
              engaged in the development of new drugs with DEL technology
              can more quickly conduct early new drug development and
              expedite the development of results to commercialization as
              soon as possible.
            </p>
          </Trans>
        </SwitchLocale>
      </Page>
    );
  }

}

export default styled(About)`
  h1 + img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 40px;
  }
`;
