import { DigestScoop } from './DigestScoop';
import { InstitutionForm } from './InstitutionForm';

import moment from 'moment';

export class Institution extends InstitutionForm {
  public static build(data: IInstitutionBlueprint): Institution {
    return new Institution(
      data.id,
      data.title,
      DigestScoop.build(data.image),
      data.url,
      data.synopsis,
      moment(data.created_at),
      (data.approved_at && moment(data.approved_at)) || undefined,
    );
  }

  constructor(
    public readonly id: number,
    title: string,
    public readonly image: DigestScoop,
    url: string,
    synopsis: string,
    public readonly createdAt: moment.Moment,
    public readonly approvedAt?: moment.Moment,
  ) {
    super(title, image, url, synopsis);
  }
}

export interface IInstitutionBlueprint {
  readonly id: number;
  readonly title: string;
  readonly image: string;
  readonly url: string;
  readonly synopsis: string;
  readonly created_at: string;
  readonly approved_at?: string;
}
