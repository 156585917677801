import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Header, Loader, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { Fragment } from '../../models/Fragment';
import { Script } from '../../models/Script';
import { ScriptQuery } from '../../models/ScriptQuery';
import { ScriptRepository } from '../../repositories/ScriptRepository';

import Layout from '../../Layout';
import ScoopImage from '../scoop/Image';
import MyHelmet from '../widgets/MyHelmet';
import { PageComponentProps, withPage } from '../widgets/Paginator';
import NeoPager from './NeoPager';
import Search from './Search';
import Sort from './Sort';
import hit from './hit_s.svg';

const DefaultSort = 'age';

type P = RouteComponentProps & PageComponentProps & {
};

interface S {
  fragment?: Fragment<Script>;
  total: number;
}

class HitIndex extends Component<P & { repo: ScriptRepository; }, S> {

  public state: S = { total: 0 };

  componentDidMount() {
    this.props.repo.count().then(cs => this.setState({ total: cs.reduce((n, c) => n + c.hits, 0) }));
    this.find();
  }

  componentDidUpdate({ location: { search } }: P) {
    if (this.props.location.search !== search) {
      this.find();
    }
  }

  render() {
    const { fragment, total } = this.state;
    const query = ScriptQuery.build(this.props.location.search);

    return (
      <Layout wide breadcrumb={[['/scripts/_', 'nav.scripts.dashboard'], 'nav.scripts.hit']}>
        <MyHelmet title="nav.scripts" />

        <H1 as="h1">
          <FormattedMessage id="script.hits">
            {h1 => (
              <span>
                <img src={hit} alt="" />
                {h1}
              </span>
            )}
          </FormattedMessage>
          <Header.Subheader>
            {total} hits were collected
          </Header.Subheader>
        </H1>

        <Search placeholder="script.hit_placeholder" action="/hits" />

        <Sheet>
          <Status fragment={fragment} />

          {!fragment ? (
            <Loader active content="Loading" />
          ) : (
            <Table>
              <thead>
                <tr>
                  <th className="seqno" />
                  <th className="source"><FormattedMessage id="script.source" /></th>
                  <th className="institution"><FormattedMessage id="script.institution" /></th>
                  <th className="library_size"><FormattedMessage id="script.library_size" /></th>
                  <th className="formula"><FormattedMessage id="script.formula" /></th>
                  <th className="target"><FormattedMessage id="script.target" /></th>
                  <th className="potency"><FormattedMessage id="script.potency" /></th>
                </tr>
              </thead>

              <tbody>
                {fragment.data.map((s, i) => (
                  <tr key={s.id}>
                    <td className="seqno">{1 + i}</td>
                    <td className="source">
                      <Link to={`/scripts/${s.id}`}>{s.source}</Link>
                    </td>
                    <td className="institution">{etc(s.institutions.map(i => i.name))}</td>
                    <td className="library_size">{s.hit && s.hit.librarySize}</td>
                    <td className="formula">
                      {s.hit && s.hit.formula && (
                        <Zoom trigger={<Image scoop={s.hit.formula} />}>
                          <Image scoop={s.hit.formula} />
                        </Zoom>
                      )}
                    </td>
                    <td className="target">{s.hit && s.hit.target}</td>
                    <td className="potency">{s.hit && s.hit.potency}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {fragment && (
            <NP total={fragment.total}>
              <input type="hidden" name="q" value={query.q} />
              <input type="hidden" name="sort" value={query.sort} />
            </NP>
          )}
        </Sheet>
      </Layout>
    );
  }

  async find() {
    this.setState({ fragment: undefined });

    const { page, pageSize, location: { search }, repo } = this.props;
    const fragment = await repo.findAll(page, pageSize, ScriptQuery.build(search).amend('hit', true).defaultSort(DefaultSort));

    this.setState({ fragment });
  }

}

export default withPage((props: P) => (
  <ScriptRepository.Context.Consumer>
    {repo => (
      <HitIndex repo={repo} {...props} />
    )}
  </ScriptRepository.Context.Consumer>
));

function etc(s: string[]) {
  if (s.length > 1) {
    return `${s[0]} etc.`;
  }

  return s[0];
}

const H1 = styled(Header)`
  margin: 40px 0;
  font-size: 34px;
  font-weight: 600;
  line-height: 40px;
  color: #333;
  text-align: center;

  & > span {
    padding-left: 65px;
    position: relative;

    img {
      width: 60px;
      height: 54px;
      margin-top: -27px;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }

  .sub.header {
    margin-top: 7px;
    font-size: 20px;
    font-weight: normal;
    line-height: 24px;
    color: #999;
  }
`;

const Sheet = styled.div`
  min-height: 400px;
  padding: 40px;
  background: #fff;
  margin-top: 30px;
  position: relative;

  &::after {
    content: "";
    display: block;
    clear: both;
  }
`;

const Status = styled(withRouter(withPage((props: RouteComponentProps & PageComponentProps & {
  className?: string;
  fragment?: Fragment<Script>;
}) => {
  const { fragment, page, pageSize, location: { search } } = props;
  const query = ScriptQuery.build(search);
  const start = pageSize * (page - 1);

  return (
    <div className={props.className}>
      <Sort query={query.defaultSort(DefaultSort)} />
      <FormattedMessage id="script.search_results" tagName="h1" />

      {fragment && (
        <div>
          <FormattedMessage id="pager.items" />
          {': '}
          {fragment.total > 0 ? `${start + 1}-${start + fragment.data.length} of ${fragment.total}` : 0}

          <NeoPager total={fragment.total}>
            <input type="hidden" name="q" value={query.q} />
            <input type="hidden" name="sort" value={query.sort} />
          </NeoPager>
        </div>
      )}
    </div>
  );
})))`
  margin-bottom: 60px;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;

  h1 {
    margin-top: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-top: 40px;
  font-size: 14px;

  th, td {
    padding: 0 0 40px 30px;
    text-align: left;
    vertical-align: top;

    &:first-child {
      padding-left: 0;
    }
  }

  th {
    padding-bottom: 60px;
    font-weight: 600;
    line-height: 20px;
  }

  td {
    line-height: 24px;
  }

  .seqno {
    width: 50px;
  }

  .source {
    width: 220px;
  }

  .institution {
    width: 230px;
  }

  .library_size {
    width: 140px;
  }

  .formula {
    width: 260px;
  }

  .target {
    width: 160px;
  }

  .potency {
    width: 200px;
  }
`;

const Image = styled(ScoopImage)`
  max-width: 100%;
`;

const Zoom = styled(Modal)`
  width: auto !important;
  max-width: 1000px;
`;

const NP = styled(NeoPager)`
  margin-top: 20px;
`;
