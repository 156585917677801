import * as React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Form, Header, InputOnChangeData, Message } from 'semantic-ui-react';
import URI from 'urijs';

import { SecurityRepository } from '../../repositories/SecurityRepository';

import Flyer from '../../Flyer';
import MyHelmet from '../widgets/MyHelmet';

type P = RouteComponentProps<void> & InjectedIntlProps;

interface IPasswordState {
  password: string;
  retypedPassword: string;
  loading: boolean;
}

interface Search {
  action?: string;
  proof?: string;
}

class Password extends React.Component<P & { repo: SecurityRepository }, IPasswordState> {

  public state = { password: '', retypedPassword: '', loading: false };

  public render() {
    const { formatMessage } = this.props.intl;
    const { password, retypedPassword, loading } = this.state;
    const params = URI.parseQuery(this.props.location.search) as Search;

    return (
      <Flyer>
        <MyHelmet title="security.set_password" />

        <Header as="h1" color="blue">
          <FormattedMessage id="security.set_password" />
        </Header>

        <Form error loading={loading} onSubmit={this.submit}>
          <Form.Input
            required
            type="password"
            label={formatMessage({ id: 'security.password' })}
            value={password}
            onChange={this.setPassword}
          />
          {
            password && !SecurityRepository.isStrongPassword(password) && (
              <Message error size="small">
                <Message.Content>
                  <FormattedMessage id="security.strong_password" values={{ min: 8, max: 20 }} />
                </Message.Content>
              </Message>
            )
          }
          <Form.Input
            required
            type="password"
            label={formatMessage({ id: 'security.retype_password' })}
            value={retypedPassword}
            onChange={this.setRetypedPassword}
          />
          {
            retypedPassword && retypedPassword !== password && (
              <Message error size="small">
                <Message.Content>
                  <FormattedMessage id="security.password_mismatch" />
                </Message.Content>
              </Message>
            )
          }
          <p className="footnote">
            <FormattedMessage
              id="security.accept"
              values={{
                terms: (
                  <Link to="/terms" target="_blank">
                    <FormattedMessage id="nav.terms_of_use" />
                  </Link>
                ),
                privacy: (
                  <Link to="/privacy" target="_blank">
                    <FormattedMessage id="nav.privacy_policy" />
                  </Link>
                )
              }}
            />
          </p>
          <Form.Button fluid primary type="submit">
            <FormattedMessage id={('activate' === params.action || 'apply' === params.action) ? 'security.activate' : 'security.reset'} />
          </Form.Button>
        </Form>
      </Flyer>
    );
  }

  private setPassword = (_: React.SyntheticEvent<{}>, { value }: InputOnChangeData) =>
    this.setState({ password: value });

  private setRetypedPassword = (_: React.SyntheticEvent<{}>, { value }: InputOnChangeData) =>
    this.setState({ retypedPassword: value });

  private submit = (e: React.FormEvent<{}>) => {
    e.preventDefault();

    const { password, retypedPassword } = this.state;
    if (!SecurityRepository.isStrongPassword(password) || retypedPassword !== password) {
      return;
    }

    const { repo, location, history } = this.props;
    const search = URI.parseQuery(location.search) as Search;
    repo
      .password(password, search.proof || '')
      .then(() => history.push(URI('/sign-in').query({ 'action': search.action }).toString()))
      .catch(() => history.push('/proof-expired'))
    ;

    this.setState({ loading: true });
  }

}

export default injectIntl((props: P) => (
  <SecurityRepository.Context.Consumer>
    {repo => (
      <Password repo={repo} {...props} />
    )}
  </SecurityRepository.Context.Consumer>
));
