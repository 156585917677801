import { DigestScoop } from '../models/DigestScoop'
import { FileScoop } from '../models/FileScoop';

import axios, { AxiosInstance } from 'axios';
import * as React from 'react';

export interface ImageFilter {
    mode: 'crop' | 'fill';
    width: number;
    height: number;
}

export class ScoopRepository {

  public static readonly $ = new ScoopRepository(axios);
  public static readonly Context = React.createContext(ScoopRepository.$);

  constructor(private a: AxiosInstance) {
  }

  public find(scoop: DigestScoop | FileScoop, filter?: ImageFilter): [string, (url: string) => void] {
    if (scoop instanceof DigestScoop) {
      const v = ['/fs'];
      if (filter) {
        v.push(filter.mode, `${filter.width},${filter.height}`);
      }
      v.push(scoop.digest.slice(0, 2), scoop.digest.slice(2, 4), scoop.digest);

      return [v.join('/'), () => undefined];
    }

    return [URL.createObjectURL(scoop.file), URL.revokeObjectURL];
  }

  public async persist(scoop: DigestScoop | FileScoop): Promise<DigestScoop> {
    if (scoop instanceof DigestScoop) {
      return scoop;
    }

    const formData = new FormData();
    formData.append('file[]', scoop.file);

    const { data } = await this.a.post<{ filenames: string[] }>('/fs/', formData, { baseURL: '' });

    return new DigestScoop(data.filenames[0]);
  }

}
