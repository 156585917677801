import * as React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, Grid, Menu } from 'semantic-ui-react';
import styled from 'styled-components';

import { IAppState } from '../../AppState';
import { Subscriber } from '../../models/Subscriber';

import logo from '../../logo.png';
import logoInversed from '../../logo_inversed.png';
import lang from './lang.png';
import langInversed from './lang_inversed.png';
import user from './user.png';
import userInversed from './user_inversed.png';

type P = InjectedIntlProps & {
  className?: string;
  phase?: number;
}

class Header extends React.Component<P & {
  subscriber: Subscriber | undefined | null;
  toggleLocale: () => void;
}> {

  public static defaultProps = { phase: 1 };

  public render() {
    const { className, subscriber } = this.props;

    return (
      <header className={className}>
        <Helmet>
          <style>
            {`#root { padding-top: 80px; }`}
          </style>
        </Helmet>

        <Grid container>
          <Grid.Column>
            <nav>
              <a href="/" className="logo">
                <img src={logoInversed} alt="DELopen" />
                <img src={logo} alt="DELopen" className="p" />
              </a>

              <Link to="/">
                <FormattedMessage id="nav.home" />
              </Link>
              <Link to="/scripts/_">
                <FormattedMessage id="nav.scripts" />
              </Link>
              <Link to="/_/kits">
                <FormattedMessage id="nav.del" />
              </Link>
              <Link to="/posts">
                <FormattedMessage id="nav.news" />
              </Link>
              <Link to="/faqs">
                <FormattedMessage id="nav.faqs" />
              </Link>
              <Link to="/service">
                <FormattedMessage id="nav.services" />
              </Link>

              <Menu.Menu position="right">
                <Link to="#" onClick={this.toggleLocale}>
                  <img src={langInversed} alt="" />
                  <img src={lang} alt="" className="p" />
                  <FormattedMessage id="nav.language" />
                </Link>
                {
                  !subscriber ? (
                    <Link to="/sign-in">
                      <img src={userInversed} alt="" />
                      <img src={user} alt="" className="p" />
                      <FormattedMessage id="security.login" />
                    </Link>
                  ) : (
                    <Dropdown
                      floating
                      direction="left"
                      icon={null}
                      trigger={
                        <span className="account">
                          <img src={userInversed} alt="" />
                          <img src={user} alt="" className="p" />
                          <FormattedMessage id="nav.account" />
                        </span>
                      }
                    >
                      <Dropdown.Menu>
                        <Link to="/profile" className="item">
                          <FormattedMessage id="nav.profile" />
                        </Link>
                        {
                          subscriber.hasRole('provider') && (
                            <Link to="/kits" className="item">
                              <FormattedMessage id="kit.yours" />
                            </Link>
                          )
                        }
                        <Link to="/trials" className="item">
                          <FormattedMessage id="trial.yours" />
                        </Link>
                        {
                          subscriber.hasRole('provider') && (
                            <Link to="/_/trials" className="item">
                              <FormattedMessage id="trial.index" />
                            </Link>
                          )
                        }
                        {
                          subscriber.hasRole('provider') && (
                            <Link to="/sign-up?source=apply" className="item">
                              <FormattedMessage id="security.customer_register" />
                            </Link>
                          )
                        }
                        <Link to="/sign-out" className="item">
                          <FormattedMessage id="security.logout" />
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  )
                }
              </Menu.Menu>
            </nav>
          </Grid.Column>
        </Grid>
      </header>
    );
  }

  private toggleLocale = (e: React.SyntheticEvent) => {
    e.preventDefault();

    this.props.toggleLocale();
  }

}

function gray(a: number, b: number, r: number) {
  const n = Math.round(a + (b - a) * r);

  return `rgb(${n}, ${n}, ${n})`;
}

export default styled(connect(
  ({ subscriber }: IAppState) => ({ subscriber }),
  dispatch => ({
    toggleLocale: () => dispatch({ type: 'TOGGLE_LOCALE' }),
  })
)(injectIntl(Header)))`
  width: 100%;
  min-width: 1200px;
  height: 80px;
  padding: 25px 0;
  background: rgba(255, 255, 255, ${props => props.phase !== undefined ? props.phase : 1});
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, ${props => 0.1 * (props.phase !== undefined ? props.phase : 1)});
  position: fixed;
  top: 0;
  z-index: 200;

  img.p {
    opacity: ${props => props.phase !== undefined ? props.phase : 1};
  }

  nav {
    display: flex;

    a, .ui.dropdown, .account {
      display: flex;
      align-items: center;
      color: ${props => gray(0xff, 0x66, props.phase !== undefined ? props.phase : 1)};

      &:hover {
        color: ${props => gray(0xff, 0x66, props.phase !== undefined ? props.phase : 1)};
      }
    }

    & > a {
      margin-left: 50px;
    }

    .right {
      display: flex;
      margin-left: auto;

      & > a, .ui.dropdown {
        margin-left: 40px;
      }

      & > a, .account {
        position: relative;

        &::before {
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }

        img {
          position: absolute;
          left: 0;
          top: 50%;
          width: 16px;
          height: 16px;
          margin-top: -8px;
        }
      }

      .ui.dropdown .item {
        text-align: right;
        color: #333 !important;
      }
    }
  }

  .logo {
    display: block;
    width: 121px;
    height: 30px;
    margin: 0 50px 0 0;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;
